const zIndex = {
  clickBG: -10,
  searchPartSingleInputItem: 10,
  mapMarker: 2019,
  busMarker: 2020,
  timeTable: 2021,
  clogMessagePart: 2022,
  navBar: 3030,
  popup: 4040,
};

export default zIndex;