import { gql } from 'apollo-boost';
import { EdgesData, ID } from 'all-common-types';
import RouteFragment from 'constants/api-fragments';
import { Stations, SingleProvider } from 'bus-common-types';

export const QUERY_STATIONS_NAMES_BY_VALUE = gql`
  query QUERY_STATIONS_NAMES_BY_VALUE($station: String!, $lang: String!) {
    stationsByName(name: $station, lang: $lang) {
      id
      name
      lat
      lon
      county
      town
    }
  }`;
export interface SingleQueryStationByName {
  id: ID
  name: string
  lat: number
  lon: number
  county: string
  town: string
}
export interface QueryStationsNamesByValueData {
  stationsByName: SingleQueryStationByName[]
}

export const QUERY_ROUTE_STATIONS = gql`
  query QUERY_ROUTE_STATIONS($routeId: Int!, $lang: String!) {
    route(xno: $routeId, lang: $lang) {
      stations {
        ...stationsInfo
      }
    }
  }
  ${RouteFragment.fragment.stationsInfo}`;
export interface QueriedRouteStations {
  route: {
    stations: Stations
  }
}

export const QUERY_BUS_PROVIDERS = gql`
  query QUERY_BUS_PROVIDERS($lang: String!) {
    providers(lang: $lang) {
      ...providerInfo
    }
  }
  ${RouteFragment.fragment.providersInfo}`;
export interface QueryBusProviders {
  providers: EdgesData<SingleProvider>
}

