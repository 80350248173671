import React from 'react';
import { MapPartWithSearchTypeProps } from './types';
import { MapPartContainerWithCtx } from './MapPartContainer';
import { MapStateToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import ContextStore, { CollinearRoutesState } from 'components/collinear-routes/constants/context';
import { connectCtx } from 'react-function-helpers';
import StopSearchMapPartWithCtx from '../StopSearchMapPart/StopSearchMapPart';

const MapPartWithSearchType = (props: MapPartWithSearchTypeProps) => {
  const { searchType } = props;
  return (
    <>
      {searchType === 'from-to-search' && (
        <MapPartContainerWithCtx />
      )}
      {searchType === 'stop-search' && (
        <StopSearchMapPartWithCtx />
      )}
    </>
  );
};

interface OwnProps {}

const mapStateToProps: MapStateToProps<CollinearRoutesState, OwnProps, MapPartWithSearchTypeProps> = (state) => {
  return ({
    searchType: state.searchType
  });
};


const MapPartWithSearchTypeWithCtx = connectCtx(ContextStore)(mapStateToProps)(MapPartWithSearchType);

export default MapPartWithSearchTypeWithCtx;