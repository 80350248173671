import React, {  } from 'react';
import CommonListItem from 'components/common-components/CommonListItem';
import { GridSize } from '@material-ui/core/Grid';
import { RouteInfoItemProps } from './types';
import { ROUTE_INFO_ITEM_WIDTH_RATIOS } from 'components/collinear-routes/config';

const RouteInfoItem = (props: RouteInfoItemProps) => {
  const {
    routeName,
    startArrivalTime,
    takenTime,
    passedStopsAmount,
  } = props;

  const textsInList = [
    routeName, 
    startArrivalTime,
    passedStopsAmount, 
    takenTime
  ];

  return (
    <CommonListItem
      {...props}
      isButton
      textsInList={textsInList}
      textsWidthRatios={ROUTE_INFO_ITEM_WIDTH_RATIOS} />
  );
};

export default RouteInfoItem;