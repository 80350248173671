import React from 'react';
import { Box } from '@material-ui/core';
import { CenterIntersectionStationListProps } from './types';
import MrtStationItem from '../../common/MrtStationItem';
import checkStationIsIntersection from 'components/mrt-fare/lib/functions/checkStationIsIntersection';
import { PADDING_BETWEEN_CENTER_STATIONS } from 'components/mrt-fare/config';
import getCenterPartDirection from './functions/getCenterPartDirection';
import getArrHalfIndex from 'lib/functions/getArrHalfIndex';

const CenterIntersectionStationList = (props: CenterIntersectionStationListProps) => {
  return (
    <Box flexShrink={0} maxWidth={150}>
      {props.stationListData.map((station, i) => {
        const isIntersection = checkStationIsIntersection(station.stationId);
        const isThisStation = station.stationId === props.selectedStationId;
        const direction = getCenterPartDirection(i, props.stationListData.length);
        const zIndex = i > getArrHalfIndex(props.stationListData.length) ? 999 : 0;
        const isLast = i === (props.stationListData.length - 1);
        const padding = isLast ? 0 : PADDING_BETWEEN_CENTER_STATIONS;

        return (
          <MrtStationItem 
            {...props}
            {...station}
            wrapperStyles={{
              zIndex,
            }}
            padding={padding}
            direction={direction}
            stationType={isIntersection ? 'INTERSECTION' : 'BUS'}
            colorKey={'BUS'}
            isThisStation={isThisStation}
          />
        );
      })}
    </Box>
  );
};

export default CenterIntersectionStationList;