import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import ibusImages from 'static/ibusImages.json';

export interface BusIconProps {
  iconImage: { name: string, src: string }
  isBarrierFree: boolean
  busIconWidth?: number
  barrierFreeIconWidth?: number
}

export const BUS_ICON_SPECS = {
  busIconWidth: 36,
  barrierFreeIconWidth: 16,
};

const useStyles = makeStyles<Theme, BusIconProps>(theme => ({
  root: {
    position: 'relative',
  },
  busIcon: {
    width: props => props.busIconWidth || BUS_ICON_SPECS.busIconWidth,
  },
  barrierFreeIcon: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: props => props.barrierFreeIconWidth || BUS_ICON_SPECS.barrierFreeIconWidth,
    // backgroundColor: '#fff',
  },
}));

const BusIcon = (props: BusIconProps) => {
  const {
    iconImage, isBarrierFree,
  } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.root}>
      <img
        className={classes.busIcon}
        alt={iconImage.name}
        src={iconImage.src}
      />
      {isBarrierFree && (
        <img 
          className={classes.barrierFreeIcon}
          alt={ibusImages.bus_icon_barrier_free.name}
          src={ibusImages.bus_icon_barrier_free.src}
        />
      )}
    </Box>
  );
};

export default BusIcon;