import { EdgesData, ID } from "all-common-types";
import { gql } from "apollo-boost";
import { GoBack } from "bus-common-types";

export const QUERY_STOP_REPORT_ROUTE_INFO = gql`
  query QUERY_STOP_REPORT_ROUTE_INFO($routeId: Int!, $lang: String!) {
    route(xno: $routeId, lang: $lang) {
      departure
      destination
      stations {
        edges {
          sid
          goBack
          node {
            id
            name
          }
        }
      }
    }
  }
`;
export interface SingleStationForStopReport {
  sid: number
  goBack: GoBack
  node: {
    id: string
    name: string
  }
}
export interface QueriedRotueInfoForStopReport {
  route: {
    departure: string
    destination: string
    stations: {
      edges: SingleStationForStopReport[]
    }
  }
}