import React from 'react';
import L from 'leaflet';
import { Typography, Divider } from '@material-ui/core';
import { defaultMapCenter } from 'config';
import { BusMarkerContentProps, BusMarkerProps } from './types';
import { Marker, Popup } from 'react-leaflet';
import { getIconByType } from '../CommonComponents/functions/getIconByType';
import zIndex from 'styles/styleObjs/zIndex';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import getProviderNameById from './functions/getProviderNameById';
import getBusTypeNameByBusType, { getSimpleBusTypeName } from './functions/getBusTypeNameByBusType';
import cityBusProviders from 'static/data/cityBusProviders.json';
import roadBusProviders from 'static/data/roadBusProviders.json';
import { StaticSingleProvider } from 'driving-map';
import { useIntl } from 'react-intl';
import { Locale } from 'all-common-types';
import { ConfirmationNumberSharp } from '@material-ui/icons';

const allProviders = [
  ...cityBusProviders,
  ...roadBusProviders,
] as any as StaticSingleProvider[];

type BusDataProps = {
  busData: BusMarkerContentProps
}
export const MarkerContent = ({ busData }: BusDataProps) => {
  const {
    locale,
  } = useIntl();
  const providerName = getProviderNameById(busData.providerId, allProviders);
  const busTypeName = getBusTypeNameByBusType(busData.carType, locale as Locale).name;
  
  return (
    <>
      <Typography variant={ 'h6' } color={'primary'}>
        { busData.carId }
      </Typography>
      <Divider />
      <IntlFormattedMessage
        langKey={'providerWithVariable'}
        variant={'subtitle1'}
        values={{
          provider: providerName
        }} />
      <IntlFormattedMessage
        langKey={'carTypeWithVariable'}
        variant={'subtitle1'}
        values={{
          carType: busTypeName
        }} />
    </>
  );
};

const BusMarker = (props: BusMarkerProps) => {
  const { 
    position=defaultMapCenter, busData, 
  } = props;

  const {
    locale,
  } = useIntl();
  const title = getSimpleBusTypeName(busData.carType, locale as Locale);

  const iconOptions = getIconByType('bus')({
    ...props.busData,
    providerId: Number(props.busData.providerId),
    title,
  });

  return (
    <Marker 
      zIndexOffset={zIndex.busMarker}
      icon={ L.divIcon(iconOptions) as any } 
      position={ position } 
    >
      <Popup autoPan={ true }>
        <MarkerContent busData={ busData } />
      </Popup>
    </Marker>
  );
};
export default BusMarker;