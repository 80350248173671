import React from 'react';
import { Box } from '@material-ui/core';
import Notices from './notices';
import { NoticesCotainerProps } from './types';
import useQueryCmsNotices from 'lib/customHooks/useQueryCmsNotices';
import IntlFormattedMessage from './IntlFormattedMessage';
import { queryCmsNoticesIntervalTime } from 'config';

const NoticesContainer = (props: NoticesCotainerProps) => {
  const {
    loading,
    handleQuery,
    notices,
  } = useQueryCmsNotices();

  React.useEffect(() => {
    handleQuery();
    
    setInterval(() => {
      handleQuery();
    }, queryCmsNoticesIntervalTime);
    
  }, [handleQuery]);

  if(loading) {
    return (
      <IntlFormattedMessage 
        langKey={'notices.isLoading'}
      />
    );
  }
  
  return (
    <Notices 
      {...props}
      notices={notices}  
    />
  );
};

export default NoticesContainer;