import { Location } from 'history';
import { Links } from 'all-common-types';

export const location_mockData = {
  pathname: '/blog/1'
};
export const matchedRoutes = [
  { path: '/', breadCrumbName: '首頁' },
  { path: '/blog', breadCrumbName: '最新消息' },
  { path: '/blog/1', breadCrumbName: '環東路地下道5/5填平開放通車 市公車自5/5恢復原動線行駛' },
];
// export const breadCrumb_mockData: import('../breadCrumbs').BreadCrumbProps = {
//   location: location_mockData as Location,
//   matchedRoutes, 
// };

export const subMenuLinks_mockData: Links = [
  {
    "id": "1",
    "title": "常見問題",
    "link": "/qa",
    "isTargetBlank": null,
    "isDisplay": true,
    "isInChildren": null,
    "children": []
  },
  {
    "id": "2",
    "title": "最新消息",
    "link": "/blog",
    "isTargetBlank": null,
    "isDisplay": true,
    "isInChildren": null,
    "children": []
  },
  {
    "id": "3",
    "title": "客運總覽",
    "link": "/userlink",
    "isTargetBlank": null,
    "isDisplay": true,
    "isInChildren": null,
    "children": []
  },
];

export const mainMenuLinks_mockData: Links =  [
  {
    "id": "1",
    "title": "首頁",
    "link": "/",
    "isTargetBlank": null,
    "isDisplay": false,
    "isInChildren": null,
    "children": []
  },
  {
    "id": "2",
    "title": "最新消息",
    "link": "/blog",
    "isTargetBlank": null,
    "isDisplay": true,
    "isInChildren": null,
    "children": []
  },
  {
    "id": "3",
    "title": "公車動態",
    "link": "/driving-map",
    "isTargetBlank": null,
    "isDisplay": true,
    "isInChildren": null,
    "children": []
  },
  {
    "id": "4",
    "title": "轉乘規劃",
    "link": "/travel-planner",
    "isTargetBlank": null,
    "isDisplay": false,
    "isInChildren": true,
    "children": []
  },
  {
    "id": "5",
    "title": "路線規劃",
    "link": '',
    "isTargetBlank": null,
    "isDisplay": true,
    "isInChildren": null,
    "children": [
      {
        "id": "4",
        "title": "轉乘規劃",
        "link": "/travel-planner",
        "isTargetBlank": null,
        "isDisplay": false,
        "isInChildren": true,
        "children": [],
      },
      {
        "id": "6",
        "title": "旅行時間",
        "link": "/collinear-routes",
        "isTargetBlank": null,
        "isDisplay": false,
        "isInChildren": false,
        "children": []
      },
    ]
  },
  {
    "id": "6",
    "title": "旅行時間",
    "link": "/collinear-routes",
    "isTargetBlank": null,
    "isDisplay": false,
    "isInChildren": false,
    "children": []
  },
];


export const notices_originQuery_mockData = [
  {
    "id": "1",
    "content": "今天很棒！",
    "link": "",
    "onShelfAt": "2019-06-19 00:00:00",
    "offShelfAt": "2019-10-31 00:00:00"
  },
  {
    "id": "2",
    "content": "回首頁看看吧",
    "link": "/",
    "onShelfAt": "2019-10-01 00:00:00",
    "offShelfAt": "2019-10-31 00:00:00"
  },
  {
    "id": "3",
    "content": "Hi",
    "link": "/",
    "onShelfAt": "2019-10-21 00:00:00",
    "offShelfAt": "2019-10-31 00:00:00"
  }
];
export const notices_mockData = [
  {
    "id": 1,
    "content": "今天很棒！",
    "link": '',
    "onShelfAt": "2019-06-19 00:00:00",
    "offShelfAt": "2019-10-31 00:00:00"
  },
  {
    "id": 2,
    "content": "回首頁看看吧",
    "link": '/',
    "onShelfAt": "2019-10-01 00:00:00",
    "offShelfAt": "2019-10-31 00:00:00"
  },
];

export const pages_mockData = [
  {
    "id": "1",
    "title": "客運總覽",
    "content": "客運總覽\n\n\n\n台中客運\n仁友客運\n統聯/中台灣客運\n巨業交通\n全航客運\n彰化客運\n南投客運\n總達客運\n和欣客運\n豐榮客運\n苗栗客運\n豐原客運\n捷順交通\n四方客運\n國光客運\n東南客運\n\n",
    "shortUrl": "userlink",
    "onShelfAt": "2019-11-05T00:00:00.000Z",
    "offShelfAt": "2019-12-31T00:00:00.000Z"
  },
  {
    "id": "2",
    "title": "常見問題",
    "content": "Q: 為什麼共線路線查不到路線資訊?\n\nA: 本功能需要查詢的站點起迄站皆經過同一條路線才會顯示共線的路線資訊。",
    "shortUrl": "qa",
    "onShelfAt": "2019-11-05T00:00:00.000Z",
    "offShelfAt": "2019-12-31T00:00:00.000Z"
  }
];