const LSkey = 'ibus-locale';

class LocaleLocalStorage {
  static getLocale() {
    // --temp--
    const res = localStorage.getItem(LSkey);
    // const res = 'zh';
    return res;
  }

  static setLocale(locale: string) {
    localStorage.setItem(LSkey, locale);
  }
}

export default LocaleLocalStorage;