/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef} from 'react';
import { AutoCompleteWithQueryWithResultsAndValues, useQueryStations } from 'components/common-components/AutoCompleteWithQuery';
import { NameResultList, NameValueResultsForAutoCompleteContainer } from 'ibus-common-components/lib/components/common-components/AutoComplete/types';
import { StopSearchInputItemContainerProps } from '../types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

export type StopSearchInputItemContainerInputName = 'stop'
type ResultType = any

export const initNameResultList: NameResultList<StopSearchInputItemContainerInputName, ResultType> = {
  stop: []
};

export const initNameValueResults: NameValueResultsForAutoCompleteContainer<StopSearchInputItemContainerInputName, ResultType> = {
  stop: {
    // placeholder: '輸入站點',
    placeholder: (
      <IntlFormattedMessage
        langKey={'collinearRoutes.fromTo.fromStop'}
      />
    ) as unknown as string,
    // value: '臺中車站',
    value: '',
    result: undefined,
    getValueResultListFn: () => {},
  }
};

const StopSearchInputItemContainer = (props: StopSearchInputItemContainerProps) => {
  const {
    getNameValueResultsWhenChanged,
    stopSearchInputItemContainerRef
  } = props;
  return (
    <AutoCompleteWithQueryWithResultsAndValues
      autoCompleteWithResultsAndValuesRef={stopSearchInputItemContainerRef}
      getNameValueResultsWhenChanged={getNameValueResultsWhenChanged}
      queryResultListHook={useQueryStations}
      initNameResultList={initNameResultList}
      initNameValueResults={initNameValueResults} />
  );
};

export default forwardRef(StopSearchInputItemContainer);