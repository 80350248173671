import React, { HTMLAttributes, memo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import ibusImages from 'static/ibusImages.json';

export interface OtherTransportationIconProps {
  isShowIcon: boolean
  imageKey: keyof typeof ibusImages
  title?: string
  iconStyles?: HTMLAttributes<HTMLImageElement>['style']
}

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 20,
    padding: 1,
  },
  image: {
    width: 'auto',
    maxHeight: 36,
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 25
    }
  }
}));

const OtherTransportationIcon = (props: OtherTransportationIconProps) => {
  const classes = useStyles();
  const {
    imageKey,
    title,
    iconStyles,
  } = props;

  if(!props.isShowIcon) {
    return null;
  }

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      className={classes.root}
    >
      <img
        className={classes.image}
        style={iconStyles}
        title={title}
        alt={ibusImages[imageKey].name}
        src={ibusImages[imageKey].src} />
    </Box>
  );
};

export default memo(OtherTransportationIcon);