import commonLangs from './commonLangs.json';
import TravelPlannerLangs from './travel-planner.json';
import HomePageLangs from './home-page.json';
import footerInfoLangs from './footerInfo.json';
import fareLangs from './fare.json';
import drivingMapLangs from './driving-map.json';
import collinearRoutesLangs from './collinear-routes.json';
import routeMapLangs from './route-map.json';
import mrtPagesLangs from './mrtPages.json';

const langs = {
  "zh_TW": {
    ...commonLangs['zh_TW'],
    ...TravelPlannerLangs['zh_TW'],
    ...HomePageLangs['zh_TW'],
    ...footerInfoLangs['zh_TW'],
    ...fareLangs['zh_TW'],
    ...drivingMapLangs['zh_TW'],
    ...collinearRoutesLangs['zh_TW'],
    ...routeMapLangs['zh_TW'],
    ...mrtPagesLangs['zh_TW'],
  },
  "en_US": {
    ...commonLangs['en_US'],
    ...TravelPlannerLangs['en_US'],
    ...HomePageLangs['en_US'],
    ...footerInfoLangs['en_US'],
    ...fareLangs['en_US'],
    ...drivingMapLangs['en_US'],
    ...collinearRoutesLangs['en_US'],
    ...routeMapLangs['en_US'],
    ...mrtPagesLangs['en_US'],
  }
};

export default langs;