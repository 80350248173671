import { RouteListsContainerProps, RouteListDepDest, GoBackEstimateStops } from "../RouteListsContainer";
import { GoBack, SingleEstimateStopWithOtherTrans } from 'bus-common-types';
import { goOrBackObj } from 'config';
import { HandleTimes } from 'constants/functions';
import { RouteListData } from 'components/driving-map/components/Route/RouteListItem';
import HandleStopIsNearIbike, { OtherTransportationStations } from 'components/driving-map/components/Route/functions/HandleStopIsNearIbike';

const RouteListHandlers = {
  getDepDestFromRouteLists({
    departure, destination, goBackEstimateStops,
  }: RouteListsContainerProps['routeLists']): RouteListDepDest {
    const isDepDisplay = goBackEstimateStops.back.length > 0;
    const isDestDisplay = goBackEstimateStops.go.length > 0;

    const res: RouteListDepDest = ({
      departure: {
        goBack: 2,
        location: departure,
        isDisplay: isDepDisplay,
      },
      destination: {
        goBack: 1,
        location: destination,
        isDisplay: isDestDisplay,
      },
    });
    return res;
  },

  combineDepDestToArr(routeListDepDest: RouteListDepDest) {
    return [
      {
        isDisplay: !!routeListDepDest.destination.isDisplay,
        goBack: 1 as GoBack,
        location: routeListDepDest.destination,
      },
      {
        isDisplay: !!routeListDepDest.departure.isDisplay,
        goBack: 2 as GoBack,
        location: routeListDepDest.departure,
      }
    ];
  },

  makeRouteListData(singleEstimateStop: SingleEstimateStopWithOtherTrans): RouteListData {
    return ({
      ...singleEstimateStop,
      takenTime: HandleTimes.getComeTimeTakenTimeFromNow(singleEstimateStop.comeTime)
    });
  },

  getListDatas(goBack: GoBack) {
    return (
      goBackEstimateStops: GoBackEstimateStops,
      otherTransportationStations: OtherTransportationStations,
    ) => {
      const goBackStr = goOrBackObj[goBack];
      const estimateStops = goBackEstimateStops[goBackStr];
      const estimateStopsWithHaveIbike = HandleStopIsNearIbike.makeEstimateStopsWithHaveIbike(estimateStops, otherTransportationStations);

      const estimateStopsWithTakenTime = estimateStopsWithHaveIbike.map(e => (
        this.makeRouteListData(e)
      ));

      return estimateStopsWithTakenTime;
    };
  }
};

export default RouteListHandlers;