import IbusCommonActionTypes from './index';
import CommonStateTypes from 'reducers/common-reducers/state';

export type SetBusProviderIdActionPayload = CommonStateTypes['busProviderId']
export interface SetBusProviderIdAction {
  type: IbusCommonActionTypes.SET_BUS_PROVIDER_ID
  payload: SetBusProviderIdActionPayload
}
export interface ResetBusProviderIdAction {
  type: IbusCommonActionTypes.RESET_BUS_PROVIDER_ID
}

export const setBusProviderId = (payload: SetBusProviderIdActionPayload): SetBusProviderIdAction => ({
  type: IbusCommonActionTypes.SET_BUS_PROVIDER_ID,
  payload,
});

export const resetBusProviderId = (): ResetBusProviderIdAction => ({
  type: IbusCommonActionTypes.RESET_BUS_PROVIDER_ID,
});

type BusProviderIdActions = SetBusProviderIdAction | ResetBusProviderIdAction

export default BusProviderIdActions;