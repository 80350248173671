import { getProviders } from "constants/functions/getTaichungBusProviders";
import { CityBusPartSelectsValues } from "components/driving-map/components/BusSelect/CityBusPart";
import { SingleSelectOption, Locale } from "all-common-types";
import { SingleProvider } from "bus-common-types";

export interface HandeGetSelectGroupOptionsParams {
  routeGroupOptions: SingleSelectOption[], 
  locale: Locale, 
  getProvidersFn: () => Promise<SingleProvider[]>
}

const handeGetSelectGroupOptions = ({
  routeGroupOptions, locale, getProvidersFn,
}: HandeGetSelectGroupOptionsParams) => async (values: CityBusPartSelectsValues) => {
  let options: SingleSelectOption[] = [];

  switch (values.selectType.value) {
    case '0': {
      options = routeGroupOptions;
      break;
    }
    case '1': {
      const busProviders = await getProvidersFn();
      options = getProviders(locale, busProviders);
      break;
    }
  }
  
  const newValues = ({
    ...values,
    'selectGroup': {
      ...values['selectGroup'],
      options,
    }
  });

  return newValues;
};

export default handeGetSelectGroupOptions;