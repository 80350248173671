import React from 'react';
import { Box, Button } from '@material-ui/core';
import { ClogButtonProps } from './types';
import ClogIcon from './ClogIcon';


const ClogButton = (props: ClogButtonProps) => {
  return (
    <Button
      {...props}
      color={'primary'}
      variant={'contained'}
    >
      <ClogIcon 
        {...props}
      />
    </Button>
  );
};

export default ClogButton;