import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FARE_BUTTON_WIDTH, PADDING_BETWEEN_STATIONS } from 'components/mrt-fare/config';
import SideStationList from './SideStationList';
import { Route309OtherStationListProps } from './types';
import MrtLine from '../../common/MrtLine';

const Route309OtherStationList = (props: Route309OtherStationListProps) => {
  if(props.route309OtherStations.length === 0) {
    return null;
  }
  
  return (
    <Box position={'relative'}>
      <MrtLine
        color={'BUS'}
        top={FARE_BUTTON_WIDTH / 2}
        right={10}
        width={270}
        direction={'horizontal'}
      />
      <Typography variant={'h6'} align={'right'} style={{
        position: 'absolute',
        right: 0,
        top: -32,
      }}>
        {'309'}
      </Typography>
      <SideStationList
        {...props}
        stationListData={props.route309OtherStations} 
        padding={PADDING_BETWEEN_STATIONS}
        stationNameDirection={'bottom'}
        partPositionStyle={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      />
    </Box>
  );
};

export default Route309OtherStationList;