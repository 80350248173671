
import { SingleRouteFromRoutes } from 'all-common-types';
import { TravelPlannerState } from '../constant/context';

import { actionTypes,  } from '../actions';
import { AllActionTypes } from '../actions';

export const routesReducer = (state: TravelPlannerState, action: AllActionTypes): SingleRouteFromRoutes[] => {
  switch (action.type) {
    case actionTypes.GET_ROUTES:
      return action.payload;
    default:
      return state.routes;
  }
};