import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { PlansContainerWithCtx } from './PlansContainer';
import { useSelectTab } from 'lib/customHooks';
import useGoldThemeLeftPartStyles from 'styles/styleObjs/goldThemeLeftPartStyles';
import { MapDispatchToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import { SearchAndPlansContainerDispatchesFromCtx, SearchAndPlansContainerWithCtxProps, SearchAndPlansContainerProps } from './types';
import { resetPlans, getRoutes } from '../actions';
import { connectCtx } from 'react-function-helpers';
import ContextStore from '../constant/context';
import SearchPartContainer from './SearchPartContainers/SearchPartContainer';
import { makeStyles } from "@material-ui/core";
import { useIntl } from 'react-intl';

const useStyle = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '85vh',
    }
  }
}));

const SearchAndPlansContainer = (props: SearchAndPlansContainerProps) => {
  const classes = useGoldThemeLeftPartStyles();
  const myClasses = useStyle();
  const [selectedTab] = useSelectTab();

  const { locale } = useIntl();

  React.useEffect(() => {
    props.resetPlansToCtx();
    props.getRoutes(locale);
  }, [props, selectedTab, locale]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={'1'}
      className={`${classes.root} ${myClasses.root}`}
    >
      <SearchPartContainer />
      <Divider />
      <PlansContainerWithCtx />
    </Box>
  );
};

interface OwnProps extends SearchAndPlansContainerWithCtxProps {}

const mapDispatchToProps: MapDispatchToProps<OwnProps, SearchAndPlansContainerDispatchesFromCtx> = (dispatch) => {
  return ({
    resetPlansToCtx: () => {
      const action = resetPlans();
      dispatch(action);
    },
    getRoutes: async (locale) => {
      const action = await getRoutes(locale);
      dispatch(action);
    }
  });
};


const SearchAndPlansContainerWithCtx = connectCtx(ContextStore)(undefined, mapDispatchToProps)(SearchAndPlansContainer);

export default SearchAndPlansContainerWithCtx;