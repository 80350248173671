import React from 'react';
import { Box } from '@material-ui/core';
import { ClogIconProps } from './types';
import clogAlert from 'components/driving-map/static/clog-alert.png';
import clogNormal from 'components/driving-map/static/clog-normal.png';

const ClogIcon = (props: ClogIconProps) => {
  const imgSrc = props.haveClog ? clogAlert : clogNormal;

  return (
    <img 
      src={imgSrc}
      alt={'clog-icon'}
    />
  );
};

export default ClogIcon;