import React, { useContext, useCallback } from 'react';
import ContextStore from '../../constants/context';
import { Box, List, Divider, makeStyles, Typography, Button } from '@material-ui/core';
import { SubRoutesSelectItem } from './SubRoutesSelectItem';
import { subRoutesSelectItemHeader } from 'components/driving-map/static/subRoutesSelectItem';
import { MapDispatchToProps } from 'lib/contextReduxLikeHelpers/mapContextToProps';
import { connectCtx } from 'react-function-helpers/lib';
import { setSelectedRoute, SetSelectedRouteActionPayLoad } from 'actions/common-actions/selectedRoute-actions';
import { SingleRouteSelectorOption } from 'constants/functions';
import { Callback } from 'all-common-types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 125,
    marginBottom: 16,
    borderBottom: '1px solid #111',
    textAlign: 'center',
    overflow: 'auto',
  },
}));

type SubRoutesHeaderProps = {
  selectedRoute: string
  isSubRoutesSelectsDisplay?: boolean
  toggleSubRoutesSelectsDisplayFn?: () => any
  resetSelectedRouteFn?: Callback
}
export const SubRoutesHeader = ({ 
  selectedRoute, 
  isSubRoutesSelectsDisplay,
  resetSelectedRouteFn,
  toggleSubRoutesSelectsDisplayFn, 
}: SubRoutesHeaderProps) => {
  const foldButton = (
    <Button
      onClick={toggleSubRoutesSelectsDisplayFn}
    >
      {isSubRoutesSelectsDisplay ? (
        <IntlFormattedMessage 
          langKey={'fold'}
        />
      ) : (
        <IntlFormattedMessage 
          langKey={'unfold'}
        />
      )}
    </Button>
  );
  
  return (
    <Box display={ 'flex' } paddingTop={1}>
      <IntlFormattedMessage 
        variant={ 'subtitle1' }
        langKey={'routeWithVar'}
        values={{
          routeName: selectedRoute,
        }}
      />
      <Box paddingLeft={1}>
        <Button 
          variant={ 'outlined' } 
          color={'primary'}
          onClick={ resetSelectedRouteFn }
        >
          <IntlFormattedMessage 
            langKey={'back'}
          />
        </Button>
        {foldButton}
      </Box>
    </Box>
  );
};

type SubRoutesSelectsProps = {
  subRoutesData: SingleRouteSelectorOption[]
  selectedRouteFn?: (payload: SetSelectedRouteActionPayLoad) => any
} & SubRoutesHeaderProps
export const SubRoutesSelects = (props: SubRoutesSelectsProps) => {
  const classes = useStyles();
  const { 
    isSubRoutesSelectsDisplay,
    subRoutesData, 
    selectedRoute, 
    selectedRouteFn,
    resetSelectedRouteFn 
  } = props;

  const handleSelectRoute = useCallback((routeId: string) => {
    selectedRouteFn && selectedRouteFn({
      id: routeId,
      routes: subRoutesData,
    });
  }, [selectedRouteFn, subRoutesData]);

  return (
    <>
      {/* <SubRoutesHeader 
        {...props} /> */}
      <List className={ classes.root } style={{
        display: isSubRoutesSelectsDisplay ? 'block' : 'none'
      }}>
        <SubRoutesSelectItem
          singleSubRoutesData={subRoutesSelectItemHeader}
          selectedRoute={''} />
        <Divider />
        {subRoutesData.map((data, i) => {
          const isButton = i !== 0;
          return (
            <SubRoutesSelectItem
              key={i}
              {...props}
              button={isButton}
              selectedRouteFn={handleSelectRoute}
              singleSubRoutesData={data} />
          );
        })}
      </List>
    </>
  );
};

export const mapDispatchToProps: MapDispatchToProps<SubRoutesSelectsProps, {
  selectedRouteFn: (payload: SetSelectedRouteActionPayLoad) => any
}> = (dispatch) => {
  return ({
    selectedRouteFn: (payload) => {
      dispatch(setSelectedRoute(payload));
    }
  });
};

export const SubRoutesSelectsWithCtx = connectCtx(ContextStore)(undefined, mapDispatchToProps)(SubRoutesSelects);

export default SubRoutesSelectsWithCtx;