import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { FromToStopListItemTitleProps } from './types';
import getFromToListItemTitle from '../functions/getFromToListItemTitle';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const FromToStopListItemTitle = ({
  providerName, routeName, depDestName
}: FromToStopListItemTitleProps) => {
  return (

    <Grid container spacing={1} >
      <Grid item xs={12}>
        <Typography variant={'h6'}>{`【${providerName}】`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <IntlFormattedMessage
          variant={'h6'}
          langKey={'routeWithVar'}
          values={{
            routeName,
          }}
        />
        <IntlFormattedMessage
          variant={'h6'}
          langKey={'toStationWithVar'}
          values={{
            station: depDestName,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FromToStopListItemTitle;