import React from 'react';
import { Box } from '@material-ui/core';
import CustomLink from 'ibus-common-components/lib/components/common-components/Menus/CustomLink';
import { ImageLinkProps } from './types';

const ImageLink = (props: ImageLinkProps) => {
  return (
    <Box
      width={`${100}%`}
      {...props}
    >
      <CustomLink
        href={props.href}
        isTargetBlank={props.isTargetBlank}
      >
        <img 
          width={`${100}%`}
          src={props.imgSrc}
          alt={props.alt}
        />
      </CustomLink>
    </Box>
  );
};

export default ImageLink;