import React from 'react';
import { Box } from '@material-ui/core';
import BusRoutesSelectsContainerWithCtx from 'components/driving-map/containers/BusSelect/BusRoutesSelectsContainer';

export const RoadBusPart = () => {
  return (
    <Box>
      <BusRoutesSelectsContainerWithCtx
        busType={'road'} />
    </Box>
  );
};

export default RoadBusPart;