import React from 'react';
import { Box } from '@material-ui/core';
import { TimeTable } from 'components/driving-map/components/BusMap';
import useTimeTable from './functions/useTimeTable';
import { TimeTableContainerProps } from './types';

const TimeTableContainer = (props: TimeTableContainerProps) => {
  const timeTableState = useTimeTable(props);
  return (
    <TimeTable 
      {...timeTableState}
      routeId={props.routeDataForTimeTable.id}
      onSelectDate={timeTableState.handleSelectDate}
      onDisplayTimeTable={props.onDisplayTimeTable}
    />
  );
};

export default TimeTableContainer;