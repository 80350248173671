import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { OtpMode } from 'travel-planner';
import { ICON_WIDTH_BASIC } from 'components/travel-planner/config';

export interface StepLineItemProps {
  travelMode: OtpMode
}

const height = `calc(100% - ${ICON_WIDTH_BASIC / 2}px)`;
export const dashedBorderStyle = 'none none none dashed';
export const solidBorderStyle = 'none none none solid';

export const getBorderStyle = (props: StepLineItemProps) => (
  props.travelMode === 'WALK' ? dashedBorderStyle : solidBorderStyle
);

const useStyles = makeStyles<Theme, StepLineItemProps>(theme => ({
  root: {
    width: 0,
    height,
    margin: 'auto',
    borderWidth: 2,
    borderStyle: getBorderStyle,
    borderColor: theme.palette.primary.main,
  }
}));

const StepLineItem = (props: StepLineItemProps) => {
  const classes = useStyles(props);
  return (
    <Box className={classes.root} />
  );
};

export default StepLineItem;