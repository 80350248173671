import { useState, useEffect, useMemo, useContext } from 'react';
import ContextStore, { ContextValue } from 'components/driving-map/constants/context';
import { resetRouteStopId } from 'components/driving-map/actions';
import { GoBack } from 'bus-common-types';
import useBusSelectPartTab from 'components/driving-map/components/BusSelect/functions/useBusSelectPartTab';
import { useParams } from 'react-router';
import { ID } from 'all-common-types';

const useDrivingMap = () => {
  const {
    dispatch,
    state,
  } = useContext(ContextStore);

  const [goBack, setGoBack] = useState(1 as GoBack);
  const handleSetGoOrBack = (goBack: 1 | 2) => {
    setGoBack(goBack);
  };

  useEffect(() => {
    dispatch( resetRouteStopId() );
  }, [dispatch, goBack, state.selectedRoute.id]);

  const {
    selectedTab,
    setSelectTab,
  } = useBusSelectPartTab(dispatch);

  return ({
    selectedTab,
    goBack,
    setSelectTab,
    handleSetGoOrBack,
  });
};

export default useDrivingMap;