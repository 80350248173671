import React from 'react';
import { ContextWrapper } from 'components/travel-planner/constant/context';
import MapContainerWithCtx from './MapContainer';
import { SinglePlan } from 'travel-planner';
import SearchAndPlansContainer from './SearchAndPlansContainer';
import LeftInfoRightMapStyleWrapper from 'components/common-components/wrappers/LeftInfoRightMapStyleWrapper';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from 'constants/API';

type Props = {
  plans?: SinglePlan[]
}
const TravelPlanner = () => {
  return (
    <ApolloProvider client={client}>
      <ContextWrapper>
        <LeftInfoRightMapStyleWrapper>
          <SearchAndPlansContainer />
          <MapContainerWithCtx />
        </LeftInfoRightMapStyleWrapper>
      </ContextWrapper>
    </ApolloProvider>
  );
};

export default TravelPlanner;