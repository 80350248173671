import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import CashPrice from './CashPrice';
import ETicketPrice from './ETicketPrice';
import { ResultPartProps } from './types';
import ECitizenPrice from './ECitizenPrice';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > :nth-child(n)': {
      paddingBottom: theme.spacing(2)
    }
  }
}));

const ResultPart = (props: ResultPartProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <IntlFormattedMessage
        langKey={'fare.searchResult.title'}
        variant={'h4'} />
      <CashPrice prices={props.cashPrices} />
      <ECitizenPrice
        shouldHideETicketInfo={props.shouldHideETicketInfo}
        prices={props.eCitizenPrices}
      />
      <ETicketPrice
        shouldHideETicketInfo={props.shouldHideETicketInfo}
        prices={props.eTicketPrices}
      />
    </Box>
  );
};

export default ResultPart;