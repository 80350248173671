import { GoBack } from "bus-common-types";
import { SingleStationForFareWithGoBack } from "components/fare/gql/gql-schema";

const FareResultHandlers = {
  checkIsCycledRouteFirstStop(
    stationId: SingleStationForFareWithGoBack['id'],
    fromStops: SingleStationForFareWithGoBack[],
  ) {
    const stopIndex = fromStops.findIndex(s => String(s.id) === String(stationId));
    return stopIndex === 0;
  },

  checkIgnoreGoBackFilter(isFirstStop: boolean, goBack: GoBack) {
    return isFirstStop && goBack === 2;
  },
};

export default FareResultHandlers;