import { formatDate } from "constants/functions";
import { useState, useEffect, useCallback } from "react";
import getDayTypeByDay from "components/driving-map/lib/getDayTypeByDay";
import { TimeTableProps } from "components/driving-map/components/BusMap/types";
import { handleTimeTable } from "components/driving-map/components/TimeTable/functions/timeTable";
import { TimeTableContainerProps } from "../types";
import { SingleTimeTablePartData } from "components/driving-map/components/TimeTable/TimeTablePart";
import useQueryDailyTimeTable from "gql/custom-hooks/useQueryDailyTimeTable";
import useQuerySchedule from "gql/custom-hooks/useQuerySchedule";

const formattedToday = formatDate(new Date());
const todayDayType = getDayTypeByDay((new Date()).getDay());
const defaultSelectedDate = {
  formattedDate: formattedToday,
  dayType: todayDayType
};

export interface UseTimeTableOptions extends TimeTableContainerProps {}

const useTimeTable = ({
  routeDataForTimeTable,
}: UseTimeTableOptions) => {
  const {
    id: routeId,
    opType,
  } = routeDataForTimeTable;

  const [selectedDate, setDate] = useState(defaultSelectedDate);
  const [timeTableListData, setTimeTableListData] = useState<SingleTimeTablePartData[]>([]);

  // const queriedState = useQueryTimeTable();
  const useQuery = opType === 9 ? useQuerySchedule : useQueryDailyTimeTable;
  const queriedState = useQuery();
  const {
    data,
    schedules,
    handleQuery,
  } = queriedState;

  const handleResetDate = useCallback(() => {
    setDate(defaultSelectedDate);
  }, []);

  const handleSelectDate: TimeTableProps['onSelectDate'] = useCallback((e) => {
    const { value: formattedDate  } = e.target;
    const _dayType = getDayTypeByDay( (new Date(formattedDate)).getDay() );
    setDate({
      formattedDate,
      dayType: _dayType,
    });
    handleQuery({
      routeId, date: formattedDate,
    });
  }, [handleQuery, routeId]);

  useEffect(() => {
    handleQuery({
      routeId, date: formattedToday,
    });
    handleResetDate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeId]);

  useEffect(() => {
    if(data) {
      const _timeTableListData = handleTimeTable.getResult({
        ...routeDataForTimeTable,
        schedule: {
          schedules,
          // 現在不用headyway資料，暫且保留欄位而已
          headway: {
            holiday: null,
            weekday: null,
          }
        },
      });
      setTimeTableListData(_timeTableListData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), JSON.stringify(routeDataForTimeTable)]);

  return ({
    dayType: selectedDate.dayType,
    selectedDate: selectedDate.formattedDate,
    timeTableListData,
    handleSelectDate,
  });
};

export default useTimeTable;