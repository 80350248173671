import { SingleLangTitleLocale } from "ibus-common-components/lib/components/common-components/Menus/types";

const defaultLangTitleLinks: SingleLangTitleLocale[] = [
  {
    title: '繁體中文',
    locale: 'zh',
    // link: '#',
  },
  {
    title: 'English',
    locale: 'en',
    // link: '#',
  }
];

export default defaultLangTitleLinks;