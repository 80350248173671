import React, { ReactNode } from 'react';
import { Box, makeStyles, BoxProps } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import TicketPriceItem from './TicketPriceItem';
import { ticketTypes, PriceTypes } from 'components/fare/config';
import { LangKeys } from 'constants/lang';

const useStyles = makeStyles(theme => ({
  root: {

  },
  prices: {
    '& > div:nth-child(n+2)': {
      paddingLeft: theme.spacing(2),
    },

  }
}));
export interface PriceListWithTitleProps extends BoxProps {
  langKey: LangKeys
  prices: PriceTypes
  children?: ReactNode
}

const PriceListWithTitle = (props: PriceListWithTitleProps) => {
  const classes = useStyles();
  const {
    prices,
  } = props;
  return (
    <Box>
      <IntlFormattedMessage
        variant={'h5'}
        langKey={props.langKey} />
      {props.children}
      <Box
        display={'flex'}
        className={classes.prices}
        {...props}
      >
        {ticketTypes.map((ticketType) => (
          <TicketPriceItem
            key={ticketType}
            ticketType={ticketType}
            price={prices[ticketType]}
          />
        ))}
      </Box>
    </Box>
  );
};

export default PriceListWithTitle;