import { ID } from "all-common-types";
import { SingleStationRouteWithGoBack } from "bus-common-types";
import { RoutePassData } from "components/driving-map/components/BusMap/RouteStopMarker";
import { nodeDataFromEdges } from "constants/functions";
import useQueryStationPassedRoutes from "gql/custom-hooks/useQueryStationPassedRoutes";
import { useToggle } from "lib/customHooks";
import { useEffect, useMemo } from "react";

export interface UseRouteStopMarkerOptions {
  stationId: ID
}

const useRouteStopMarker = (options: UseRouteStopMarkerOptions) => {
  const {
    stationId,
  } = options;
  const {
    handleQuery,
    data,
  } = useQueryStationPassedRoutes();

  const {
    toggle: routePassDisplay,
    handleToggle: handleToggleRoutePass
  } = useToggle(false);

  const routePassData: RoutePassData = useMemo(() => (
    data ? data.station.routes.edges.map(s => ({
      ...s.node,
      goBack: s.goBack,
      seq: s.node.seq,
    })) : []
  ), [data]);

  useEffect(() => {
    (routePassDisplay) && handleQuery(stationId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routePassDisplay, stationId]);

  return ({
    routePassData,
    routePassDisplay,
    handleToggleRoutePass,
  });
};

export default useRouteStopMarker;