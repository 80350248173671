import { ID } from "all-common-types";
import { SingleClogMessage, Clogs } from "bus-common-types";
import { isArray } from "util";

const clickedRotuesLSkey = 'clicked-routes-for-clogs';

export interface RouteIdWithExpireTime {
  routeId: ID
  expiredTime: Date
}

export interface CheckShouldPopupClogsMessageOptions {
  routeId: ID
  clogs: SingleClogMessage[]
}

class ClogMessageToggleHandler {
  static checkHaveClogs(clogs: SingleClogMessage[]) {
    const res = clogs.length > 0;
    return res;
  }

  static checkIsClicked(routeId: ID, data: RouteIdWithExpireTime[]) {
    const isClicked = data.find(d => String(d.routeId) === String(routeId));
    const res = !!isClicked;
    return res;
  }

  static filterByExpiredDate(data: RouteIdWithExpireTime[]): RouteIdWithExpireTime[] {
    const today = new Date();
    const res = data.filter(d => {
      const expiredTime = new Date(d.expiredTime);
      const isNotExpired = today.getTime() - expiredTime.getTime() <= 0;
      return isNotExpired;
    });
    return res;
  }

  static makeClickedRotueWithExpiredTime(routeId: ID): RouteIdWithExpireTime {
    const today = new Date();
    today.setHours(23, 59);

    const expiredTime = today;
    const res = {
      routeId,
      expiredTime,
    };
    return res;
  }
  
  static setClickedRouteToLS(routeId: ID) {
    let newData: RouteIdWithExpireTime[] = [];

    const originData = this.getClickedRoutesFromLS();
    const latestRouteIdWithExpiredTime = this.makeClickedRotueWithExpiredTime(routeId);

    const isClicked = this.checkIsClicked(routeId, originData);
    if(!isClicked) {
      newData = [...originData, latestRouteIdWithExpiredTime];
    } else {
      newData = [...originData];
    }

    const stringified = JSON.stringify(newData);
    localStorage.setItem(clickedRotuesLSkey, stringified);
  }

  static getClickedRoutesFromLS() {
    const LSdata = localStorage.getItem(clickedRotuesLSkey);
    const data = JSON.parse(LSdata || '[]') as RouteIdWithExpireTime[];

    if(Array.isArray(data)) {
      //filter by get process
      const res = this.filterByExpiredDate(data);
      return res;
    }
    return [];
  }

  static checkRouteIsClicked(routeId: ID) {
    const clickedRoutes = this.getClickedRoutesFromLS();
    const isClicked = clickedRoutes.find(c => {
      return String(c.routeId) === String(routeId);
    });
    return isClicked;
  }

  static checkShouldPopupClogsMessage(options: CheckShouldPopupClogsMessageOptions) {
    const {
      routeId,
      clogs,
    } = options;
    let res = false;

    const firstCheckedRes = this.checkHaveClogs(clogs);
    res = firstCheckedRes;
    // console.log(res);

    const routeIsClicked = this.checkRouteIsClicked(routeId);
    if(routeIsClicked) {
      res = !routeIsClicked;
    }
    // console.log(res);
    
    return res;
  }
}

export default ClogMessageToggleHandler;