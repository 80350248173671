import { gql } from "apollo-boost";
import { GoBack } from "bus-common-types";
import RouteFragment, { QueriedTravelTimeFragment } from "constants/api-fragments";

export interface SingleCollinearRoutesTravelTimeInput {
  xno: number
  goBack: GoBack
  fromStationId?: number
  toStationId?: number
}

export interface QueryTravelTimesParams {
  inputs: SingleCollinearRoutesTravelTimeInput[]
}

const QUERY_TRAVEL_TIMES = gql`
  query QUERY_TRAVEL_TIMES($inputs: [TravelTimeInput]) {
    travelTime(inputs: $inputs) {
      ...travelTimeFragment
    }
  }
  ${RouteFragment.fragment.travelTimeFragment}
`;

export interface QueriedTravelTimes {
  travelTime: QueriedTravelTimeFragment
}

export default QUERY_TRAVEL_TIMES;