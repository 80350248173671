import useRadioTabList from "components/common-components/RadioTab/custom-hooks/useRadioTabList";
import { StationContentMode } from "mrt-pages";
import { useMemo } from "react";

const useMrtFare = () => {
  const {
    value: selectedContentMode,
    handleChange,
  } = useRadioTabList<StationContentMode>();

  return ({
    selectedContentMode,
    // value,
    handleChange,
  });
};

export default useMrtFare;