import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const RouteInfoNoResult = () => {
  return (
    <Box
      textAlign={'center'}
    >
      <IntlFormattedMessage
        variant={'h6'}
        langKey={'collinearRoutes.fromTo.noResult'}
      />
    </Box>
  );
};

export default memo(RouteInfoNoResult);