import { useLazyQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import QUERY_FROM_TO_STATION_ESTIMATES, { QueriedFromToStationEstimates } from '../schemas/QUERY_FROM_TO_STATION_ESTIMATES';
import { EstimateStationInput } from 'all-common-types';
import { client } from 'constants/API';

export interface UseQueryFromToStationEstimatesOptions {
  fromStationTargets: EstimateStationInput[]
  toStationTargets: EstimateStationInput[]
}

const useQueryFromToStationEstimates = () => {
  const {
    locale,
  } = useIntl();

  const [queryFn, { loading, error, data }] = useLazyQuery<QueriedFromToStationEstimates>(QUERY_FROM_TO_STATION_ESTIMATES, {
    client: client,
  });

  const handleQuery = useCallback((options: UseQueryFromToStationEstimatesOptions) => {
    queryFn({
      variables: {
        ...options,
        lang: locale,
      }
    });
  }, [locale, queryFn]);

  return ({
    loading,
    data,
    handleQuery,
  });
};

export default useQueryFromToStationEstimates;