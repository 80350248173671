import { SingleSelectorOption } from "ibus-common-components/lib/components/common-components/FilterSelector/types";

export interface SliceStopsByStopOptions {
  isCycledRoute: boolean
  fromStopValue?: string
  stops: SingleSelectorOption[]
}

const sliceStopsByStop = ({
  fromStopValue,
  stops,
}: SliceStopsByStopOptions) => {
  let res = stops;
  const stopIndex = stops.findIndex(s => s.value === fromStopValue);
  if(stopIndex !== -1) {
    res = stops.slice(stopIndex + 1);
  }

  return res;
};

export default sliceStopsByStop;