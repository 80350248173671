import React, { ReactNode } from 'react';
import { Box, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { EstimateTime } from 'theme/theme-types';
import ListItemTextInGrid, { ListItemTextInGridProps } from 'components/common-components/ListItemTextInGrid';

export interface EstimateTimeProps extends ListItemTextInGridProps {
  estimateTimeStatus: keyof EstimateTime['timeStatus']
  comeTimeStr: ReactNode
}

const useStyles = makeStyles<Theme, EstimateTimeProps>(theme => ({
  root: {
    wordBreak: 'break-all',
    maxWidth: 100,
    padding: theme.spacing(0.25),
    margin: 0,
    color: theme.palette.common.white,
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    lineHeight: 1.2,
    backgroundColor: props => theme.estimateTime.timeStatus[props.estimateTimeStatus],
    '& p': {
      fontSize: props => (props.zoomRatio || 1) * theme.typography.fontSize,
      lineHeight: 1.2,
    }
  }
}));
const RouteListEstimateTimeItem = (props: EstimateTimeProps) => {
  const classes = useStyles(props);
  const { comeTimeStr } = props;
  return (
    <ListItemTextInGrid 
      className={ classes.root } 
      primary={ comeTimeStr }
      zoomRatio={props.zoomRatio}
    />
  );
};

export default RouteListEstimateTimeItem;