import React from 'react';
import { Box, Container } from '@material-ui/core';
import StationsPart from './containers/StationsPart/StationsPart';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from 'constants/API';
import useGoldThemeLeftPartStyles from 'styles/styleObjs/goldThemeLeftPartStyles';
import SelectPart from './components/SelectPart/SelectPart';
import useMrtFare from './lib/custom-hooks/useMrtFare';
import MrtDescription from './components/common/MrtDescription';

const MrtFare = () => {
  const classes = useGoldThemeLeftPartStyles();
  const {
    selectedContentMode,
    handleChange,
  } = useMrtFare();

  return (
    <ApolloProvider client={client}>
      <Container className={classes.wrapper} style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxWidth: '100%',
      }}>
        <Box
          className={classes.root}
          overflow={'auto'}
        >
          <SelectPart
            value={selectedContentMode}
            onChange={handleChange}
          />
          <StationsPart selectedContentMode={selectedContentMode} />
          {/* <MrtDescription /> */}
        </Box>
      </Container>
    </ApolloProvider>
  );
};

export default MrtFare;