import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { MrtFareButtonProps } from './types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { FARE_BUTTON_WIDTH, MRT_COLORS } from '../../config';
import { basicHoverEffectStyle } from 'components/home-page/blogs';

const useStyles = makeStyles<Theme, MrtFareButtonProps>(theme => ({
  wrapper: {
    padding: theme.spacing(0.25),
  },
  root: props => ({
    width: FARE_BUTTON_WIDTH,
    height: FARE_BUTTON_WIDTH,
    border: props.stationType === 'INTERSECTION' ? 
      `${5}px solid ${MRT_COLORS[props.colorKey]}` : 
      `${3}px solid ${MRT_COLORS[props.colorKey]}`,
    backgroundColor: props.isThisStation ? MRT_COLORS[props.colorKey] : '#fff',
    borderRadius: 1000,
    boxSizing: 'border-box',
    textAlign: 'center',
    ...basicHoverEffectStyle(),
  })
}));

const MrtFareButton = (props: MrtFareButtonProps) => {
  const {
    isThisStation,
    wrapperStyles,
    routeId,
    stationType,
    stationId,
    content,
    onSetStation,
  } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.wrapper} style={wrapperStyles}>
      <Box 
        className={classes.root}
        onClick={onSetStation({
          routeId,
          stationId,
          stationType,
        })}
      >
        <Typography variant={'body1'}>
          {!isThisStation && content}
        </Typography>
      </Box>
    </Box>
  );
};

export default MrtFareButton;