import StationNameAutoCompleteHandlers from "components/common-components/AutoCompleteWithQuery/lib/StationNameAutoCompleteHandlers";
import QuerySposMrtsHandlers from "./QuerySposMrtsHandlers";
import querySpotsMrtsAndStations from "./querySpotsAndMrts";
import queryStationsDirectly, { QueryStationsByNameOptions } from "./queryStationsDirectly";
import queryTgosAddress from "./queryTgosAddress";
import TgosAddressDataHandlers from "./TgosAddressDataHandlers";

export interface QueryStationsAndAddressDirectlyOptions extends QueryStationsByNameOptions {
  
}

const QueryAddressStationsHandlers = {
  queryAddresses: queryTgosAddress,
  queryStations: queryStationsDirectly,
  querySpotsMrtsStations: querySpotsMrtsAndStations,
  
  async queryStationsAndAddressDirectly(options: QueryStationsAndAddressDirectlyOptions) {
    const stationsRes = await this.queryStations(options);
    const sposAndMrts = await this.querySpotsMrtsStations(options.name, options.lang);
    const sposAndMrtsValueResultList = QuerySposMrtsHandlers.convertToValueResultList(sposAndMrts);

    if(stationsRes.length > 0 || sposAndMrtsValueResultList.mrts.length > 0 || sposAndMrtsValueResultList.spots.length > 0) {
      const stationsValeResultList = StationNameAutoCompleteHandlers.makeValueResultList(stationsRes);
      const res = [
        ...sposAndMrtsValueResultList.mrts,
        ...stationsValeResultList,
        ...sposAndMrtsValueResultList.spots,
      ];
      return res;
    }
    // if stations data is empty
    else if(stationsRes.length === 0) {
      const addressList = await this.queryAddresses(options.name);
      const handledAddressValueResultList = TgosAddressDataHandlers.handleRawData(addressList);
      return handledAddressValueResultList;
    }
    return [];
  },
};

export default QueryAddressStationsHandlers;