import React from 'react';
import { Box } from '@material-ui/core';
import { OtherTransportationsItemProps } from './types';
import OtherTransportationsHandlers from './functions/OtherTransportationsHandlers';

const OtherTransportationsItem = (props: OtherTransportationsItemProps) => {
  return (
    <Box display={'flex'} flexWrap={'wrap'}>
      {OtherTransportationsHandlers.getComponents(props)}
    </Box>
  );
};

export default OtherTransportationsItem;