import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import RouteMapListPart from './components/RouteMapListPart/RouteMapListPart';
import useRouteMapListPage from './lib/functions/useRouteMapListPage';
import LoadingErrorMessageWrapper from 'components/common-components/wrappers/LoadingErrorEmptyMessageWrapper';
import SearchPart from './components/SearchPart/SearchPart';

const useStyles = makeStyles(theme => ({
  root: {
    height: 1,
    overflow: 'auto',
    padding: theme.spacing(1),
    background: theme.background.surface,
  },
  pagination: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '&> ul': {
      justifyContent: 'center',
    }
  }
}));

const RouteMapListPage = () => {
  const classes = useStyles();

  const routeMapListPageState = useRouteMapListPage();
  const {
    searchBarState,
    pageNow,
    pagesCount,
    handleChangePage,
    routeMapLinkListData,
  } = routeMapListPageState;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      className={classes.root}
    >
      <SearchPart
        onChange={searchBarState.handleChangeInput}
        onClearInput={searchBarState.handleClearSearch}
        onSearch={searchBarState.handleSearch}
        value={searchBarState.value}
      />
      <LoadingErrorMessageWrapper {...routeMapListPageState}>
        <RouteMapListPart
          routeMapLinkListData={routeMapLinkListData}
        />
        <Pagination
          className={classes.pagination}
          page={pageNow}
          count={pagesCount}
          onChange={handleChangePage}
        />
      </LoadingErrorMessageWrapper>
    </Box>
  );
};

export default RouteMapListPage;