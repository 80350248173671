import { ROUTE_LIST_PART_ZOOM_LS_KEY } from "components/driving-map/config";
import ContextStore from "components/driving-map/constants/context";
import useQueryNearTransportations from "components/driving-map/gql/custom-hooks/useQueryNearTransportations";
import useQueryLanternStations from 'components/driving-map/gql/custom-hooks/useQueryLanternStations';
import useZoomInOut from "lib/customHooks/useZoomInOut";
import ZoomLocalStorageHandlers from "lib/Handlers/ZoomLocalStorageHandlers";
import { useContext, useEffect } from "react";

const useRoutePart = () => {
  const {
    state: {
      selectedRoute: {
        id: routeId,
      }
    }
  } = useContext(ContextStore);
  const zoomState = useZoomInOut({
    initZoomValue: ZoomLocalStorageHandlers.getZoomValueFromLS(ROUTE_LIST_PART_ZOOM_LS_KEY),
    callback: ZoomLocalStorageHandlers.saveZoomValueToLS(ROUTE_LIST_PART_ZOOM_LS_KEY)
  });

  const {
    transportationStations,
    handleQuery,
  } = useQueryNearTransportations();

  const {
    lanternStations,
    handleQuery: lanternQuery
  } = useQueryLanternStations();

  useEffect(() => {
    routeId && handleQuery(routeId);
    routeId && lanternQuery(routeId);
  }, [handleQuery, lanternQuery, routeId]);

  return ({
    routeId,
    zoomState,
    transportationStations,
    lanternStations
  });
};

export default useRoutePart;