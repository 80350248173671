import React from 'react';
import { Container, Box } from '@material-ui/core';
import { Route, withRouter, RouteComponentProps } from 'react-router-dom';
import BlogListsPage, { BlogListsPageProps } from './blogListsPage';
import { BreadCrumbsForBlog } from 'components/common-components/breadCrumbs';
import { Links } from 'all-common-types';
import { SingleBlog } from 'blog';
import useGoldThemeLeftPartStyles from 'styles/styleObjs/goldThemeLeftPartStyles';
import SingleBlogPageContainer from './SingleBlogPageContainer';


import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles(theme => ({
  root: {
    height: 1,
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    }
  }
}));

type BlogComponentProps = {
  links: Links
} & BlogListsPageProps

export const getMatchIdBlog = (id: string, blogLists: SingleBlog[]) => {
  return blogLists.find(blogList => String(blogList.id) === String(id));
};

type PropsFromRoute = RouteComponentProps<{ id: string }> & BlogComponentProps
export const SingleBlogInRoutes = ({ blogLists, links, ...props }: PropsFromRoute) => {
  const { id } = props.match.params;

  return (
    <SingleBlogPageContainer
      id={id}
    />
  );
};

type BlogPageProps = BlogComponentProps & RouteComponentProps<{ id: string }>
// interface BlogPageProps extends BlogListsPageProps, RouteComponentProps { links: Links }
const BlogPage = (props: BlogPageProps) => {
  const classes = useGoldThemeLeftPartStyles();
  const myClasses = useStyle();
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      paddingTop={1}
    >
      <Container style={{
        display:'flex',
        flexDirection:'column',
        flexGrow:1
      }}>
        <BreadCrumbsForBlog {...props} />
        <Box
          display={'flex'}
          flexDirection={'column'}
          flexGrow={1}
          paddingTop={1}
          paddingBottom={1}
        >
          <Box
            className={`${classes.root} ${myClasses.root}`}
            flexGrow={1}
          >
            <Route path={'/blog'} exact render={(routeProps) => (
              <BlogListsPage
                {...routeProps}
                {...props} />
            )} />
            <Route path={'/blog/:id'} render={(routeProps) => (
              <SingleBlogInRoutes
                {...props}
                {...routeProps} />
            )} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default withRouter<BlogPageProps, any>(BlogPage);