import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import RefreshBar, { RefreshBarProps } from "./RefreshBar";
import QueryRoute from "./QueryRoute";
import RouteListsContainer, {
  RouteListsContainerProps,
} from "components/driving-map/containers/Route/RouteListsContainer";
import { GoBack } from "bus-common-types";
import ProvidersList from "../CommonComponents/ProvidersList";
import { ProvidersListProps } from "../CommonComponents/types";
import { ID } from "all-common-types";
import useRoutePart from "./functions/useRoutePart";
import { ZoomInOutProps } from "../CommonComponents/ZoomInOut/types";
import ZoomInOut from "../CommonComponents/ZoomInOut/ZoomInOut";
import { OtherTransportationStations } from "./functions/HandleStopIsNearIbike";

const useStyles = makeStyles((theme) => ({
  root: {},
  barWrapper: {
    position: "relative",
  },
  zoomPart: {
    position: "absolute",
    top: 4,
    right: 60,
  },
}));

type RoutePartProps = Omit<
  RouteListsContainerProps,
  "otherTransportationStations"
> &
  Omit<RefreshBarProps, keyof ZoomInOutProps> &
  ProvidersListProps &
  Pick<OtherTransportationStations, "nearBikeStations">;

export const RoutePart = (props: RoutePartProps) => {
  const classes = useStyles();
  const {
    routeId: _routeId,
    zoomState,
    transportationStations,
    lanternStations,
  } = useRoutePart();

  return (
    <>
      <ProvidersList {...props} routeId={_routeId} />
      <RouteListsContainer
        {...props}
        otherTransportationStations={{
          ...transportationStations,
          nearBikeStations: props.nearBikeStations,
          lanternStations,
        }}
        zoomRatio={zoomState.zoomValue}
      />
      <Box className={classes.barWrapper}>
        <RefreshBar {...props} />
        <Box className={classes.zoomPart}>
          <ZoomInOut
            zoomValue={zoomState.zoomValue}
            onReset={zoomState.handleResetZoom}
            onZoomIn={zoomState.handleZoomIn}
            onZoomOut={zoomState.handleZoomOut}
          />
        </Box>
      </Box>
    </>
  );
};

export type RoutePartWithQueryProps = {
  routeId: ID;
  goBack: GoBack;
  setGoOrBackFn: (goBack: GoBack) => any;
};
const RoutePartWithQuery = (props: RoutePartWithQueryProps) => {
  return (
    <QueryRoute
      {...props}
      render={(renderProps) => (
        <RoutePart
          {...props}
          {...renderProps}
          refreshFn={renderProps.queryRoute}
          routeLists={renderProps.routeData}
          busProviders={renderProps.routeData.providers}
          nearBikeStations={renderProps.nearBikeStations}
        />
      )}
    />
  );
};

export default RoutePartWithQuery;
