import React from 'react';
import { Button, Box } from '@material-ui/core';
import { SwitchButtonProps } from './types';
import IbusImage from '../ImageComponents/IbusImage';

const SwitchButton = (props: SwitchButtonProps) => {
  return (
    <Button 
      {...props}
      onClick={props.onSwitch} 
      variant={'contained'}
      color={'default'}
    >
      <IbusImage 
        width={16}
        imageKey={'icons_switch'}
      />
    </Button>
  );
};

export default SwitchButton;