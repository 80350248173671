import React from 'react';
import { Box } from '@material-ui/core';
import SearchButton from 'components/common-components/Buttons/SearchButton';
import SearchBar from './SearchBar';
import { SearchPartProps } from './types';

const SearchPart = (props: SearchPartProps) => {
  return (
    <Box
      paddingY={1}
      display={'flex'}
      justifyContent={'flex-end'}
    >
      <SearchBar {...props} />
    </Box>
  );
};

export default SearchPart;