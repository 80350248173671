import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { MapStateToProps } from "react-function-helpers/lib/functions/mapContextToProps";
import ContextStore, {
  RouteMapState,
} from "components/route-map/constants/context";
import RouteMapContentpart from "components/route-map/components/RouteMapContentPart/RouteMapContentPart";
import {
  RouteMapContentpartContainerProps,
  RouteMapContentpartContainerWithCtxProps,
  RouteMapContentpartStatesFromCtx,
} from "./types";
import useRouteMapContentpart from "./useRouteMapContentpart";
import { connectCtx } from "react-function-helpers";
import verifyRouteId from "lib/verifyRouteId";
import HintMessage from "components/route-map/components/RouteMapContentPart/HintMessage";
import LoadingAndError from "components/common-components/LoadingAndError";
import SelectsPartWithCtx from "../SelectsPart/SelectsPart";
import { ProviderInfo } from "components/route-map/components/RouteMapContentPart";
import UpdatedAt from "components/route-map/components/RouteMapContentPart/UpdatedAt";
import { useIntl } from "react-intl";

const RouteMapContentPartContainer = (
  props: RouteMapContentpartContainerProps
) => {
  const { formatMessage } = useIntl();
  const { routeId } = props;
  const states = useRouteMapContentpart({
    routeId,
  });
  const routeIdIsValid = verifyRouteId(routeId);

  const innerComponent = () => {
    if (!routeIdIsValid)
      return <HintMessage langKey={"routeMap.pleaseSelectRoute"} />;

    if (states.loading)
      return <LoadingAndError loading={true} error={undefined} />;

    if (states.routeMapContentPartData)
      return (
        <RouteMapContentpart
          {...states}
          routeMapContentPartData={states.routeMapContentPartData}
        />
      );

    return <HintMessage langKey={"routeMap.routeDataNotFound"} />;
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={7}>
          <SelectsPartWithCtx />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          {states.routeMapContentPartData && (
            <>
              <ProviderInfo {...states.routeMapContentPartData.providerInfo} />
              <UpdatedAt updatedAt={states.routeMapContentPartData.updatedAt} />
              <Typography color={"error"} style={{ fontWeight: "bold" }}>
                {formatMessage({ id: "routeMap.hint" })}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      {innerComponent()}
    </Box>
  );
};

interface OwnProps extends RouteMapContentpartContainerWithCtxProps {}

const mapStateToProps: MapStateToProps<
  RouteMapState,
  OwnProps,
  RouteMapContentpartStatesFromCtx
> = (state) => {
  return {
    routeId: state.routeId,
  };
};

const RouteMapContentPartContainerWithCtx = connectCtx(ContextStore)(
  mapStateToProps
)(RouteMapContentPartContainer);

export default RouteMapContentPartContainerWithCtx;
