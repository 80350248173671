import { actionTypes as Types } from '../actions';
import { CollinearRoutesState, SelectedRouteId, IntervalSearchSelectedFromTo, initStopSearchFromTo, initCollinearRoutesState } from "../constants/context";
import { CollinearRoutesActionTypes } from "../actions";

export const selectedRouteId_reducer = (
  state: CollinearRoutesState, 
  action: CollinearRoutesActionTypes
): SelectedRouteId => {
  switch (action.type) {
    case Types.SET_ROUTE_ID:
      return action.payload.routeId;
    case Types.RESET_ROUTE_ID:
      return null;
    default:
      return state.selectedRouteId;
  }
};

export const stopSearchFromTo_reducer = (
  state: CollinearRoutesState, 
  action: CollinearRoutesActionTypes
): IntervalSearchSelectedFromTo => {
  switch (action.type) {
    case Types.SET_STOP_SEARCH_FROM_TO:
      return action.payload;
    case Types.RESET_STOP_SEARCH_FROM_TO:
      return initStopSearchFromTo;
    default:
      return state.intervalSearchSelectedFromTo;
  }
};

export const selectedFromStation = (
  state: CollinearRoutesState, 
  action: CollinearRoutesActionTypes
): CollinearRoutesState['selectedFromStation'] => {
  switch (action.type) {
    case Types.SET_FROM_STATION:
      return action.payload.selectedFromStation;
    case Types.RESET_FROM_STATION:
      return initCollinearRoutesState.selectedFromStation;
    default:
      return state.selectedFromStation;
  }
};