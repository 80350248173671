import React from 'react';
import { Box } from '@material-ui/core';
import StopsSelector from 'components/stop-report-page/components/StopReportInputPart/StopsSelector';
import useStopsSelector from './functions/useStopsSelector';
import { StopsSelectorContainerProps } from './types';
import TitleWrapper from 'components/stop-report-page/components/StopReportInputPart/TitleWrapper';

const StopsSelectorContainer = (props: StopsSelectorContainerProps) => {
  const stopsSelectorState = useStopsSelector(props);

  return (
    <TitleWrapper {...props} titleLangKey={'stopReport.title3'}>
      <StopsSelector
        {...props}
        {...stopsSelectorState}
        changeFn={stopsSelectorState.handleChange}
      />
    </TitleWrapper>
  );
};

export default StopsSelectorContainer;