import { ID, Locale, SingleRouteFromRoutes } from "all-common-types";
import gql from "graphql-tag";
import { sortByAttr } from "lib/fn";
import { QueriedRoutesByStationIds } from "./queryRoutesByStationIds";
import { SingleRouteWithStationId } from "./queryStationRoutesByStationName";
import StationRoutesByStationNameHandlers from "./StationRoutesByStationNameHandlers";

const RoutesByStationIdsHandlers = {
  makeSchema(stationIds: ID[], lang: Locale) {
    let schemaStr = '';
    stationIds.forEach(id => {
      const schema = StationRoutesByStationNameHandlers.makeSingleQueryStationRoutesSchema({
        stationId: Number(id),
        isFromStation: true,
        lang,
      });
      schemaStr += schema;
    });
    return gql`
      {
        ${schemaStr}
      }
    `;
  },

  filterDuplicateRoutes(routes: SingleRouteWithStationId[]) {
    let res: SingleRouteWithStationId[] = [];
    const routesObj: Record<ID, SingleRouteWithStationId> = {};
    
    routes.forEach(route => {
      routesObj[route.node.id] = route;
    });
    for (const routeId in routesObj) {
      res.push(routesObj[routeId]);
    }

    return res;
  },
  
  parseRawToRoutes(rawData: QueriedRoutesByStationIds) {
    // 借用原先的from to 的station routes，只用到fromStationRoutes
    const routes = StationRoutesByStationNameHandlers.parseQueriedDynamicSchemaFromToStationRoutes(rawData).fromStationRoutes;
    let filteredRoutes = this.filterDuplicateRoutes(routes);
    filteredRoutes = filteredRoutes.map(r => ({ ...r, seq: r.node.seq, })).sort(sortByAttr('seq'));
    return  filteredRoutes;
  }
};

export default RoutesByStationIdsHandlers;