import { useLazyQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { QueriedCmsRouteMap, QUERY_CMS_ROUTE_MAP } from '../gql-schema';
import { useCallback } from 'react';
import { ID } from 'all-common-types';
import { cmsClient } from 'constants/API';
import { RawRouteMapData } from 'route-map';

const useQueryCmsRouteMap = () => {
  let routeMapData: RawRouteMapData | null = null;

  const { locale } = useIntl();
  const [queryFn, { loading, error, data }] = useLazyQuery<QueriedCmsRouteMap>(QUERY_CMS_ROUTE_MAP, {
    client: cmsClient,
  });

  const handleQuery = useCallback((routeId: ID) => {
    queryFn({
      variables: {
        locale,
        routeId,
      }
    });
  }, [locale, queryFn]);

  if(data && data.routeMaps.length > 0) {
    routeMapData = data.routeMaps[0];
  }

  return ({
    loading,
    error,
    routeMapData,
    data,
    handleQuery,
  });
};

export default useQueryCmsRouteMap;