import React from 'react';
import { Box, FormControlLabel, Switch } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { GoBackSwitchProps } from './types';

const GoBackSwitch = (props: GoBackSwitchProps) => {
  const label = props.checked ? props.backLabel : props.goLabel;
  const labelComponent = props.checked ? props.backLabelComponent : props.goLabelComponent;

  const labelComp = (
    <>
      {labelComponent ? labelComponent : (
        <>
          {label && (
            <IntlFormattedMessage 
              langKey={'toStationWithVar'}
              values={{
                station: label
              }}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <FormControlLabel
      control={
        <Switch {...props} color={'primary'} name="goBackSwitch" />
      }
      label={labelComp}
      style={{
        maxWidth: 100,
        ...props.wrapperStyles
      }}
    />
  );
};

export default GoBackSwitch;