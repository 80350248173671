import { TicketType } from 'bus-common-types';
import { ReactNode } from 'react';

export type PriceTypes = {
  [x in TicketType]: ReactNode
}

export const ticketTypes: TicketType[] = ['full', 'half'];

export const defaultSelectedXno = 'all';

export const selectFromToSelectorMinWidth = '50%';

export const hidenETicketPriceInfoRouteIds = [
  985, 986, 987, 988
];