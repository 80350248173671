import { useIntl } from "react-intl";
import { useLazyQuery } from "@apollo/react-hooks";
import { useCallback, useMemo } from "react";
import { QUERY_BUS_PROVIDERS, QueryBusProviders } from "constants/API/commonAPI";
import { SingleProvider } from "bus-common-types";
import { sortByAttr } from "lib/fn";
import { client } from "constants/API";
import { nodeDataFromEdges } from "constants/functions";

const useQueryBusProviders = () => {
  const {
    locale,
  } = useIntl();

  const [queryFn, { loading, data } ] = useLazyQuery<QueryBusProviders>(QUERY_BUS_PROVIDERS);

  const handleQueryBusProviders = useCallback(() => {
    queryFn({
      variables: {
        lang: locale,
      }
    });
  }, [locale, queryFn]);

  const handleQueryBustProvidersPromisely = async () => {
    return client.query<QueryBusProviders>({
      variables: {
        lang: locale,
      },
      query: QUERY_BUS_PROVIDERS,
    }).then(res => {
      const providers = nodeDataFromEdges(res.data.providers);
      return providers.sort(sortByAttr('id'));
    });
  };

  const busProviders: SingleProvider[] = useMemo(() => {
    if(data) {
      const providers = nodeDataFromEdges(data.providers);
      const res = providers.sort(sortByAttr('id'));
      return res;
    } else {
      return [];
    }
  }, [data]);

  return ({
    loading,
    handleQueryBusProviders,
    handleQueryBustProvidersPromisely,
    data,
    busProviders,
  });
};

export default useQueryBusProviders;