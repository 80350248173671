import { useSelectTab } from "lib/customHooks";
import { useEffect, useCallback } from "react";
import { GoBack } from "bus-common-types";
import { Callback } from "all-common-types";

export interface UseGoBackTabOptions {
  callback?: Callback
  initGoBack?: GoBack
}

const useGoBackTab = (options: UseGoBackTabOptions) => {
  const {
    initGoBack,
    callback
  } = options;

  const initSelectedTab = typeof initGoBack === 'number' ? initGoBack - 1 : 0;
  
  const [selectedTab, selectTab] = useSelectTab(initSelectedTab);
  const goBack = selectedTab + 1 as GoBack;

  const handleResetSelectedTab = useCallback(() => {
    selectTab({}, initSelectedTab);
  }, [initSelectedTab, selectTab]);

  useEffect(() => {
    callback && callback(goBack);
  }, [callback, goBack]);

  useEffect(() => {
    selectTab({}, initSelectedTab);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSelectedTab]);

  return ({
    selectTab,
    selectedTab,
    handleResetSelectedTab,
    goBack,
  });
};
export default useGoBackTab;