import React from 'react';
import { THSRIconItemProps } from './types';
import OtherTransportationIcon from './OtherTransportationIcon';

const THSRIconItem = (props: THSRIconItemProps) => {
  return (
    <OtherTransportationIcon
      isShowIcon={props.haveTHSR}
      imageKey={'icons_thsr'}
      title={'THSR'}
      iconStyles={{
        width: 24,
      }}
    />
  );
};

export default THSRIconItem;