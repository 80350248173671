import React from 'react';
import { Box } from '@material-ui/core';
import { OtpLeg, OtpPosition  } from 'travel-planner';
import SingleStepItem, { LastStepItem } from './SingleStepItem';
import { getStepType } from 'components/travel-planner/constant/fn';

import ContextStore, { TravelPlannerState } from 'components/travel-planner/constant/context';
import { MapStateToProps } from 'lib/contextReduxLikeHelpers/mapContextToProps';
import connectCtx from 'lib/contextReduxLikeHelpers/connectCtx';
import { StepListFromCtx } from 'components/travel-planner/containers/types';
import { SingleRouteFromRoutes } from 'all-common-types';

interface Props extends StepListFromCtx{
  legs: OtpLeg[]
  to: OtpPosition
}

const StepList = (props: Props) => {
  const { legs } = props;
  return (
    <Box>
      {legs.map((leg, i) => {
        var singleRoute: SingleRouteFromRoutes | undefined;
        if (leg.mode !== 'WALK' && props.routes){
          singleRoute = props.routes.find(route => route.id === leg.route);
        }
        return (
          <SingleStepItem
            key={i}
            stepType={getStepType(legs, i)}
            singleRoute={singleRoute}
            {...leg} />
        );
      })}
      <LastStepItem {...props} stepType={'last'} />
    </Box>
  );
};

interface OwnProps extends Props {}
export const mapStateToProps: MapStateToProps<
TravelPlannerState, OwnProps, StepListFromCtx> = (state) => {
  return ({
    routes: state.routes
  });
};

export const StepListWithCtx = connectCtx(ContextStore)(mapStateToProps, undefined)(StepList);
export default StepListWithCtx;