import { useState, useCallback } from "react";
import fetch3xxTo5xxHandler from "lib/functions/fetch3xxTo5xxHandler";

export default function useFetch<T>(
  apiPath: string,
  initResponseData: T,
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setErr] = useState();
  const [responseData, setResponseData] = useState<T>(initResponseData);

  const fetchData = useCallback(() => {
    setLoading(true);

    fetch(apiPath)
      .then(fetch3xxTo5xxHandler)
      .then(res => res.json())
      .catch(e => initResponseData)
      .then(res => setResponseData(res))
      .catch(error => {
        console.error(error);
        setErr(error);
      })
      .finally(() => {
        setLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiPath]);

  return {
    loading,
    error,
    fetchData,
    responseData
  };
}