import { SingleSelectOption, Locale } from 'all-common-types';
import lang from 'static/lang/driving-map.json';
import { ALL_PROVIDERS } from 'lib/makeBusProvidersSelectorOptions';
import { SingleProvider } from 'bus-common-types';
import filterDataListByOpType from 'lib/busProviders/filterDataListByOpType';
import sortTaxiToTop from 'lib/busProviders/sortTaxiToTop';


export const allProvidersZh: SingleProvider = {
  id: ALL_PROVIDERS as any,
  name: lang.zh_TW["drivingMap.busTypeTab.selectAllProviders"],
  opType: 0,
};

export const allProvidersEn: SingleProvider = {
  ...allProvidersZh,
  name: lang.en_US["drivingMap.busTypeTab.selectAllProviders"],
};

export const getProviders = (locale: Locale, providers: SingleProvider[], ): SingleSelectOption[] => {
  const allProviderLocale = locale === 'zh' ? allProvidersZh : allProvidersEn;

  const filteredByOpTypeProviders = filterDataListByOpType(providers);
  const sortedTaxiToTopProviders = sortTaxiToTop(filteredByOpTypeProviders)
    .filter((provider) => provider.opType !== 11);
    // mrt provider OpType = 11

  const allProviders = [
    allProviderLocale,
    ...sortedTaxiToTopProviders,
  ];
  return allProviders.map((p) => ({
    ...p,
    value: String(p.id),
    text: p.name
  }));
};