import { BusInfos, SingleEstimateStop, SingleStationInEdge } from "bus-common-types";
import { BusesFragment, SingleEstimeTimeFragment } from "constants/api-fragments";
import { nodeDataFromEdges } from "constants/functions";
import { HandledRouteData } from "driving-map";
import { SingleEstimateTimeInFragment } from "gql/fragments.gql";
import fillNumber from "lib/functions/fillNumber";
import { seperateData } from "lib/seperateDataHelpers";
import { QueriedDynamicRouteData, QueriedStaticRouteData } from "../gql-schema";

const defaultEstimateTimeFragment: SingleEstimateTimeInFragment = {
  comeTime: '',
  etas: [],
  isConstruction: false,
  isEvent: false,
  isOperationDay: false,
  isSuspended: false,
  clogType: 0,
  id: '',
  goBack: 1,
  xno: -1,
};

const RouteDataHandlers = {
  defaultEstimateTimeFragment,

  convertToEstimateStop(
    station: SingleStationInEdge,
    estimateTime: SingleEstimeTimeFragment,
  ): SingleEstimateStop {
    const filledIvrNo = fillNumber(station.ivrNo);
    const estimateStop: SingleEstimateStop = {
      ...station.node,
      ...estimateTime,
      goBack: station.goBack,
      orderNo: station.orderNo,
      ivrNo: filledIvrNo,
      id: station.node.id,
      stopName: station.node.name,
      etas: estimateTime.etas.map(e => ({ ...e, eta: e.etaTime, }))
    };
    return estimateStop;
  },

  convertToBusInfos(buses: BusesFragment): BusInfos {
    const res: BusInfos = {
      edges: buses.edges.map(b => ({
        ...b,
        node: {
          ...b.node,
          carType: b.node.type,
          providerId: b.node.provider.id,
          busStatus: b.node.status,
        }
      }))
    };
    return res;
  },

  findEstimateTimeIdx(
    estimateTimes: SingleEstimeTimeFragment[],
    station: SingleStationInEdge,
  ) {
    const idx = estimateTimes.findIndex(e => (
      String(e.id) === String(station.node.id) &&
      e.goBack === station.goBack
    ));
    return idx;
  },

  mergeToEstimateStops(
    stations: QueriedStaticRouteData['route']['stations'],
    estimateTimes: QueriedDynamicRouteData['route']['estimateTimes']
  ): SingleEstimateStop[] {
    let res: SingleEstimateStop[] = [];
    let handledEstimateTimes = nodeDataFromEdges(estimateTimes);
    const _stations = (stations).edges;

    _stations.forEach(station => {
      const matchedEstimateTimeIdx = this.findEstimateTimeIdx(handledEstimateTimes, station);
      const matchedEstimateTime = handledEstimateTimes[matchedEstimateTimeIdx] || defaultEstimateTimeFragment;
      const estimateStop = this.convertToEstimateStop(station, matchedEstimateTime);
      res.push(estimateStop);

      if(matchedEstimateTimeIdx !== -1) {
        handledEstimateTimes.splice(matchedEstimateTimeIdx, 1);
      }
    });

    return res;
  },

  // 處理為舊格式以相容
  handleRawData(
    staticRouteData: QueriedStaticRouteData,
    dynamicRouteData: QueriedDynamicRouteData,
  ): HandledRouteData {
    const providers = nodeDataFromEdges(staticRouteData.route.providers)
      .filter(r => !!r); // 過濾null的provider

    const estimateStops = this.mergeToEstimateStops(staticRouteData.route.stations, dynamicRouteData.route.estimateTimes);
    const goBackEstimateStops = seperateData.makeGoBackDataToObj(estimateStops);
    const busInfos = this.convertToBusInfos(dynamicRouteData.route.buses);

    return ({
      ...staticRouteData.route,
      nearBikeStations: staticRouteData.nearBikeStation,
      providers,
      goBackEstimateStops,
      busInfos,
    });
  },
};

export default RouteDataHandlers;