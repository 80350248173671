import React from 'react';
import { ListItem, ListItemText, makeStyles, Grid, ListItemProps } from '@material-ui/core';
import { SingleSubRoute } from 'driving-map';
import { SingleRouteSelectorOption } from 'constants/functions';

const useStyles = makeStyles({
  root: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  routeName: {
    // flex: '1 1 30%',
    // textAlign: 'center',
  },
  routeDescp: { 
    // flex: '1 1 70%', 
    // textAlign: 'center',
  },
});


interface SubRoutesSelectItemProps extends ListItemProps {
  singleSubRoutesData: SingleRouteSelectorOption
  selectedRoute: string
  selectedRouteFn?: (value: string) => any
}

export const SubRoutesSelectItem = (props: SubRoutesSelectItemProps) => {
  const classes = useStyles();
  const {
    button,
    singleSubRoutesData,
    selectedRoute,
    selectedRouteFn
  } = props;
  const {
    name, value, description
  } = singleSubRoutesData;
  const handleSelectRoute = (val: string) => {
    return selectedRouteFn && (() => selectedRouteFn(val));
  };
  return (
    <ListItem 
      className={classes.root}
      onClick={ handleSelectRoute(value) }
      //use routeName
      selected={ selectedRoute === name }
      button={button as any}
    >
      <Grid container>
        <Grid item xs={3}>
          <ListItemText 
            className={ classes.routeName } 
            primary={ name } />
        </Grid>
        <Grid item xs={9}>
          <ListItemText 
            className={ classes.routeDescp } 
            primary={ description } />
        </Grid>
      </Grid>
    </ListItem>
  );
};