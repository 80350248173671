import React from 'react';
import { Box, Button } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { SearchButtonProps } from './types';

const IntlSearchButton = (props: SearchButtonProps) => {
  return (
    <Button 
      color={'primary'}
      variant={'contained'}
      onClick={props.onSearch}
    >
      <IntlFormattedMessage 
        style={{
          color: '#fff',
        }}
        langKey={'search'}
      />
    </Button>
  );
};

export default IntlSearchButton;