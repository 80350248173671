export interface ProvidersIdNameHashMap {
  [providerId: number]: string
}

function getProviderNameHashMap<SingleProvider extends {id: number, name: string}>(providers: SingleProvider[]): ProvidersIdNameHashMap { 
  const res: ProvidersIdNameHashMap = providers.reduce((prev, next) => {
    prev = {
      ...prev,
      [next.id]: next.name,
    };
    return prev;
  }, {});

  return res;
}

export default getProviderNameHashMap;