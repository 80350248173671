import { getRouteStopMarkers, getGoBackRouteline } from "components/driving-map/constants/functions";
import { RouteDataType, GoBackStr, Position } from "bus-common-types";
import { GoBackMarkersAndRouteLine } from "../RouteStopsAndRouteLine";
import { seperateData, getGoBackResultByResultType } from "lib/seperateDataHelpers";
import { RouteStopMarkerProps } from "../RouteStopMarker";
import { SelectedRouteStopId } from "components/driving-map/constants/context";
import { mapConfigs, defaultMapBounds } from "config";
import { RouteDataForMapPart } from "driving-map";

export const getGoBackRouteStopMarkersAndPoints = (routeData: RouteDataForMapPart): GoBackMarkersAndRouteLine => {
  const routeStopMarkers = getRouteStopMarkers(routeData);
  const goBackMarkers = seperateData.fromGoBackData(routeStopMarkers)(getGoBackResultByResultType)('obj');
  const goBackRouteline = getGoBackRouteline(routeData.routePoint);
  const getGoBack = (goBack: GoBackStr) => ({
    routeStopMarkers: goBackMarkers[goBack],
    routePoints: goBackRouteline[goBack]
  });
  return ({
    go: getGoBack('go'),
    back: getGoBack('back')
  });   
};

type MapSpec = {
  center: Position
  bounds: Position[] | undefined
  zoom: number
}
type GetMapCenterParams = {
  position: Position, 
  routeStopMarkers: {position: Position}[], 
  selectedRouteStopIndex: SelectedRouteStopId
}
type GetMapSpecParams = GetMapCenterParams & {
  routePoints: Position[]
}
export class handleMapSpec {
  static checkIsRouteStopSelected(routeStopIndex: SelectedRouteStopId) {
    return typeof routeStopIndex === 'number';
  }

  static getBounds (
    isRouteStopSelected: boolean, 
    routePoints: Position[]
  ) {
    if(routePoints.length === 0) {
      return undefined;
    }
    //--Q-- 需要判斷是否被選嗎???
    return isRouteStopSelected ? routePoints : routePoints;
  };

  static getCenter({
    position,
    routeStopMarkers,
    selectedRouteStopIndex
  }: GetMapCenterParams): Position {
    if(typeof selectedRouteStopIndex === 'number') {
      const targetMarker = routeStopMarkers[selectedRouteStopIndex];
      if(!targetMarker) {
        return position;
      } else {
        return targetMarker.position;
      }
    }
    return position;
  }

  static getZoom(isRouteStopSelected: boolean): number {
    return isRouteStopSelected ? 
      mapConfigs.zoom.focused : mapConfigs.zoom.unfocused;
  }

  static getSpec(params: GetMapSpecParams): MapSpec {
    const {
      selectedRouteStopIndex,
      routePoints
    } = params;

    const isSelected = handleMapSpec.checkIsRouteStopSelected(selectedRouteStopIndex);
    const center = handleMapSpec.getCenter(params);
    const bounds = handleMapSpec.getBounds(isSelected, routePoints);
    const zoom = handleMapSpec.getZoom(isSelected);

    return ({
      center,
      bounds,
      zoom,
    });
  }
} 
