import { RouteListItemActionTypes } from './routeListItem_actions';
import { RouteListsActionTypes } from './routeLists_actions';
import IbikeInfoListActions from './ibikeInfoList-actions';

export * from './routeListItem_actions';
export * from './routeLists_actions';

export enum actionTypes {
  SET_ROUTE_STOP_ID,
  RESET_ROUTE_STOP_ID,
  
  SET_ROUTE_DATA,
  RESET_ROUTE_DATA,

  SET_IBIKE_INFO_LIST,
}

export type AllActionTypes = 
  RouteListItemActionTypes | 
  RouteListsActionTypes |
  IbikeInfoListActions