import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { ClogMessageListProps } from './types';
import ClogMessageItem from './ClogMessageItem';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const ClogMessageList = (props: ClogMessageListProps) => {
  const {
    clogMessages,
  } = props;

  if(clogMessages.length === 0) {
    return (
      <Box
        padding={1}
        textAlign={'center'}
      >
        <IntlFormattedMessage 
          langKey={'drivingMap.mapControl.clog.noMessage'}
        />
      </Box>
    );
  }

  return (
    <Box
      padding={1}
    >
      {clogMessages.map(c => (
        <>
          <ClogMessageItem 
            key={c.id}
            {...c}
          />
          <Divider />
        </>
      ))}
    </Box>
  );
};

export default ClogMessageList;