import { getDate } from "lib/fn";

const latestDataTimeRange = 3 * 60; //3min

export const checkDataTimeIsInRange = (time: Date | string) => {
  const timeNow = new Date().getTime();
  const busTime = getDate(time).getTime();

  const timeIsInRange = (busTime - timeNow) <= latestDataTimeRange * 1000;
  return timeIsInRange;
};

export const checkBusStatusIsAccepted = (busStatus: number) => (
  busStatus === 0 || busStatus === 3 || busStatus === 90
);

const checkBusShouldDisplay = (busStatus: number, time: Date | string) => {
  const timeIsInRange = checkDataTimeIsInRange(time);
  const busStatusIsAccepted = checkBusStatusIsAccepted(busStatus);

  if(timeIsInRange && busStatusIsAccepted) {
    return true;
  }
  return false;
};

export default checkBusShouldDisplay;