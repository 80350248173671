import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
// import { FilterSelector } from "ibus-common-components";
import FilterSelector from "./FilterSelectorContainer";
import { IntlFilterSelectorProps } from "./types";
import commonLangs from "static/lang/commonLangs.json";

const useStyles = makeStyles((theme) => {
  return theme.name == "xmas"
    ? {
        root: {
          flex: 1,
          "& button": {
            backgroundColor: theme.palette.primary.main,
            color: "#ffffff",
          },
          "& button:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#ffffff",
          },
        },
      }
    : { root: { flex: 1 } };
});

const IntlFilterSelector = (props: IntlFilterSelectorProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <FormattedMessage
        id={"selectOneRoute"}
        defaultMessage={commonLangs.zh_TW.selectOneRoute}
      >
        {(text) => (
          <FilterSelector {...props} defaultSelectedText={text as string} />
        )}
      </FormattedMessage>
    </Box>
  );
};

export default IntlFilterSelector;
