import React, { ReactNode } from 'react';
import { Box, Hidden, Drawer, makeStyles } from '@material-ui/core';
import { Callback } from 'all-common-types';

export interface AppDrawerWrapperOpenClose {
  open: boolean
  onClose: Callback
}

export interface AppDrawerWrapperProps extends AppDrawerWrapperOpenClose {
  children: ReactNode
}

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.complementary.dark,
  }
}));

const AppDrawerWrapper = (props: AppDrawerWrapperProps) => {
  const classes = useStyles();

  return (
    <Hidden smUp>
      <Drawer
        {...props}
        variant={'temporary'}
        anchor={'top'}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          className={classes.root}
        >
          {props.children}
        </Box>
      </Drawer>
    </Hidden>
  );
};

export default AppDrawerWrapper;