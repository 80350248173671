import React from 'react';
import { Box } from '@material-ui/core';
import ScheduleTimeHandlers from './functions/ScheduleTimeHandlers';
import { NoonScheduleItemProps } from './types';
import ScheduleList from './ScheduleList';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import NoonTitle from './NoonTitle';

const NoonScheduleItem = ({
  scheduleListData, noonType
}: NoonScheduleItemProps) => {
  const dividedSchedules = ScheduleTimeHandlers.splitByHalf(scheduleListData);

  return (
    <Box
    >
      <NoonTitle 
        noonType={noonType}
      />
      <Box
        // display={'flex'}
      >
        {/* {dividedSchedules.map((s, i) => (
          <ScheduleList 
            key={i}
            scheduleListData={s}
          />
        ))} */}
        <ScheduleList 
          scheduleListData={scheduleListData}
        />
      </Box>
    </Box>
  );
};

export default NoonScheduleItem;