import { GoBack } from "bus-common-types";
import { INTERSECTION_STATION_SID } from "components/mrt-fare/config";
import { SingleMrtFare } from "components/mrt-fare/gql/fragments.gql";
import { QueriedMrtFares } from "components/mrt-fare/gql/schema.gql";
import getContentStr from "components/mrt-fare/lib/functions/getContentStr";
import { HandledStations } from "components/mrt-fare/lib/Handlers/MrtStationsCombineHandlers";
import { nodeDataFromEdges } from "constants/functions";
import { IntersectionStationContents, SingleBasicMrtFare, SingleBasicStationForMrtAndBus, StationContentMode, StationType } from "mrt-pages";
import { HandledStationsWithIntersectionContents } from "../custom-hooks/useMrtStationsFares";

interface SingleBasicStationForMrtAndBusWithFare extends SingleBasicStationForMrtAndBus {
  allFares: SingleBasicMrtFare
}

const MrtStationsFareHandlers = {
  initFares: {
    eCitizen: '',
    full: '',
    reduced: '',
  } as SingleBasicMrtFare,

  initIntersectionStationContents: {
    bus: '',
    mrtGreen: '',
  } as IntersectionStationContents,

  getContentByFareAndContentMode(
    contentMode: StationContentMode,
    fare: SingleBasicMrtFare,
    type: StationType
  ) {
    switch (contentMode) {
      case 'FARE_ETICKET_FULL':
        // 捷運沒有市民卡票價，所以改為用full(全票)
        return type === 'MRT_GREEN' ? fare.full : fare.eCitizen;
      case 'FARE_FULL':
        return fare.full;
      case 'FARE_REDUCED':
        return fare.reduced;
      default:
        return '';
    }
  },
  convertFaresToContent(stationWithFare: SingleBasicStationForMrtAndBusWithFare, contentMode: StationContentMode) {
    return (isSameType: boolean, type: StationType) => {
      const content = this.getContentByFareAndContentMode(contentMode, stationWithFare.allFares, type);
      const decoratedContent = getContentStr(contentMode, content, isSameType);

      return ({
        ...stationWithFare,
        content: decoratedContent,
      });
    };
  },

  checkStationPassMrt(stationIds: number[]) { // 檢查資料是否經過捷運路線
    for (let i = 0; i < stationIds.length; i++) {
      const stationId = stationIds[i];
      // 如果有交集站點的資料，表示顯示的資料有大於或等於交集站點
      if(INTERSECTION_STATION_SID.BUS.includes(stationId)) {
        return true;
      }
    }
    return false;
  },

  checkStationPassMrtByCommonStationFares(commonBusStationsWithFares: SingleBasicStationForMrtAndBusWithFare[]) {
    const filteredCommonBusStationList = commonBusStationsWithFares
      .filter(f => {
        const haveFare = typeof f.allFares.full === 'number';
        return haveFare;
      })
      .map(f => f.stationId);
    // console.log(filteredCommonBusStationList);
    const isStationPassMrt = this.checkStationPassMrt(filteredCommonBusStationList);
    return isStationPassMrt;
  },

  combineSingleRouteStations(
    stationListData: SingleBasicStationForMrtAndBus[],
    fares: SingleMrtFare[],
    isPassedMrt=true,
  ) {
    let res: SingleBasicStationForMrtAndBusWithFare[] = [];

    for (let i = 0; i < stationListData.length; i++) {
      const station = stationListData[i];
      const matchedFare = fares.find(f => f.to === station.stationId);
      const allFares: SingleBasicMrtFare = (matchedFare && isPassedMrt) ?
        matchedFare : this.initFares;

      res[i] = {
        ...station,
        allFares,
      };
    }

    return res;
  },

  mapStationsWithFare(selectedType: StationType, contentMode: StationContentMode) {
    return (type: StationType) => (station: SingleBasicStationForMrtAndBusWithFare) => {
      const isSameType = selectedType === type;
      return (
        this.convertFaresToContent(station, contentMode)(isSameType, type)
      );
    };
  },

  getIntersectionStationContents(
    busStations: SingleBasicStationForMrtAndBus[],
    mrtGreenStations: SingleBasicStationForMrtAndBus[]
  ): IntersectionStationContents {
    const busStation = busStations.find(b => INTERSECTION_STATION_SID.BUS.includes(b.stationId));
    const mrtGreenStation = mrtGreenStations.find(b => INTERSECTION_STATION_SID.MRT_GREEN.includes(b.stationId));

    const bus = busStation ? busStation.content : '';
    const mrtGreen = mrtGreenStation ? mrtGreenStation.content : '';

    return ({
      bus,
      mrtGreen
    });
  },

  combineStationsWithFaresRawData(
    handledStations: HandledStations,
    faresRawData: QueriedMrtFares | undefined,
    goBack: GoBack
  ) {
    if(!faresRawData) {
      return (): HandledStationsWithIntersectionContents => ({
        ...handledStations,
        intersectionContents: this.initIntersectionStationContents,
      });
    }
    const {
      route309Fares,
      route310Fares,
      mrtGreenFares,
    } = faresRawData;

    const route309FareList = nodeDataFromEdges(route309Fares).filter(f => f && f.goBack === goBack);
    const route310FareList = nodeDataFromEdges(route310Fares).filter(f => f && f.goBack === goBack);
    const commonFareList = route309FareList.length > 0 ? route309FareList : route310FareList;
    const mrtGreenFareList = nodeDataFromEdges(mrtGreenFares).filter(e => e);

    const route309OtherStationsWithFares = this.combineSingleRouteStations(
      handledStations.route309OtherStations, route309FareList
    );
    const route310OtherStationsWithFares = this.combineSingleRouteStations(
      handledStations.route310OtherStations, route310FareList
    );
    const commonBusStationsWithFares = this.combineSingleRouteStations(
      handledStations.commonBusStations, commonFareList
    );

    const isStationPassMrt = this.checkStationPassMrtByCommonStationFares(commonBusStationsWithFares);
    const mrtGreenStationsWithFares = this.combineSingleRouteStations(
      handledStations.mrtGreenStations, mrtGreenFareList, isStationPassMrt,
    );

    return (contentMode: StationContentMode, selectedType: StationType) => {
      const mapFn = this.mapStationsWithFare(selectedType, contentMode);
      const commonBusStations = commonBusStationsWithFares.map(mapFn('BUS'));
      const mrtGreenStations = mrtGreenStationsWithFares.map(mapFn('MRT_GREEN'));

      const intersectionContents = this.getIntersectionStationContents(
        commonBusStations, mrtGreenStations
      );

      return ({
        // ...handledStations,
        route309OtherStations: route309OtherStationsWithFares.map(mapFn('BUS')),
        route310OtherStations: route310OtherStationsWithFares.map(mapFn('BUS')),
        commonBusStations,
        mrtGreenStations,
        intersectionContents,
      });
    };
  },
};

export default MrtStationsFareHandlers;