import IbusCommonActionTypes from './index';
import CommonStateTypes from 'reducers/common-reducers/state';
import { RoutesSelectorOptions } from 'constants/functions';

export interface SetSelectedRouteActionPayLoad {
  routes: RoutesSelectorOptions,
  id: CommonStateTypes['selectedRoute']['id']
}
export interface SetSelectedRouteAction {
  type: IbusCommonActionTypes.SET_SELECTED_ROUTE
  payload: SetSelectedRouteActionPayLoad
}

export interface ResetSelectedRouteAction {
  type: IbusCommonActionTypes.RESET_SELECTED_ROUTE
}

export const setSelectedRoute = (payload: SetSelectedRouteActionPayLoad): SetSelectedRouteAction => ({
  type: IbusCommonActionTypes.SET_SELECTED_ROUTE,
  payload,
});

export const resetSelectedRoute = (): ResetSelectedRouteAction => ({
  type: IbusCommonActionTypes.RESET_SELECTED_ROUTE,
});

type SelectedRouteActions = SetSelectedRouteAction | ResetSelectedRouteAction
export default SelectedRouteActions;