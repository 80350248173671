import { useEffect } from "react";
import useReportDescriptionInput, { UseReportDescriptionInputOptions } from "./useReportDescriptionInput";
import useReportDescriptionSelector, { UseReportDescriptionSelectorOptions } from "./useReportDescriptionSelector";

export interface UseReportDescriptionPartOptions extends UseReportDescriptionSelectorOptions, UseReportDescriptionInputOptions {
  
}

const useReportDescriptionPart = (options: UseReportDescriptionPartOptions) => {
  const reportDescriptionSelectorState = useReportDescriptionSelector(options);
  const reportDescriptionInputState = useReportDescriptionInput(options);

  useEffect(() => {
    const isOtherValue = reportDescriptionSelectorState.value === 'other';
    if(isOtherValue) {
      reportDescriptionInputState.setVal('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDescriptionSelectorState.value]);

  return ({
    reportDescriptionSelectorState,
    reportDescriptionInputState,
  });
};

export default useReportDescriptionPart;