import React from 'react';
import { OtpItinerary } from 'travel-planner';
import SingleStepLineItem, { getPosition } from './SingleStepLineItem';
import { Marker } from 'react-leaflet';
import { getStepType } from 'components/travel-planner/constant/fn';
import getStepLineItemIcon from './getStepLineItemIcon';

type Props = OtpItinerary
const StepLines = ({ legs, to }: Props) => {
  return (
    <>
      {legs.map((leg, i) => (
        <SingleStepLineItem
          key={i}
          {...leg}
          stepType={getStepType(legs, i)} />
      ))}
      <Marker
        position={getPosition(to)}
        icon={getStepLineItemIcon('last')} />
    </>
  );
};

export default StepLines;