function reverseArrData<Data>(dataList: Data[]) {
  let res: Data[] = [];
  const dataLength = dataList.length; 

  for (let i = 0; i < dataLength; i++) {
    const reversedIndex = (dataLength - 1) - i;
    const data = dataList[reversedIndex];
    res[i] = data;
  }

  return res;
}
export default reverseArrData;