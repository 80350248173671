import React from 'react';
import SelectRoutes from 'components/fare/components/SearchPart/SelectRoutes';
import { getRoutesSelectorOptions } from 'constants/functions';
import ContextStore, { FareState } from 'components/fare/constant/context';
import { MapStateToProps } from 'lib/contextReduxLikeHelpers/mapContextToProps';
import { connectCtx } from 'react-function-helpers/lib/index';
import { MapDispatchToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import { SetSelectedRouteActionPayLoad, setSelectedRoute } from 'actions/common-actions/selectedRoute-actions';
import { SelectRoutesContainerProps } from '../types';
import { resetFromToStopsId } from 'components/fare/actions';
import useSelectRoutes from './functions/useSelectRoutes';

export const SelectRoutesContainer = (props: SelectRoutesContainerProps) => {
  const {
    routes,
    selectedRoute,
  } = props;

  const {
    handleSelectRoute
  } = useSelectRoutes(props);

  const defaultSelectedRouteFromRouter = {
    text: selectedRoute.name || '',
    value: String(selectedRoute.id),
  };

  return (
    <SelectRoutes
      {...props}
      defaultSelectedSingleSelectorOption={defaultSelectedRouteFromRouter}
      getSelectedOptionFn={handleSelectRoute}
      options={routes} />
  );
};

type OwnProps = {}

export const mapStateToProps: MapStateToProps<FareState, OwnProps, SelectRoutesContainerProps> = (state) => {
  return ({
    busProviderId: state.busProviderId,
    selectedRoute: state.selectedRoute,
    routes: getRoutesSelectorOptions(state.routes),
  });
};

export const mapDispatchToProps: MapDispatchToProps<OwnProps, {
  setSelectedRouteFn: (payload: SetSelectedRouteActionPayLoad) => any
  resetFromToStopsIdFn: () => any
}> = (dispatch) => {
  return ({
    setSelectedRouteFn: (payload) => {
      dispatch(setSelectedRoute(payload));
    },
    resetFromToStopsIdFn: () => {
      const action = resetFromToStopsId();
      dispatch(action);
    }
  });
};

const SelectRoutesContainerWithCtx = connectCtx(ContextStore)(mapStateToProps, mapDispatchToProps)(SelectRoutesContainer);

export default SelectRoutesContainerWithCtx;