import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import ibusImages from 'static/ibusImages.json';

const useStyles = makeStyles({
  topFlag: {
    width: 16,
    height: 16,
    // backgroundColor: '#f0a',
    background: `url(${ibusImages.icons_blog_top_flag.src})`,
    backgroundRepeat: 'none',
    borderRadius: 10000,
  }
});

const TopFlag = () => {
  const classes = useStyles({});
  return (
    <Box className={classes.topFlag}>
    </Box>
  );
};

export default TopFlag;