import { ImagePreviewFile } from "all-common-types";

const readFileList = (onSetPreviewFile: (file: ImagePreviewFile) => any) => (fileList: FileList | null) => {

  if(fileList && fileList.length > 0) {
    for (const _file of fileList) {
      const reader = new FileReader();
      const fileName = _file.name;

      reader.onload = (e) => {
        e.target && onSetPreviewFile({
          src: e.target.result as string,
          name: fileName,
        });
      };
      reader.readAsDataURL(_file);
    }
  }
};

export default readFileList;