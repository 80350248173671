import React, { useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import useQueryRoutes from 'lib/customHooks/useQueryRoutes';
import RoutesSelectsWithCtx from 'components/driving-map/components/BusSelect/RoutesSelects';
import { BusRoutesSelectsContainerProps, BusRoutesSelectsContainerWithCtxProps } from '../types';
import { MapStateToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import ContextStore, { DrivingMapState } from 'components/driving-map/constants/context';
import { connectCtx } from 'react-function-helpers';
import BusRoutesSelectsHandlers from './functions/BusRoutesSelectsHandlers';

const BusRoutesSelectsContainer = (props: BusRoutesSelectsContainerProps) => {
  const {
    busType,
    busProviderId,
    routeIdsForFilter,
  } = props;

  const {
    loading,
    selectorOptionsRoutes: routes,
  } = useQueryRoutes(busType, busProviderId, [11]);

  const filteredRoutes = useMemo(() => (
    BusRoutesSelectsHandlers.filterRoutes({
      routes, busType, routeIdsForFilter,
    })
  ), [busType, routeIdsForFilter, routes]);

  if(loading) return (
    <CircularProgress />
  );

  return (
    <RoutesSelectsWithCtx
      rawAllRoutes={routes}
      routes={filteredRoutes} />
  );
};


const mapStateToProps: MapStateToProps<DrivingMapState, BusRoutesSelectsContainerWithCtxProps, {
  busProviderId: BusRoutesSelectsContainerProps['busProviderId']
}> = (state) => {
  return ({
    busProviderId: state.busProviderId,
  });
};


const BusRoutesSelectsContainerWithCtx = connectCtx(ContextStore)(mapStateToProps)(BusRoutesSelectsContainer);

export default BusRoutesSelectsContainerWithCtx;