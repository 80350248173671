import React from 'react';
import { SingleQueryStationByName } from "constants/API/commonAPI";
import { SingleStationInStationsByName } from "gql/schema.gql";
import { ValueResult } from "ibus-common-components/lib/components/common-components/AutoComplete/types";
import { removeDuplicates } from "lib/fn";
import MapHelpers from "lib/MapHelpers";
import { StationAndAddressAutoCompleteResultType } from "./types";
import { TravelPlannerAutoCompleteIconType } from 'travel-planner';
import { Place, DirectionsBus, DirectionsSubway, Home } from '@material-ui/icons';

class StationNameAutoCompleteHandlers {
  static makeIconByType(type: TravelPlannerAutoCompleteIconType) {
    switch (type) {
      case 'SPOT':
        return (
          <Place style={{
            color: '#ba1e1e',
          }} />
        );
      case 'STATION':
        return (
          <DirectionsBus style={{
            color: '#3b6ebf',
          }} />
        );
      case 'MRT':
        return (
          <DirectionsSubway style={{
            color: '#8EB72A',
          }} />
        );
      case 'ADDRESS':
        return (
          <Home />
        );
    }
  }

  static makeSingleValueResult(station: SingleStationInStationsByName): ValueResult<StationAndAddressAutoCompleteResultType> {
    const res: ValueResult<StationAndAddressAutoCompleteResultType> = {
      icon: this.makeIconByType('STATION'),
      value: station.name,
      result: {
        name: station.name,
        position: MapHelpers.getPosition(station),
      }
    };
    return res;
  }

  static makeValueResultList(stations: SingleStationInStationsByName[]): ValueResult<StationAndAddressAutoCompleteResultType>[] {
    let res: ValueResult<StationAndAddressAutoCompleteResultType>[] = [];
    let stationNameNow = '';

    stations.forEach((s) => {
      if(s.name !== stationNameNow) {
        res = [
          ...res,
          this.makeSingleValueResult(s),
        ];
      }
      stationNameNow = s.name;
    });

    return res;
  }

  static filterSameStationId(stations: SingleQueryStationByName[]) {
    const res = removeDuplicates(stations, s => s.id);
    return res;
  }

  
}

export default StationNameAutoCompleteHandlers;