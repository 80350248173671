import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  indicator: {
    width: theme.spacing(0.75),
    marginRight: theme.spacing(1),
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.homepageColors.titleColor,
  },
  titleZh: {
    fontSize: 24,
    // color: theme.palette.text.primary
    // color: theme.palette.primary.light,
    color: theme.homepageColors.titleColor,
  },
  titleEng: {
    display: 'inline',
    fontSize: 20,
    marginLeft: 6,
    // color: theme.palette.text.secondary
    color: theme.homepageColors.titleColor,
    // color: theme.palette.common.white,
  }
}));

export type TitleProps = {
  titleZh: string
  titleEng: string
}
const Title = ({ titleZh, titleEng }: TitleProps) => {
  const classes = useStyles();
  return (
    <Box display={'flex'} className={classes.root}>
      <Box className={classes.indicator} />
      <Typography 
        variant={'h3'} 
        className={classes.titleZh}
      >
        {titleZh}
        <Typography 
          variant={'subtitle1'} 
          className={classes.titleEng}
        >
          {titleEng}
        </Typography>
      </Typography>
    </Box>
  );
};

export default Title;