import { RouteMapState } from "components/route-map/constants/context";
import useQueryCmsRouteMap from "components/route-map/gql/custom-hooks/useQueryCmsRouteMap";
import useQueryRouteForRouteMap from "components/route-map/gql/custom-hooks/useQueryRouteForRouteMap";
import useQueryVisibleJointOperator from "components/route-map/gql/custom-hooks/useQueryVisibleJointOperator";
import { useEffect } from "react";
import { RouteMapContentPartData } from "components/route-map/components/RouteMapContentPart/types";
import checkAllAreLoaded from "lib/checkAllAreLoaded";
import verifyRouteId from "lib/verifyRouteId";
import RouteMapContentHandlers from "./functions/RouteMapContentHandlers";

export interface UseRouteMapContentpartOptions {
  routeId: RouteMapState['routeId']
}

const useRouteMapContentpart = (options: UseRouteMapContentpartOptions) => {
  let routeMapContentPartData: RouteMapContentPartData | null = null;
  const {
    routeId,
  } = options;

  const {
    loading: loadingQueryCmsRouteMap,
    routeMapData,
    handleQuery: handleQueryCmsRouteMap,
  } = useQueryCmsRouteMap();

  const {
    loading: loadingQueryVisibleJointOperator,
    data: jointOperators
  } = useQueryVisibleJointOperator();

  const {
    loading: loadingQueryRouteForRouteMap,
    data: routeData,
    handleQuery: handleQueryRouteForRouteMap,
  } = useQueryRouteForRouteMap();

  const loading = !checkAllAreLoaded([
    loadingQueryCmsRouteMap,
    loadingQueryRouteForRouteMap,
    loadingQueryVisibleJointOperator,
  ]);

  useEffect(() => {
    if(verifyRouteId(routeId) && routeId !== null) {
      handleQueryCmsRouteMap(routeId);
      handleQueryRouteForRouteMap(routeId);
    }
  }, [handleQueryCmsRouteMap, handleQueryRouteForRouteMap, routeId]);

  if(routeMapData && routeData) {
    // handle routeMapContentData
    let route = { ...routeData.route };
    if (jointOperators.length > 0){
      let jointOperatiorIds = jointOperators
        .filter(operator => operator.routeId === Number(routeId))
        .map(operator => operator.providerId);

      route = {
        ...routeData.route,
        providers: {
          ...routeData.route.providers,
          edges: routeData.route.providers.edges.filter( edge => {
            const providerId = Number(edge.node.id);
            if (providerId === 3 || providerId === 15){
              return jointOperatiorIds.includes(Number(edge.node.id));
            }
            return true;
          })
        }
      };
    }

    routeMapContentPartData = RouteMapContentHandlers.makeRouteMapContent({
      routeData: route,
      routeMapData,
    });
  }

  const res = ({
    loading,
    routeMapContentPartData,
  });

  return res;
};

export default useRouteMapContentpart;