import React from 'react';
import { IbikeIconItemProps } from './types';
import OtherTransportationIcon from './OtherTransportationIcon';

const IbikeIconItem = (props: IbikeIconItemProps) => {
  return (
    <OtherTransportationIcon
      isShowIcon={props.haveIbike}
      imageKey={'icons_ibike'}
      title={'ibike 1.0'}
    />
  );
};

export default IbikeIconItem;