import { SelectFromToContainerProps } from "../types";
import { useSelectorSelect } from 'lib/customHooks/useSelectorSelect';
import { useEffect } from "react";
import { useToggle } from "lib/customHooks";

export interface UseSelectFromToOptions extends SelectFromToContainerProps {
}

function useSelectFromTo({
  fromToStops,
  fromToStopsId,
  setFromToStopsIdToCtx,
}: UseSelectFromToOptions) {
  const {
    handleSelect,
    values: fromToValues,
  } = useSelectorSelect(fromToStopsId, setFromToStopsIdToCtx);
  const {
    toggle: fromToggle,
    setToggle: setFromToggle,
    handleToggle: handleFromToggle,
  } = useToggle();
  const {
    toggle: toToggle,
    setToggle: setToToggle,
    handleToggle: handleToToggle,
  } = useToggle();

  //reset toStationId when from stop changed
  useEffect(() => {
    setFromToStopsIdToCtx && setFromToStopsIdToCtx({
      from: fromToValues.from,
      to: '',
    });
  }, [setFromToStopsIdToCtx, fromToValues.from]);

  // 切換路線後初始化toggle
  const JSONStops = fromToStops.anotherWayStops.map(s => s.id).join('');
  useEffect(() => {
    setFromToggle(false);
    setToToggle(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSONStops]);

  const fromToToggles = {
    from: { fromToggle, handleFromToggle },
    to: { toToggle, handleToToggle, }
  };

  return ({
    handleSelect,
    fromToValues,
    fromToToggles,
  });
}

export default useSelectFromTo;