import { EdgesData, ID } from "all-common-types";
import { EstimateTimeHandlerOptions, GoBack } from "bus-common-types";
import gql from "graphql-tag";

const estimateTimesFragment = gql`
  fragment estimateTimesFragment on StationEstimateConnection {
    edges {
      node {
        xno
        id
        goBack
        comeTime
        isSuspended
        isConstruction
        isEvent
        isOperationDay
        clogType
        etas {
          etaTime
          isLast
        }
      }
    }
  }
`;

export interface SingleEstimateTimeInFragment extends EstimateTimeHandlerOptions {
  xno: number
  id: string
  goBack: GoBack
}
export type EstimateTimesFragment = EdgesData<SingleEstimateTimeInFragment>

const stationsByNameFragment = gql`
  fragment stationsByNameFragment on StationListConnection {
    edges {
      node {
        id
        name
        lat
        lon
      }
    }
  }
`;
export interface SingleStationByNameInFragment {
  id: ID
  name: string
  lat: number
  lon: number
}
export type StationsByNameFragment = EdgesData<SingleStationByNameInFragment>

const gqlFragments = {
  estimateTimesFragment,
  stationsByNameFragment,
};

export default gqlFragments;