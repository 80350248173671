import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { SingleIconLinkProps } from './iconLinks';

const imageRootDir = './asset/images/home-page/nav-images/';

const useStyles = makeStyles(theme => ({
  root: {
    width: 100,
    height: 100, 
  }
}));

interface SingleIconLinkImageProps extends SingleIconLinkProps {}

const SingleIconLinkImage = ({ imageSrc, title }: SingleIconLinkImageProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img alt={title} src={imageRootDir + imageSrc} />
    </Box>
  );
};

export default SingleIconLinkImage;