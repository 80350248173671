import { ID, Locale } from "all-common-types";
import { FromTo } from "collinear-routes";
import RoutesByStationNameHandler from "components/collinear-routes/containers/Handlers/RoutesByStationNameHandler";
import { useToggle } from "lib/customHooks";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import queryStationRoutesByStationName, { FromToStationRoutes } from "../functions/queryStationRoutesByStationName";

function useQueryFromToStationRoutes() {
  const {
    locale,
  } = useIntl();

  const [error, setError] = useState();
  const [data, setData] = useState<FromToStationRoutes>();
  const {
    toggle: loading,
    setToggle: setLoading,
  } = useToggle();

  const handleQuery = useCallback((fromToStationName: FromTo) => {
    (async() => {
      setLoading(true);
      const data = await queryStationRoutesByStationName(fromToStationName, locale as Locale);
      setData(data);
      setLoading(false);
    })();
  }, [locale, setLoading]);

  const handledFinalResult = useMemo(() => (
    data ? (
      RoutesByStationNameHandler.getFinalFromToStationRoutesForEstimateStopInput(data)
    ) : (
      RoutesByStationNameHandler.defaultRoutesByStationNameHandlerFinalResult
    )
  ), [data]);

  return ({
    error,
    loading,
    data,
    handledFinalResult,
    handleQuery,
  });
};

export default useQueryFromToStationRoutes;