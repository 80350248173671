/* eslint-disable no-restricted-globals */
import { MAX_UPLOAD_IMAGE_AMOUNTS } from "components/stop-report-page/config";
import { UPLOAD_API } from "components/stop-report-page/constants/API";
import { initReporDataOption } from "components/stop-report-page/containers/StopReportInputPart/functions/useStopReportInputPart";
import useDragDropFiles from "lib/customHooks/useDragDropFiles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MakeCreateStopReportDataOptions, MakeCreateStopReportDataOptionsKeys } from 'stop-report';
import checkAllAreNotError from "./checkAllAreNotError";
import useUploadAndPreviewFiles from "./useUploadAndPreviewFiles";
import { useIntl } from "react-intl";

export type ReportOptionsErr = {
  [key in MakeCreateStopReportDataOptionsKeys]: boolean
}

export const initReportOptionsErr: ReportOptionsErr = 
  {
    routeId: false,
    stopId: false,
    goBack: false,
    description: false,
  };

export const getReportOptionsErr = (options: MakeCreateStopReportDataOptions): ReportOptionsErr => ({
  routeId: !options.routeId,
  stopId: !options.stopId,
  goBack: !options.goBack,
  description: !options.description,
});

const useStopReportPage = () => {
  const {
    messages,
  } = useIntl();
  const [options, setOptions] = useState<MakeCreateStopReportDataOptions>(initReporDataOption);

  const optionsErr = getReportOptionsErr(options);
  const isAllAreNotError = useMemo(() => checkAllAreNotError(optionsErr), [optionsErr]);

  const handleBeforeUploadCheck = useCallback(() => {
    return isAllAreNotError;
  }, [isAllAreNotError]);

  const handleUploadSuccess = useCallback(() => {
    const uploadSuccessMessage = messages['stopReport.uploadSuccess'];
    window.alert(uploadSuccessMessage);
    location.reload();
  }, [messages]);

  const handleUploadFailed = useCallback((e={}) => {
    window.alert(e.error || e.message);
  }, []);

  const state = useUploadAndPreviewFiles({
    uploadApiUrl: UPLOAD_API,
    maxUploadFileAmount: MAX_UPLOAD_IMAGE_AMOUNTS,
    stopReportDataOptions: options,
    onUploadSuccess: handleUploadSuccess,
    onBeforeUploadCheck: handleBeforeUploadCheck,
    onUploadFailed: handleUploadFailed,
  });

  const dragDropFilesState = useDragDropFiles({
    onSetDropFiles: state.handleAddFiles,
  });

  const isUploadAvailble = isAllAreNotError;

  
  return ({
    ...state,
    ...dragDropFilesState,
    isUploading: state.isUploading,
    isUploadAvailble,
    setOptions,
    optionsErr,
  });
};

export default useStopReportPage;