import { StaticSingleProvider } from 'driving-map';

const getProviderNameById = (providerId: string, allProviders: StaticSingleProvider[]) => {
  let providerName = '';
  const matchedProvider = allProviders.find(p => p.ID === providerId);
  if(matchedProvider) {
    providerName = matchedProvider.nameZh;
  }
  return providerName;
};

export default getProviderNameById;