import React from 'react';
import { Box, Grid, ListItemText, makeStyles, useTheme } from '@material-ui/core';
import { GridProps } from '@material-ui/core/Grid';
import { ListItemTextProps } from '@material-ui/core/ListItemText';

export interface ListItemTextInGridProps extends GridProps {
  zoomRatio?: number
  primary?: ListItemTextProps['primary']
  secondary?: ListItemTextProps['primary']
}

const useStyles = makeStyles(theme => ({
  listItemText: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  }
}));

const ListItemTextInGrid = (props: ListItemTextInGridProps) => {
  const {
    zoomRatio=1,
  } = props;
  
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid item {...props} style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <ListItemText 
        className={classes.listItemText}
        {...props}
        primaryTypographyProps={{
          style: {
            fontSize: theme.typography.fontSize * zoomRatio,
          },
        }}
      />
    </Grid>
  );
};

export default ListItemTextInGrid;