import { RefObject, useCallback } from "react";
import { AutoCompleteWithResultsAndValuesRef } from "ibus-common-components/lib/components/common-components/AutoComplete/types";
import { InitNames } from "./types";

function useExchangeAutoCompletes<ResultType extends any=any>(
  ref?: RefObject<AutoCompleteWithResultsAndValuesRef<InitNames, ResultType>>
) {
  const handleExchange = useCallback(() => {
    if(ref && ref.current) {
      const nameValueResults = ref.current.getNameValueResults();
      ref.current.setNameValueResult('from')(nameValueResults.to.value, nameValueResults.to.result);
      ref.current.setNameValueResult('to')(nameValueResults.from.value, nameValueResults.from.result);
    }
  }, [ref]);

  return ({
    handleExchange
  });
}

export default useExchangeAutoCompletes;