import React, { ReactElement } from "react";
import { Map as LeafletMap, TileLayer } from "react-leaflet";
import { SelectedRouteStopId } from "components/driving-map/constants/context";
import { RouteStopsAndRouteLineProps } from "../RouteStopsAndRouteLine";
import { Position, GoBack } from "bus-common-types";
import { goOrBackObj } from "config";
import { handleMapSpec } from "../functions/busMapPart";
// import 'styles/styles.scss';
import { getGoBackChoice } from "constants/functions";
import styleLayout from "styles/styleObjs/styleLayout";
import TGOSTileLayer from "../../../../tgos/TGOSTileLayer";

export type BusMapBasicProps = {
  position: Position;
  selectedRouteStopIndex: SelectedRouteStopId;
  goBack: GoBack;
};
export type BusMapProps = BusMapBasicProps & RouteStopsAndRouteLineProps;

const BusMapWrapper = (
  props: BusMapProps & {
    children: ReactElement<RouteStopsAndRouteLineProps>;
  }
) => {
  const { goBack, children, routeStopMarkersAndPoints } = props;

  const thisRouteStopMarkersAndPoints = getGoBackChoice(
    routeStopMarkersAndPoints
  )(goBack);
  const mapSpec = handleMapSpec.getSpec({
    ...props,
    ...thisRouteStopMarkersAndPoints,
  });

  return (
    <LeafletMap
      {...mapSpec}
      style={{
        height: "100%",
      }}
    >
      {/* {process.env.NODE_ENV === 'development' ? (
        <TileLayer
          opacity={0.9}
          // url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
          url={'https://wmts.nlsc.gov.tw/wmts/EMAP/default/GoogleMapsCompatible/{z}/{y}/{x}'}
        />
      ) : (
        <TGOSTileLayer opacity={0.9} />
      )} */}
      <TGOSTileLayer opacity={0.9} />
      {children}
    </LeafletMap>
  );
};

export default BusMapWrapper;
