import { Reducer } from "all-common-types";
import { FareState } from "../constant/context";
import FarePageActions, { FareActionTypes } from "../actions";

type RoutesReducer = Reducer<FareState, FarePageActions, FareState['routes']>

export const routes: RoutesReducer = (state, action) => {
  switch (action.type) {
    case FareActionTypes.SET_ROUTES:
      return action.payload.routes;
    default:
      return state.routes;
  }
};