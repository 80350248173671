import ibusImages from 'static/ibusImages.json';

const getBusIconByProviderId = (providerId: number): typeof ibusImages['background_image'] => { 
  //無障礙icon用加工方式蓋上去
  // 以防萬一，強制轉換型別
  switch (Number(providerId)) {
    case 1: //台中客運
      return ibusImages.provider_bus_icons_tcbus;
      
    case 2: //仁友客運
    case 19:
      return ibusImages.provider_bus_icons_rybus;
      
    case 3: //統聯客運
    case 15:
      return ibusImages.provider_bus_icons_ubus;

    case 4: //巨業交通
      return ibusImages.provider_bus_icons_gbus;

    case 5: //全航客運
      return ibusImages.provider_bus_icons_chbus;

    case 6: //豐原客運
      return ibusImages.provider_bus_icons_fybus;

    case 8: //和欣客運
      return ibusImages.provider_bus_icons_hohsbus;
 
    case 11: //東南客運
      return ibusImages.provider_bus_icons_shbus;

    case 12: //苗栗客運
      return ibusImages.provider_bus_icons_mibus;

    case 14: //豐榮客運
      return ibusImages.provider_bus_icons_gtbus;

    case 16: //南投客運
      return ibusImages.provider_bus_icons_ntbus;

    case 17: // 四方客運
      return ibusImages.provider_bus_icons_sfbus;

    case 18: //捷順交通
      return ibusImages.provider_bus_icons_jsbus;

    case 21: //總達客運
      return ibusImages.provider_bus_icons_adbus;

    case 23: //國光客運
      return ibusImages.provider_bus_icons_kkbus;

    case 24: //建明客運
      return ibusImages.provider_bus_icons_fgbus;

    default:
      return ibusImages['provider_bus_icons_default_bus'];
  }
};

export default getBusIconByProviderId;