import { makeStyles, Theme } from "@material-ui/core";
import { numberIcon } from "config";

const style_downTriangle = {
  content: '""',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: `${numberIcon.downTriangleHeight}px 5px 0 5px`,
};

const style_numberIconCommon = {
  width: '100%',
  height: numberIcon.basicWidth * 2,
  display: 'block'
};

const useBasicMapStyles = makeStyles<Theme>(theme => ({
  root: {
    position: 'relative',
    '& .number-icon': {
      position: 'relative',
      color: '#fff',
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 600,
      '& .go': {
        ...style_numberIconCommon,
        backgroundColor: theme.goBack && theme.goBack.goBackStatus.go,
        '&:after': {
          ...style_downTriangle,
          borderColor: theme.goBack && `${ theme.goBack.goBackStatus.go } transparent transparent transparent`,
        }
      },
      '& .back': {
        ...style_numberIconCommon,
        backgroundColor: theme.goBack && theme.goBack.goBackStatus.back,
        '&:after': {
          ...style_downTriangle,
          borderColor: theme.goBack && `${ theme.goBack.goBackStatus.back } transparent transparent transparent`,
        }
      },
    },
    [theme.breakpoints.down('xs')]: {
      height: '70vh',
    },
  },
}));

export default useBasicMapStyles;