import { SingleStationInEdge } from "bus-common-types";
import { seperateData } from "lib/seperateDataHelpers";

export interface MakeTravelTimesInputoOptions {
  stationName: string
  stations: SingleStationInEdge[]
}

const StopSearchTravelTimeHandlers = {
  getStationId({
    stationName, stations,
  }: MakeTravelTimesInputoOptions) {
    const station = stations.find(s => s.node.name === stationName);
    return station ? Number(station.node.id) : undefined;
  },

  makeTravelTimesInputFromStationIds({
    stationName, stations,
  }: MakeTravelTimesInputoOptions) {
    const goBackStations = seperateData.makeGoBackDataToObj(stations);
    const goStationId = this.getStationId({
      stationName, stations: goBackStations.go,
    });
    const backStationId = this.getStationId({
      stationName, stations: goBackStations.back,
    });

    return ({
      goStationId,
      backStationId
    });
  }
};

export default StopSearchTravelTimeHandlers;