import React, { ReactNode } from 'react';
import { ApolloError } from 'apollo-boost';
import { Box, BoxProps } from '@material-ui/core';
import EmptyMessage from '../Other/EmptyMessage';
import LoadingAndError from '../LoadingAndError';

export interface LoadingErrorMessageWrapperProps {
  wrapperProps?: BoxProps
  error?: ApolloError
  loading?: boolean
  isEmpty?: boolean
  emptyMessage?: ReactNode
  children?: ReactNode
}

const LoadingErrorMessageWrapper = (props: LoadingErrorMessageWrapperProps) => {
  const {
    wrapperProps={},
    loading, error, isEmpty, emptyMessage, children,
  } = props;

  if(loading || error)
    return (
      <LoadingAndError
        loading={!!loading}
        error={error}
      />
    );

  if(isEmpty)
    return (
      <EmptyMessage>
        {emptyMessage}
      </EmptyMessage>
    );

  if(children) 
    return (
      <Box {...wrapperProps}>{children}</Box>
    );

  return null;
};

export default LoadingErrorMessageWrapper;