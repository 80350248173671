import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ProviderInfoProps } from './types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const ProviderInfo = (props: ProviderInfoProps) => {
  return (
    <IntlFormattedMessage 
      variant={'h6'}
      langKey={'telWithVar'}
      values={{
        tel: props.tel,
      }}
    />
  );
};

export default ProviderInfo;