import fetch from "unfetch";
import { InMemoryCache, IdGetterObj } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { ApolloClient } from "apollo-client";
import { GoBack } from "bus-common-types";

export const routeMapUrlAPI = (routeId?: string) => `//citybus.taichung.gov.tw/cms/api/route/${routeId}`;
export const cmsUri = process.env.REACT_APP_CMS_API || "//citybus.taichung.gov.tw/ebus/strapi";
export const mainSideRoutesGroupAPI = process.env.REACT_APP_MAIN_SIDE_ROUTE || "//citybus.taichung.gov.tw/ebus/strapi/main-side-routes";
export const api_routesByProviderId = (id: string) => `/zh/providers/${id}/routes?op_type=0`;
const ebusGraphql = process.env.REACT_APP_EBUS_GRAPHQL_API || 'https://citybus.taichung.gov.tw/ebus/graphql';

const link = createHttpLink({ uri: ebusGraphql, fetch });
const cmsLink = createHttpLink({uri: `${cmsUri}/graphql`, fetch });

interface CustomIdGetterObj extends IdGetterObj {
  id: string;
  goBack: GoBack;
  comeTime: string;
}
const cache = new InMemoryCache({
  dataIdFromObject: (obj: CustomIdGetterObj) => obj.id + obj.goBack || "",
} as {});
export const client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

export const cmsClient = new ApolloClient({ cache, link: cmsLink });
