import { ID, EdgesData, InputDate } from 'all-common-types';

export const checkIsValidDate = (date: InputDate) => {
  const dateObj = new Date(date);
  const dateTime = dateObj.getTime();
  const isValid = !isNaN(dateTime);
  if(isValid) {
    return true;
  } else {
    throw new TypeError('Invalid date input format.');
  }
};

export const getDate = (date?: InputDate): Date => {
  const defaultDate = new Date();
  try {
    if(!!date) {
      const isValidDate = checkIsValidDate(date);
      if(isValidDate) {
        return new Date(date);
      }
    }
  } catch(e) {
    // console.log(e + ', and get today date by default.');
  }
  return defaultDate;
};

export const splitPath = (path: string, splitBy='/') => {
  let res = [] as string[];
  const splitPaths = path.split(splitBy);
  splitPaths.forEach((loc, i) => {
    const concatWithSplitString = i === 0 ? '/' : splitPaths.slice(0, i + 1).join('/');
    res = [...res, concatWithSplitString];
  });
  return {
    joinedPaths: res,
    splitPaths,
  };
};

export const convertTimeToString = (time: string, concatBy='-') => {
  const TIME = new Date(time);
  const year = TIME.getFullYear();
  const month = TIME.getMonth() + 1 > 9 ? TIME.getMonth() + 1 : '0' + (TIME.getMonth() + 1); 
  const date = TIME.getDate() > 9 ? TIME.getDate() : '0' + TIME.getDate();
  const timeArr = [year, month, date];
  return timeArr.join(concatBy);
};


type dataWithFlag = {
  flag: string | null
  [x: string]: any
}[]
export const sortByTop = (data: dataWithFlag) => {
  const dataWithTopFlag = data.filter(d => d.flag === 'top');
  const otherData = data.filter(d => d.flag !== 'top');
  return [
    ...dataWithTopFlag,
    ...otherData
  ];
};

type SortByCondition = {
  type: 'date' | 'text' | 'number'
  column: string
  ascOrDesc: 1 | -1
}

export const sortByDate = (column: string, ascOrDesc: 1 | -1) => {
  return (a: { [x: string]: any }, b: { [x: string]: any }) => {
    if(!a[column] || !b[column]) return 0;
    const dateA = new Date(a[column]);
    const dateB = new Date(b[column]);
    const timeDateA = dateA.getTime();
    const timeDateB = dateB.getTime();
    if(timeDateB > timeDateA) return ascOrDesc === 1 ? -1 : 1;
    if(timeDateB < timeDateA) return ascOrDesc === 1 ? 1 : -1;
    return 0;
  };
};
export const sortByCondition = ({ type, column, ascOrDesc }: SortByCondition) => {
  switch (type) {
    case 'date':
      return sortByDate(column, ascOrDesc);
    default:
      break;
  }
};

export function filterDuplicatedByKey<SingleData extends {
  id: ID
  [x: string]: any
}>(data: SingleData[], key: keyof SingleData='id'): SingleData[] {
  let res: SingleData[] = [];
  const checkIdIsExist = (x: any) => res.length > 0 && res.find(r => r[key] === x);
  data.forEach(d => {
    if( !checkIdIsExist(d[key]) ) {
      res = [...res, d];
    }
  });
  return res;
}


type SortObj = { seq?: number, [x: string]: any }
export function sortByAttr<Obj extends SortObj>(attr: keyof Obj='seq') {
  return (a: Obj, b: Obj): 1 | -1 | 0 => {
    const prev = parseInt( a[attr] );
    const next = parseInt( b[attr] );
    if (prev > next ) return 1;
    if (prev < next) return -1;
    return 0;
  };
}

export function getEdgesData<Data>(data: Data): EdgesData<Data> {
  return ({
    edges: [{
      node: data
    }]
  });
}
export function makeMultiEdgesData<Data>(data: Data[]): EdgesData<Data> {
  return ({
    edges: data.map(d => ({
      node: d
    }))
  });
}


export const arrObjToObj = (arr: {
  [x: string]: any
}[]) => {
  return arr.reduce((prev, current) => {
    const key = Object.keys(current)[0];
    prev[key] = current[key];
    return prev;
  }, {});
};

export function arrStrToObjStr<Str extends string>(arr: Str[]) {
  const init = {} as { [x in Str]: Str };
  const result = arr.reduce((prev, current) => {
    prev[current] = current;
    return prev;
  }, init);
  return result;
}

export function removeDuplicates<Data extends any>(dataList: Data[], keyFn: (data: Data) => Data[keyof Data]) {
  const res = [
    ...new Map( dataList.map(data => [keyFn(data), data]) ).values()
  ];
  return res;
}