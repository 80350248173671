import React from 'react';
import { Box, makeStyles, Grid } from '@material-ui/core';
import { OtpItinerary } from 'travel-planner';
import { getPlanHintIconData } from 'components/travel-planner/fn';
import PlanHintIconList, { PlanHintIconTravelMode } from './PlanHintIconList';
import { basicHoverEffectStyle } from 'components/home-page/blogs';
import StepList from './StepList';
import PlanTagItem from './PlanTagItem';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { PlanTagItemProps } from './types';
import useDurationMsg from 'components/travel-planner/lib/functions/useDurationMsg';

export type SetChosenIndexFn = (index: number) => any
export type PlanItemHeaderProps = {
  index: number,
  duration: number
  tags: PlanTagItemProps[]
  planHintIconListsData: PlanHintIconTravelMode[]
  setChosenIndexFn?: SetChosenIndexFn
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: theme.spacing(7),
    padding: theme.spacing(1),
    backgroundColor: '#eee',
    ...basicHoverEffectStyle('#ddd'),
  },
  planText: {
    color: theme.complementary.dark
  }
}));

export const PlanItemHeader = ({
  index,
  duration,
  tags,
  planHintIconListsData,
  setChosenIndexFn
}: PlanItemHeaderProps) => {
  const planIndex = index + 1;
  const classes = useStyles();
  const durationMsg = useDurationMsg(duration);
  const handleSetChooseIndex = () => {
    setChosenIndexFn && setChosenIndexFn(index);
  };

  return (
    <Grid
      container
      className={classes.root}
      onClick={handleSetChooseIndex}
    >
      <Grid sm={6}>
        <Box display={'flex'} alignItems={'center'}>
          <IntlFormattedMessage
            variant={'h5'}
            langKey={'plan'}
            className={classes.planText}
            values={{
              planIndex,
            }}
          />
          <Box component={'small'}>{`(${durationMsg})`}</Box>
        </Box>
      </Grid>
      <Grid sm={6}>
        <PlanHintIconList
          planHintIconListsData={planHintIconListsData} />
      </Grid>
      <Box>
        {tags.map((t, i) => (
          <PlanTagItem
            key={i}
            {...t}
          />
        ))}
      </Box>
    </Grid>
  );
};


export interface SinglePlanItemProps extends OtpItinerary {
  tags: PlanTagItemProps[]
  index: number
  displayContent: boolean
}

const SinglePlanItem = (props: SinglePlanItemProps) => {
  const {
    legs,
    displayContent,
  } = props;
  const planHintIconListsData = legs.map(leg => getPlanHintIconData(leg));

  return (
    <Box>
      <PlanItemHeader
        {...props}
        planHintIconListsData={planHintIconListsData}
      />
      {displayContent && (
        <StepList {...props} />
      )}
    </Box>
  );
};

export default SinglePlanItem;