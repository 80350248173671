import { useState } from "react";

function useRadioTabList<ValueKeys extends string | undefined>(initValue?: ValueKeys) {
  const [value, setValue] = useState<ValueKeys | undefined>(initValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as ValueKeys);
  };

  return ({
    value,
    handleChange,
  });
};

export default useRadioTabList;