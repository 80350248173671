import { gql } from "apollo-boost";
import { EdgesData, ID } from "all-common-types";
import { BusInfos, EstimateTimeHandlerOptions, GoBack, SingleBusInfoInEdge } from "bus-common-types";
import { SingleBusInstantInfo } from "driving-map";

let RouteFragment = {} as {
  fragment: {
    providersInfo: any
    headwayInfo: any
    scheduleInfo: any
    estimateTimesInfo: any
    stationsInfo: any
    busInfosInfo: any
    busesFragment: any
    clogsInfo: any
    travelTimeFragment: any
  }
};

const providersInfoFragment = gql`
  fragment providerInfo on ProviderConnection {
    edges {
      node {
        id
        name
        telephone
        opType
      }
    }
  }
`;

const stationsInfoFragment = gql`
  fragment stationsInfo on RouteStationConnection {
    edges {
      sid
      ivrNo
      goBack
      orderNo
      node {
        id
        name
        lat
        lon
      }
    }
  }
`;

type StationsFromRouteNode = {
  id: string;
  name: string;
  lat: number;
  lon: number;
};
export type StationsFromRouteFragmentType = {
  edges: {
    sid: number;
    goBack: GoBack;
    orderNo: number;
    ivrNo: number
    node: StationsFromRouteNode;
  }[];
};

const headwayInfoFragment = gql`
  fragment headwayInfo on HeadwayEntity {
    id
    scheduleTimeTypeId
    firstBusGo
    lastBusGo
    firstBusBack
    lastBusBack
    peaks {
      id
      peakType
      upperLimit
      lowerLimit
      startTime
      endTime
      goBack
    }
  }
`;

const scheduleInfoFragment = gql`
  fragment scheduleInfo on TimeTable {
    schedules {
      xno
      id
      goBack
      scheduleTime
      carId
      carType
      rawCarType
    }
    headway {
      weekday {
        ...headwayInfo
      }
      holiday {
        ...headwayInfo
      }
    }
  }
  ${headwayInfoFragment}
`;

// 新版的estimate times
const estimateTimesInfoFragment = gql`
  fragment estimateTimesInfoFragment on EstimateTimeConnection {
    edges {
      node {
        id
        sid
        comeTime
        goBack
        isOperationDay
        isSuspended
        isConstruction
        isEvent
        clogType
        etas {
          isLast
          busId
          etaTime
        }
      }
    }
  }
`;
export interface SingleEstimeTimeFragment extends EstimateTimeHandlerOptions {
  id: ID
  sid: number
  goBack: GoBack
}
export type EstimateTimesInfoFragment = EdgesData<SingleEstimeTimeFragment>

const busInfosInfoFragment = gql`
  fragment busInfosInfo on RouteBusConnection {
    edges {
      goBack
      node {
        id
        type
        lat
        lon
        azimuth
        driverId
        dataTime
        dutyStatus
        provider {
          id
        }
      }
    }
  }
`;
export interface BusesInfoFragment extends BusInfos {

}

// 新版API的bus
const busesFragment = gql`
  fragment busesFragment on RouteBusConnection {
    edges {
      goBack
      node {
        id
        lat
        lon
        azimuth
        type
        driverId
        dataTime
        status
        provider {
          id
        }
      }
    }
  }
`;

export interface BusNode {
  id: ID;
  lat: number;
  lon: number;
  azimuth: number;
  type: string;
  driverId: number;
  dataTime: string;
  status: number;
  provider: {
    id: ID;
  }
}
export interface BusesFragment {
  edges: {
    goBack: GoBack
    node: BusNode
  }[]
}

const clogsInfoFragment = gql`
  fragment clogsInfo on ClogMessageConnection {
    edges {
      node {
        id
        xno
        title
        description
        type
        providerId
        beginTime
        template
        stations {
          id
          goBack
          orderNo
        }
      }
    }
  }
`;

export interface BusInfosFragmentType {
  edges: SingleBusInfoInEdge[];
}

const travelTimeFragment = gql`
  fragment travelTimeFragment on TravelTime {
    id
    xno
    sid
    goBack
    orderNo
    stationId
    travelTime
  }
`;
export interface SingleQueriedTravelTime {
  id: ID
  stationId: number
  xno: number
  sid: number
  goBack: GoBack
  orderNo: number
  travelTime: string
}
export type QueriedTravelTimeFragment = SingleQueriedTravelTime[][]

RouteFragment.fragment = {
  providersInfo: providersInfoFragment,
  headwayInfo: headwayInfoFragment,
  scheduleInfo: scheduleInfoFragment,
  estimateTimesInfo: estimateTimesInfoFragment,
  stationsInfo: stationsInfoFragment,
  busInfosInfo: busInfosInfoFragment,
  busesFragment,
  clogsInfo: clogsInfoFragment,
  travelTimeFragment,
};

export default RouteFragment;
