import { FareActionTypes } from './index';
import { FareState } from '../constant/context';

export interface SetFromToStopsIdAction {
  type: FareActionTypes.SET_FROM_TO_STOPS_ID
  payload: FareState['fromToStopsId']
}

export interface ResetFromToStopsIdAction {
  type: FareActionTypes.RESET_FROM_TO_STOPS_ID
}

export interface SetFromToStopsAction {
  type: FareActionTypes.SET_FROM_TO_STOPS,
  payload: FareState['fromToStops']
}

export const setFromToStopsId = (fromToStopsId: FareState['fromToStopsId']): SetFromToStopsIdAction => ({
  type: FareActionTypes.SET_FROM_TO_STOPS_ID,
  payload: fromToStopsId,
});

export const resetFromToStopsId = (): ResetFromToStopsIdAction => ({
  type: FareActionTypes.RESET_FROM_TO_STOPS_ID,
});

export const setFromToStops = (fromToStops: FareState['fromToStops']): SetFromToStopsAction => ({
  type: FareActionTypes.SET_FROM_TO_STOPS,
  payload: fromToStops,
});

export type SelectFromToActions = 
  SetFromToStopsIdAction | 
  ResetFromToStopsIdAction |
  SetFromToStopsAction