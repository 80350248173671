import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { PlanTagItemProps } from './types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { LangKeys } from 'constants/lang';

const useStyles = makeStyles<Theme, PlanTagItemProps>(theme => ({
  root: {
    display: 'inline-block',
    // maxWidth: theme.spacing(9),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    color: theme.palette.common.white,
    backgroundColor: props => theme.travelPlan.planStatus[props.planTag],
    borderRadius: 1000,
    textAlign: 'center',
  }
}));

export const convertPlanTagToLangKey = (planTag: PlanTagItemProps['planTag']): LangKeys => {
  switch (planTag) {
    case 'direct':
      return 'travelPlanner.planType.direct';
    case 'fatest':
      return 'travelPlanner.planType.fatest';
    case 'walkless':
      return 'travelPlanner.planType.walkless';
    default:
      return 'empty';
  }
};

const PlanTagItem = (props: PlanTagItemProps) => {
  const classes = useStyles(props);
  const langKey = convertPlanTagToLangKey(props.planTag);
  return (
    <Box
      display={'inline-block'}
      padding={0.5}
    >
      <Box className={classes.root}>
        <IntlFormattedMessage 
          langKey={langKey}
        />
      </Box>
    </Box>
  );
};

export default PlanTagItem;