import React from 'react';
import { Box } from '@material-ui/core';
import { GoBackButtonsContainerProps } from './types';
import GoBackButtons from 'components/fare/components/SearchPart/GoBackButtons';
import useGoBackButtons from './functions/useGoBackButtons';

const GoBackButtonsContainer = (props: GoBackButtonsContainerProps) => {
  const state = useGoBackButtons(props);
  
  return (
    <GoBackButtons 
      {...state} 
      selectTabFn={state.selectTab} 
    />
  );
};

export default GoBackButtonsContainer;