import { BUS_309_ROUTE_ID, BUS_310_ROUTE_ID, MRT_GREEN_ROUTE_ID } from "components/mrt-fare/config";
import { QueryMrtTravelTimesParams } from "../schema.gql";

export interface MakeMrtTravelTimesParamsOptions extends QueryMrtTravelTimesParams {
}

const makeMrtTravelTimesParams = (options: MakeMrtTravelTimesParamsOptions) => {
  const {
    routeId,
    mrtFromStationId,
    busFromStationId,
    goBack,
  } = options;

  let res = {
    busInputs: [
      {
        xno: routeId,
        fromSid: busFromStationId,
        goBack: goBack,
      },
    ],
    mrtGreenInputs: [
      {
        xno: MRT_GREEN_ROUTE_ID,
        fromSid: mrtFromStationId,
        goBack: 1,
      },
      {
        xno: MRT_GREEN_ROUTE_ID,
        fromSid: mrtFromStationId,
        goBack: 2,
      },
    ],
  };

  if(routeId === BUS_309_ROUTE_ID && goBack === 2) {
    res = {
      ...res,
      busInputs: [
        ...res.busInputs,
        {
          xno: BUS_310_ROUTE_ID,
          fromSid: options.route310LastStation.stationId,
          goBack,
        }
      ]
    };
  }
  
  return res;
};

export default makeMrtTravelTimesParams;