import React, { useCallback, useMemo } from 'react';
import ContextStore, { DrivingMapState } from '../../constants/context';
import { setRouteStopId } from '../../actions';
import { SingleEstimateStop, TakenTime } from 'bus-common-types';
import { MapStateToProps, MapDispatchToProps } from 'lib/contextReduxLikeHelpers/mapContextToProps';
import connectCtx from 'lib/contextReduxLikeHelpers/connectCtx';
import CommonListItem, { CommonListItemProps } from 'components/common-components/CommonListItem';
import RouteListEstimateTimeItemContainer from './RouteListEstimateTimeItemContainer';
import { ROUTE_LIST_ITEM_TEXTS_WIDTH_RATIO } from 'components/driving-map/config';
import { SingleIbikeInfo } from 'driving-map';
import OtherTransportationsItem from './OtherTransportationsItem';
import { OtherTransportationsItemProps } from './types';


export interface RouteListData extends SingleEstimateStop, OtherTransportationsItemProps {
  takenTime: TakenTime
}

type RouteListItemProps = {
  index: number
  listData: RouteListData
  isSelected: boolean
  haveMRT: boolean
  listClickFn?: (index: number) => any
} & Pick<CommonListItemProps, 'zoomRatio'>
export const RouteListItem = ({ 
  index=0, 
  listData, 
  isSelected,
  haveMRT,
  zoomRatio=1,
  listClickFn 
}: RouteListItemProps) => {
  const {
    id, ivrNo, stopName,
  } = listData;

  const stationIndex = useMemo(() => index + 1, [index]);
  const EstimateTimeItem = useMemo(() => (
    <RouteListEstimateTimeItemContainer {...listData} zoomRatio={zoomRatio} />
  ), [listData, zoomRatio]);
  
  const otherTransportationsItem = useMemo(() => (
    <OtherTransportationsItem 
      {...listData}
    />
  ), [listData]);

  const listTexts = [
    stationIndex, ivrNo, stopName, otherTransportationsItem, EstimateTimeItem
  ];
  
  const handleClick = useCallback(() => {
    listClickFn && listClickFn(index);
  }, [index, listClickFn]);

  return (
    <CommonListItem 
      isButton
      zoomRatio={zoomRatio}
      isSelected={isSelected}
      textsInList={listTexts}
      textsWidthRatios={ROUTE_LIST_ITEM_TEXTS_WIDTH_RATIO}
      onClick={handleClick} />
  );
};

export interface RouteListItemWithCtxProps extends Omit<RouteListItemProps, keyof RouteListItemStateFromCtx | keyof RouteListItemDispatchFromCtx> { 
  index: number, 
  listData: RouteListData,
  haveTMRT?: boolean 
}
export interface RouteListItemStateFromCtx {
  isSelected: boolean
  ibikeInfoList: SingleIbikeInfo[]
}
export type RouteListItemDispatchFromCtx = {
  listClickFn: (routeStopId: number) => any
}

export const mapStateToProps: MapStateToProps<DrivingMapState, RouteListItemWithCtxProps, RouteListItemStateFromCtx> = (state, ownProps) => ({
  isSelected: ownProps.index === state.selectedRouteStopIndex,
  ibikeInfoList: state.ibikeInfoList,
});
export const mapDispatchToProps: MapDispatchToProps<RouteListItemWithCtxProps, RouteListItemDispatchFromCtx> = (dispatch) => ({
  listClickFn: (routeStopId: number) => {
    dispatch(setRouteStopId(routeStopId));
  }
});

const RouteListItemWithCtx = connectCtx(ContextStore)(mapStateToProps, mapDispatchToProps)(RouteListItem);

export default RouteListItemWithCtx;