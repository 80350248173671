import React, { useCallback } from 'react';
import { AutoCompleteWithResultsAndValuesRef } from 'ibus-common-components/lib/components/common-components/AutoComplete/types';
import { InitNames } from 'components/common-components/TwoAutoCompletesWithExchangeAndSearch/types';
import useExchangeAutoCompletes from 'components/common-components/TwoAutoCompletesWithExchangeAndSearch/useExchangeAutoCompletes';
import { QueriedStationsByNameResultType } from 'components/common-components/AutoCompleteWithQuery/QueryStationsByNameAutoCompleteHandler';
import { SearchByFromToStopsContainerProps } from '../../types';
import { useIntl } from 'react-intl';

export interface UseSearchByFromToStopsOptions extends SearchByFromToStopsContainerProps {
  
}

const useSearchByFromToStops = ({
  setFromStationToCtx,
  setFromToStationToCtx,
  resetSelectedRouteId,
}: UseSearchByFromToStopsOptions) => {
  const ref = React.useRef<AutoCompleteWithResultsAndValuesRef<InitNames, QueriedStationsByNameResultType>>(null);
  const {
    formatMessage,
  } = useIntl();

  const handleSearch = useCallback(() => {
    if(ref.current) {
      const {
        from,
        to,
      } = ref.current.getNameValueResults();

      if(from.result && to.result) {
        const selectedFromStation = {
          id: Number(from.result.id),
          name: from.value,
        };
        const selectedToStation = {
          id: Number(to.result.id),
          name: to.value,
        };

        setFromToStationToCtx({
          from: selectedFromStation,
          to: selectedToStation,
        });
        setFromStationToCtx({
          selectedFromStation,
        });
      } else {
        window.alert(
          formatMessage({
            id: 'collinearRoutes.intervalSearch.input.warning'
          })
        );
      }
      
      resetSelectedRouteId();
    }
  }, [formatMessage, resetSelectedRouteId, setFromStationToCtx, setFromToStationToCtx]);

  const {
    handleExchange
  } = useExchangeAutoCompletes(ref);

  return ({
    ref,
    handleSearch,
    handleExchange,
  });
};

export default useSearchByFromToStops;