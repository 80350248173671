import { createMuiTheme, Theme } from "@material-ui/core";
import goldTheme from "./goldTheme";
import newYearRed from './homepageImages/newYearRed.json';

const primary = {
  light: '#E67272',
  main: '#9E1422',
  dark: '#7A0012',
};
const secondary = {
  light: '#F9EDDB',
  main: '#E4B367',
  dark: '#D1903B',
};

const xmasThemeRedBlack = createMuiTheme({
  ...goldTheme,
  name: 'new-year-red',
  homepageColors: {
    ...goldTheme.homepageColors,
    titleColor: secondary.light,
    navBar: `
      linear-gradient(${180}deg, ${primary.dark} 0%, ${primary.main} 100%)`,
    noticePart: goldTheme.palette.primary.main,
    middlePart: `linear-gradient(${90}deg, ${'#CBB672'} 0%, ${'#A56611'} 100%)`,
    footer: `
      linear-gradient(${180}deg, ${secondary.dark} 0%, ${secondary.main} 100%)`,
  },
  homepageImages: {
    backgroundImage: newYearRed.homepage_background.src,
    topLeft: newYearRed.homepage_top_left.src,
    topRight: newYearRed.homepage_top_right.src,
    bottomLeft: newYearRed.homepage_below_left.src,
  },
  background: {
    surface: `linear-gradient(${135}deg, ${'#fff'} 0%, ${'rgba(230,214,167,1)'} 100%)`,
    homepage: `url(${newYearRed.homepage_background.src}), ${primary.main}`,
  },
  palette: {
    primary,
    secondary: {
      main: '#fff',
    }
  },
});

export const checkIsXmasTheme = (theme: Theme) => (
  theme.palette.primary.main === primary.main
);

export default xmasThemeRedBlack;