import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  Ref,
  useImperativeHandle,
} from "react";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Theme,
  makeStyles,
} from "@material-ui/core";
import { SingleSelectOption } from "all-common-types";
import { useToggle } from "lib/customHooks";

const defaultMinWidth = 100;

const useStyles = makeStyles<Theme, SelectorProps>({
  formPart: {
    minWidth: (props) =>
      typeof props.minWidth === "number" || "string"
        ? props.minWidth
        : defaultMinWidth,
  },
  root: {
    textAlign: "center",
  },
});

export type SelectorProps = {
  id: string;
  label: ReactNode;
  minWidth?: number | string;
  options: SingleSelectOption[];
  changeFn: (e: ChangeEvent<{ value: unknown }>) => any;
  value: string;
  children?: ReactNode;
};
export interface SelectorRef {
  onToggleOptionsDisplay: (toggle: boolean) => any;
}

const Selector = (props: SelectorProps, ref: Ref<SelectorRef>) => {
  const { id, label, options, changeFn, value } = props;
  const classes = useStyles(props);
  const { toggle, setToggle, handleOpenToggle, handleCloseToggle } =
    useToggle(false);

  useImperativeHandle(
    ref,
    () => ({
      onToggleOptionsDisplay: setToggle,
    }),
    [setToggle]
  );

  return (
    <FormControl className={classes.formPart}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        value={value}
        // variant={}
        open={toggle}
        onOpen={handleOpenToggle}
        onClose={handleCloseToggle}
        onChange={changeFn}
        className={classes.root}
        inputProps={{
          name: id,
          id,
        }}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
        {props.children}
      </Select>
    </FormControl>
  );
};
export default forwardRef(Selector);
