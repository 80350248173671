import React from 'react';
import { Box, Typography, TypographyProps } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { langKeys, LANG, LangKeys } from 'constants/lang';
import { Variant } from '@material-ui/core/styles/createTypography';


export interface IntlFormattedMessageProps<Values> extends TypographyProps {
  values?: Values,
  langKey: LangKeys,
}

function IntlFormattedMessage<Values extends Record<string, any>>(props: IntlFormattedMessageProps<Values>) {
  const {
    values,
    langKey,
    variant,
  } = props;
  
  return (
    <>
      <FormattedMessage<Values> 
        id={langKeys[langKey]}
        defaultMessage={LANG.zh_TW[langKey]}
        values={values}>
        {txt => (
          <Typography 
            {...props}
            variant={variant}
          >
            {txt}
          </Typography>
        )}
      </FormattedMessage>
    </>
  );
}

export default IntlFormattedMessage;