import React from 'react';
import { Box } from '@material-ui/core';
import { SelectTab } from 'components/driving-map/components/BusSelect';
import { SelectTabProps } from 'components/driving-map/components/BusSelect/SelectTab';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const travelPLannerTabData = [
  {
    label: (
      <IntlFormattedMessage 
        langKey={'collinearRoutes.searchTypeTab.searchByFromTo'}
      />
    )
  },
  {
    label: (
      <IntlFormattedMessage 
        langKey={'collinearRoutes.searchTypeTab.searchByStopLine'}
      />
    )
  }
];

const PlanTabList = (props: SelectTabProps) => {
  return (
    <SelectTab 
      {...props}
      tabDatas={travelPLannerTabData}/>
  );
};

export default PlanTabList;