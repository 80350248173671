import React from 'react';
import { SingleRouteGroup } from 'driving-map';
import useFetch from 'lib/customHooks/useFetch';

const routeGroupAPI =
  process.env.REACT_APP_API_ROUTE_GROUP ||
  'http://citybus.taichung.gov.tw/cms/api/route-group';

const useGetRouteGroup = () => {
  const {
    fetchData,
    loading,
    responseData,
  } = useFetch<SingleRouteGroup[]>(routeGroupAPI, []);

  React.useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ({
    isLoadingRouteGroupList: loading,
    routeGroupList: responseData,
  });
};

export default useGetRouteGroup;