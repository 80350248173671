import React from 'react';
import { Box } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const BlogNotFoundPage = () => {
  return (
    <Box>
      <IntlFormattedMessage 
        variant={'h6'}
        langKey={'blog.notFound'}
      />
    </Box>
  );
};

export default BlogNotFoundPage;