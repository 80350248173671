import React from "react";
import { Link } from "react-router-dom";
import { Box, Paper, Typography, makeStyles } from "@material-ui/core";
import IntlFormattedMessage from "components/common-components/IntlFormattedMessage";
import TopFlag from "components/common-components/topFlag";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
}));

export const getBlogLink = (id: string) => "/blog/" + id;

export type SingleBlogItemProps = import("blog").SingleBlog;
export const SingleBlogItem = ({ title, id, flag }: SingleBlogItemProps) => {
  const classes = useStyles();
  const link = getBlogLink(id);

  return (
    <Box paddingTop={1} paddingBottom={1}>
      <Link to={link}>
        <Paper className={classes.root}>
          <Box
            display={'flex'}
            alignItems={'center'}
          >
            {flag === "top" && <TopFlag />}
            <Typography variant={"h5"}>{title}</Typography>
          </Box>
          <Box>
            <Typography variant={"body1"}>{title}</Typography>
            {/* <Link to={link}>{'see all'}</Link> */}
          </Box>
        </Paper>
      </Link>
    </Box>
  );
};

export type BlogListsPageProps = {
  blogLists: SingleBlogItemProps[];
};
const BlogListsPage = ({ blogLists }: BlogListsPageProps) => {
  if (blogLists.length === 0) {
    return (
      <IntlFormattedMessage
        langKey={"blogs.noMessage"}
        variant={"h6"}
        color={"textSecondary"}
      />
    );
  }

  const sortedBlogLists = blogLists.sort((a, b) => {
    return new Date(b.onShelfAt).getTime() - new Date(a.onShelfAt).getTime();
  });

  const topBlogLists = sortedBlogLists
    .filter((blog) => blog.flag === "top")
    .concat(sortedBlogLists.filter((blog) => blog.flag !== "top"));

  return (
    <>
      {topBlogLists.map((blog) => (
        <SingleBlogItem key={blog.id} {...blog} />
      ))}
    </>
  );
};

export default BlogListsPage;
