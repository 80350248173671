import { GetStationNameDirectionFn } from "components/mrt-fare/components/common/types";
import getArrHalfIndex from "lib/functions/getArrHalfIndex";

const getMrtPartDirection: GetStationNameDirectionFn = (index, arrLength) => {
  const halfIndex = getArrHalfIndex(arrLength);
  
  if(index < halfIndex) {
    return 'bottom';
  }
  return 'top';
};

export default getMrtPartDirection;