import { ID } from "all-common-types";
import ActionTypes from ".";

export interface SetRouteIdActionPayload {
  routeId: ID
}

export interface SetRouteIdAction {
  type: ActionTypes.SET_ROUTE_ID
  payload: SetRouteIdActionPayload
}
export interface ResetRouteIdAction {
  type: ActionTypes.RESET_ROUTE_ID
}

export const setRouteId = (payload: SetRouteIdActionPayload): SetRouteIdAction => ({
  type: ActionTypes.SET_ROUTE_ID,
  payload,
});
export const resetRouteId = (): ResetRouteIdAction => ({
  type: ActionTypes.RESET_ROUTE_ID,
});

type RouteMapActions = 
  SetRouteIdAction |
  ResetRouteIdAction

export default RouteMapActions;