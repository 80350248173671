import React, { memo } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { MrtStationNameStrProps } from './types';
import getTransformByDirection from '../../lib/functions/getTransformByDirection';
import { FARE_BUTTON_WIDTH } from 'components/mrt-fare/config';

const useStyles = makeStyles<Theme, MrtStationNameStrProps>(theme => ({
  stationName: props => {
    const isVertical = 
      props.direction === 'top' || 
      props.direction === 'bottom';

    return ({
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      writingMode: isVertical ? 'vertical-rl' : 'unset',
      transform: getTransformByDirection(props.direction),
      paddingTop: theme.spacing(0.25),
      // position: 'relative',
      // bottom: props.direction === 'top' ? FARE_BUTTON_WIDTH + 10 : undefined,
      // [theme.breakpoints.down('md')]: {
      //   writingMode: 'unset',
      //   transform: 'unset',
      // }
    });
  }
}));

const MrtStationNameStr = (props: MrtStationNameStrProps) => {
  const classes = useStyles(props);

  return (
    <Typography className={classes.stationName}>
      {props.stationName}
    </Typography>
  );
};

export default memo(MrtStationNameStr);