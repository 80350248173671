import { EdgesData, ID } from "all-common-types";
import { gql } from "apollo-boost";
import { GoBack } from "bus-common-types";

const singleStopFragment = gql`
  fragment singleStopFragment on RouteStationConnection {
    edges {
      sid
      goBack
      node {
        id
        name
      }
    }
  }
`;
export interface SingleQueriedStationForMrtAndBus {
  sid: number
  goBack: GoBack
  node: {
    id: string
    name: string
  }
}

export const fareFragment = gql`
  fragment fareFragment on FareConnection {
    edges {
      node {
        id
        goBack
        from # sid
        to # sid

        eCitizen
        # eFull
        full
        reduced
      }
    }
  }
`;
export interface SingleMrtFare {
  id: ID
  goBack: GoBack
  from: number
  to: number

  eCitizen: number
  // eFull: number
  full: number
  reduced: number
}
export type QueriedMrtFareFragment = EdgesData<SingleMrtFare>

const mrtFareFragments = {
  singleStopFragment,
  fareFragment,
};

export default mrtFareFragments;