import React, { useEffect } from 'react';
import { SingleAutoCompleteWithQuery, AutoCompleteWithQueryWithResultsAndValuesProps } from '.';
import { AutoCompleteFunctions } from 'ibus-common-components';

function AutoCompleteWithQueryWithResultsAndValues<
  StateNames extends string,
  ResultType,
>(props: AutoCompleteWithQueryWithResultsAndValuesProps<StateNames, ResultType>) {
  const {
    queryResultListHook,
    initNameValueResults,
    autoCompleteWithResultsAndValuesRef,
    getNameValueResultsWhenChanged
  } = props;

  const {
    nameValueResults,
    setNameValueResult,
    handleChangeValuesByInput,
  } = AutoCompleteFunctions.useSearchInput({
    ...props,
    ref: autoCompleteWithResultsAndValuesRef,
  });

  const names = Object.keys(initNameValueResults) as StateNames[];

  useEffect(() => {
    getNameValueResultsWhenChanged && getNameValueResultsWhenChanged(nameValueResults);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameValueResults]);

  return (
    <>
      {names.map((name) => {
        const valueResult = nameValueResults[name];
        const handleSetValue = setNameValueResult(name);
        const handleChange = handleChangeValuesByInput(name);

        return (
          <SingleAutoCompleteWithQuery<ResultType>
            key={name}
            {...valueResult}
            queryResultListHook={queryResultListHook}
            onChange={handleChange}
            setValueFn={handleSetValue}
          />
        );
      })}
    </>
  );
};

export default AutoCompleteWithQueryWithResultsAndValues;