import React, {  } from 'react';
import MapMarker from './MapMarker';
import { Typography, Button, List, makeStyles } from '@material-ui/core';
import { Position, GoBack, SingleStationRouteWithGoBack } from 'bus-common-types';
import { useToggle } from 'lib/customHooks';
import RoutePassListContainer, { SingleDataForQueryEstimateTimes } from 'components/driving-map/containers/BusMap/RoutePassListContainer';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import useKeyForUpdating from 'lib/customHooks/useKeyForUpdating';
import { Callback } from 'all-common-types';

const POPUP_WIDTH_OPEN = 532;
const POPUP_WIDTH_SMALL_OPEN = 320;

const useStyles = makeStyles(theme => ({
  listContainer: {
    width: POPUP_WIDTH_OPEN,
    maxHeight: 300,
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      width: POPUP_WIDTH_SMALL_OPEN,
      maxHeight: 260,
    }
  }
}));

export type SingleRouteStopMarker = {
  id: string
  routeId: string
  routeName: string
  name: string
}
type MarkerContentProps = {
  index: number
  routeStopData: SingleRouteStopMarker
}
export const MarkerContent = ({ index, routeStopData }: MarkerContentProps) => {
  const stopSeq = index + 1;
  const routeName = routeStopData.routeName;

  return (
    <>
      <Typography variant={ 'subtitle1' }>
        { `[${ routeStopData.id }] ${ routeStopData.name }` }
      </Typography>
      <IntlFormattedMessage
        langKey={'routeWithVar'}
        variant={ 'body1' }
        values={{
          routeName,
        }}
      />
      <IntlFormattedMessage
        langKey={'stopSeqWithVar'}
        variant={ 'body1' }
        values={{
          stopSeq,
        }}
      />
    </>
  );
};


export type RoutePassData = SingleStationRouteWithGoBack[]
export type RouteStopMarkerProps = {
  isRoutePassDisplay: boolean
  stationId: number
  isOpen?: boolean
  position: Position
  goBack: GoBack
  routePassData: RoutePassData
  onToggleRoutePassDisplay: Callback
} & MarkerContentProps


export const getRoutePassListData = (props: RouteStopMarkerProps): SingleDataForQueryEstimateTimes[] => {
  const { routePassData } = props;
  const res = routePassData.map(d => ({
    ...d,
    stationId: props.stationId,
    goBack: d.goBack,
    xno: Number(d.id),
    seq: d.seq,
  }));
  return res;
};

const RouteStopMarker = (props: RouteStopMarkerProps) => {
  const {
    isRoutePassDisplay,
    index,
    routeStopData,
    onToggleRoutePassDisplay,
  } = props;

  const classes = useStyles();
  const {
    key,
    handleUpdateKey,
  } = useKeyForUpdating();

  const handledRoutePassListData = getRoutePassListData(props);

  return (
    <MapMarker
      {...props}
      popupMinWidth={POPUP_WIDTH_SMALL_OPEN}
    >
      <MarkerContent
        index={ index }
        key={key}
        routeStopData={ routeStopData }
      />
      <Button
        onClick={ onToggleRoutePassDisplay }
        color={'primary'}
      >
        <IntlFormattedMessage
          langKey={'passThroughRoutes'}
          variant={'body1'}
        />
      </Button>
      { isRoutePassDisplay && (
        <List className={classes.listContainer}>
          <RoutePassListContainer
            onTriggerUpdatePopup={handleUpdateKey}
            dataForQueryEstimateTimes={handledRoutePassListData} />
        </List>
      ) }
    </MapMarker>
  );
};
export default RouteStopMarker;