import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { DayType } from 'bus-common-types';
import { timeTableConfigs } from 'config';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

type dayType = {
  dayType: DayType
}
type HeadwayFirstLastProps = {
  first: string
  last: string
} & dayType

type HeadwayInfoProps = {
  firstLastTimeData: {
    first: string
    last: string
  }
} & dayType

export const getHeadwayFirstLastStr = (first: string, last: string) => {
  return `${ first } - ${ last }`;
};

export const HeadwayFirstLast = ({ dayType, first, last }: HeadwayFirstLastProps) => {
  const dayTypeStr = timeTableConfigs.dayTypeStrObj[dayType];
  return (
    <Box>
      <Typography variant={ 'subtitle1' }>
        {dayTypeStr}
      </Typography>
      <Typography variant={ 'body1' }>
        {getHeadwayFirstLastStr(first, last)}
      </Typography>
    </Box>
  );
};

const HeadwayInfo = ({ dayType, firstLastTimeData }: HeadwayInfoProps) => {
  return (
    <Box>
      <IntlFormattedMessage 
        langKey={'drivingMap.timeTable.headwayInfoTitle'}
      />
      <HeadwayFirstLast 
        dayType={ dayType }
        first={ firstLastTimeData.first }
        last={ firstLastTimeData.last } />
    </Box>
  );
};

export default HeadwayInfo;
