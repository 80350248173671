import React from 'react';
import { BusMarkerListProps, BusMarkerProps } from './types';
import { SingleBusInstantInfo } from 'driving-map';
import BusMarker from './BusMarker';

export const handleBusInstantInfoToBusMarker = (singleBusInstantInfo: SingleBusInstantInfo): BusMarkerProps => {
  return ({
    position: [
      singleBusInstantInfo.lat, 
      singleBusInstantInfo.lon
    ],
    busData: {
      carId: String(singleBusInstantInfo.id),
      providerId: String(singleBusInstantInfo.provider.id),
      carType: singleBusInstantInfo.type,
    }
  });
};

const BusMarkerList = (props: BusMarkerListProps) => {
  const {
    busIntantInfoList
  } = props;
  
  return (
    <>
      {busIntantInfoList.map((b, i) => {
        return (
          <BusMarker 
            key={`${b.id}-${b.goBack}`}
            {...handleBusInstantInfoToBusMarker(b)} />
        );
      })}
    </>
  );
};

export default BusMarkerList;