// /* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import CityBusPart, {
  SelectorName,
} from "components/driving-map/components/BusSelect/CityBusPart";
import { SingleRouteGroup } from "driving-map";
import useSelectsValues from "lib/customHooks/useSelectsValues";
import initCityBusPartSelectsValues from "./cityBusPartSelectsValues";
import handeGetSelectGroupOptions from "./functions/handleGetSelectGroupOptions";
import handleRouteGroupOptionsToSelectorOptions from "./functions/handleRouteGroupOptionsToSelectorOptions";
import HandleFilterRoutesByValue from "./functions/handleFilterRoutesByValue";
import { MapDispatchToProps } from "react-function-helpers/lib/functions/mapContextToProps";
import ContextStore from "components/driving-map/constants/context";
import { ID, Locale } from "all-common-types";
import { setBusProviderId } from "actions/common-actions/busProviderId-actions";
import { connectCtx } from "react-function-helpers";
import { useIntl } from "react-intl";
import useQueryBusProviders from "lib/customHooks/useQueryBusProviders";

export interface CityBusPartContainerProps {
  routeGroupList: SingleRouteGroup[];
  setProviderIdToCtxFn: (providerId: ID | undefined) => any;
}

const CityBusPartContainer = (props: CityBusPartContainerProps) => {
  const { routeGroupList, setProviderIdToCtxFn } = props;

  const { locale } = useIntl();

  const { busProviders, handleQueryBustProvidersPromisely } =
    useQueryBusProviders();

  const routeGroupOptions = handleRouteGroupOptionsToSelectorOptions(
    locale as Locale
  )(routeGroupList);

  const {
    selectsValues,
    handleChange,
    handledData: routeIdsForFilter,
  } = useSelectsValues<SelectorName, string[]>({
    initSelectsValues: initCityBusPartSelectsValues,
    initDataForHandle: [],
    handleFunctions: {
      selectGroup: (s) => s,
      selectType: handeGetSelectGroupOptions({
        routeGroupOptions,
        locale: locale as Locale,
        getProvidersFn: handleQueryBustProvidersPromisely,
      }),
    },
    handleDataUpdateFn: HandleFilterRoutesByValue.filterRoutesByValue({
      routeGroupList,
      setProviderIdToCtxFn,
    }),
  });

  return (
    <>
      <CityBusPart
        selectsValues={selectsValues}
        changeFn={handleChange}
        routeIdsForFilter={routeIdsForFilter}
      />
    </>
  );
};

export interface CityBusPartContainerWithCtxProps
  extends Omit<CityBusPartContainerProps, "setProviderIdToCtxFn"> {}

const mapDispatchToProps: MapDispatchToProps<
  CityBusPartContainerWithCtxProps,
  {
    setProviderIdToCtxFn: CityBusPartContainerProps["setProviderIdToCtxFn"];
  }
> = (dispatch) => {
  return {
    setProviderIdToCtxFn: (providerId) => {
      const action = setBusProviderId(providerId);
      dispatch(action);
    },
  };
};

const CityBusPartContainerWithCtx = connectCtx(ContextStore)(
  undefined,
  mapDispatchToProps
)(CityBusPartContainer);

export default CityBusPartContainerWithCtx;
