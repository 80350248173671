import { gql } from 'apollo-boost';
import cmsApiFragments from './cmsApiFragments';
import { SingleBlog } from 'blog';
import { SinglePage, SingleNotice, SingleLink, SingleTimeTableRoute } from 'all-common-types';

const QUERY_IBUS_COMMON_DATA = gql`
  query QUERY_IBUS_COMMON_DATA($locale: String!) {
    blogs(where: {
      isPublish: true
      locale: $locale
    }) {
      ...blogsFragment
    }
    pages(where: {
      isPublish: true
      locale: $locale
    }) {
      ...pagesFragment
    }
    notices(where: {
      is_publish: true
      locale: $locale
    }) {
      ...noticesFragment
    }
    mainMenuLinks(where: {
      locale: $locale
    }) {
      ...mainMenuLinksFragment
    }
    subMenuLinks(where: {
      isInChildren: false
      locale: $locale
    }) {
      ...subMenuLinksFragment
    }
    timeTableRoutes {
      timeTableType
      routeId
    }
  }
  ${cmsApiFragments.fragment.blogsFragment}
  ${cmsApiFragments.fragment.pagesFragment}
  ${cmsApiFragments.fragment.noticesFragment}
  ${cmsApiFragments.fragment.mainMenuLinksFragment}
  ${cmsApiFragments.fragment.subMenuLinksFragment}`;

export const QUERY_CMS_NOTICES = gql`
  query QUERY_CMS_NOTICES($locale: String!) {
    notices(where: {
      is_publish: true
      locale: $locale
    }) {
      ...noticesFragment
    }
  }
  ${cmsApiFragments.fragment.noticesFragment}`;

export const QUERY_CMS_BLOG = gql`
  query QUERY_CMS_BLOG($id: ID!) {
    blog(id: $id) {
      ...blogsFragment
    }
  }
  ${cmsApiFragments.fragment.blogsFragment}`;


export interface QueryIbusCommonDataType {
  blogs: SingleBlog[]
  pages: SinglePage[]
  notices: SingleNotice[]
  mainMenuLinks: SingleLink[]
  subMenuLinks: SingleLink[]
  timeTableRoutes: SingleTimeTableRoute[]
}

export interface QueryCmsNotices {
  notices: SingleNotice[]
}

export interface QueryCmsBlog {
  blog: SingleBlog
}

export default QUERY_IBUS_COMMON_DATA;