import React, {  } from 'react';
import { Box, List, Divider, } from '@material-ui/core';
import RouteListItem, { RouteListData, RouteListItemWithCtxProps } from './RouteListItem';
import { makeStyles } from '@material-ui/core';
import CommonListItem from 'components/common-components/CommonListItem';
import { ROUTE_LIST_ITEM_HEADER_TEXTS, ROUTE_LIST_ITEM_HEADER_TEXTS_WIDTH_RATIO } from 'components/driving-map/config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  button: {
    width: '50%',
  },
  routeList: {
    overflow: 'auto',
    flexGrow: 1,
    height: 1,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    }
  }
}));

export const RouteListHeader = () => {
  return (
    <CommonListItem
      textsInList={ROUTE_LIST_ITEM_HEADER_TEXTS}
      textsWidthRatios={ROUTE_LIST_ITEM_HEADER_TEXTS_WIDTH_RATIO} />
  );
};

//temp use go / back to set goBack data
export interface RouteListsProps extends Pick<RouteListItemWithCtxProps, 'zoomRatio'> {
  listDatas: RouteListData[]
}
export const RouteLists = ({
  listDatas,
  zoomRatio,
}: RouteListsProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <List className={ classes.routeList }>
        <RouteListHeader />
        <Divider />
        {listDatas.map((data, i) => (
          <RouteListItem
            key={ i }
            index={ i }
            listData={ data }
            haveMRT={data.haveMRT}
            zoomRatio={zoomRatio}
          />
        ))}
      </List>
    </Box>
  );
};

export default RouteLists;