import { QueriedRouteForRouteMap } from "components/route-map/gql/gql-schema";
import { RouteMapContentPartData, SingleGoBackTitleData } from "components/route-map/components/RouteMapContentPart/types";
import { nodeDataFromEdges } from "constants/functions";
import { DepDest, GoBack, SingleProviderWithTel, SingleStationInEdge } from "bus-common-types";
import { RawRouteMapData } from "route-map";
import getDepDestByGoBack from "lib/getDepDestByGoBack";
import { convertTimeToString } from "lib/fn";

export interface MakeRouteMapContentOptions {
  routeMapData: RawRouteMapData
  routeData: QueriedRouteForRouteMap['route']
}

export interface MakeGoBackTitle {
  routeName: string
  providerName: string
  depDest: DepDest
  goBack: GoBack
}

class RouteMapContentHandlers {
  static makeProviderInfo(providers: SingleProviderWithTel[]) {
    const res = providers
      .map(p => `${p.name}: ${p.telephone}`)
      .join(', ');
    return res;
  }

  static getStopsForRouteMapContent(stops: SingleStationInEdge[]) {
    const res = stops.map(s => ({
      stopName: s.node.name,
      goBack: s.goBack,
    }));
    return res;
  }

  static getGoBackTitle({
    goBack, routeName, depDest, providerName,
  }: MakeGoBackTitle): SingleGoBackTitleData {
    const depDestName = getDepDestByGoBack(depDest, goBack);
    const res: SingleGoBackTitleData = {
      depDestName,
      routeName,
      providerName,
    };
    return res;
  }

  static makeRouteMapContent(options: MakeRouteMapContentOptions): RouteMapContentPartData {
    const {
      routeMapData,
      routeData,
    } = options;

    const routeDescription = routeData.description;
    const routeName = routeData.name;

    const providers = nodeDataFromEdges(routeData.providers);
    const providerName = providers.map(p => p.name).join(', ');

    const goTitle = this.getGoBackTitle({
      goBack: 1,
      routeName,
      depDest: routeData,
      providerName,
    });
    const backTitle = this.getGoBackTitle({
      goBack: 2,
      routeName,
      depDest: routeData,
      providerName,
    });
    const updatedAt = convertTimeToString(routeMapData.updatedAt, '.');

    const res: RouteMapContentPartData = {
      id: String(routeData.id),
      from: routeData.departure,
      to: routeData.destination,
      routeDescription: routeDescription,
      goTitle,
      backTitle,
      updatedAt,
      routeNameTitleData: {
        routeName,
        providers: providers.map(p => p.name),
        description: routeDescription,
        onShelfAt: routeMapData.onShelfAt,
      },
      stops: this.getStopsForRouteMapContent(routeData.stations.edges),
      routeMapImage: {
        name: routeDescription,
        src: routeMapData.source && routeMapData.source.url,
      },
      providerInfo: {
        tel: this.makeProviderInfo(providers)
      }
    };

    return res;
  }
}

export default RouteMapContentHandlers;