import React, { memo } from 'react';
import { Box, makeStyles, TextareaAutosize } from '@material-ui/core';
import { MAX_REPORT_DESCRIPTION_INPUT_LENGTH } from 'components/stop-report-page/config';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { ReportDescriptionInputProps } from './types';
import commonLangs from 'static/lang/commonLangs.json';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
     
  },
  textArea: {
    width: '100%',
    borderRadius: theme.spacing(0.25),
    padding: theme.spacing(0.5),
  }
}));

const ReportDescriptionInput = (props: ReportDescriptionInputProps) => {
  const classes = useStyles();

  return (
    <Box>
      <FormattedMessage
        id={'stopReport.selectReportDescInput.placeHolder'}
        defaultMessage={commonLangs.zh_TW['stopReport.selectReportDescInput.placeHolder']}
      >
        {(placeholder) => (
          <Box padding={1}>
            <TextareaAutosize
              {...props}
              className={classes.textArea}
              value={props.textAreaValue}
              autoFocus={true}
              placeholder={placeholder as string}
              rowsMin={2}
              maxLength={MAX_REPORT_DESCRIPTION_INPUT_LENGTH}
            />
          </Box>
        )}
      </FormattedMessage>
      
    </Box>
  );
};

export default memo(ReportDescriptionInput);