import React from 'react';
import { SingleTravelTime } from "collinear-routes";
import IntlFormattedMessage from "components/common-components/IntlFormattedMessage";
import { GridSize } from '@material-ui/core';

export const STOP_SEARCH_MAP_MARKER_POPUP_MIN_WIDTH = 120;
export const ROUTE_INFO_ITEM_WIDTH_RATIOS: GridSize[] = [2, 4, 3, 3,];
export const STOP_SEARCH_ROUTE_INFO_ITEM_WIDTH_RATIOS: GridSize[] = [1, 7, 2, 2];


export const headerInfo = {
  stopSeq: (
    <IntlFormattedMessage 
      langKey={'stopSeq'}
    />
  ),
  stopName: (
    <IntlFormattedMessage 
      langKey={'stopName'}
    />
  ),
  id: (
    <IntlFormattedMessage 
      langKey={'stopId'}
    />
  ),
  travelTime: (
    <IntlFormattedMessage 
      langKey={'travelTime'}
    />
  )
} as any;