import StationNameAutoCompleteHandlers from "components/common-components/AutoCompleteWithQuery/lib/StationNameAutoCompleteHandlers";
import { StationAndAddressAutoCompleteResultType } from "components/common-components/AutoCompleteWithQuery/lib/types";
import { nodeDataFromEdges } from "constants/functions";
import { ValueResult } from "ibus-common-components/lib/components/common-components/AutoComplete/types";
import MapHelpers from "lib/MapHelpers";
import { SingleAttraction, SingleMetro } from "travel-planner";
import { QueriedSpotsMrtsAndStations } from "./querySpotsAndMrts";

const QuerySposMrtsHandlers = {
  makeSingleValueResultFromSpot(spot: SingleAttraction): ValueResult<StationAndAddressAutoCompleteResultType> {
    return ({
      icon: StationNameAutoCompleteHandlers.makeIconByType('SPOT'),
      value: spot.name,
      result: {
        name: spot.name,
        position: MapHelpers.getPosition({
          lat: spot.lat,
          lon: spot.lon,
        })
      },
    });
  },
  makeSingleValueResultFromMrt(mrt: SingleMetro): ValueResult<StationAndAddressAutoCompleteResultType> {
    return ({
      icon: StationNameAutoCompleteHandlers.makeIconByType('MRT'),
      value: mrt.name,
      result: {
        name: mrt.name,
        position: MapHelpers.getPosition({
          lat: mrt.lat,
          lon: mrt.lon,
        })
      },
    });
  },

  convertToValueResultList(rawData: { data: QueriedSpotsMrtsAndStations } | undefined): {
    spots: ValueResult<StationAndAddressAutoCompleteResultType>[],
    mrts: ValueResult<StationAndAddressAutoCompleteResultType>[],
  } {
    if(!rawData) {
      return ({
        spots: [], mrts: []
      });
    }
    const spots = nodeDataFromEdges(rawData.data.attractionByName);
    const mrts = nodeDataFromEdges(rawData.data.metroByName);

    const spotValueResultList = spots.map(s => this.makeSingleValueResultFromSpot(s));
    const mrtValueResultList = mrts.map(m => this.makeSingleValueResultFromMrt(m));

    return ({
      spots: spotValueResultList,
      mrts: mrtValueResultList
    });
  }
};

export default QuerySposMrtsHandlers;