import { gql } from 'apollo-boost';

export const noticesFragment = gql`
  fragment noticesFragment on Notice {
    id
    content
    link
    onShelfAt
    offShelfAt
  }`;

export const blogsFragment = gql`
  fragment blogsFragment on Blog {
    id
    title
    content
    created_at
    flag
    onShelfAt
    offShelfAt
  }`;

export const pagesFragment = gql`
  fragment pagesFragment on Page {
    id
    title
    content
    shortUrl
    onShelfAt
    offShelfAt
  }`;

export const mainMenuLinksFragment = gql`
  fragment mainMenuLinksFragment on MainMenuLinks {
    id
    title
    link
    isDisplay
    isInChildren
    isTargetBlank
    children {
      id
      title
      link
      isDisplay
      isTargetBlank
      children {
        id
      }
    }
  }`;

export const subMenuLinksFragment = gql`
  fragment subMenuLinksFragment on SubMenuLinks {
    id
    title
    link
    isDisplay
    isInChildren
    isTargetBlank
    children {
      id
      title
      link
      isDisplay
      isTargetBlank
    }
  }`;

export const routeMapFragment = gql`
  fragment routeMapFragment on RouteMap {
    route_Id
    title
    source {
      url
    }
    onShelfAt
    updatedAt
  }
`;

const cmsApiFragments = {
  fragment: {
    noticesFragment,
    blogsFragment,
    pagesFragment,
    mainMenuLinksFragment,
    subMenuLinksFragment,
    routeMapFragment,
  }
};

export default cmsApiFragments;