import { ID, Locale } from "all-common-types";
import useCustomFnFetch from "lib/customHooks/useCustomFnFetch";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import queryRoutesByStationIds, { defaultRoutesByStationIds } from "../functions/queryRoutesByStationIds";
import { SingleRouteWithStationId } from "../functions/queryStationRoutesByStationName";
import RoutesByStationHandlers from "../functions/RoutesByStationHandlers";

function useQueryRoutesByStationIds() {
  const {
    locale,
  } = useIntl();

  const {
    error,
    loading,
    responseData: data,
    fetchData: handleQuery,
  } = useCustomFnFetch<SingleRouteWithStationId[], ID[]>({
    apiPath: '',
    fetchFn: (stationIds: ID[]) => queryRoutesByStationIds(stationIds, (locale as Locale)),
    initResponseData: defaultRoutesByStationIds,
  });

  const routesSelectorOptions = useMemo(() => (
    RoutesByStationHandlers.convertToSelectorOptions(data)
  ), [data]);

  return ({
    error,
    loading,
    data,
    routesSelectorOptions,
    handleQuery,
  });
};

export default useQueryRoutesByStationIds;