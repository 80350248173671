/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Dispatch } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { compose, lifecycle } from "recompose";
import { getIbusCommonData } from "actions";
import HomePage from "components/home-page";
import DrivingMap from "components/containers/DrivingMap";
import { PagesWithCtx } from "components/common-components/Page";
import BlogPage from "components/blog/blogPage";
import AppBarPart from "components/common-components/navBar/AppBarPart";
import ContextStore from "./constants/context";
import TravelPlanner from "components/travel-planner/containers/TravelPlanner";
import CollinearRoutesContainer from "components/collinear-routes/containers/CollinearRoutesContainer";
import connectWithCmsData from "components/containers/AppWithCmsData";
import { MapDispatchToProps } from "lib/contextReduxLikeHelpers/mapContextToProps";
import connectCtx from "lib/contextReduxLikeHelpers/connectCtx";
import { Box, makeStyles } from "@material-ui/core";
import Footer from "components/common-components/Footer";
import { Locale } from "all-common-types";
import Fare from "components/fare/containers/Fare";
import LoadingPage from "components/common-components/LoadingPage";
import RouteMapPage from "components/route-map/RouteMapPage";
import routes from "constants/routes";
import RouteMapListPage from "components/route-map-list/RouteMapListPage";
import StopReportPage from "components/stop-report-page/StopReportPage";
import MrtFare from "components/mrt-fare/MrtFare";
import { QueryIbusCommonDataType } from "constants/cmsAPI/cmsAPI";
import checkHolidayTheme from "theme/checkHolidayTheme";

const useStyles = makeStyles((theme) => ({
  appRootWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflow: "hidden",
  },
  root: {
    background: theme.background.homepage,
    backgroundSize:
      theme.name === "dragon-boat"
        ? "100% 100%"
        : checkHolidayTheme(theme)
        ? "auto 100%"
        : "100%",
    backgroundRepeat: "no-repeat",
  },
}));

export type AppProps = {
  locale: string;
  setLocale: (locale: Locale) => any;
  dispatch?: (x: any) => any;
};

export type AppWithIbusCommonDataProps = AppProps & {
  ibusCommonDataLoading: boolean;
  ibusCommonData: QueryIbusCommonDataType;
};

const App = (props: AppWithIbusCommonDataProps) => {
  const { ibusCommonData, dispatch, ibusCommonDataLoading } = props;
  const classes = useStyles();

  const { blogs: blogLists, mainMenuLinks, notices } = ibusCommonData;
  const jsonizedIbusCommonData = JSON.stringify(ibusCommonData);

  useEffect(() => {
    //console.log(ibusCommonData);
    dispatch && dispatch(getIbusCommonData(ibusCommonData));
  }, [jsonizedIbusCommonData]);

  const otherPagesWithLoading = (
    <>
      {ibusCommonDataLoading ? (
        <Route component={LoadingPage} />
      ) : (
        <Route component={PagesWithCtx} />
      )}
    </>
  );

  return (
    <Box className={classes.appRootWrapper}>
      <AppBarPart {...props} {...ibusCommonData} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        className={classes.root}
      >
        <Switch>
          <Route
            exact
            path={routes.homePage}
            render={() => (
              <HomePage
                blogsData={blogLists}
                linksData={mainMenuLinks}
                noticeData={notices}
              />
            )}
          />
          <Route path={routes.travelPlanner} component={TravelPlanner} />
          <Route path={routes.drivingMap} component={DrivingMap} />
          <Route
            path={routes.collinearRoutes}
            component={CollinearRoutesContainer}
          />
          <Route path={routes.fare} component={Fare} />
          <Route path={routes.routeMap} component={RouteMapPage} />
          <Route path={routes.routeMapListPage} component={RouteMapListPage} />
          <Route path={routes.stopReportPage} component={StopReportPage} />
          <Route path={routes.mrtFare} component={MrtFare} />
          <Route
            path={routes.blog}
            render={() => (
              <BlogPage blogLists={blogLists} links={mainMenuLinks} />
            )}
          />
          {otherPagesWithLoading}
        </Switch>
      </Box>
      <Footer />
    </Box>
  );
};

const mapDispatchToProps: MapDispatchToProps<
  AppProps,
  {
    dispatch: Dispatch<any>;
  }
> = (dispatch) => ({
  dispatch: dispatch,
});
const connectCtxFn = connectCtx(ContextStore)(undefined, mapDispatchToProps);

const withScrollTop = compose<any, AppProps>(
  connectWithCmsData,
  withRouter,
  connectCtxFn,
  lifecycle({
    componentDidUpdate(prevProps: any) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    },
  })
);
const HOCApp = withScrollTop(App);
export default HOCApp;
