import { createMuiTheme, Theme } from "@material-ui/core";
import goldTheme from "./goldTheme";
import { ThemeShelfAtDate } from "lib/Handlers/ThemeLocalStorage";
import xmas from './homepageImages/xmas.json';
import xmasThemeRedGreen from "./xmasThemeRedGreen";

export const enableBetween: ThemeShelfAtDate = {
  onShelfAt: {
    year: 2021,
    month: 12,
    date: 10,
  },
  offShelfAt: {
    year: 2021,
    month: 12,
    date: 26,
  },
};

const primary = {
  light: '#FF5036',
  main: '#D00009',
  dark: '#960000',
};

const myTheme = createMuiTheme({
  ...goldTheme,
  name: 'xmas',
  homepageImages: {
    backgroundImage: xmas.homepage_background.src,
    topLeft: xmas.homepage_top_left.src,
    topRight: xmas.homepage_top_right.src,
    bottomLeft: xmas.homepage_below_left.src,
  },
  background: {
    surface: `linear-gradient(0deg, #ffffff 0%, #ffebc6 100%)`,
    homepage: `
      url(${xmas.homepage_background.src}),
      linear-gradient(180deg, #960000 0%, #580000 100%)
    `,
  },
  palette: {
    primary,
    secondary: {
      main: '#fff',
    }
  },
});

export default myTheme;