import { makeStyles } from "@material-ui/core";

const useGoldThemeLeftPartStyles = makeStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'content-box',
    background: theme.background.surface,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  }
}));

export default useGoldThemeLeftPartStyles;