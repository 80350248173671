import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from '../../constants/API';
import { IntlProvider } from 'react-intl';
import { LANG } from '../../constants/lang';
import { Locale } from 'all-common-types';

export const getLang = (locale: Locale) => {
  switch (locale) {
    case 'zh':
      return LANG.zh_TW;
    case 'en':
      return LANG.en_US;
    default:
      return LANG.zh_TW;
  }
};

type Props = {
  locale: Locale
  client: typeof client
  children: import('react').ReactElement
}
const Provider = ({ locale, client, ...props }: Props) => {
  return (
    <ApolloProvider client={ client }>
      <IntlProvider locale={ locale } messages={ getLang(locale) } {...props}>
        { props.children }
      </IntlProvider>
    </ApolloProvider>
  );
};

export default Provider;