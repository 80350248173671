import { SinglePage } from 'all-common-types';
import handleRouteMatched, { PathsAndLinks } from 'lib/handleRouteMatched/handleRouteMatched';

type PageUrlAndTitle = {
  url: string,
  title: string
}
type PageMatchedParams = {
  joinedPath: string
}

export const regShortUrl = (shortUrl: string) => (
  shortUrl.includes('/') ? shortUrl : `/${shortUrl}`
);

class handleLinksAndPagesMatched extends handleRouteMatched {
  static getPageUrlAndTitle = (page: SinglePage): PageUrlAndTitle => ({
    url: regShortUrl(page.shortUrl),
    title: page.title,
  })

  static getPageMatched = ({ url, title }: PageUrlAndTitle) => {
    return ({ joinedPath }: PageMatchedParams) => {
      const pageMatched = joinedPath === url;
      return pageMatched ? title : false;
    };
  }

  static getMatchedRouteWithPages(urlAndTitle: PageUrlAndTitle) {
    const matchFn = this.getPageMatched(urlAndTitle);
    return this.getMatchedRoute(matchFn);
  }

  static getMatchedRouteListWithPages(page: SinglePage) {
    const pageUrlAndTitle = this.getPageUrlAndTitle(page);
    const { url } = pageUrlAndTitle;
    const matchedRouteFn = this.getMatchedRouteWithPages(pageUrlAndTitle);
    return this.getMatchedRouteList(matchedRouteFn)(url);
  }
}

export default handleLinksAndPagesMatched;

