import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { SearchButtonProps } from './types';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  searchIcon: {
    fill: '#fff',
  }
}));

const SearchButton = (props: SearchButtonProps) => {
  const classes = useStyles();

  return (
    <Button 
      {...props}
      onClick={props.onSearch} 
      variant={'contained'}
      color={'primary'}
    >
      <Search
        className={classes.searchIcon}
      />
    </Button>
  );
};

export default SearchButton;