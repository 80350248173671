/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement } from 'react';
import { Tabs, Tab, BoxProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import DirectionsBus from '@material-ui/icons/DirectionsBus';
import OldStyledTabs from 'components/common-components/OldStyledTabs/OldStyledTabs';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { SingleTabData } from 'components/common-components/OldStyledTabs/types';

const useStyles = makeStyles(() => ({
  tabs: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row'
    }
  },
  tab: {
    minHeight: 48,
    width: '50%',
    maxWidth: 'initial',
  }
}));

const drivingMapTabData = [
  {
    icon: <DirectionsBus />,
    label: (
      <IntlFormattedMessage 
        langKey={'drivingMap.busTypeTab.cityBus'}
      />
    )
  },
  {
    icon: <DirectionsBus />,
    label: (
      <IntlFormattedMessage 
        langKey={'drivingMap.busTypeTab.roadBus'}
      />
    )
  }
];
export interface SelectTabProps extends BoxProps {
  selectedTab: number
  selectTabFn?: (e: any, value: any) => any
  tabDatas?: SingleTabData[]
}
const SelectTab = (props: SelectTabProps) => {
  // const { selectedTab, selectTabFn, tabDatas=drivingMapTabData } = props;
  const classes = useStyles();
  return (
    //new generation
    // <Tabs 
    //   className={ classes.tabs }
    //   value={ selectedTab } 
    //   onChange={ selectTabFn }
    //   indicatorColor={ 'primary' }
    //   textColor={ 'primary' }
    // >
    //   {tabDatas.map((data, i) => (
    //     <Tab 
    //       key={i} 
    //       fullWidth={true} 
    //       icon={data.icon} 
    //       label={data.label} 
    //       className={classes.tab} 
    //     />
    //   ))}
    // </Tabs>
    <OldStyledTabs {...props} />
  );
};

export default SelectTab;
