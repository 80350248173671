import { Locale } from "all-common-types";
import queryStationsByName from "components/common-components/AutoCompleteWithQuery/lib/queryStationsByName";

export interface QueryStationsByNameOptions {
  name: string
  lang: Locale
}

const queryStationsDirectly = (options: QueryStationsByNameOptions) => {
  return queryStationsByName(options.lang, options.name);
};

export default queryStationsDirectly;