import { EdgesData, Locale } from "all-common-types";
import { client} from "constants/API";
import { LangKeys } from "constants/lang";
import { NewSchemaApiTypes } from "constants/NewSchemaAPI/commonAPI";
import gql from "graphql-tag";
import { SingleAttraction, SingleMetro } from "travel-planner";

const QUERY_SPOTS_AND_MRTS = gql`
  query QUERY_SPOTS_AND_MRTS($name: String!, $lang: String!) {
    attractionByName(name: $name) {
      edges {
        node {
          id
          lat
          lon
          name
        }
      }
    }

    metroByName(name: $name, lang: $lang) {
      edges {
        node {
          id
          lat
          lon
          name
          seq
        }
      }
    }
  }
`;

export interface QueriedSpotsMrtsAndStations {
  attractionByName: EdgesData<SingleAttraction>
  metroByName: EdgesData<SingleMetro>
}

const querySpotsAndMrts = (name: string, lang: Locale) => {
  try {
    return client
      .query<QueriedSpotsMrtsAndStations>({
      query: QUERY_SPOTS_AND_MRTS,
      variables: {
        name, lang,
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export default querySpotsAndMrts;