import React from 'react';
import { Box, Typography } from '@material-ui/core';


export const DefaultRenderComponent = () => {
  return (
    <Box>
      <Typography>
        { 'No Route Data Now...' }
      </Typography>
    </Box>
  );
};

type ErrorMessageProps = { 
  selectedRouteId: string
  error: any 
}
export const ErrorMessage = ({ selectedRouteId, error }: ErrorMessageProps) => {
  return (
    <Typography>
      { `selectedRouteId: ${ selectedRouteId }, typeOf: ${ typeof(selectedRouteId) }, Error: ${ error }` }
    </Typography>
  );
};
