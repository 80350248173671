
export interface FetchDataByAPIOptions<DefaultRes> {
  uri: string
  requestInit?: RequestInit
  useCorsPrefix?: boolean
  defaultRes: DefaultRes
}

async function customFetchDataByAPIWithCors<DefaultRes>({
  uri,
  defaultRes,
  useCorsPrefix=true,
  requestInit,
}: FetchDataByAPIOptions<DefaultRes>) {
  let response = defaultRes;

  await fetch(uri, {
    mode: 'cors',
    ...requestInit,
  })
    .then(res => res.json())
    .then(res => {
      response = res;
    })
    .catch(rej => console.log(rej));
  return response;
}

export default customFetchDataByAPIWithCors;