import { RoutesFilterSelectorProps } from "./types";
import { useCallback } from 'react';
import { useParams } from 'react-router';
import useQueryRoutes from 'lib/customHooks/useQueryRoutes';
import { GetSelectedOptionFn } from 'ibus-common-components/lib/components/common-components/FilterSelector/types';
import filterRoutesByOptypeAndTaxi from "lib/filterRoutesByOptypeAndTaxi";
import { SingleRouteSelectorOption } from "constants/functions";

export interface UseRoutesFitlerSelectorOptions extends RoutesFilterSelectorProps {

}

const useRoutesFitlerSelector = (options: UseRoutesFitlerSelectorOptions) => {
  const {
    busProviderId, setRouteIdFn, onSetRouteName
  } = options;

  const {
    selectorOptionsRoutes,
  } = useQueryRoutes('city', busProviderId, [11]);

  const {
    xno,
  } = useParams<{xno: string}>();
  const defaultSelectedRouteFromRouter = {
    text: '',
    value: xno || '',
  };

  const handleSetRouteId: GetSelectedOptionFn = useCallback((selectedOption) => {
    const {
      value: routeId,
      name: routeName,
    } = selectedOption as SingleRouteSelectorOption;

    setRouteIdFn(routeId);
    onSetRouteName && onSetRouteName(routeName);
  }, [onSetRouteName, setRouteIdFn]);

  const filteredSelectorOptionsRoutes = filterRoutesByOptypeAndTaxi(selectorOptionsRoutes);

  return ({
    defaultSelectedRouteFromRouter,
    selectorOptionsRoutes: filteredSelectorOptionsRoutes,
    handleSetRouteId,
  });
};

export default useRoutesFitlerSelector;