import moment from "moment";

const checkHomepageImageLinkAvailable = () => {
  if (process.env.REACT_APP_HOMEPAGE_IMAGE_LINK_ON_SHELF_AT && process.env.REACT_APP_HOMEPAGE_IMAGE_LINK_OFF_SHELF_AT){
    const onShelfAt = moment(process.env.REACT_APP_HOMEPAGE_IMAGE_LINK_ON_SHELF_AT, 'YYYY/MM/DD');
    const offShelfAt = moment(process.env.REACT_APP_HOMEPAGE_IMAGE_LINK_OFF_SHELF_AT, 'YYYY/MM/DD').add(1,'d').add(-1,'s');
    const now = moment();
    return now.isBetween(onShelfAt, offShelfAt);
  }
  return false;

};

export default checkHomepageImageLinkAvailable;