import React from 'react';
import { Box, Container } from '@material-ui/core';
import useGoldThemeLeftPartStyles from 'styles/styleObjs/goldThemeLeftPartStyles';
import { PageWrapperProps } from './types';

const PageWrapper = (props: PageWrapperProps) => {
  const classes = useGoldThemeLeftPartStyles();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      paddingTop={2}
      paddingBottom={2}
    >
      <Container style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box
          flexGrow={1}
          style={{ height: 1, overflow:'auto' }}
          className={classes.root}
          {...props}
        >
          {props.children}
        </Box>
      </Container>
    </Box>

  );
};

export default PageWrapper;