import { RouteMapState } from "../constants/context";
import ActionTypes, { Actions } from "../actions";

export const routeId = (state: RouteMapState, action: Actions): RouteMapState['routeId'] => {
  switch (action.type) {
    case ActionTypes.SET_ROUTE_ID:
      return action.payload.routeId;

    case ActionTypes.RESET_ROUTE_ID:
      return null;
      
    default:
      return state.routeId;
  }
};

