import { createMuiTheme } from "@material-ui/core";
import goldTheme from "./goldTheme";
import { ThemeShelfAtDate } from "lib/Handlers/ThemeLocalStorage";
import homepageImages from './homepageImages/moonFestival.json';


export const enableFromTo: ThemeShelfAtDate = {
  onShelfAt: {
    year: 2021,
    month: 6,
    date: 8,
  },
  offShelfAt: {
    year: 2021,
    month: 9,
    date: 21,
  },
};

const primary = {
  light: '#8f79e4',
  main: '#5d4db1',
  dark: '#292481',
};

const secondary = {
  light: '#ffffff',
  main: '#e2d4fa',
  dark: '#b0a3c7'
};

export default createMuiTheme({
  ...goldTheme,
  name: 'moon-festival',
  homepageImages: {
    topLeft: homepageImages.homepage_top_left.src,
    topRight: homepageImages.homepage_top_right.src,
    bottomLeft: homepageImages.homepage_below_left.src,
  },
  background: {
    surface: `linear-gradient(${135}deg, ${'#fff'} 0%, ${'#e2d4fa'} 100%)`,
    homepage: `
      url(${homepageImages.homepage_background.src}),
      linear-gradient(${0}deg, ${'#171162'} 40%, ${'#ebe2fa'} 100%)`,
  },
  palette: {
    primary,
    secondary,
  },
});