/* eslint-disable react-hooks/exhaustive-deps */
import React, {  } from 'react';
import { CircularProgress } from '@material-ui/core';
import SelectTab from './SelectTab';
import ContextStore, { SelectedRoute, DrivingMapState } from 'components/driving-map/constants/context';
import CityBusPartContainer, { CityBusPartContainerWithCtxProps } from 'components/driving-map/containers/BusSelect/CityBusPartContainer';
import RoadBusPart from './RoadBusPart';
import { MapStateToProps, MapDispatchToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import { Callback } from 'all-common-types';
import { connectCtx } from 'react-function-helpers';
import useGetRouteGroup from 'components/driving-map/lib/useGetRouteGroup';
import useBusSelectPartTab from './functions/useBusSelectPartTab';
import { SingleIbikeInfo } from 'driving-map';
import checkAllAreLoaded from 'lib/checkAllAreLoaded';
import { setIbikeInfoList } from 'components/driving-map/actions/ibikeInfoList-actions';
import LoadingAndError from 'components/common-components/LoadingAndError';

interface BusSelectPartProps extends Omit<CityBusPartContainerWithCtxProps, 'routes' | 'routeGroupList'> {
  selectedTab: number
  selectedRouteId: SelectedRoute['id']
  setIbikeInfoListToCtxFn?: (ibikeInfoList: SingleIbikeInfo[]) => any
  dispatch?: (x: any) => any
}
export const BusSelectPart = (props: BusSelectPartProps) => {
  const {
    selectedTab,
    dispatch,
  } = props;

  const {
    isLoadingRouteGroupList,
    routeGroupList,
  } = useGetRouteGroup();

  const areLoaded = checkAllAreLoaded([
    isLoadingRouteGroupList,
    // loadingIbikeInfoList,
  ]);

  if(!areLoaded) {
    return (
      <LoadingAndError
        loading={true}
        error={undefined}
      />
    );
  }

  return (
    <>
      {/* <SelectTab 
              selectTabFn={ setSelectTab } 
              selectedTab={ selectedTab } /> */}
      { selectedTab === 0 && (
        <CityBusPartContainer 
          routeGroupList={routeGroupList} />
      ) }
      { selectedTab === 1 && (
        <RoadBusPart />
      ) }
    </>
  );
};

interface OwnProps {
  selectedTab: number
}

const mapStateToProps: MapStateToProps<DrivingMapState, OwnProps, {
  selectedRouteId: DrivingMapState['selectedRoute']['id'],
}> = (state) => {
  return ({
    selectedRouteId: state.selectedRoute.id,
  });
};

const mapDispatchToProps: MapDispatchToProps<OwnProps, {
  dispatch: Callback,
  setIbikeInfoListToCtxFn: (ibikeInfoList: SingleIbikeInfo[]) => any
}> = (dispatch) => {
  return ({
    dispatch,
    setIbikeInfoListToCtxFn: (ibikeInfoList) => {
      const action = setIbikeInfoList(ibikeInfoList);
      dispatch(action);
    }
  });
};

const BusSelectPartWithCtx = connectCtx(ContextStore)(mapStateToProps, mapDispatchToProps)(BusSelectPart);

export default BusSelectPartWithCtx;