import { busProviderId } from './busProviderId-reducers';
import CommonStateTypes from './state';
import { Reducer } from 'all-common-types';
import { IbusCommonActions } from 'actions/common-actions';
import { selectedRoute } from './selectedRoute-reducers';

const commonReducers: {
  [x in keyof CommonStateTypes]: Reducer<CommonStateTypes, IbusCommonActions, CommonStateTypes[x]>
} = {
  busProviderId: busProviderId,
  selectedRoute: selectedRoute,
};

export default commonReducers;