import SelectedRouteActions from './selectedRoute-actions';import BusProviderIdActions from './busProviderId-actions';

enum IbusCommonActionTypes {
  SET_SELECTED_ROUTE = 'SET_SELECTED_ROUTE',
  RESET_SELECTED_ROUTE = 'RESET_SELECTED_ROUTE',

  SET_BUS_PROVIDER_ID = 'SET_BUS_PROVIDER_ID',
  RESET_BUS_PROVIDER_ID = 'RESET_BUS_PROVIDER_ID'
}

export default IbusCommonActionTypes;

export type IbusCommonActions = SelectedRouteActions | BusProviderIdActions