import { Position, RoutePoint } from "bus-common-types";
import polyline from 'polyline';
import { defaultMapCenter, mapConfigs } from "config";

class MapHelpers {
  static getPolyline(polylineStr: string) {
    return (
      polylineStr ? polyline.decode(polylineStr) as Position[] : []
    );
  }

  static getPosition(data: { lat: number, lon: number }): Position {
    return [data.lat, data.lon];
  }

  static getMapBounds(data: undefined | { routePoints: Position[] }) {
    const res = data ? data.routePoints : undefined;
    return res;
  }

  static getZoomByFocused(isFocus?: boolean) {
    const res = isFocus ? mapConfigs.zoom.focused : mapConfigs.zoom.unfocused;
    return res;
  }
}

export default MapHelpers;