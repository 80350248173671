import React, { useMemo } from 'react';
import { Box, makeStyles, Divider } from '@material-ui/core';
import { Schedules } from './index';
import { DayType, SingleSchedule, SingleProvider } from 'bus-common-types';
import { BusProviders } from './BusProviders';
import { HeadwayPart, HeadwayType } from './HeadwayPart';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { ID } from 'all-common-types';
import { handleTimeTable } from './functions/timeTable';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    marginBottom: 20,
    borderRadius: theme.spacing(0.5),
    // borderRight: '2px solid #ddd',
    '& h3': {
      fontSize: 24,
      padding: 6,
      fontWeight: 800,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    '& h4': {
      fontSize: 18,
      backgroundColor: '#ddd',
      padding: 4,
    }
  }
}));

export interface SingleTimeTablePartData {
  schedules: SingleSchedule[]
  headway: {
    hideHeadWay: boolean
    weekday: HeadwayType
    holiday: HeadwayType
  }
  lastStation: string
  providers: SingleProvider[]
}
export interface TimeTablePartProps {
  routeId: ID
  dayType: DayType
  timeTableData: SingleTimeTablePartData
}

//single go or back timeTable
const TimeTablePart = ({
  routeId, timeTableData, dayType
}: TimeTablePartProps) => {
  const classes = useStyles();
  const {
    headway,
    providers,
  } = timeTableData;

  const schedulesData = useMemo(() => (
    handleTimeTable.appendProvidersToSchedules(timeTableData)
  ), [timeTableData]);

  return (
    <Box className={ classes.root }>
      <IntlFormattedMessage<{station: string}>
        langKey={'drivingMap.timeTable.toStation'}
        variant={'h3'}
        values={{
          station: timeTableData.lastStation
        }} />
      <HeadwayPart dayType={ dayType } headway={ headway } />
      <Schedules schedulesData={schedulesData} />
      <Divider />
      <BusProviders
        routeId={routeId}
        busProviders={ providers }
      />
    </Box>
  );
};

export default TimeTablePart;