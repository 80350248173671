import React from 'react';
import { Typography } from '@material-ui/core';
import { SingleProvider } from 'bus-common-types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { getBusProvidersStr } from '../CommonComponents/ProvidersList';
import { ID } from 'all-common-types';

type BusProvidersProps = {
  routeId: ID
  busProviders: SingleProvider[]
}

export const BusProviders = ({ routeId, busProviders }: BusProvidersProps) => {
  const providersStr = getBusProvidersStr(routeId)(busProviders, ', ');
  return (
    <>
      <IntlFormattedMessage 
        langKey={'drivingMap.timeTable.busProvidersTitle'} 
      />
      <Typography variant={ 'body1' }>
        {providersStr}
      </Typography>
    </>
  );
};