import React from 'react';
import { HeadwayInfo, HeadwayInterval } from './index';
import { SinglePeakTimeData } from './HeadwayInterval';
import { DayType } from 'bus-common-types';

export type HeadwayNotNull = {
  first: string
  last: string
  peaks: SinglePeakTimeData[]
}
export type HeadwayType = HeadwayNotNull | null
export type HeadwayPartProps = {
  headway: {
    hideHeadWay: boolean
    weekday: HeadwayType
    holiday: HeadwayType
  }
  dayType: DayType
}

export const HeadwayPart = ({ dayType, headway }: HeadwayPartProps) => {
  const thisDayTypeHeadway = headway[dayType];
  if(headway.hideHeadWay) {
    return null;
  }
  else if(!thisDayTypeHeadway) {
    return null;
  }
  else {
    return (
      <>
        <HeadwayInfo 
          dayType={ dayType }
          firstLastTimeData={ thisDayTypeHeadway } 
        />
        <HeadwayInterval 
          dayType={ dayType }
          peakTimeDatas={ thisDayTypeHeadway.peaks } 
        />
      </>
    );
  }
};