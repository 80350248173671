import React from 'react';
import { Box, makeStyles, Link, Breadcrumbs } from '@material-ui/core';
import { LANG } from 'constants/lang';
import IntlFormattedMessage from './IntlFormattedMessage';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
    textAlign: 'center',
    // backgroundColor: theme.palette.primary.main,
    background: theme.homepageColors.footer,
    // backgroundSize: `${25}%, ${100}%`,
    backgroundSize: `${100}%`,
  },
  texts: {
    color: '#fff',
    '& ol': {
      justifyContent: 'center',
    }
  }
}));

const separator = '|';

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Breadcrumbs className={classes.texts} separator={separator}>
        <Link
          className={classes.texts}
          href={LANG.en_US['footer.title_link']}
        >
          <IntlFormattedMessage langKey={'footer.title'} />
        </Link>
        <IntlFormattedMessage langKey={'footer.address'} />
        <IntlFormattedMessage langKey={'footer.support'} />
        <IntlFormattedMessage langKey={'footer.resolution'} />
      </Breadcrumbs>
    </Box>
  );
};

export default Footer;