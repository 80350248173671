import { SingleEstimateStop, SingleStationInEdge } from "bus-common-types";
import { seperateData } from "lib/seperateDataHelpers";
import { SingleRouteStop } from "components/common-components/wrappers/types";
import { CollinearRoutesState } from "components/collinear-routes/constants/context";
import { defaultEstimateTimeOptions } from "constants/EstimateTimeHandler";

export interface SingleEstimateStopWithIndex extends SingleEstimateStop {
  index: number
}

export interface MakerRouteStopsOptions {
  fromStationName: string
  stations: SingleStationInEdge[]
}

class RouteStopsAfterStopNameHandler {
  static makeEstimateStopByStations({
    fromStationName, stations,
  }: MakerRouteStopsOptions): SingleEstimateStop[] {
    const stationIdx = stations.findIndex(s => String(s.node.name) === String(fromStationName));
    const slicedStations = stations.slice(stationIdx);
    const res: SingleEstimateStop[] = slicedStations.map((s, i) => ({
      ...defaultEstimateTimeOptions,
      ...s,
      orderNo: i + stationIdx, // 從from station index開始，且不用+1
      id: s.node.id,
      stopName: s.node.name,
      lat: s.node.lat,
      lon: s.node.lon,
    }));
    return res;
  }

  static filterEstimateStops(options: MakerRouteStopsOptions): SingleEstimateStop[] {
    const {
      fromStationName, stations,
    } = options;

    const stationsByGoBack = seperateData.makeGoBackDataToObj(stations);
    const estimateStopsGo = this.makeEstimateStopByStations({ 
      fromStationName, stations: stationsByGoBack.go 
    });
    const estimateStopsBack = this.makeEstimateStopByStations({ 
      fromStationName, stations: stationsByGoBack.back
    });

    const res: SingleEstimateStop[] = [
      ...estimateStopsGo,
      ...estimateStopsBack,
    ];

    return res;
  }

  static makeSingleRouteStop(singleStop: SingleEstimateStop, index: number): SingleRouteStop {
    const res: SingleRouteStop = {
      ...singleStop,
      position: [singleStop.lat, singleStop.lon],
    };
    return res;
  };

  static makeRouteStops(options: MakerRouteStopsOptions) {
    const estimateStops = this.filterEstimateStops(options);

    const routeStops = estimateStops.map(RouteStopsAfterStopNameHandler.makeSingleRouteStop);
    return routeStops;
  }
}

export default RouteStopsAfterStopNameHandler;