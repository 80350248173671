import React, { useMemo } from "react";
import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import ScheduleItem from "./ScheduleItem";
import IntlFormattedMessage from "components/common-components/IntlFormattedMessage";
import EmptyMessage from 'components/common-components/Other/EmptyMessage';
import ScheduleTimeHandlers from "./functions/ScheduleTimeHandlers";
import { SingleSchedule } from "bus-common-types";
import NoonScheduleItem from "./NoonScheduleItem";
import { SingleScheduleForTimeTable } from "./types";

export type SchedulesProps = {
  schedulesData: SingleScheduleForTimeTable[];
};

const useStyles = makeStyles(theme => ({
  root: {
     
  },
  noonSchedule: {
    width: '50%',
    padding: theme.spacing(0.5),
    '&:first-child': {
      borderRight: `${2}px solid #ddd`,
    }
  }
}));

const Schedules = ({ schedulesData = [] }: SchedulesProps) => {
  const classes = useStyles();
  const dividedSchedulesByNoon = ScheduleTimeHandlers.splitByNoon(schedulesData);
  const isEmpty = useMemo(() => (
    schedulesData.length === 0
  ), [schedulesData.length]);

  return (
    <Box>
      <IntlFormattedMessage langKey={"drivingMap.timeTable.schedulesTitle"} />
      {isEmpty ? (
        <EmptyMessage>
          <IntlFormattedMessage 
            langKey={'drivingMap.timeTable.noSchedule'}
            color={'textSecondary'}
            align={'left'}
          />
        </EmptyMessage>
      ) : (
        <Box
          display={'flex'}
        >
          {dividedSchedulesByNoon.map((s, i) => (
            <Box key={i} className={classes.noonSchedule}>
              <NoonScheduleItem
                key={i}
                noonType={ScheduleTimeHandlers.convertIndexToNoonType(i)}
                scheduleListData={s}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Schedules;
