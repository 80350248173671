import React from 'react';
import { Box, FormControlLabel, Radio } from '@material-ui/core';
import { RadioTabItemProps } from './types';
import IntlFormattedMessage from '../IntlFormattedMessage';

const RadioTabItem = (props: RadioTabItemProps) => {
  const {
    value, labelLangKey, langValues, color='primary'
  } = props;
  
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio {...props} color={color} />
      } 
      label={
        <IntlFormattedMessage
          langKey={labelLangKey}
          values={langValues}
        />
      }
    />
  );
};

export default RadioTabItem;