import React from 'react';
import { SingleSubRoute } from "driving-map";
import { SingleRouteSelectorOption } from "constants/functions";
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

export const subRoutesSelectItemHeader: SingleRouteSelectorOption = {
  id: '',
  seq: 0,
  opType: 0,
  routeName: (
    <IntlFormattedMessage
      langKey={'route'}
    />
  ),
  name: (
    <IntlFormattedMessage
      langKey={'route'}
    />
  ) as any,
  routeDescp: (
    <IntlFormattedMessage
      langKey={'routeDescp'}
    />
  ) as any,
  description: (
    <IntlFormattedMessage
      langKey={'routeDescp'}
    />
  ) as any,
  text: '',
  value: '',
  providerIds: [],
};