import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 700,
     
  }
}));

const WarningMessage = () => {
  const classes = useStyles();

  return (
    <Box paddingY={1}>
      <IntlFormattedMessage
        className={classes.root}
        color={'error'}
        langKey={'stopReport.reportTruthWarning'}
      />
    </Box>
  );
};

export default WarningMessage;