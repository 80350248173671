import { Locale } from "all-common-types";
import { client} from "constants/API";
import { QueryStationsNamesByValueData, QUERY_STATIONS_NAMES_BY_VALUE } from "constants/API/commonAPI";
import { nodeDataFromEdges } from "constants/functions";
import NEW_SCHEMA_API_SCHEMAS, { NewSchemaApiTypes } from "constants/NewSchemaAPI/commonAPI";
import StationNameAutoCompleteHandlers from "./StationNameAutoCompleteHandlers";

export const TAI_CHINESE = ['臺', '台'];

export const checkContainTai = (input: string) => (
  input.includes(TAI_CHINESE[0]) ||
  input.includes(TAI_CHINESE[1])
);

export const convertInputContainsTai = (input: string) => {
  let res = [input];

  if(input.includes(TAI_CHINESE[0])) {
    res.push(
      input.replace(TAI_CHINESE[0], TAI_CHINESE[1])
    );
  }
  else if(input.includes(TAI_CHINESE[1])) {
    res.push(
      input.replace(TAI_CHINESE[1], TAI_CHINESE[0])
    );
  }

  return res;
};

export const clientQueryStationsByName = (lang: Locale, inputVal: string) => {
  return client
    .query<NewSchemaApiTypes['QueriedStationsByName']>({
    query: NEW_SCHEMA_API_SCHEMAS.QUERY_STATIONS_NAMES_BY_VALUE,
    variables: {
      lang,
      station: inputVal,
    }
  });
};

const queryStationsByName = async (lang: Locale, inputVal: string): Promise<NewSchemaApiTypes['SingleQueriedStationByName'][]> => {
  const isContainedTai = checkContainTai(inputVal);

  if(isContainedTai) {
    const convertedInputs = convertInputContainsTai(inputVal);
    const firstRes = await clientQueryStationsByName(lang, convertedInputs[0]);
    const secondRes = await clientQueryStationsByName(lang, convertedInputs[1]);
    const stations = [
      ...nodeDataFromEdges(firstRes.data.stationsByName),
      ...nodeDataFromEdges(secondRes.data.stationsByName),
    ];
    const res = StationNameAutoCompleteHandlers.filterSameStationId(stations);
    return res;
  } else {
    const res = await clientQueryStationsByName(lang, inputVal);
    const stations = nodeDataFromEdges(res.data.stationsByName);
    return stations;
  }
};

export default queryStationsByName;