import React from 'react';
import { Box } from '@material-ui/core';
import { UpdatedAtProps } from './types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const UpdatedAt = ({
  updatedAt,
}: UpdatedAtProps) => {
  return (
    <Box>
      <IntlFormattedMessage 
        langKey={'routeMap.updatedAt'}
        values={{
          updatedAt,
        }}
      />
    </Box>
  );
};

export default UpdatedAt;