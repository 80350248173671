import React, { ReactNode } from 'react';
import { PlanMode } from 'travel-planner';
import { PlanModeSelectorProps } from './types';
import Selector from 'components/common-components/SelectsComponents/Selector';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

export const planModeSelectorOptions: {
  value: PlanMode
  text: ReactNode
}[] = [
  {
    value: 'BUS',
    text: <IntlFormattedMessage langKey={'travelPlanner.planMode.bus'} />,
  },
  {
    value: 'TRANSIT',
    text: <IntlFormattedMessage langKey={'travelPlanner.planMode.transit'} />,
  },
];

const PlanModeSelector = (props: PlanModeSelectorProps) => {
  return (
    <Selector
      {...props}
      changeFn={props.onChange}
      id={'PlanModeSelector'}
      label={
        <IntlFormattedMessage langKey={'travelPlanner.planMode.selectorLabel'} />
      }
      options={planModeSelectorOptions}
    />
  );
};

export default PlanModeSelector;