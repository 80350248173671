/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import ContextStore from '../../constants/context';
import { setRouteData } from 'components/driving-map/actions';
import { RouteBasicDataType, SingleEstimateStop, GoBackStr, GoBack, SingleNearBikeStation, SingleProvider, BusInfos, NearBikeStations } from 'bus-common-types';
import { DefaultRenderComponent } from '../CommonComponents/ComponentsForQuery';
import LoadingAndError from 'components/common-components/LoadingAndError';
import { nodeDataFromEdges } from 'constants/functions';
import EmptyMessage from 'components/common-components/Other/EmptyMessage';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import useQueryRouteData from 'components/driving-map/gql/custom-hooks/useQueryRouteData';
import { HandledRouteData } from 'driving-map';

type PropsForRender = {
  goBack: GoBack
  routeData: HandledRouteData
  nearBikeStations: SingleNearBikeStation[],
  queryRoute: () => any
}
type QueryRoutePartWithCtxProps = {
  goBack: GoBack
  render?: (props: PropsForRender) => import('react').ReactNode
  defaultRender?: typeof DefaultRenderComponent
  setRouteDataToCtxFn?: (routeData: any) => any
  [x: string]: any
}
type QueryRoutePartProps = {
  selectedRouteId: string
} & QueryRoutePartWithCtxProps

export const QueryRoutePart = (props: QueryRoutePartProps) => {
  const { 
    selectedRouteId, 
    goBack, 
    render=DefaultRenderComponent, 
    setRouteDataToCtxFn,
  } = props;

  const {
    state: {
      routeData: routeDataFromCtx,
    }
  } = useContext(ContextStore);

  const {
    loading,
    handledRouteData,
    handleQueryDynamicRouteData,
  } = useQueryRouteData({ routeId: selectedRouteId, });

  useEffect(() => {
    if(handledRouteData) {
      // console.log('set route data to ctx.');
      setRouteDataToCtxFn && setRouteDataToCtxFn(handledRouteData);
    }
  }, [JSON.stringify(handledRouteData)]);

  const isFirstLoading = loading && !handledRouteData;
  if(isFirstLoading) {
    return (
      <EmptyMessage>
        <IntlFormattedMessage langKey={'drivingMap.routePart.loading'} />
      </EmptyMessage>
    );
  };
  
  const propsForRender = (routeDataFromCtx) ? { 
    goBack,
    routeData: routeDataFromCtx, 
    queryRoute: handleQueryDynamicRouteData,
    nearBikeStations: routeDataFromCtx ? nodeDataFromEdges(routeDataFromCtx.nearBikeStations) : [],
  } : undefined;
  return(
    <>
      {
        propsForRender ? render(propsForRender) : (
          <LoadingAndError 
            loading={true}
            error={undefined}
          />
        )
      }
    </>
  );
};


export const QueryRoute = ({ defaultRender, ...props }: QueryRoutePartWithCtxProps) => {
  const { state, dispatch } = useContext(ContextStore);
  const { id: selectedRouteId } = state.selectedRoute;

  const handleSetRouteDataToCtx = (routeData: any) => {
    const action = setRouteData(routeData);
    // console.log(routeData);
    dispatch(action);
  };

  return (
    selectedRouteId ? (
      <QueryRoutePart 
        {...props}
        setRouteDataToCtxFn={handleSetRouteDataToCtx}
        selectedRouteId={ selectedRouteId }  />
    ) : defaultRender ? defaultRender() : null
  );
};

export default QueryRoute;