import { StationContentMode, StationContentSimpleMode } from "mrt-pages";

const checkStationContentMode = (contentMode: StationContentMode): StationContentSimpleMode => {
  switch (contentMode) {
    case 'FARE_ETICKET_FULL':
    case 'FARE_FULL':
    case 'FARE_REDUCED':  
      return 'FARE';
  
    case 'TRAVEL_TIME':
      return 'TRAVEL_TIME';
  }
};

export default checkStationContentMode;