import reducers from 'reducers';
import { SingleBlog } from 'blog';
import { SingleLink, SinglePage, SingleNotice, HandledTimeTableRoutes } from 'all-common-types';
import ContextWrapperFn from 'react-function-helpers/lib/functions/ContextWrapper';
import { createContextStore, createContextValueFn } from 'lib/contextHelpers';
import ibusImages from 'static/ibusImages.json';
import { QueryIbusCommonDataType } from './cmsAPI/cmsAPI';

export interface IbusCommonData extends Omit<QueryIbusCommonDataType, 'timeTableRoutes'> {
  handledTimeTableRoutes: HandledTimeTableRoutes
}

export interface SingleIbusImage {
  name: string
  src: string
}
export type IbusImages = {
  [imageKey in keyof typeof ibusImages]: SingleIbusImage
}

export type State = {
  ibusCommonData: IbusCommonData;
  ibusImages: IbusImages
}

export const initIbusCommonData: IbusCommonData = {
  blogs: [],
  subMenuLinks: [],
  mainMenuLinks: [],
  pages: [],
  notices: [],
  handledTimeTableRoutes: {
    headway: [],
    mixed: [],
  },
};
const initState: State = {
  ibusCommonData: initIbusCommonData,
  ibusImages: ibusImages,
};

export const ContextValue = createContextValueFn(initState, reducers);
const ContextStore = createContextStore(initState);

export default ContextStore;

export const ContextWrapper = ContextWrapperFn(ContextValue, ContextStore);