import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { PreviewPartProps } from './types';

const PreviewPart = ({
  previewImageList,
}: PreviewPartProps) => {
  return (
    <Box>
      {previewImageList.map((file) => (
        <Box>
          <img 
            width={200}
            height={'auto'}
            alt={file.name}
            src={file.src}
          />
          <Typography>{file.name}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PreviewPart;