import { FareActionTypes } from ".";
import { FareState } from "../constant/context";

export interface SetRoutesPayload {
  routes: FareState['routes']
}

export interface SetRoutes {
  type: FareActionTypes.SET_ROUTES,
  payload: SetRoutesPayload
}

export const setRoutes = (routes: FareState['routes']): SetRoutes => ({
  type: FareActionTypes.SET_ROUTES,
  payload: { routes }
});

type FareActions = SetRoutes

export default FareActions;