import { gql } from 'apollo-boost';
import RouteFragment from 'constants/api-fragments';
import { EdgesData, SingleRouteFromRoutes } from 'all-common-types';
import { GoBack } from 'bus-common-types';

//lang: zh / en
export const QUERY_ROUTES = gql`
  query Routes($lang: String!) {
    routes(lang: $lang) {
      edges {
        node {
          id
          name
          seq
          departure
          description
          destination
          opType
          providers {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }`;
export type QueriedRoutes = {
  routes: EdgesData<SingleRouteFromRoutes>
}

export const QUERY_CLOGS = gql`
  query QUERY_CLOGS($routeId: Int!) {
    clogMessages(xno: $routeId) {
    	...clogsInfo
    }
  }
  ${ RouteFragment.fragment.clogsInfo }`;

export interface SingleStationInClogMessage {
  id: number
  goBack: GoBack
  orderNo: number
}
export interface SingleQueriedClogMessage {
  id: string
  xno: string
  type: number
  providerId: number
  title: string
  description: string
  template: string
  beginTime: string
  stations: SingleStationInClogMessage[]
}
export interface QueriedClogMessages {
  clogMessages: EdgesData<SingleQueriedClogMessage>
}
