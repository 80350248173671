import { GoBack } from "bus-common-types";
import { actionTypes as Types } from './index';
import { CollinearRoutesState } from "../constants/context";

export interface SetGoBackActionPayload {
  goBack: GoBack
}
export interface SetRouteDataPayload {
  routeData: CollinearRoutesState['stopSearchStates']['routeData']
}

export interface SetGoBackAction {
  type: Types.SET_GO_BACK,
  payload: SetGoBackActionPayload
}
export interface SetRouteDataAction {
  type: Types.SET_ROUTE_DATA,
  payload: SetRouteDataPayload
}
export interface ResetRouteDataAction {
  type: Types.RESET_ROUTE_DATA
}

export const setGoBack = (goBack: GoBack): SetGoBackAction => ({
  type: Types.SET_GO_BACK,
  payload: { goBack }
});
export const setRouteData = (routeData: CollinearRoutesState['stopSearchStates']['routeData']): SetRouteDataAction => ({
  type: Types.SET_ROUTE_DATA,
  payload: { routeData }
});
export const resetRouteData = (): ResetRouteDataAction => ({
  type: Types.RESET_ROUTE_DATA
});

type StopSearchStatesActions = 
  SetGoBackAction |
  SetRouteDataAction |
  ResetRouteDataAction

export default StopSearchStatesActions;