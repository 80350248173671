import { GoBack, RouteDataType, SingleStationInEdge } from "bus-common-types";
import { SingleTravelTime } from "collinear-routes";
import { MarkersWithRouteLineProps } from "components/collinear-routes/components/MapPart/MarkersWithRouteLine";
import { SingleStopMarker } from "components/collinear-routes/components/MapPart/StopMarker";
import { QueriedCollinearRoutesRouteData } from "components/collinear-routes/constants/API";
import { CollinearRoutesState } from "components/collinear-routes/constants/context";
import getBusInstantInfoListFromRouteData from "components/driving-map/components/BusMap/functions/getBusInstantInfoListFromRouteData";
import { SingleQueriedTravelTime } from "constants/api-fragments";
import { getRoutePoints } from "constants/functions";
import MapHelpers from "lib/MapHelpers";
import AccumulateTravelTimesHandlers from "../../Handlers/AccumulateTravelTimesHandlers";

export interface IntervalSearchMapHandlersRawRouteDataParams {
  queried: {
    routeData: QueriedCollinearRoutesRouteData['route'] | null, 
    buses: RouteDataType['buses'],
    travelTimes: SingleQueriedTravelTime[]
  }, 
  goBack: GoBack
}
export interface MakeSingleStopMarkerParams {
  index: number
  routeName: string 
  stations: SingleStationInEdge[]
  travelTimes: SingleQueriedTravelTime[]
}
export interface HandleQuriedTravelTimeToTravelTimePropsParams {
  stationIndex: number
  slicedStations: SingleStationInEdge[]
  accumulatedTravelTimes: number[]
}

const IntervalSearchMapHandlers = {
  filterStationsByFromStationId(
    goBack: GoBack,
    stations: SingleStationInEdge[],
  ): SingleStationInEdge[] {
    return stations.filter(s => s.goBack === goBack);   
  },

  handleQuriedTravelTimeToTravelTimeProps({
    stationIndex, slicedStations, accumulatedTravelTimes,
  }: HandleQuriedTravelTimeToTravelTimePropsParams): SingleTravelTime[] {
    return slicedStations.map((s, i) => {
      return ({
        stopName: s.node.name,
        stopSeq: stationIndex + i + 1,
        id: s.node.id,
        travelTimeMins: accumulatedTravelTimes[i],
      });
    });
  },

  makeSingleStopMarker({
    index,
    routeName,
    stations,
    travelTimes,
  }: MakeSingleStopMarkerParams): SingleStopMarker {
    const station = stations[index];
    const slicedStations = stations.slice(index);
    const accumulatedTravelTimes = AccumulateTravelTimesHandlers.accumulateAllTravelTimes(travelTimes);

    const _travelTimes = this.handleQuriedTravelTimeToTravelTimeProps({
      stationIndex: index,
      slicedStations,
      accumulatedTravelTimes: accumulatedTravelTimes.map(a => a.travelTimeMinStr),
    });
    return ({
      index,
      goBack: station.goBack,
      position: MapHelpers.getPosition({
        lat: station.node.lat,
        lon: station.node.lon,
      }),
      routeName,
      travelTimes: _travelTimes,
    });
  },
 
  handleRawRouteData({
    queried, goBack, 
  }: IntervalSearchMapHandlersRawRouteDataParams): MarkersWithRouteLineProps | null {
    if(!queried.routeData) {
      return null;
    } else {
      const {
        routeData,
        travelTimes,
      } = queried;
      const stations = this.filterStationsByFromStationId(goBack, routeData.stations.edges);
      const routePoints = getRoutePoints(routeData, goBack);
      const busIntantInfoList = getBusInstantInfoListFromRouteData(queried.buses);

      const routeStops = stations.map((station, i) => (
        this.makeSingleStopMarker({
          index: i,
          stations,
          routeName: routeData.name,
          travelTimes,
        })
      ));

      return ({
        routePoints,
        routeStops,
        busIntantInfoList,
        goBack,
      });
    }
  },
};

export default IntervalSearchMapHandlers;