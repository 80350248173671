import polyline from 'polyline';
import { SingleRouteFromRoutes, EdgesData, SingleSelectOption } from 'all-common-types';
import { GoBack, Position, RouteDataType, ComeTime, TakenTime, EstimateTimeExceptionKeys } from 'bus-common-types';
import { goOrBackObj, defaultMapCenter, defaultMapBounds } from 'config';
import { sortByAttr, getDate } from 'lib/fn';
import { Theme } from '@material-ui/core';
import { EstimateTimeProps } from 'components/driving-map/components/Route/RouteListEstimateTimeItem';
import { getTakenTimeFromComeTime } from 'components/collinear-routes/fn';
import { estimateTimeExceptionStatusStr } from 'config';

export type GoBackChoice<GoChoice, BackChoice> = {
  go: GoChoice, back: BackChoice
}
export function getGoBackChoice<GoChoice, BackChoice>({ go, back }: GoBackChoice<GoChoice, BackChoice>) {
  return (goBack: GoBack) => {
    return goBack === 1 ? go : back;
  };
};

export const getGoBackString = (goBack: GoBack) => (
  goOrBackObj[goBack]
);
export const getReverseGoBackString = (goBack: GoBack) => (
  goOrBackObj[goBack === 1 ? 2 : 1]
);

export const getRoutePoints = (routeData: { routePoint: RouteDataType['routePoint'], }, goBack: GoBack) => {
  const goBackStr = getGoBackString(goBack);
  return getPolyline(routeData.routePoint[goBackStr]);
};

export const getPolyline = (polylineStr: string) => (
  polylineStr ? polyline.decode(polylineStr) as [number, number][] : []
);


export class HandleTimes {
  static getHoursAndMin = (date: Date) => {
    return date.getHours() + ':' + date.getMinutes();
  }

  static getComeTimeTakenTimeFromNow(comeTime: ComeTime) {
    const timeNow = getDate();
    const time = getTakenTimeFromComeTime(this.getHoursAndMin(timeNow), comeTime);
    return time;
  }

  static getExceptionEstimateTimeStr(comeTime: EstimateTimeExceptionKeys) {
    const res = estimateTimeExceptionStatusStr[comeTime];
    return res;
  }

  static getEstimateTime(comeTime: ComeTime | EstimateTimeExceptionKeys, takenTime: TakenTime): EstimateTimeProps {
    if(comeTime === '-4' || comeTime === '-5' || comeTime === '-6') {
      const res: EstimateTimeProps = {
        estimateTimeStatus: 'default',
        comeTimeStr: this.getExceptionEstimateTimeStr(comeTime)
      };
      return res;
    }
    
    switch (takenTime) {
      case null: 
        if(comeTime === '') {
          return {
            comeTimeStr: '未發車',
            estimateTimeStatus: 'default',
          };
        } else {
          return {
            comeTimeStr: comeTime,
            estimateTimeStatus: 'default',
          };
        }

      case -3:
        return {
          comeTimeStr: '末班駛離',
          estimateTimeStatus: 'default',
        };
        
      default:
        if(takenTime <= 3) {
          return {
            comeTimeStr: '即將到站',
            estimateTimeStatus: 'almostArrival',
          };
        } else if(takenTime > 3 && takenTime <= 15) {
          return {
            comeTimeStr: takenTime + ' 分',
            estimateTimeStatus: 'minutesRemained',
          };
        } else {
          return {
            comeTimeStr: takenTime + ' 分',
            estimateTimeStatus: 'minutesRemained',
          };
        }
    }
  }
}

type RoutePoints = Position[]
export const getRoutePointsCenter = (routePoints: RoutePoints) => {
  const amount = routePoints.length;
  let lat = 0, long = 0;
  for (let i = 0; i < routePoints.length; i++) {
    lat += routePoints[i][0];
    long += routePoints[i][1];
  }
  return [lat / amount, long / amount];
};

export const formatDate = (date: Date) => {
  const year = date.getFullYear().toString();
  const month = date.getMonth() + 1 > 9 ? 
    (date.getMonth() + 1).toString() : '0' + (date.getMonth() + 1).toString();
  const day = date.getDate() + 1 > 9 ? 
    date.getDate().toString() : '0' + date.getDate().toString();
  return year + '-' + month + '-' + day;
};

export const objIsNotEmpty = (obj: object) => {
  return Object.getOwnPropertyNames(obj).length > 0;
};

export function nodeDataFromEdges<N>(data: EdgesData<N>) {
  if(data.edges.length === 0) {
    return [];
  }
  return data.edges.map(edge => edge.node);
};

export interface SingleRouteForSelectorOption extends Pick<SingleRouteFromRoutes, 'id' | 'name' | 'description' | 'seq'> {
  providers?: SingleRouteFromRoutes['providers']
}
type RoutesData = SingleRouteForSelectorOption[]
export interface SingleRouteSelectorOption extends SingleSelectOption, SingleRouteForSelectorOption {
  providerIds: number[]
}
export type RoutesSelectorOptions = SingleRouteSelectorOption[]

export function getRoutesSelectorOptions(data: RoutesData): RoutesSelectorOptions {
  return data.map(d => ({
    ...d,
    providerIds: d.providers ? nodeDataFromEdges(d.providers).map(p => Number(p)) : [],
    value: d.id,
    text: `[${ d.name }] ${ d.description }`,
  })).sort(sortByAttr('seq'));
};

export const getPolylineColor = (goBack: GoBack, theme: Theme) => (
  goBack === 1 ? theme.goBack.goBackStatus.go : theme.goBack.goBackStatus.back
);
