import React from "react";
import { Box, Grid } from "@material-ui/core";
import QRcodes from "../qrCodes";
import OtherLinksPart from "../OtherLinksPart";
import { qrcodesData } from "../static/home-page";
import { LinksPartProps } from "./types";
import HomepageImageLink from "./HomepageImageLink";
import GoldenDriverImageLink from "./GoldenDriverImageLink";
import LocaleTitle from "../LocaleTitle";

const LinksPart = (props: LinksPartProps) => {
  return (
    <Box>
      <LocaleTitle titleLangKey={"appLinks.title"} />
      <Grid container>
        <Grid item xs={12} md={6}>
          <QRcodes qrcodesData={qrcodesData} />
          <HomepageImageLink />
          <GoldenDriverImageLink />
        </Grid>
        <Grid item xs={12} md={6}>
          <OtherLinksPart />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LinksPart;
