import { EstimateStationInput } from "all-common-types";
import { RoutePassListItemProps } from "components/driving-map/components/BusMap/RoutePassList";
import { nodeDataFromEdges } from "constants/functions";
import useQueryPassedRoutesEstimateTimes from "gql/custom-hooks/useQueryPassedRoutesEstimateTimes";
import { useEffect, useMemo } from "react";
import { RoutePassListContainerProps } from "../RoutePassListContainer";
import RoutePassListHandlers from "./RoutePassListHandlers";

export interface UseRoutePassListOptions extends RoutePassListContainerProps {
  
}

const useRoutePassList = (options: UseRoutePassListOptions) => {
  const {
    onTriggerUpdatePopup,
    dataForQueryEstimateTimes
  } = options;

  const {
    loading,
    error,
    data,
    handleQuery,
  } = useQueryPassedRoutesEstimateTimes();

  useEffect(() => {
    const targets: EstimateStationInput[] = dataForQueryEstimateTimes.map(d => ({
      xno: d.xno,
      stationId: d.stationId,
      goBack: d.goBack,
    }));
    handleQuery(targets);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleQuery, JSON.stringify(dataForQueryEstimateTimes)]);

  useEffect(() => {
    if(data && data.stationEstimates.edges.length > 0) {
      onTriggerUpdatePopup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  const routePassListData: RoutePassListItemProps[] = useMemo(() => (
    data ? RoutePassListHandlers.mergeToRoutePassListData(
      dataForQueryEstimateTimes, nodeDataFromEdges(data.stationEstimates)
    ) : []
  ), [data, dataForQueryEstimateTimes]);

  return ({
    loading,
    error,
    routePassListData,
  });
};

export default useRoutePassList;