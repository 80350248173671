import React from 'react';
import { Box } from '@material-ui/core';
import TabList from './TabList';
import { DepDestTabListProps, SingleTabData } from './types';
import IntlFormattedMessage from '../IntlFormattedMessage';

export const defaultLocation = '--';

export const defaultDepDest: DepDestTabListProps['depDest'] = {
  departure: {
    location: defaultLocation,
    isDisplay: true,
    disabled: true,
  },
  destination: {
    location: defaultLocation,
    isDisplay: true,
    disabled: true,
  }
};

export const getTabDatasLength = (depDest: DepDestTabListProps['depDest']) => {
  let res = 0;
  if(depDest.departure.isDisplay) res += 1;
  if(depDest.destination.isDisplay) res += 1;
  return res;
};

const DepDestTabList = (props: DepDestTabListProps) => {
  const tabDatas: SingleTabData[] = [
    {
      ...defaultDepDest.destination,
      ...props.depDest.destination,
      disabled: props.depDest.destination.disabled,
      label: (
        <IntlFormattedMessage
          align={'center'}
          langKey={'toStationWithVar'}
          values={{
            station: props.depDest.destination.location
          }}
        />
      ),
    },
    {
      ...defaultDepDest.departure,
      ...props.depDest.departure,
      disabled: props.depDest.departure.disabled,
      label: (
        <IntlFormattedMessage
          align={'center'}
          langKey={'toStationWithVar'}
          values={{
            station: props.depDest.departure.location
          }}
        />
      ),
    },
  ];

  return (
    <TabList 
      {...props}
      tabDatas={tabDatas}
      tabDatasLength={getTabDatasLength(props.depDest)}
    />
  );
};

export default DepDestTabList;