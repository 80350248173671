import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { BusMarkerListProps } from 'components/driving-map/components/BusMap/types';
import { GoBack } from 'bus-common-types';
import filterBusInstantInfoListByGoBack from 'components/driving-map/components/BusMap/functions/filterBusInstantInfoListByGoBack';
import checkBusShouldDisplay from 'components/driving-map/lib/checkBusShouldDisplay';
import { BusMarkerList } from 'components/driving-map/components/BusMap';

export interface BusMarkerListContainerProps extends BusMarkerListProps {
  goBack: GoBack
}

const BusMarkerListContainer = (props: BusMarkerListContainerProps) => {
  const filteredBusInstantInfoList = useMemo(() => (
    filterBusInstantInfoListByGoBack(
      props.busIntantInfoList, props.goBack
    )
  ), [props.busIntantInfoList, props.goBack]);
  const busBeDisplayList = useMemo(() => (
    filteredBusInstantInfoList.filter(b => (
      checkBusShouldDisplay(b.status, b.dataTime)
    ))
  ), [filteredBusInstantInfoList]);

  return (
    <BusMarkerList
      busIntantInfoList={busBeDisplayList} 
    />
  );
};

export default BusMarkerListContainer;