import { useLazyQuery } from "@apollo/react-hooks";
import { GoBack } from "bus-common-types";
import { client} from "constants/API";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import makeMrtTravelTimesParams from "../functions/makeMrtTravelTimesParams";
import { QueriedMrtTravelTimes, QUERY_MRT_TRAVEL_TIMES, QueryMrtTravelTimesParams } from "../schema.gql";

export interface UseQueryMrtTravelTimesOptions {
  goBack: GoBack
}

const initQueriedTravelTimes: QueriedMrtTravelTimes | undefined = undefined;

function useQueryMrtTravelTimes(options: UseQueryMrtTravelTimesOptions) {
  const [queryFn, { error, loading, data }] = useLazyQuery<QueriedMrtTravelTimes>(QUERY_MRT_TRAVEL_TIMES, {
    client: client,
  });
  const [queriedData, setData] = useState<QueriedMrtTravelTimes | undefined>(initQueriedTravelTimes);

  const handleQuery = useCallback((params: QueryMrtTravelTimesParams) => {
    if(params.busFromStationId && params.mrtFromStationId) {
      const inputs = makeMrtTravelTimesParams(params);
      queryFn({
        variables: {
          ...inputs,
        }
      });
    } else {
      setData(initQueriedTravelTimes);
    }
  }, [queryFn]);

  useEffect(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    setData(initQueriedTravelTimes);
  }, [options.goBack]);

  return ({
    error,
    loading,
    data: queriedData,
    handleQuery,
  });
};

export default useQueryMrtTravelTimes;