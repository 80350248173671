import React from "react";
import { EstimateTimeProps } from "components/driving-map/components/Route/RouteListEstimateTimeItem";
import {
  EstimateTimeExceptionKeys,
  EstimateTimeHandlerOptions,
  SingleEstimateStop,
} from "bus-common-types";
import { estimateTimeExceptionStatusStr } from "config";
import IntlFormattedMessage from "components/common-components/IntlFormattedMessage";
import RouteDataHandlers from "components/driving-map/gql/functions/RouteDataHandlers";

export const defaultEstimateTimeOptions: EstimateTimeHandlerOptions = RouteDataHandlers.defaultEstimateTimeFragment;

// estimateTimeStatus 主宰顏色
const isSuspendedEstimateTime: EstimateTimeProps = {
  comeTimeStr: <IntlFormattedMessage langKey={"estimateTime.isSuspended"} />,
  estimateTimeStatus: "default",
};
const isConstructionEstimateTime: EstimateTimeProps = {
  comeTimeStr: <IntlFormattedMessage langKey={"estimateTime.isConstruction"} />,
  estimateTimeStatus: "default",
};
const isEventEstimateTime: EstimateTimeProps = {
  comeTimeStr: <IntlFormattedMessage langKey={"estimateTime.isEvent"} />,
  estimateTimeStatus: "default",
};
const temporaryEstimateTime: EstimateTimeProps = {
  comeTimeStr: <IntlFormattedMessage langKey={"estimateTime.temporary"} />,
  estimateTimeStatus: "default",
};
const noParkingEstimateTime: EstimateTimeProps = {
  comeTimeStr: <IntlFormattedMessage langKey={"estimateTime.noParking"} />,
  estimateTimeStatus: "default",
};

const undepartedEstimateTime: EstimateTimeProps = {
  comeTimeStr: <IntlFormattedMessage langKey={"estimateTime.undeparted"} />,
  estimateTimeStatus: "default",
};
const almostArrivalEstimateTime: EstimateTimeProps = {
  comeTimeStr: <IntlFormattedMessage langKey={"estimateTime.almostArrival"} />,
  estimateTimeStatus: "almostArrival",
};
const arrivingEstimateTime: EstimateTimeProps = {
  comeTimeStr: <IntlFormattedMessage langKey={"estimateTime.arriving"} />,
  estimateTimeStatus: "almostArrival",
};

const getComeTimeEstimateTime = (comeTime: string): EstimateTimeProps => ({
  comeTimeStr: (
    <IntlFormattedMessage
      langKey={"estimateTime.comeTime"}
      values={{
        comeTime,
      }}
    />
  ),
  estimateTimeStatus: "default",
});
const getMinsRemainedEstimateTime = (
  takenTime: string | number
): EstimateTimeProps => ({
  comeTimeStr: (
    <IntlFormattedMessage
      langKey={"estimateTime.minsRemained"}
      values={{
        takenTime,
      }}
    />
  ),
  estimateTimeStatus: "minutesRemained",
});
const getMinsRemainedHHMMFormatEstimateTime = (
  comeTime: string | number
): EstimateTimeProps => ({
  comeTimeStr: (
    <IntlFormattedMessage
      langKey={"estimateTime.comeTime"}
      values={{
        comeTime,
      }}
    />
  ),
  estimateTimeStatus: "minutesRemained",
});

class EstimateTimeHandler {
  static getExceptionEstimateTime(
    comeTime: EstimateTimeExceptionKeys
  ): EstimateTimeProps {
    const comeTimeStr = estimateTimeExceptionStatusStr[comeTime];
    const res: EstimateTimeProps = {
      estimateTimeStatus: "default",
      comeTimeStr,
    };
    return res;
  }

  static splitComeTime(comeTime: string) {
    const dividedComeTime = comeTime.split(":").map((s) => Number(s));
    const res = {
      hours: dividedComeTime[0],
      mins: dividedComeTime[1],
    };
    return res;
  }

  static convertComeTimeToDate(comeTime: string) {
    let dateNow = new Date();
    const hoursMins = this.splitComeTime(comeTime);
    dateNow.setHours(hoursMins.hours, hoursMins.mins);
    return dateNow;
  }

  static msToMins(msecs: number) {
    const secs = msecs / 1000;
    const mins = Math.round(secs / 60);
    return mins;
  }

  static getTakenTimeFromEtas(etas: SingleEstimateStop["etas"]) {
    const etasNums = etas.map((e) => e.etaTime);
    const res = Math.min(...etasNums);
    return res;
  }

  static getTakenTimeFromComeTime(comeTime: string) {
    if(!comeTime) {
      return 0;
    }
    const timeNow = new Date();
    const comeTimeDate = this.convertComeTimeToDate(comeTime);
    const msecs = comeTimeDate.getTime() - timeNow.getTime();

    const takenTime = this.msToMins(msecs);
    return takenTime;
  }

  static addZeroToDigit(digit: number) {
    const res = digit < 10 ? `0${digit}` : `${digit}`;
    return res;
  }
  static getComeTimeFromDate(date: Date): string {
    const H = date.getHours();
    const M = date.getMinutes();

    const res = `${this.addZeroToDigit(H)}:${this.addZeroToDigit(M)}`;
    return res;
  }
  //takenTime is minutes
  static makeComeTimeFromTakenTime(takenTime: number) {
    const now = new Date();
    const takenTimeMs = takenTime * 60 * 1000;

    const newTime = now.getTime() + takenTimeMs;
    const comeTimeDate = new Date(newTime);

    const res = this.getComeTimeFromDate(comeTimeDate);
    return res;
  }

  static getTakenTimeFromEtasAndComeTime({
    etas, comeTime
  }: Pick<SingleEstimateStop, 'etas' | 'comeTime'>) {
    if(etas.length > 0) {
      return this.getTakenTimeFromEtas(etas);
    }
    return this.getTakenTimeFromComeTime(comeTime);
  }

  static getTakenTimeEstimateTime(takenTime: number, comeTime: string) {
    let res: EstimateTimeProps = {
      comeTimeStr: comeTime,
      estimateTimeStatus: "default",
    };

    if (takenTime === 0) {
      res = arrivingEstimateTime;
    }
    if (takenTime <= 3) {
      res = almostArrivalEstimateTime;
    }
    else if (takenTime > 3 && takenTime <= 60) {
      res = getMinsRemainedEstimateTime(takenTime);
    }
    // 大於60分或沒有comeTime
    else if(takenTime > 60 || !comeTime) {
      // 轉換為24:00制時間
      const newComeTime = this.makeComeTimeFromTakenTime(takenTime);
      res = getMinsRemainedHHMMFormatEstimateTime(newComeTime);
    }
    else {
      res = getComeTimeEstimateTime(comeTime);
    }

    return res;
  }

  static getEstimateTime(
    options: EstimateTimeHandlerOptions
  ): EstimateTimeProps {
    const { comeTime, etas, isOperationDay, isSuspended, clogType } = options;

    let res: EstimateTimeProps = undepartedEstimateTime;

    if (!isOperationDay) {
      res = this.getExceptionEstimateTime("-4");
      return res;
    }
    else if (isSuspended) {
      res = isSuspendedEstimateTime;
      return res;
    }
    else if(clogType === -5) {
      res = isConstructionEstimateTime;
      return res;
    }
    else if(clogType === -6) {
      res = isEventEstimateTime;
      return res;
    }
    else if(clogType === -7) {
      res = temporaryEstimateTime;
      return res;
    }
    else if(clogType === -8) {
      res = noParkingEstimateTime;
      return res;
    }
    else if (etas.length === 0 && comeTime === "") {
      // res = undepartedEstimateTime;
      return res;
    }

    if (etas.length > 0) {
      const takenTime = this.getTakenTimeFromEtas(etas);
      res = this.getTakenTimeEstimateTime(takenTime, comeTime);
    } else {
      res = getComeTimeEstimateTime(comeTime);
    }

    return res;
  }
}

export default EstimateTimeHandler;
