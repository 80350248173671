import BusTypes from 'static/data/BusTypes.json';
import { Locale } from 'all-common-types';

type Key = keyof typeof BusTypes['zh']

const getBusTypeNameByBusType = (busType: string, locale: Locale) => {
  return (
    BusTypes[locale][busType as Key] || ''
  );
};


export const getSimpleBusTypeName = (busType: string, locale: Locale) => {
  const busTypeName = getBusTypeNameByBusType(busType, locale);
  
  // if(busTypeName.includes('無障礙')) {
  //   return '無障礙';
  // }
  // else if(busTypeName.includes('電動')) {
  //   return '電動';
  // }
  // return '一般';
  const res = busTypeName.shortName || busType;
  return res;
};

export default getBusTypeNameByBusType;

