import { useState, useCallback, useEffect, useMemo } from "react";
import { SingleDepDestAndStopSearchedRouteInfo } from "../stopSearchContainers/StopSearched_RouteInfoList_Container";
import useQueryRouteData from "./useQueryRouteData";
import { SelectedRouteId, CollinearRoutesState } from "components/collinear-routes/constants/context";
import { RouteDataType } from "bus-common-types";
import { HandleDepDestAndStopSearchedRouteInfo } from "components/collinear-routes/fn";
import { setRouteData } from "components/collinear-routes/actions/stop-search-states-actions";
import RouteStopsAfterStopNameHandler from "../StopSearchMapPart/RouteStopsAfterStopNameHandler";
import { nodeDataFromEdges } from "constants/functions";
import useQueryBuses from "gql/custom-hooks/useQueryBuses";
import { refreshTime } from "config";
import { StopSearchWithRouteInfoDispatchesFromCtx, StopSearchWithRouteInfoStateFromCtx } from "../stopSearchContainers/types";
import useQueryTravelTimes from "components/collinear-routes/gql/custom-hooks/useQueryTravelTimes";
import { QueryRouteInfoFn } from "../stopSearchContainers/StopSearchContainer";
import StopSearchTravelTimeHandlers from "./StopSearchTravelTimeHandlers";

export interface UseStopSearchWithRouteInfoOptions extends StopSearchWithRouteInfoStateFromCtx, StopSearchWithRouteInfoDispatchesFromCtx {
  routeId: SelectedRouteId
}

const useStopSearchWithRouteInfo = (options: UseStopSearchWithRouteInfoOptions) => {
  const {
    routeId,
    fromStation,
    setFromStation,
    setRouteDataToCtx,
  } = options;
  const [depDestAndStopSearchedRouteInfos, setRouteInfos] = useState<SingleDepDestAndStopSearchedRouteInfo[]>([]);

  const {
    handleQuery: queryFn,
    routeData: routeDataWithoutBusInfos,
  } = useQueryRouteData();
  
  const {
    busInfos,
  } = useQueryBuses({
    routeId,
    isQueryIntervally: true,
    intervalSecs: refreshTime,
  });
  const {
    travelTimes,
    handleQuery: handleQueryTravelTimes,
  } = useQueryTravelTimes();

  const handleQuery: QueryRouteInfoFn = useCallback((fromStation, routeId: SelectedRouteId) => {
    setFromStation({ selectedFromStation: fromStation, });
    queryFn(routeId);
  }, [queryFn, setFromStation]);

  const handleSetRouteInfoByRouteAndStopName = useCallback((fromStation: UseStopSearchWithRouteInfoOptions['fromStation'], routeData: RouteDataType | null) => {
    if(routeData && fromStation) {
      const handled = HandleDepDestAndStopSearchedRouteInfo.getHandledFinalResult(
        fromStation.name, routeData, travelTimes
      );
      setRouteInfos(handled);

      const handledRouteDataForStopSearch: CollinearRoutesState['stopSearchStates']['routeData'] = {
        ...routeData,
        handledRouteStops: RouteStopsAfterStopNameHandler.makeRouteStops({
          fromStationName: fromStation.name,
          stations: routeData.stations.edges,
        }),
      };
      setRouteDataToCtx(handledRouteDataForStopSearch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(travelTimes)]);

  const routeData: RouteDataType | null = useMemo(() => (
    routeDataWithoutBusInfos ? ({
      ...routeDataWithoutBusInfos,
      buses: busInfos,
    }) : null
  ), [busInfos, routeDataWithoutBusInfos]);

  useEffect(() => {
    //console.log(stopName, routeData);
    handleSetRouteInfoByRouteAndStopName(fromStation, routeData);
  }, [routeData, fromStation, handleSetRouteInfoByRouteAndStopName]);

  useEffect(() => {
    if(fromStation && routeId && routeDataWithoutBusInfos) {
      const fromStationIds = StopSearchTravelTimeHandlers.makeTravelTimesInputFromStationIds({
        stations: routeDataWithoutBusInfos.stations.edges, 
        stationName: fromStation.name,
      });
      handleQueryTravelTimes({
        inputs: [{
          xno: Number(routeId),
          fromStationId: fromStationIds.goStationId,
          goBack: 1,
        }, {
          xno: Number(routeId),
          fromStationId: fromStationIds.backStationId,
          goBack: 2,
        }]
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(fromStation), JSON.stringify(routeDataWithoutBusInfos), routeId]);

  return ({
    depDestAndStopSearchedRouteInfos,
    handleSetRouteInfoByRouteAndStopName,
    handleQuery,
  });
};

export default useStopSearchWithRouteInfo;