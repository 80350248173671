import React from 'react';
import { Typography, CircularProgress, Box } from '@material-ui/core';
import { SingleStationRoute } from 'bus-common-types';
import { RoutePassList } from 'components/driving-map/components/BusMap/RoutePassList';
import useRoutePassList from './functions/useRoutePassList';
import { EstimateStationInput } from 'all-common-types';

export const NoRoutesMessage = () => {
  return (
    <Typography>{'暫時無資料'}</Typography>
  );
};

export type SingleDataForQueryEstimateTimes = EstimateStationInput & SingleStationRoute & {
  seq: number
}

export interface RoutePassListContainerProps {
  onTriggerUpdatePopup: any
  dataForQueryEstimateTimes: SingleDataForQueryEstimateTimes[]
}

export const RoutePassListContainer = (props: RoutePassListContainerProps) => {
  const {
    loading,
    error,
    routePassListData
  } = useRoutePassList(props);

  if(loading) {
    return (
      <Box height={100}>
        <CircularProgress />
      </Box>
    );
  }

  if(error) {
    return <NoRoutesMessage />;
  }

  return (
    <RoutePassList routePassData={routePassListData} />
  );
};

export default RoutePassListContainer;