import { ID } from "all-common-types";
import { split } from "apollo-boost";
import { GoBack } from "bus-common-types";
import { BUS_309_ROUTE_ID, BUS_310_ROUTE_ID, MRT_GREEN_ROUTE_ID } from "components/mrt-fare/config";
import { QueriedMrtTravelTimes } from "components/mrt-fare/gql/schema.gql";
import accumulateArr from "components/mrt-fare/lib/functions/accumulateArr";
import getContentStr from "components/mrt-fare/lib/functions/getContentStr";
import { HandledStations, HandledStationsKeys } from "components/mrt-fare/lib/Handlers/MrtStationsCombineHandlers";
import { SingleQueriedTravelTime } from "constants/api-fragments";
import { nodeDataFromEdges } from "constants/functions";
import getArrLastEl from "lib/functions/getArrLastEl";
import { IntersectionStationContents, SingleBasicStationForMrtAndBus, StationType } from "mrt-pages";
import { HandledStationsWithIntersectionContents } from "../custom-hooks/useMrtStationsFares";
import CombineLastStationTravelTime from "./CombineLastStationTravelTime";
import MrtStationsFareHandlers from "./MrtStationsFareHandlers";
import RouteMrtTravelTimeHandlers, { SingleQueriedTravelTimeWithAccumTravelTime } from "./RouteMrtTravelTimeHandlers";

export interface SingleBasicStationForMrtAndBusWithTravelTime extends SingleBasicStationForMrtAndBus {
  travelTime: SingleQueriedTravelTime
  travelTimeSecs: number
}

export type HandledStationsWithTravelTimes = {
  [x in HandledStationsKeys]: SingleBasicStationForMrtAndBusWithTravelTime[]
}

export const initTravelTime: SingleQueriedTravelTimeWithAccumTravelTime = {
  id: '',
  xno: 0,
  sid: 0,
  goBack: 1,
  orderNo: 0,
  stationId: 0,
  travelTime: '',
  thisStationTravelTimeSecs: 0,
  accumTravelTimeSecs: 0,
};

const MrtStationsTravelTimeHandlers = {
  checkStationIdMatchedWithTravelTime(
    travelTime: Pick<SingleQueriedTravelTime, 'stationId' | 'sid'>, 
    station: Pick<SingleBasicStationForMrtAndBus, 'stationId'>
  ) {
    return travelTime.stationId === station.stationId || travelTime.sid === station.stationId;
  },

  checkStationPassMrtByCommonStationsWithTravelTimes(commonStations: SingleBasicStationForMrtAndBusWithTravelTime[]) {
    const filteredCommonStations = commonStations
      .filter(s => {
        return s.travelTime.travelTime.trim();
      })
      .map(s => s.stationId);
    const isPassedMrt = MrtStationsFareHandlers.checkStationPassMrt(filteredCommonStations);
    return isPassedMrt;
  },

  getAccumReverseByGoBack(goBack: GoBack, isMrt?: boolean) {
    if(goBack === 1) {
      if(isMrt) {
        return true;
      }
      return false;
    }
    return true;
  },

  convertTravelTimeToSecs(rawTravelTime: string) {
    if(!rawTravelTime) {
      return 0;
    }
    const splitTime = rawTravelTime.split(':').map(t => Number(t));
    return splitTime[0] * 60 * 60 + splitTime[1] * 60 + splitTime[2];
  },

  parseTravelTimeToContent(travelTimeSecs: number) {
    const res = Math.round(travelTimeSecs / 60);
    return res;
  },
  accumulateTravelTimes(
    stationWithTravelTimeSecsListData: SingleBasicStationForMrtAndBusWithTravelTime[],
  ) {
    const goBack = stationWithTravelTimeSecsListData.length > 0 ? 
      stationWithTravelTimeSecsListData[0].travelTime.goBack : 1;
    const isMrtRoute = stationWithTravelTimeSecsListData[0].routeId === MRT_GREEN_ROUTE_ID;
    const isReverse = this.getAccumReverseByGoBack(goBack, isMrtRoute);
    const initNumber = isReverse ? 
      getArrLastEl(stationWithTravelTimeSecsListData).travelTimeSecs : 
      stationWithTravelTimeSecsListData[0].travelTimeSecs;

    return accumulateArr({
      dataList: stationWithTravelTimeSecsListData, 
      key: 'travelTimeSecs',
      initNumber,
      isReverse,
    });
  },
  
  convertTravelTimeToContent(
    stationWithTravelTime: SingleBasicStationForMrtAndBusWithTravelTime,
    isSameType: boolean
  ) {
    const content = stationWithTravelTime.travelTime.travelTime ? 
      this.parseTravelTimeToContent(stationWithTravelTime.travelTimeSecs) :
      '';
    const decoratedContent = getContentStr('TRAVEL_TIME', content, isSameType); 

    return ({
      ...stationWithTravelTime,
      content: decoratedContent,
    });
  },

  mapStationsWithTravelTime(selectedType: StationType) {
    return (type: StationType) => (station: SingleBasicStationForMrtAndBusWithTravelTime) => {
      const isSameType = selectedType === type;
      return (
        this.convertTravelTimeToContent(station, isSameType)
      );
    };
  },

  getMatchedTravelTime<Data extends SingleQueriedTravelTime>({
    travelTimes, station, isPassedMrt
  }: {
    travelTimes: Data[],
    station: SingleBasicStationForMrtAndBus,
    isPassedMrt: boolean,
  }) {
    const matchedTravelTimeIdx = travelTimes.findIndex(t => (
      this.checkStationIdMatchedWithTravelTime(t, station)
    ));
    // 如果是找不到就不給他travelTime，而且一定要通過捷運路線才行
    if(matchedTravelTimeIdx !== -1 && isPassedMrt) { // not -1 or 0
      return travelTimes[matchedTravelTimeIdx];
    }
    return initTravelTime;
  },

  combineStationsWithTravelTimes(
    stationListData: SingleBasicStationForMrtAndBus[], 
    travelTimes: SingleQueriedTravelTime[],
    isPassedMrt=true, // 預設是通過捷運路線(因為只有common那條要檢查是否通過)
  ): SingleBasicStationForMrtAndBusWithTravelTime[] {
    let res: SingleBasicStationForMrtAndBusWithTravelTime[] = [];

    for (let i = 0; i < stationListData.length; i++) {
      const station = stationListData[i];
      const travelTime = this.getMatchedTravelTime({
        travelTimes, station, isPassedMrt,
      });

      res[i] = {
        ...station,
        travelTime,
        travelTimeSecs: this.convertTravelTimeToSecs(travelTime.travelTime)
      };
    }

    res = this.accumulateTravelTimes(res); // 累加travelTimeSecs
    // console.log(res);

    return res;
  },

  getRoute310TravelTimes(travelTimesRawData: QueriedMrtTravelTimes) {
    if(travelTimesRawData.busTravelTimes.length > 1) {
      return travelTimesRawData.busTravelTimes[1];
    }
    return (travelTimesRawData.busTravelTimes)[0]
      .filter(t => t.xno === BUS_310_ROUTE_ID);
  },

  splitTravelTimes(travelTimesRawData: QueriedMrtTravelTimes) {
    const busTravelTimes = (travelTimesRawData.busTravelTimes)[0];

    const route309TravelTimes = busTravelTimes.filter(t => t.xno === BUS_309_ROUTE_ID);
    const route310TravelTimes = this.getRoute310TravelTimes(travelTimesRawData);
    const commonTravelTimes = 
      route309TravelTimes.length > 0 ? 
        route309TravelTimes : route310TravelTimes;
    const mrtGreenTravelTimes = (travelTimesRawData.mrtGreenTravelTimes)[0] || [];

    return ({
      route309TravelTimes,
      route310TravelTimes,
      commonTravelTimes,
      mrtGreenTravelTimes,
    });
  },

  combineStationsWithTravelTimesRawData(
    handledStations: HandledStations, 
    travelTimesRawData: QueriedMrtTravelTimes | undefined,
  ) {
    if(!travelTimesRawData) {
      return (): HandledStationsWithIntersectionContents => ({
        ...handledStations,
        intersectionContents: MrtStationsFareHandlers.initIntersectionStationContents,
      });
    }
    const {
      route309TravelTimes,
      route310TravelTimes,
      commonTravelTimes,
      mrtGreenTravelTimes,
    } = this.splitTravelTimes(travelTimesRawData);

    const commonBusStations = this.combineStationsWithTravelTimes(
      handledStations.commonBusStations, commonTravelTimes
    );
    const isStationPassMrt = this.checkStationPassMrtByCommonStationsWithTravelTimes(commonBusStations);
    const mrtGreenStations = this.combineStationsWithTravelTimes(
      handledStations.mrtGreenStations, mrtGreenTravelTimes, isStationPassMrt,
    );
    // console.log(mrtGreenStations);

    const stationsWithTravelTimes = {
      route309OtherStations: this.combineStationsWithTravelTimes(
        handledStations.route309OtherStations, route309TravelTimes
      ),
      route310OtherStations: this.combineStationsWithTravelTimes(
        handledStations.route310OtherStations, route310TravelTimes
      ),
      commonBusStations,
      mrtGreenStations,
    };

    return (selectedType: StationType, goBack: GoBack): HandledStationsWithIntersectionContents => {

      // 根據狀況累積需要累積的最後一站的時間
      const combinedStationsWithTravelTimes = CombineLastStationTravelTime.checkAndCombine({
        goBack,
        stationsWithTravelTimes: stationsWithTravelTimes,
      });

      const mapFn = this.mapStationsWithTravelTime(selectedType);
      const route309OtherStations = 
        combinedStationsWithTravelTimes.route309OtherStations.map(mapFn('BUS'));
      const route310OtherStations = 
        combinedStationsWithTravelTimes.route310OtherStations.map(mapFn('BUS'));
      const commonBusStations = combinedStationsWithTravelTimes.commonBusStations.map(mapFn('BUS'));
      const mrtGreenStations = 
        // stationsWithTravelTimes.mrtGreenStations.map(mapFn('MRT_GREEN'));
        RouteMrtTravelTimeHandlers.combineStationsWithRawTravelTimes(
          handledStations.mrtGreenStations,
          travelTimesRawData.mrtGreenTravelTimes,
          isStationPassMrt,
        ).map(mapFn('MRT_GREEN'));

      const intersectionContents = MrtStationsFareHandlers.getIntersectionStationContents(
        commonBusStations, 
        mrtGreenStations,
      );
      
      return ({
        route309OtherStations,
        route310OtherStations,
        commonBusStations,
        mrtGreenStations,
        intersectionContents,
      });
    };
  },
};

export default MrtStationsTravelTimeHandlers;