import { OpType, ID } from "all-common-types";
import filterDataListByOpType from "./busProviders/filterDataListByOpType";
import filterRoutesByTaxiProvider from "components/route-map/lib/functions/filterRoutesByTaxiProvider";

function filterRoutesByOptypeAndTaxi<SingleRoute extends { opType?: OpType, providerIds: ID[] }>(routes: SingleRoute[]) {
  const filteredByOptype = filterDataListByOpType(routes);
  const filteredByTaxi = filterRoutesByTaxiProvider(filteredByOptype);
  const res = filteredByTaxi;
  return res;
}

export default filterRoutesByOptypeAndTaxi;