import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SingleIconLinkImage from './SingleIconLinkImage';
import iconLinksImages from 'static/iconLinksImages.json';
import { SingleLink } from 'all-common-types';
import CustomLink from 'ibus-common-components/lib/components/common-components/Menus/CustomLink';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    padding: theme.spacing(1),
    background: theme.homepageColors.middlePart,
    borderRadius: theme.spacing(1),
  }
}));

const useStyles_singleIconLink = makeStyles(theme => ({
  root: {
    transition: '0.2s',
    '&:hover': {
      transition: '0.2s',
      backgroundColor: `rgba(255, 255, 255, 0.2)`,
      borderRadius: theme.spacing(1),
    }
  },
  linkTitle: {
    color: theme.palette.common.white
  }
}));

export interface SingleIconLinkProps {
  title: string
  link: string
  imageSrc: string
}

export const SingleIconLink = (props: SingleIconLinkProps) => {
  const { title, link } = props;
  const classes = useStyles_singleIconLink();
  return (
    <Box>
      <CustomLink 
        href={link} 
        isTargetBlank={true}
      >
        <Box
          className={classes.root}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <SingleIconLinkImage {...props} />
          <Typography 
            variant={'h6'} 
            align={'center'} 
            className={classes.linkTitle}
          >
            {title}
          </Typography>
        </Box>
      </CustomLink>
    </Box>
  );
};

export interface IconLinksProps {
  linksData: SingleLink[]
}

export const getCombinedLinksWithIconLinksImages = (links: SingleLink[]) => 
  (iconLinksImage: typeof iconLinksImages[0]): SingleIconLinkProps => {
    const fulfilledLink = links.find(link => link.link === iconLinksImage.link);
    return ({
      ...iconLinksImage,
      title: fulfilledLink ? fulfilledLink.title : iconLinksImage.title,
    });
  };

const IconLinks = ({ linksData }: IconLinksProps) => {
  const classes = useStyles();
  const linksDataWithImage = iconLinksImages.map(getCombinedLinksWithIconLinksImages(linksData));
  return (
    <Box 
      display={'flex'}
      justifyContent={'space-around'}
      flexWrap={'wrap'} 
      className={classes.root}
    >
      {linksDataWithImage.map((link, i) => (
        <SingleIconLink key={i} {...link} />
      ))}
    </Box>
  );
};

export default IconLinks;