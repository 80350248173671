import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { SingleTravelTime } from 'collinear-routes';
import { makeStyles } from '@material-ui/core';
import CommonListItem from 'components/common-components/CommonListItem';
import { GridSize } from '@material-ui/core/Grid';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { headerInfo } from 'components/collinear-routes/config';

export const SingleTravelTimeItemWidthRatios: GridSize[] = [3, 6, 3];
const travelTimeListHeaderText: SingleTravelTime = headerInfo;

const useStyles = makeStyles({
  root: {
    minWidth: 300,
    maxHeight: 300,
    overflowY: 'scroll',
  }
});

export const SingleTravelTimeItem = (props: SingleTravelTime) => {
  const {
    stopSeq,
    stopName,
    travelTimeMins
  } = props;
  const textsInList = [
    stopSeq,
    stopName,
    (
      <IntlFormattedMessage
        langKey={'collinearRoutes.estimateTravelTime'}
        values={{
          minutes: travelTimeMins,
        }}
      />
    ),
  ];

  return (
    <CommonListItem
      textsInList={textsInList}
      textsWidthRatios={SingleTravelTimeItemWidthRatios} />
  );
};

export type TravelTimePopupProps = {
  routeName: string
  travelTimes: SingleTravelTime[]
}
const TravelTimePopup = ({
  routeName,
  travelTimes
}: TravelTimePopupProps) => {
  const { stopName } = travelTimes[0];
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box>
        <Typography>{stopName}</Typography>
        <IntlFormattedMessage
          langKey={'routeWithVar'}
          values={{
            routeName,
          }}
        />
      </Box>
      <List>
        <SingleTravelTimeItem {...travelTimeListHeaderText} />
        {travelTimes.map((travelTime, i) => (
          <SingleTravelTimeItem key={i} {...travelTime} />
        ))}
      </List>
    </Box>
  );
};

export default TravelTimePopup;