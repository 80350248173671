import { PlansActionTypes } from './plans-actions';
import { RouteActionTypes } from './routeActions';
export enum actionTypes {
  SELECT_PLAN,
  GET_PLANS,
  RESET_PLANS,
  GET_ROUTES,
}

export * from './plans-actions';
export * from './routeActions';

export type AllActionTypes = PlansActionTypes | RouteActionTypes