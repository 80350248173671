import React from 'react';
import { Box } from '@material-ui/core';
import IntlButton from 'components/common-components/IntlComponents/IntlButtons';
import { ChangeOptionsGoBackButtonProps } from './types';

const ChangeOptionsGoBackButton = (props: ChangeOptionsGoBackButtonProps) => {
  const {
    toggle
  } = props;
  return (
    <IntlButton 
      color={toggle ? 'primary' : 'default'}
      variant={'contained'}
      langKey={toggle ? 
        'fare.changeOptionsGoBack.changeToSameDirection' : 
        'fare.changeOptionsGoBack.changeToAnotherDirection'
      }
      onClick={props.onChangeOptionsGoBack}
    />
  );
};

export default ChangeOptionsGoBackButton;