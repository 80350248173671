import { mapNumberIconConfig } from 'config';
import ibusImages from 'static/ibusImages.json';
import getIcon, { getIconOptions } from 'lib/getIcon';
import { IMAGE_BUS_ICON, BUS_ICON_WIDTH } from 'components/driving-map/config';
import { DivIconOptions } from 'leaflet';
import BusIconHandlers, { BusIconOptions } from 'components/common-components/Other/functions/BusIconHandlers';

export interface BusIconConfigOptions extends BusIconOptions {
  title: string
}

export const getBusIconAngleClassName = (angle: number) => {
  const angleIndex = 0;
  const res = `multiAngle-${angleIndex}`;
  return res;
};

export const getBusIconBarrierIconHtml = (isBarrierFree?: boolean): string => (
  isBarrierFree ? `
    <img
      class=bus-icon--barrier-free
      alt=${ibusImages.bus_icon_barrier_free.name}
      src=${ibusImages.bus_icon_barrier_free.src}
    />` : ''
);
export const getBusIconHtml = (image: typeof ibusImages.bus_icon_barrier_free): string => {
  const className = "bus-icon--provider-icon" + "--" + (image.name === 'bus_icon_electric_bus' && 'electric');

  return (`
  <img
    class=${className}
    alt=${image.name}
    src=${image.src}
  />`
  );
};

export const getBusIconConfig = (options: BusIconConfigOptions): DivIconOptions => {
  const busIconProps = BusIconHandlers.getIcon(options);
  const barrierIconHtml = getBusIconBarrierIconHtml(busIconProps.isBarrierFree);
  const busIconHtml = getBusIconHtml(busIconProps.iconImage);

  return ({
    iconSize: [BUS_ICON_WIDTH, BUS_ICON_WIDTH],
    iconAnchor: [
      BUS_ICON_WIDTH / 2,
      BUS_ICON_WIDTH / 2,
    ],
    html: `
      <div class=bus-icon data-content=${options.title || ''}>
        ${busIconHtml}
        ${barrierIconHtml}
      </div>
    `
  });
};

export const getUndefined = () => undefined;
export function getIconByType(): typeof getUndefined
export function getIconByType(iconType: 'routeStop'): typeof mapNumberIconConfig
export function getIconByType(iconType: 'bus'): typeof getBusIconConfig

export function getIconByType (iconType?: 'routeStop' | 'bus') {
  switch (iconType) {
    case 'routeStop':
      return mapNumberIconConfig;
    case 'bus': 
      return getBusIconConfig;
    default:
      return getUndefined;
  }
};


