import React, { ReactNode } from 'react';
import CityBusPart, { CityBusPartSelectsValues, CityBusPartChangeFn } from 'components/driving-map/components/BusSelect/CityBusPart';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

type SelectTypeType = {
  value: '0' | '1'
  text: ReactNode
}
const selectType_types: SelectTypeType[] = [
  { 
    value: '0', 
    text: (
      <IntlFormattedMessage 
        langKey={'drivingMap.busTypeTab.selectGenreRouteGroup'}
      />
    ), 
  },
  { 
    value: '1', 
    text: (
      <IntlFormattedMessage 
        langKey={'drivingMap.busTypeTab.selectGenreProvider'}
      />
    ), 
  }
];

const initCityBusPartSelectsValues: CityBusPartSelectsValues = {
  'selectType': {
    name: (
      <IntlFormattedMessage 
        langKey={'drivingMap.busTypeTab.selectGenreDefault'}
      />
    ) as unknown as string,
    value: '',
    options: selectType_types
  },
  'selectGroup': {
    name: (
      <IntlFormattedMessage 
        langKey={'drivingMap.busTypeTab.selectGroupDefault'}
      />
    ) as unknown as string,
    value: '',
    options: []
  }
};

export default initCityBusPartSelectsValues;