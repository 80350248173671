import { ID, Locale } from "all-common-types";
import { getLang } from "components/containers/Provider";
import { SingleClogMessage } from "bus-common-types";

const getStaticClogs = (locale: Locale): SingleClogMessage[] => {
  const lang = getLang(locale);
  const clog = {
    id: 'static-clog',
    title: lang["drivingMap.clog.staticTitle"],
    description: lang["drivingMap.clog.staticDescription"],
  };
  return [clog];
};

export default getStaticClogs;