import React from 'react';
import { Box } from '@material-ui/core';
import BusIconHandlers, { BusIconOptions } from './functions/BusIconHandlers';
import BusIcon, { BusIconProps } from './BusIcon';

export interface BusIconContainerProps extends BusIconOptions, Omit<BusIconProps, 'iconImage' | 'isBarrierFree'> {
  
}

const BusIconContainer = (props: BusIconContainerProps) => {
  const busIconProps = BusIconHandlers.getIcon(props);

  return (
    <BusIcon {...props} {...busIconProps} />
  );
};

export default BusIconContainer;