import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { RouteNameTitleProps } from './types';
import makeRouteMapTitle from '../functions/makeRouteMapTitle';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  description: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
}));

const RouteNameTitle = (props: RouteNameTitleProps) => {
  const {
    variant='h6',
  } = props;
  
  const classes = useStyles();
  const handledStrs = makeRouteMapTitle(props);

  return (
    <Box
      display={'flex'}
      flexWrap={'wrap'}
    >
      <Typography
        variant={variant}
      >
        {`(${handledStrs.busProvidersStr})`}
      </Typography>
      <IntlFormattedMessage 
        variant={variant}
        langKey={'routeWithVar'}
        values={{
          routeName: props.routeName
        }}
      />
      <Typography
        variant={variant}
        className={classes.description}
      >
        {` ${props.description} `}
      </Typography>
      {/* <IntlFormattedMessage 
        variant={variant}
        langKey={'onShelfAtWithVar'}
        values={{
          date: handledStrs.dateStr,
        }}
      /> */}
    </Box>
  );
};

export default RouteNameTitle;