import React from 'react';
import { Box } from '@material-ui/core';
import { TransportationIconProps } from './types';

const TransportationIcon = (props: TransportationIconProps) => {
  switch (props.mrtTransportationType) {
    case 'RAIL':
      return <></>;

    case 'THSR':
      return <></>;
    
    default:
      return null;
  }
};

export default TransportationIcon;