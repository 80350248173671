import React from 'react';
import { MainMenuLinksProps, LinkButtonStyle } from 'ibus-common-components/lib/components/common-components/Menus/types';
import { ContextWrapper } from 'ibus-common-components/lib/components/common-components/Menus/buttonContext';
import BasicMenuLinks from 'ibus-common-components/lib/components/common-components/Menus/BasicMenu/BasicMenuLinks';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    '& button': {
      backgroundColor: 'transparent',
      color: '#fff',
    },
    '& button[is-selected="true"]': {
      background: `${theme.complementary.main}`,
      opacity: 1,
    }
  }
}));

const mainMenuLinksLinkButtonStyles: LinkButtonStyle = {
  variant: 'h6',
  paddingLeftRight: 1,
  paddingTopBottom: 1,
};

const MainMenuLinks = ({ links }: MainMenuLinksProps) => {
  const classes = useStyles();

  return (
    <ContextWrapper customInitState={{
      linkButtonStyle: mainMenuLinksLinkButtonStyles
    }}>
      <Box
        className={classes.root}
      >
        <BasicMenuLinks links={links} />
      </Box>
    </ContextWrapper>
  );
};

export default MainMenuLinks;