import { EdgesData, ID } from "all-common-types";
import { gql } from "apollo-boost";
import { BusInfos, GoBack, SingleBusInfoInEdge, SingleStationRoute } from "bus-common-types";
import RouteFragment from "constants/api-fragments";
import gqlFragments, { EstimateTimesFragment } from "./fragments.gql";

export interface SingleTravelTimeInput {
  xno: number
  fromStationId?: number
  toStationId?: number
  fromSid?: number
  toSid?: number
  goBack: number
}

export const QUERY_TRAVEL_TIME = gql`
  query QUERY_TRAVEL_TIME($travelTimeInputs: [TravelTimeInput!]) {
    travelTimes(inputs: $travelTimeInputs) {
      edges {
        node {
          ...travelTimeFragment
        }
      }
    }
  }
  ${RouteFragment.fragment.travelTimeFragment}
`;


export const QUERY_BUSES = gql`
  query QUERY_BUSES($routeId: Int!) {
    route(xno: $routeId, lang: "zh") {
      buses {
        ...busInfosInfo
      }
    }
  }
  ${RouteFragment.fragment.busInfosInfo}
`;
export interface SingleBusInfo extends SingleBusInfoInEdge {

}
export interface QueriedBusInfoListData{
  route: {
    buses: BusInfos
  }
}

export const QUERY_STATIONS_BY_NAME = gql`
  query QUERY_STATIONS_NAMES_BY_VALUE($name: String!, $lang: String!) {
    stationsByName(name: $name, lang: $lang) {
      edges {
        node {
          id
          name
          lat
          lon
        }
      }
    }
  }
`;
export interface SingleStationInStationsByName {
  id: ID
  name: string
  lat: number
  lon: number
}
export interface QueriedStationsByName {
  stationsByName: EdgesData<SingleStationInStationsByName>
}

export const QUERY_STATION_PASSED_ROUTES = gql`
  query QUERY_STATION_PASSED_ROUTES($stationId: Int!, $lang: String!) {
    station(id: $stationId, lang: $lang) {
      id
      routes {
        edges {
          goBack
          node {
            id
            name
            description
            seq
          }
        }
      }
    }
  }
`;
export interface SingleRouteInStation {
  goBack: GoBack
  node: SingleStationRoute
}
export interface QuriedStationPassedRoutes {
  station: {
    id: ID
    routes: {
      edges: SingleRouteInStation[]
    }
  }
}


export const QUERY_PASSED_ROUTES_ESTIMATE_TIMES = gql`
  query QUERY_PASSED_ROUTES_ESTIMATE_TIMES($targets: [EstimateStationInput!]!) {
    stationEstimates(targets: $targets) {
      ...estimateTimesFragment
    }
  }
  ${gqlFragments.estimateTimesFragment}
`;
export interface QueriedPassedRoutesEstimateTimes {
  stationEstimates: EstimateTimesFragment
}

export const QUERY_ROUTES_BY_STATION = gql`
  query QUERY_ROUTES_BY_STATION($stationId: Int!, $lang: String!) {
    station(id: $stationId, lang: $lang) {
      id
      routes {
        edges {
          node {
            id
            seq
            name
            description
          }
        }
      }
    }
  }
`;

export interface SingleRouteFromRoutesByStation {
  id: ID
  seq: number
  name: string
  description: string
}
export interface QuriedRoutesByStation {
  station: {
    id: ID
    routes: EdgesData<SingleRouteFromRoutesByStation>
  }
}