import { createMuiTheme, Theme } from "@material-ui/core";
import goldTheme from "./goldTheme";
import newYearCustomer from './homepageImages/newYearCustomer.json';

const primary = {
  light: '#E67272',
  // main: '#B73232',
  // main: '#D40A1F',
  main: '#D6032B',
  dark: '#8E1414',
};
const secondary = {
  light: '#F9EDDB',
  main: '#E4B367',
};

const newYearThemeCustomer = createMuiTheme({
  ...goldTheme,
  name: 'new-year-customer',
  homepageColors: {
    titleColor: primary.main,
    navBar: `
      linear-gradient(${90}deg, ${'#AD0019'} 0%, ${'#FF3F4F'} 100%)`,
    noticePart: goldTheme.palette.primary.main,
    // middlePart: `linear-gradient(${90}deg, ${primary.main} 0%, ${' #e14080'} 100%)`,
    middlePart: '#E5AC2C',
    footer: `${primary.main}`,
  },
  homepageImages: {
    topLeft: newYearCustomer.homepage_top_left.src,
    topRight: newYearCustomer.homepage_top_right.src,
    bottomLeft: newYearCustomer.homepage_below_left.src,
  }, 
  background: {
    surface: `#fff`,
    homepage: `
      linear-gradient(${180}deg, ${'rgba(255, 214, 0, 0.3)'} 0%, ${'rgba(255, 99, 0, 0.3)'} 100%)`,
  },
  palette: {
    primary,
    secondary: {
      ...secondary,
    }
  },
});

export const checkIsNewNearCustomer = (theme: Theme) => (
  theme.name === 'new-year-customer'
);

export default newYearThemeCustomer;