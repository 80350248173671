import { combineReducers } from 'react-function-helpers';
import { FareState } from '../constant/context';
import { fromToStopsId, fromToStops } from './selectFromTo_reducers';
import commonReducers from 'reducers/common-reducers';
import { routes } from './fare-reducers';

export function defaultReducer<State extends object>(state: State) {
  return <Key extends keyof State>(stateKey: Key) => {
    const s = state[stateKey];
    type ss = typeof s
    return s as ss;
  };
}

const reducers = combineReducers<FareState>({
  busProviderId: commonReducers.busProviderId,
  routes: routes,
  fromToStopsId: fromToStopsId,
  selectedRoute: commonReducers.selectedRoute,
  fromToStops: fromToStops
});

export default reducers;