import { SelectRoutesContainerProps } from "../../types";
import { useCallback } from "react";
import { SingleSelectorOption } from "ibus-common-components/lib/components/common-components/FilterSelector/types";

const useSelectRoutes = (params: SelectRoutesContainerProps) => {
  const {
    routes,
    setSelectedRouteFn,
    resetFromToStopsIdFn,
  } = params;

  const handleSelectRoute = useCallback((selectedRoute: SingleSelectorOption) => {
    if(setSelectedRouteFn) {
      setSelectedRouteFn({
        routes,
        id: selectedRoute.value,
      });
      resetFromToStopsIdFn && resetFromToStopsIdFn();
    }
  }, [resetFromToStopsIdFn, routes, setSelectedRouteFn]);

  return ({
    handleSelectRoute
  });
};

export default useSelectRoutes;