import React from 'react';
import { Box } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { NoonTitleProps } from './types';

const NoonTitle = ({
  noonType,
}: NoonTitleProps) => {
  return (
    <Box textAlign={'center'}>
      <IntlFormattedMessage 
        langKey={noonType}
        variant={'body1'}
      />
    </Box>
  );
};

export default NoonTitle;