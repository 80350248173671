import React from 'react';
import { Box, Button } from '@material-ui/core';
import { IntlButtonProps } from './types';
import { FormattedMessage } from 'react-intl';

const IntlButton = (props: IntlButtonProps) => {
  return (
    <FormattedMessage
      id={props.langKey}
      defaultMessage={props.defaultMessage}
    >
      {t => (
        <Button 
          {...props}
        >
          <>
            {props.icon}
            <Box component={'span'} paddingLeft={0.5}>
              {t}
            </Box>
          </>
        </Button>
      )}
    </FormattedMessage>
  );
};

export default IntlButton;