import React, { memo } from 'react';
import IntlFilterSelector from 'components/common-components/IntlComponents/IntlFilterSelector';
import { RoutesFilterSelectorProps } from './types';
import useRoutesFitlerSelector from './useRoutesFilterSelector';
import TitleWrapper from 'components/stop-report-page/components/StopReportInputPart/TitleWrapper';

const RoutesFilterSelector = (props: RoutesFilterSelectorProps) => {
  const states = useRoutesFitlerSelector(props);

  return (
    <IntlFilterSelector
      defaultSelectedSingleSelectorOption={states.defaultSelectedRouteFromRouter}
      options={states.selectorOptionsRoutes}
      getSelectedOptionFn={states.handleSetRouteId}
    />
  );
};

export default memo(RoutesFilterSelector);