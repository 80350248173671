import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Link, withRouter, RouteProps, RouteComponentProps } from 'react-router-dom';
import handleLinkAndBlogMatch from 'lib/handleRouteMatched/handleLinksAndBlogsMatched';
import { SingleLink } from 'all-common-types';
import { SingleBlog } from 'blog';
import { splitPath } from 'lib/fn';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ddd',
    padding: theme.spacing(1),
  }
}));

type NameOrLinkProps = {
  route: MatchedRoute,
  pathname: string
}
export const NameOrLink = ({ route, pathname }: NameOrLinkProps) => {
  const { path, breadCrumbName } = route;
  const isThisPage = pathname === path;
  const splitPathname = splitPath(pathname);
  const pathIsNotFound = !splitPathname.joinedPaths.find(p => (
    p === path
  ));
  return (isThisPage || pathIsNotFound) ? (
    <Box component={'span'}>
      {`${breadCrumbName}`}
    </Box>
  ) : (
    <Box component={'span'}>
      <Link to={path}>{`${breadCrumbName}`}</Link>
      {' / '}
    </Box>
  );
};

export type MatchedRoute = {
  path: string
  breadCrumbName: string
}
export type BreadCrumbProps = {
  matchedRoutes: MatchedRoute[]
} & RouteComponentProps
const BreadCrumbs = ({ location, matchedRoutes }: BreadCrumbProps) => {
  const classes = useStyles();
  const { pathname } = location;
  return (
    <Box className={classes.root}>
      {matchedRoutes.map((route, i) => (
        <NameOrLink
          key={i}
          route={route} 
          pathname={pathname} />
      ))}
    </Box>
  );
};


type PropsForBlogWithBreadCrumbs = {
  links: SingleLink[] 
  blogLists: SingleBlog[]
  [x: string]: any
} & RouteComponentProps
export const BreadCrumbsForBlog = (props: PropsForBlogWithBreadCrumbs) => {
  const { location, links, blogLists } = props;
  const { pathname } = location;
  const matchedRoutes = handleLinkAndBlogMatch.getMatchedRouteListWithBlogs(blogLists)(pathname)(links);
  //console.log(links, blogLists, pathname);
  return (
    <BreadCrumbs {...props} matchedRoutes={matchedRoutes}  />
  );
};


type HOC = import('all-common-types').HOC<PropsForBlogWithBreadCrumbs>
export const pageWithBreadCrumbs: HOC = (Page) => props => {
  return (
    <>
      <BreadCrumbsForBlog {...props}/>
      <Page {...props}/>
    </>
  );
};

export default withRouter<BreadCrumbProps, any>(BreadCrumbs);