import React, { ComponentType } from 'react';
import { Typography } from '@material-ui/core';
import LoadingAndError from 'components/common-components/LoadingAndError';
import { AppProps, AppWithIbusCommonDataProps } from 'App';
import useQueryCms from '../../lib/customHooks/useQueryCms';
import { useIntl } from 'react-intl';
import { Locale } from 'all-common-types';

function connectWithCmsData(Component: ComponentType<AppWithIbusCommonDataProps>) {
  return (props: AppProps) => {
    const {
      locale,
    } = useIntl();

    const { loading, error, ibusCommonData } = useQueryCms({
      locale: locale as Locale,
    });

    //Due to dislike loading :(
      
    // if(loading) {
    //   return (
    //     <LoadingAndError loading={loading} error={undefined} />
    //   );
    // }

    return (
      <>
        {error && (
          <Typography>
            {'Network have something wrong, it is offline mode'}
          </Typography>
        )}
        <Component 
          {...props} 
          ibusCommonDataLoading={loading}
          ibusCommonData={ibusCommonData} />
      </>
    );
  };
};

export default connectWithCmsData;