import { GoBack, SingleClogMessage, SingleStationInEdge } from "bus-common-types";
import { SingleQueriedClogMessage, SingleStationInClogMessage } from "components/driving-map/constants/API";
import { ClogMessageTemplateParams, HandledRouteData } from "driving-map";
import getDepDestByGoBack from "lib/getDepDestByGoBack";
import replaceAllWithVariables from "lib/replaceAllWithVariables";

export interface GetIssueStationsParams {
  affectedStations: SingleStationInClogMessage[]
  goBack: GoBack
  stations: SingleStationInEdge[]
}
export interface ParseSingleClogMessageParams {
  clogMessage: SingleQueriedClogMessage
  routeData: HandledRouteData
  // goBack: GoBack
}
export interface HandleRawClogMessagesDataParams extends Pick<ParseSingleClogMessageParams, 'routeData'> {
  clogMessages: SingleQueriedClogMessage[]
}

export const CLOG_TEMPLATE_TYPES = [
  374722794, 374722795, 377288033
];

const ClogMessagesHandlers = {
  // 上午3:15:13 -> 上午3:15
  parseIssueTimeStr: (issueTime: string) => {
    const time = new Date(issueTime);
    return time.toLocaleTimeString().split(':').slice(0, 2).join(':');
  },

  getClogTypeStr: (type: number) => {
    if(type === 374722794) {
      return '交通事故';
    }
    if(type === 374722795) {
      return '車輛故障';
    }
    if(type === 377288033) {
      return '車輛改道';
    }
    return '';
  },

  getProviderNameStr: (providerId: number, providers: HandledRouteData['providers']) => {
    const filteredProviders = providers.filter(p => String(p.id) === String(providerId));
    const str = filteredProviders.map(p => p.name).join(', ');
    return str;
  },

  attachStationWithName: ({
    affectedStation,
    stations,
  }: {
    affectedStation: SingleStationInClogMessage
    stations: SingleStationInEdge[]
  }) => {
    const foundStation = stations.find(_s => (
      Number(_s.node.id) === affectedStation.id
    ));
    const stationName = foundStation ? foundStation.node.name : '';
    return ({ ...affectedStation, stationName, });
  },

  getIssueStationsStr({
    affectedStations,
    goBack,
    stations,
  }: GetIssueStationsParams): string{
    const filtered = affectedStations.filter(s => s.goBack === goBack);
    const stationsWithName = filtered.map(s =>
      this.attachStationWithName({ stations, affectedStation: s, })
    );
    const str = stationsWithName.map(s => `「${s.stationName}」站`).join(', ');
    return `於${str}`;
  },

  checkShouldUseClogTemplate: (clogType: number) => {
    return CLOG_TEMPLATE_TYPES.includes(clogType);
  },

  getClogMessageGoBack: (stations: SingleQueriedClogMessage['stations']) => (
    stations.length ? stations[0].goBack : 1
  ),

  parseSingleClogMessage({
    clogMessage,
    routeData,
    // goBack,
  }: ParseSingleClogMessageParams): string {
    if(!this.checkShouldUseClogTemplate(clogMessage.type)) {
      return clogMessage.description;
    }
    const goBack = this.getClogMessageGoBack(clogMessage.stations);
    const depDest = getDepDestByGoBack(routeData, goBack);
    const providersNameStr = this.getProviderNameStr(clogMessage.providerId, routeData.providers);
    const issueTime = this.parseIssueTimeStr(clogMessage.beginTime);
    const issueStation = this.getIssueStationsStr({
      affectedStations: clogMessage.stations,
      goBack,
      stations: routeData.stations.edges,
    });

    const res = replaceAllWithVariables<ClogMessageTemplateParams>(clogMessage.template, {
      providerName: providersNameStr,
      routeName: routeData.name,
      goBack: depDest,
      issueStation,
      issueTime,
      cause: clogMessage.title,
    });
    return res;
  },

  handleRawData(params: HandleRawClogMessagesDataParams): SingleClogMessage[] {
    return params.clogMessages.map(clogMessage => {
      const description = this.parseSingleClogMessage({
        ...params,
        clogMessage,
      });
      return ({
        ...clogMessage,
        description,
      });
    });
  },
};

export default ClogMessagesHandlers;