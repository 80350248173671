import { SingleProvider } from "bus-common-types";
import { TAXI_PROVIDER_IDS } from "components/driving-map/config";

const sortTaxiToTop = (providers: SingleProvider[]) => {
  let res: SingleProvider[] = [];

  for (let i = 0; i < providers.length; i++) {
    const provider = providers[i];
    const isTaxiProvider = TAXI_PROVIDER_IDS.includes(Number(provider.id));

    if(isTaxiProvider) {
      res = [
        provider,
        ...res
      ];
    } else {
      res = [...res, provider];
    }
  }

  return res;
};

export default sortTaxiToTop;