import React from "react";
import { Grid, Box } from "@material-ui/core";

type OneToTwelve = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
interface Props {
  className?: string;
  md: OneToTwelve;
  lg: OneToTwelve;
  imgSrc: string;
}

const HomePageImage = ({ className, md, lg, imgSrc }: Props) => {
  return (
    <Grid
      item
      xs={6}
      md={md}
      lg={lg}
      style={{ margin: "auto" }}
      className={className}
    >
      <Box maxHeight={240}>
        <img
          style={{ width: "100%" }}
          src={imgSrc}
          alt={`decorator ${imgSrc}`}
        />
      </Box>
    </Grid>
  );
};

export default HomePageImage;
