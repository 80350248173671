import { SelectedRoute } from "components/driving-map/constants/context";
import { MainSideRoutes } from "components/driving-map/constants/functions";

const checkRouteIdInMainSideRouteChildren = (mainRouteId: SelectedRoute['id']) => (sideRouteId: SelectedRoute['id'], mainSideRoutes: MainSideRoutes | null) => {
  if(mainRouteId && mainSideRoutes && mainSideRoutes.length > 0) {
    const matchedRoute = mainSideRoutes.find(r => String(r.xno) === String(mainRouteId));
    if(matchedRoute) {
      const isFoundInChildren = matchedRoute.children.find(r => String(r) === String(sideRouteId));
      return isFoundInChildren;
    }
  }
};

export default checkRouteIdInMainSideRouteChildren;