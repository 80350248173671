import React from 'react';
import IntlFormattedMessage from "components/common-components/IntlFormattedMessage";

export const getTakenTimeStr = (minutes: number) => (
  <IntlFormattedMessage 
    langKey={'collinearRoutes.estimateTravelTime'}
    values={{
      minutes
    }}
  />
);
export const initRouteTimesInRouteInfo = {
  startArrivalTime: '末班駛離',
  takenTime: getTakenTimeStr(0) as any,
  destArrivalTime: '--',
};