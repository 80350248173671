// import * as Types from './action-types';
import { OtpItinerary } from "travel-planner";
import { actionTypes } from './index';
import getTravelPlanByFromTo from "../lib/getTravelPlanByFromTo";
import { GetTravelPlanByCombinedFromToAPIParams } from "../constant/API";

type SelectPlanAction = {
  type: actionTypes.SELECT_PLAN
  payload: {
    plan: OtpItinerary
  }
}
type GetPlansAction = {
  type: actionTypes.GET_PLANS
  payload: {
    plans: OtpItinerary[]
  }
}
interface ResetPlansAction {
  type: actionTypes.RESET_PLANS
}

export interface GetPlansParams extends GetTravelPlanByCombinedFromToAPIParams{

}

export const selectPlan = (plan: OtpItinerary): SelectPlanAction => ({
  type: actionTypes.SELECT_PLAN,
  payload: { plan },
});

export const getPlans = async (params: GetPlansParams): Promise<GetPlansAction> => {
  const plans = await getTravelPlanByFromTo(params);
  return ({
    type: actionTypes.GET_PLANS,
    payload: { plans },
  });
};

export const resetPlans = (): ResetPlansAction => ({
  type: actionTypes.RESET_PLANS,
});

export type PlansActionTypes =
  SelectPlanAction |
  GetPlansAction |
  ResetPlansAction