import { Callback } from "all-common-types";
import { SelectorProps } from "components/common-components/SelectsComponents/types";
import useQueryBusProviders from 'lib/customHooks/useQueryBusProviders';
import useSelectorSelect from 'lib/customHooks/useSelectorSelect';
import makeBusProvidersSelectorOptions from "lib/makeBusProvidersSelectorOptions";
import { useEffect } from "react";

export interface UseBusProviderSelectsOptions {
  selectorName: string
  setSelectedBusProviderFn: Callback
  initSelectorProps: SelectorProps
}

const useBusProvidersSelector = (options: UseBusProviderSelectsOptions): SelectorProps => {
  const {
    selectorName,
    setSelectedBusProviderFn,
    initSelectorProps,
  } = options;

  const {
    handleQueryBusProviders,
    loading,
    busProviders,
  } = useQueryBusProviders();
  const selectorOptions = makeBusProvidersSelectorOptions(busProviders);

  const selectorSelectStates = useSelectorSelect({
    [selectorName]: '',
  }, (values) => setSelectedBusProviderFn(values[selectorName]));

  useEffect(() => {
    handleQueryBusProviders();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ({
    ...initSelectorProps,
    ...selectorSelectStates,
    changeFn: selectorSelectStates.handleSelect(selectorName),
    value: selectorSelectStates.values[selectorName],
    options: selectorOptions,
    loading,
  });
};

export default useBusProvidersSelector;