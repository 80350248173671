import { QueriedTgosAddress, TgosAddressQueryParams } from "bus-common-types";
import { removeXmlHtmlTagRegExp } from "constants/regExp";
import replaceAllWithVariables from "lib/replaceAllWithVariables";

declare var window: Window & typeof globalThis & {
  _env_: any
};
if(!window._env_)
  window._env_ = {};

const URI =
  window._env_.REACT_APP_TGOS_ADDRESS_QUERY ||
  process.env.REACT_APP_TGOS_ADDRESS_QUERY;

const appId = process.env.REACT_APP_TGOS_ADDRESS_APP_ID || "";
const apiKey = process.env.REACT_APP_TGOS_ADDRESS_API_KEY || "";

export const initQueriedTgosAddressResult: QueriedTgosAddress = {
  Info: {},
  AddressList: [],
};

const queryTgosAddress = (address: string) => {
  const id = appId;
  const key = apiKey;

  const uri = replaceAllWithVariables<TgosAddressQueryParams>(URI, {
    appId: id, apiKey: key, address
  });

  return fetch(uri)
    .then(res => res.text())
    .then(res => {
      const resText = res.replace(removeXmlHtmlTagRegExp, '');
      if(resText.match(/\{|\}/g)) {
        return JSON.parse(resText) as QueriedTgosAddress;
      }
      return initQueriedTgosAddressResult;
    })
    .catch(e => {
      console.log(e);
      return initQueriedTgosAddressResult;
    });
};

export default queryTgosAddress;