import React from 'react';
import { SingleProvider } from "bus-common-types";
import { SelectorOptions, SingleSelectorOption } from "ibus-common-components/lib/components/common-components/FilterSelector/types";
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import filterProvidersWithoutTaxi from './busProviders/filterProvidersWithoutTaxi';
import filterDataListByOpType from './busProviders/filterDataListByOpType';

export const ALL_PROVIDERS = 'ALL_PROVIDERS';

const defaultAllProvidersSelectorOption: SingleSelectorOption = {
  text: (
    <IntlFormattedMessage 
      langKey={'drivingMap.busTypeTab.selectAllProviders'}
    />
  ) as any,
  value: ALL_PROVIDERS
};

const makeBusProvidersSelectorOptions = (providers: SingleProvider[]): SelectorOptions => {
  let res: SelectorOptions = [defaultAllProvidersSelectorOption];
  const filteredByOpTypeProviders = filterDataListByOpType(providers);
  const filteredProviders = filterProvidersWithoutTaxi(filteredByOpTypeProviders);

  res = [
    ...res,
    ...filteredProviders.map(p => ({
      text: p.name,
      value: p.id as any
    }))
  ];
  
  return res;
};

export default makeBusProvidersSelectorOptions;