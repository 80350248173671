export type DisplayModeType = 'PC' | 'RWD'

const DisplayModeHandlers = {
  displayModeLSKey: 'ibus-tc-display-mode',
  defaultDisplayMode: 'RWD' as DisplayModeType,

  pcViewportContent: 'width=1440, initial-scale=1, minimum-scale=0.5',
  rwdViewportContent: 'width=device-width, initial-scale=1',

  getViewportMetaEl: () => document.querySelector('meta[name="viewport"]'),

  setPcViewport() {
    const metaEl = this.getViewportMetaEl();
    metaEl && metaEl.setAttribute('content', this.pcViewportContent);
  },

  setRwdViewport() {
    const metaEl = this.getViewportMetaEl();
    metaEl && metaEl.setAttribute('content', this.rwdViewportContent);
  },

  getDisplayMode(): DisplayModeType {
    return localStorage.getItem(this.displayModeLSKey) as DisplayModeType || 
      this.defaultDisplayMode;
  },

  setDisplayMode(mode: DisplayModeType) {
    localStorage.setItem(this.displayModeLSKey, mode);
  },

  setAndReload(mode: DisplayModeType) {
    this.setDisplayMode(mode);
    window.location.reload();
  },

  detectDisplayModeAndSetViewport() {
    const displayMode = this.getDisplayMode();
    if(displayMode === 'PC') {
      this.setPcViewport();
    } else {
      this.setRwdViewport();
    }
  },
};

export default DisplayModeHandlers;