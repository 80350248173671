import React, { useMemo } from "react";
import {
  GoBackStr,
  SingleEstimateStop,
  GoBack,
  SingleNearBikeStation,
} from "bus-common-types";
import { RouteLists } from "components/driving-map/components/Route";
import DepDestTabList from "components/common-components/TabComponents/DepDestTabList";
import { DepDestTabListProps } from "components/common-components/TabComponents/types";
import useGoBackTab from "lib/customHooks/useGoBackTab";
import { HandleDepDestAndStopSearchedRouteInfo } from "components/collinear-routes/fn";
import RouteListHandlers from "./functions/RouteListHandlers";
import { RouteListsProps } from "components/driving-map/components/Route/RouteLists";
import { SingleNearMrt } from "components/driving-map/gql/gql-schema";
import { OtherTransportationStations } from "components/driving-map/components/Route/functions/HandleStopIsNearIbike";
import { RouteStationIcon } from "components/driving-map/gql/gql-schema";

export type GoBackEstimateStops = {
  [x in GoBackStr]: SingleEstimateStop[];
};
export interface RouteListsContainerProps
  extends Pick<RouteListsProps, "zoomRatio"> {
  routeLists: {
    departure: string;
    destination: string;
    goBackEstimateStops: GoBackEstimateStops;
  };
  otherTransportationStations: OtherTransportationStations;
  setGoOrBackFn: (x: GoBack) => any;
  goBack: GoBack;
}

type DepDestType = DepDestTabListProps["depDest"];
export type RouteListDepDest = {
  [key in keyof DepDestType]: {
    goBack: GoBack;
  } & DepDestType["departure"];
};

export const RouteListsContainer = (props: RouteListsContainerProps) => {
  const { routeLists, goBack, otherTransportationStations, setGoOrBackFn } =
    props;
  // const [selectedTab, selectTab] = useSelectTab(0);
  const listDatas = useMemo(
    () =>
      RouteListHandlers.getListDatas(goBack)(
        routeLists.goBackEstimateStops,
        otherTransportationStations
      ),
    [goBack, otherTransportationStations, routeLists.goBackEstimateStops]
  );

  const depDest = RouteListHandlers.getDepDestFromRouteLists(routeLists);
  const combinedDepDest = RouteListHandlers.combineDepDestToArr(depDest);
  const initGoBack =
    HandleDepDestAndStopSearchedRouteInfo.getInitGoBack(combinedDepDest);

  const { selectTab, selectedTab } = useGoBackTab({
    callback: setGoOrBackFn,
    initGoBack: initGoBack,
  });

  return (
    <>
      <DepDestTabList
        variant={"contained"}
        depDest={depDest}
        selectedTab={selectedTab}
        selectTabFn={selectTab}
      />
      <RouteLists
        key={goBack}
        listDatas={listDatas}
        zoomRatio={props.zoomRatio}
      />
    </>
  );
};

export default RouteListsContainer;
