import {
  Box,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  RootRef,
  makeStyles,
} from "@material-ui/core";

import React, { forwardRef, memo, Ref, useCallback } from "react";
import { useStylesSelectsInFilter } from "ibus-common-components/lib/components/common-components/FilterSelector/styles";
import {
  SelectsInFilterProps,
  SingleSelectorOption,
} from "ibus-common-components/lib/components/common-components/FilterSelector/types";
import { useScrollToSingleSelect } from "ibus-common-components/lib/lib/customHooks/index";
import ListItemIndexesForRenderingHandler from "ibus-common-components/lib/components/common-components/FilterSelector/ListItemIndexesForRenderingHandler";
import useDynamiceRenderList from "ibus-common-components/lib/components/common-components/FilterSelector/useDynamiceRenderList";
import EmptyListItem from "ibus-common-components/lib/components/common-components/FilterSelector/EmptyListItem";
import CopyButton from "ibus-common-components/lib/components/common-components/FilterSelector/CopyButton";
import { Callback } from "all-common-types";
import ibusImages from "static/ibusImages.json";

type SelectorOption = SingleSelectorOption & {
  icon?: keyof typeof ibusImages;
};

type SelectorProps = {
  option: SelectorOption;
  isSelected: boolean;
  clickFn?: Callback;
};

const useStyles = makeStyles((theme) => ({
  root: {},
  singleSelect: {
    "& svg": {
      display: "none",
    },
    "&:hover svg": {
      display: "block",
    },
  },
}));

export const NoOptionsInfo = ({ input }: { input: string }) => {
  const noOptionText = (
    <Box>
      <Box component={"span"}>{"No result matched "}</Box>
      <Box
        component={"span"}
        style={{
          fontWeight: 800,
        }}
      >
        {input}
      </Box>
    </Box>
  );
  return (
    <ListItem>
      <ListItemText primary={noOptionText} />
    </ListItem>
  );
};

export const SingleSelect = forwardRef(
  (
    { option, isSelected, clickFn }: SelectorProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const classes = useStyles();
    return (
      <Box display={"flex"} className={classes.singleSelect}>
        <ListItem ref={ref} selected={isSelected} onClick={clickFn} button>
          <ListItemText primary={option.text} />
          {option.icon && (
            <Box display={"flex"} alignItems={"center"}>
              <img
                style={{ width: "auto", maxHeight: 36, maxWidth: "100%" }}
                alt={ibusImages[option.icon].name}
                src={ibusImages[option.icon].src}
              />
            </Box>
          )}
        </ListItem>
        <CopyButton copyText={option.text} />
      </Box>
    );
  }
);

export const Selects = ({
  filterInput,
  filteredOptions,
  selectedIndex,
  filterFn,
  selectFn,
  closeSelectsFn,
}: SelectsInFilterProps) => {
  const optionsLength = filteredOptions.length;
  const classes = useStylesSelectsInFilter();

  const {
    listPartRef,
    listRefs,

    startToEndIndexesForRendering,
    handleScroll,
  } = useDynamiceRenderList(filteredOptions, selectedIndex);

  const singleOptionFn = (singleOption: SelectorOption, i: number) => {
    const singleSelect = (
      <SingleSelect
        key={i}
        ref={listRefs.current[i]}
        option={singleOption}
        isSelected={selectedIndex === i}
        clickFn={handleSelectRoute(i)}
      />
    );
    const emptyListItem = <EmptyListItem key={i} ref={listRefs.current[i]} />;
    const Component =
      ListItemIndexesForRenderingHandler.getSingleSelectOrEmptyListItemByCheckShouldRender(
        i,
        startToEndIndexesForRendering
      )(singleSelect, emptyListItem);

    return Component;
  };

  const handleSelectRoute = useCallback(
    (index: number) => {
      return () => {
        if (selectFn) {
          selectFn(index);
        }
      };
    },
    [selectFn]
  );

  const handleClose = useCallback(() => {
    if (closeSelectsFn) {
      closeSelectsFn();
    }
  }, [closeSelectsFn]);

  useScrollToSingleSelect(listRefs, selectedIndex);

  return (
    <Box className={classes && classes.root}>
      <Paper>
        <Box padding={1}>
          <TextField
            fullWidth
            autoFocus={true}
            variant={"outlined"}
            onChange={filterFn}
            value={filterInput}
          />
        </Box>
        <RootRef rootRef={listPartRef}>
          <Box className={classes.selects} onScroll={handleScroll}>
            {optionsLength > 0 ? (
              filteredOptions.map(singleOptionFn)
            ) : (
              <NoOptionsInfo input={filterInput} />
            )}
          </Box>
        </RootRef>
      </Paper>
      <Box
        id={"selectsInFilterBG"}
        className={classes.clickBG}
        onClick={handleClose}
      />
    </Box>
  );
};

export default memo(Selects);
