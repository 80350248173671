import React, { memo, useMemo } from 'react';
import { Box } from '@material-ui/core';
import Selector from 'components/common-components/SelectsComponents/Selector';
import { ReportDescriptionSelectorProps } from './types';
import { useIntl } from 'react-intl';
import getReportDescriptionOptions from 'components/stop-report-page/static/getReportDescriptionOptions';
import { Locale } from 'all-common-types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const ReportDescriptionSelector = (props: ReportDescriptionSelectorProps) => {
  const { locale } = useIntl();
  const options = useMemo(() => (
    getReportDescriptionOptions(locale as Locale)
  ), [locale]);

  return (
    <Box>
      <Selector 
        {...props}
        value={props.selectorValue}
        id={'ReportDescriptionSelector'}
        minWidth={'100%'}
        label={
          <IntlFormattedMessage langKey={'stopReport.selectReportDesc.placeHolder'} />
        }
        options={options}
      />
    </Box>
  );
};

export default memo(ReportDescriptionSelector);