import { useLazyQuery } from "@apollo/react-hooks";
import { useCallback, useEffect } from "react";
import { FromToStationIds } from "fare";
import FarePricesHandler, { defaultFareResult } from "components/fare/functions/FarePricesHandler";
import { FareState } from "components/fare/constant/context";
import { FareResult } from "components/fare/components/ResultPart/types";
import { QUERY_FARES, QueriedFares } from "../gql-schema";
import { GoBack } from "bus-common-types";
import { client} from "constants/API";

type RouteId = FareState['selectedRoute']['id']

export interface UseQueryFaresOptions {
  fromToStationIds: FromToStationIds
  routeId: RouteId
  goBack: GoBack
  shouldIgnoreGoBackFilter: boolean
}

function useQueryFares({
  fromToStationIds, routeId, goBack, shouldIgnoreGoBackFilter
}: UseQueryFaresOptions) {
  let handledFareResult: FareResult = defaultFareResult;
  const {
    fromStationId,
    toStationId,
  } = fromToStationIds;

  const [queryFn, { loading, error, data }] = useLazyQuery<QueriedFares>(QUERY_FARES, {
    client: client
  });

  const handleQuery = useCallback(() => {
    queryFn({
      variables: {
        routeId: Number(routeId),
        fromStationId: Number(fromStationId) || undefined,
        toStationId: Number(toStationId) || undefined,
      }
    });
  }, [fromStationId, queryFn, routeId, toStationId]);

  useEffect(() => {
    if(routeId && fromStationId && toStationId) {
      handleQuery();
    }
  }, [fromStationId, handleQuery, routeId, toStationId]);

  if(data) {
    handledFareResult = FarePricesHandler.getFareResultFromRawData(data)(
      fromToStationIds,
      goBack,
      shouldIgnoreGoBackFilter
    );
  }

  return ({
    loading,
    error,
    handleQuery,
    data,
    handledFareResult,
  });
}

export default useQueryFares;