import React from "react";
import { Box, useTheme } from "@material-ui/core";
import { otherLinks } from "./static/home-page";
import OtherLinkItem from "./OtherLinkItem";
import { OtherLinksPartProps } from "./types";
import IbusImage from "components/common-components/ImageComponents/IbusImage";

const OtherLinksPart = (props: OtherLinksPartProps) => {
  const theme = useTheme();
  return (
    <Box>
      <Box>
        {otherLinks.map((l, i) => (
          <OtherLinkItem key={i} {...l} />
        ))}
      </Box>
      <Box maxWidth={200} paddingTop={2} margin={"auto"}>
        <IbusImage
          imageKey={
            theme.name === "xmas"
              ? "homepage_below_right_xmas"
              : "homepage_below_right"
          }
        />
      </Box>
    </Box>
  );
};

export default OtherLinksPart;
