import { ID, Locale } from "all-common-types";
import { client } from "constants/API";
import { SingleStationInQuriedFromToStation } from "../schemas/QUERY_FROM_TO_STATION_ROUTES";
import { SingleRouteWithStationId } from "./queryStationRoutesByStationName";
import RoutesByStationIdsHandlers from "./RoutesByStationIdsHandlers";

export interface QueriedRoutesByStationIds {
  [x: string]: SingleStationInQuriedFromToStation
}

export const defaultRoutesByStationIds: SingleRouteWithStationId[] = [];

const queryRoutesByStationIds = async (stationIds: ID[], lang: Locale) => {
  try {
    const schema = RoutesByStationIdsHandlers.makeSchema(stationIds, lang);
    const res = await client.query<QueriedRoutesByStationIds>({
      query: schema,
    });
    const parsed = RoutesByStationIdsHandlers.parseRawToRoutes(res.data);
    return parsed;
  } catch (e) {
    console.log(e);
    return defaultRoutesByStationIds; 
  }
};

export default queryRoutesByStationIds;