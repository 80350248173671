import { RouteDataType } from "bus-common-types";
import { SingleBusInstantInfo } from "driving-map";

const getBusInstantInfoListFromRouteData = (busInfos: RouteDataType['buses']): SingleBusInstantInfo[] => {
  const {
    edges
  } = busInfos;
  
  return edges.map(e => ({
    ...e.node,
    goBack: e.goBack,
  }));
};

export default getBusInstantInfoListFromRouteData;