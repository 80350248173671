import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import IntlFormattedMessage from "components/common-components/IntlFormattedMessage";
import { OtherLinkItemProps } from "./types";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.homepageColors.titleColor,
    borderColor: theme.homepageColors.titleColor,
    backgroundColor: theme.name == "xmas" ? "#D3A4A5" : "transparent",
  },
}));

const OtherLinkItem = (props: OtherLinkItemProps) => {
  const { link } = props;
  const classes = useStyles();

  return (
    <Box padding={0.5} margin={"auto"} maxWidth={160}>
      <Link to={link}>
        <Button
          className={classes.button}
          variant={"outlined"}
          // color={'secondary'}
          fullWidth={true}
        >
          <IntlFormattedMessage langKey={props.langKey} />
        </Button>
      </Link>
    </Box>
  );
};

export default OtherLinkItem;
