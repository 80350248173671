import { Locale } from "all-common-types";
import { FromTo } from "collinear-routes";
import { nodeDataFromEdges } from "constants/functions";
import gql from "graphql-tag";
import { QueriedFromToStationsByName } from "../schemas/QUERY_FROM_TO_STATIONS_BY_NAME";
import { SingleStationInQuriedFromToStation } from "../schemas/QUERY_FROM_TO_STATION_ROUTES";
import { FromToStationRoutes } from "./queryStationRoutesByStationName";

export interface FromToStationIds {
  fromStationIds: number[]
  toStationIds: number[]
}
export interface MakeSingleQueryStationRoutesSchemaOptions {
  stationId: number
  isFromStation: boolean
  lang: Locale
}
export interface MakeQueryFromToStationRoutesSchemaOptions {
  fromToStationIds: FromToStationIds
  lang: Locale
}

const StationRoutesByStationNameHandlers = {
  STATION_PREFIX_FROM: 'fromStation',
  STATION_PREFIX_TO: 'toStation',

  getStationIds: (stationName: string, stations: QueriedFromToStationsByName['fromStations']) => {
    const filtered = nodeDataFromEdges(stations).filter(s => s.name === stationName);
    return filtered.map(s => Number(s.id));
  },
  getFromToStationIds(fromToStationName: FromTo, queried: QueriedFromToStationsByName): FromToStationIds {
    return ({
      fromStationIds: this.getStationIds(fromToStationName.from, queried.fromStations),
      toStationIds: this.getStationIds(fromToStationName.to, queried.toStations),
    });
  },

  makeSingleQueryStationRoutesSchema({
    stationId, isFromStation, lang,
  }: MakeSingleQueryStationRoutesSchemaOptions) {
    const stationPrefix = isFromStation ? 
      this.STATION_PREFIX_FROM : this.STATION_PREFIX_TO;
    return `
      ${stationPrefix}_stationId_${stationId}: station(id: ${stationId}, lang: "${lang}") {
        id
        name
        routes {
          edges {
            goBack
            sid
            orderNo
            node {
              seq
              name
              description
              id
            }
          }
        }
      }
    `;
  },

  makeQueryFromToStationRoutesSchema({
    fromToStationIds, lang,
  }: MakeQueryFromToStationRoutesSchemaOptions) {
    let schemaStr = '';

    fromToStationIds.fromStationIds.forEach(id => {
      const schema = this.makeSingleQueryStationRoutesSchema({
        stationId: id,
        isFromStation: true,
        lang,
      });
      schemaStr += schema;
    });
    fromToStationIds.toStationIds.forEach(id => {
      const schema = this.makeSingleQueryStationRoutesSchema({
        stationId: id,
        isFromStation: false,
        lang,
      });
      schemaStr += schema;
    });

    return gql`
      {
        ${schemaStr}
      }
    `;
  },

  parseDynamicSchemaKey: (key: string) => {
    const devided = key.split('_');
    return ({
      stationPrefix: devided[0],
      stationId: devided[2]
    });
  },

  parseQueriedDynamicSchemaFromToStationRoutes(queried: QueriedDynamicSchemaFromToStationRoutes): FromToStationRoutes {
    let res: FromToStationRoutes = {
      fromStationRoutes: [],
      toStationRoutes: [],
    };

    for (const queriedKey in queried) {
      const parsedKey = this.parseDynamicSchemaKey(queriedKey);
      const routes = queried[queriedKey].routes.edges;
      const routesWithStationId = routes.map(r => ({
        ...r,
        ...r.node,
        stationId: parsedKey.stationId,
      }));
      if(parsedKey.stationPrefix === this.STATION_PREFIX_FROM) {
        res.fromStationRoutes.push(...routesWithStationId);
      } else {
        res.toStationRoutes.push(...routesWithStationId);
      }
    }
    
    return res;
  },
};

export interface QueriedDynamicSchemaFromToStationRoutes {
  [x: string]: SingleStationInQuriedFromToStation
}

export default StationRoutesByStationNameHandlers;