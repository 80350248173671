import { Callback } from "all-common-types";
import { ChangeEvent, useCallback, useState } from "react";
import uploadFetch, { UploadFetchOptions } from "./uploadFetch";

export interface UseUploadOptions extends UploadFetchOptions {
  maxUploadFileAmount?: number
  overMaxUploadFileMessage: string
  onSetFiles?: (fileList: FileList) => any
}

const useUpload = (options: UseUploadOptions) => {
  const {
    maxUploadFileAmount=Infinity,
    overMaxUploadFileMessage,
    onSetFiles,
  } = options;
  const [isUploading, setUploading] = useState(false);
  const [fileList, setFiles] = useState<FileList | null>(null);

  const handlePushFileList = useCallback((_files: FileList) => {
    setFiles(files => {
      if(!files) {
        return _files;
      } else {
        return [...files, ..._files] as any;
      }
    });
  }, []);

  const handleAddFiles = useCallback((_files: FileList) => {
    const fileListAmount = fileList ? fileList.length : 0;
    const isOverUploadLimit = 
      (_files.length + fileListAmount > maxUploadFileAmount);

    if(isOverUploadLimit) {
      window.alert(overMaxUploadFileMessage);
    } 
    else {
      handlePushFileList(_files);
      onSetFiles && onSetFiles(_files);
    }
  }, [fileList, handlePushFileList, maxUploadFileAmount, onSetFiles, overMaxUploadFileMessage]);

  const handleChangeFiles = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const {
      files
    } = e.target;

    if(files) {
      handleAddFiles(files);
    }
  }, [handleAddFiles]);

  const handleUpload = useCallback((request: RequestInit) => {
    setUploading(true);
    return uploadFetch({
      onUploadFinnished: () => setUploading(false),
      ...options,
      request,
    });
  }, [options]);

  return ({
    isUploading,
    setUploading,
    fileList,
    setFiles,
    handleAddFiles,
    handleChangeFiles,
    handleUpload,
  });
};
export default useUpload;