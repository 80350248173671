import { gql } from "apollo-boost";
import { GoBack } from "bus-common-types";
import RouteFragment, { QueriedTravelTimeFragment } from "constants/api-fragments";
import { SingleBasicStationForMrtAndBus } from "mrt-pages";
import { BUS_309_ROUTE_ID, BUS_310_ROUTE_ID, MRT_GREEN_ROUTE_ID } from "../config";
import mrtFareFragments, { QueriedMrtFareFragment, SingleQueriedStationForMrtAndBus } from "./fragments.gql";

export const QUERY_MRT_STATIONS = gql`
  query QUERY_MRT_STATIONS($lang: String!) {
    route309: route(xno: ${BUS_309_ROUTE_ID}, lang: $lang) {
      stations {
        ...singleStopFragment
      }
    }

    route310: route(xno: ${BUS_310_ROUTE_ID}, lang: $lang) {
      stations {
        ...singleStopFragment
      }
    }

    # mrt green
    mrtGreen: route(xno: ${MRT_GREEN_ROUTE_ID}, lang: $lang) {
      stations {
        ...singleStopFragment
      }
    }
  }
  ${mrtFareFragments.singleStopFragment}
`;
export interface SingleQueriedRoute {
  stations: {
    edges: SingleQueriedStationForMrtAndBus[]
  }
}
export interface QueriedMrtStations {
  route309: SingleQueriedRoute
  route310: SingleQueriedRoute
  mrtGreen: SingleQueriedRoute
}

export interface QueryMrtFaresParams {
  busFromStationId: number
  mrtFromStationId: number
}
export const QUERY_MRT_FARES = gql`
  query QUERY_MRT_FARES($busFromStationId: Int!, $mrtFromStationId: Int!) {
    route309Fares: fares(xno: ${BUS_309_ROUTE_ID}, from: $busFromStationId) {
      ...fareFragment
    }

    route310Fares: fares(xno: ${BUS_310_ROUTE_ID}, from: $busFromStationId) {
      ...fareFragment
    }

    mrtGreenFares: fares(xno: ${MRT_GREEN_ROUTE_ID}, from: $mrtFromStationId) {
      ...fareFragment
    }
  }

  ${mrtFareFragments.fareFragment}
`;
export interface QueriedMrtFares {
  route309Fares: QueriedMrtFareFragment
  route310Fares: QueriedMrtFareFragment
  mrtGreenFares: QueriedMrtFareFragment
}

export interface QueryMrtTravelTimesParams extends QueryMrtFaresParams {
  route310LastStation: SingleBasicStationForMrtAndBus
  routeId: number
  goBack: GoBack
}
export const QUERY_MRT_TRAVEL_TIMES = gql`
  query QUERY_MRT_TRAVEL_TIMES($busInputs: [TravelTimeInput], $mrtGreenInputs: [TravelTimeInput]) {
    busTravelTimes: travelTime(inputs: $busInputs) {
      ...travelTimeFragment
    }

    mrtGreenTravelTimes: travelTime(inputs: $mrtGreenInputs) {
      ...travelTimeFragment
    }
  }

  ${RouteFragment.fragment.travelTimeFragment}
`;

export interface QueriedMrtTravelTimes {
  busTravelTimes: QueriedTravelTimeFragment
  mrtGreenTravelTimes: QueriedTravelTimeFragment
}