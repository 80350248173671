import { RoutePassListItemProps } from "components/driving-map/components/BusMap/RoutePassList";
import RouteDataHandlers from "components/driving-map/gql/functions/RouteDataHandlers";
import { SingleEstimateTimeInFragment } from "gql/fragments.gql";
import { SingleDataForQueryEstimateTimes } from "../RoutePassListContainer";

const RoutePassListHandlers = {
  getMatchedStationEstimate(
    singleDataForQueryEstimateTimes: SingleDataForQueryEstimateTimes,
    stationEstimates: SingleEstimateTimeInFragment[],
  ) {
    return stationEstimates.find(s => (
      s.xno === singleDataForQueryEstimateTimes.xno &&
      s.goBack === singleDataForQueryEstimateTimes.goBack
    )) || RouteDataHandlers.defaultEstimateTimeFragment;
  },

  mergeToRoutePassListData(
    dataForQueryEstimateTimes: SingleDataForQueryEstimateTimes[],
    stationEstimates: SingleEstimateTimeInFragment[],
  ): RoutePassListItemProps[] {
    dataForQueryEstimateTimes.sort((a, b) => (a.seq - b.seq));
    return dataForQueryEstimateTimes.map((d, i) => ({
      ...d,
      ...this.getMatchedStationEstimate(d, stationEstimates),
    }));
  }
};

export default RoutePassListHandlers;