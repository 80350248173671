import { nodeDataFromEdges } from "constants/functions";
import { QuriedRoutesByStation, SingleRouteFromRoutesByStation } from "gql/schema.gql";
import { SingleRouteWithStationId } from "./queryStationRoutesByStationName";

export interface SingleRouteSelectorOption extends SingleRouteFromRoutesByStation {
  value: string
  text: string
}

const RoutesByStationHandlers = {
  getSelectorOptionText: (
    route: Pick<SingleRouteFromRoutesByStation, 'name' | 'description'>
  ) => {
    return `[${ route.name }] ${ route.description }`;
  },

  makeSingleSelectorOption(route: SingleRouteWithStationId): SingleRouteSelectorOption {
    return ({
      ...route.node,
      value: String(route.node.id),
      text: this.getSelectorOptionText(route.node)
    });
  },

  convertToSelectorOptions(routes: SingleRouteWithStationId[]): SingleRouteSelectorOption[] {
    return routes.map(r => (
      this.makeSingleSelectorOption(r)
    ));
  },
};

export default RoutesByStationHandlers;