import reducers from '../reducers';
import { RouteDataType } from 'bus-common-types';
import { createContextValueFn, createContextStore, MyContext } from 'lib/contextHelpers';
import CommonStateTypes, { initCommonState } from 'reducers/common-reducers/state';
import ContextWrapperFn from 'react-function-helpers/lib/functions/ContextWrapper';
import { HandledRouteData, SingleIbikeInfo } from 'driving-map';

export type SelectedRoute = CommonStateTypes['selectedRoute']
export type SelectedRouteStopId = number | undefined
export type RouteDataWithBusInstantInfoList = HandledRouteData
export type IbikeInfoList = SingleIbikeInfo[]

export interface DrivingMapState extends CommonStateTypes {
  selectedRouteStopIndex: SelectedRouteStopId
  routeData: RouteDataWithBusInstantInfoList | undefined
  ibikeInfoList: IbikeInfoList
}
export type Context = MyContext<DrivingMapState>

export const initSelectedRouteStopId = undefined;
export const initRouteData = undefined;
export const initIbikeInfoList = [];


export const initDrivingMapState: DrivingMapState = {
  ...initCommonState,
  selectedRouteStopIndex: initSelectedRouteStopId,
  routeData: initRouteData,
  ibikeInfoList: initIbikeInfoList
};

export const ContextValue = createContextValueFn(initDrivingMapState, reducers);
const ContextStore = createContextStore<DrivingMapState>(initDrivingMapState);

export default ContextStore;

export const ContextWrapper = ContextWrapperFn(ContextValue, ContextStore);


