import { RoutesSelectorOptions } from "constants/functions";
import { CityBusPartSelectsValues } from "components/driving-map/components/BusSelect/CityBusPart";
import { Callback } from "all-common-types";
import { SingleRouteGroup } from "driving-map";
import { CityBusPartContainerProps } from "../CityBusPartContainer";

class HandleFilterRoutesByValue {
  static getRouteIds(singleRouteGroup: SingleRouteGroup) {
    return singleRouteGroup.routes.map(r => String(r.Id));
  }

  static checkIdIsInRouteIds(id: string, routeIds: string[]) {
    return routeIds.find(rid => rid === id);
  }

  static getRouteIdsBySelectedRouteGroup({
    groupId,
    routeGroupList,
  }: {
    groupId: string, 
    routeGroupList: SingleRouteGroup[],
  }): string[] {
    const selectedRouteGroup = routeGroupList.find(r => r.id === groupId);
    if(selectedRouteGroup) {
      const routeGroupRoutes = selectedRouteGroup.routes.map(r => String(r.Id));
      return routeGroupRoutes;
    }
    return [];
  }

  static filterRoutesByRouteIds(routes: RoutesSelectorOptions, routeIds: string[] | undefined) {
    if(!routeIds) {
      return routes;
    }
    else if(routeIds.length === 0) {
      return routes;
    }
    return routes.filter(r => {
      const checkIsInRouteIds = this.checkIdIsInRouteIds(r.id, routeIds);
      return checkIsInRouteIds;
    });
  }
  
  static filterRoutesByValue = ({
    routeGroupList,
    setProviderIdToCtxFn,
  }: { 
    routeGroupList: SingleRouteGroup[],
    setProviderIdToCtxFn: CityBusPartContainerProps['setProviderIdToCtxFn']
  }) => (selectsValues: CityBusPartSelectsValues, updateCb: Callback) => {
    const {
      selectType,
      selectGroup,
    } = selectsValues;

    //0 is 群組路線
    if(selectType.value === '0') {
      const groupId = selectGroup.value;
      const routeIds = HandleFilterRoutesByValue.getRouteIdsBySelectedRouteGroup({
        groupId,
        routeGroupList,
      });

      updateCb(routeIds);
      setProviderIdToCtxFn(undefined);
    }
    
    //1 is 業者
    if(selectType.value === '1') {
      const providerId = selectGroup.value;

      //reset routeIdsForFilter
      updateCb([]);
      setProviderIdToCtxFn(providerId);
    }
  }
}

export default HandleFilterRoutesByValue;