import { CollinearRoutesState } from "../constants/context";
import { actionTypes as Types, CollinearRoutesActionTypes } from "../actions";

export const searchType = (
  state: CollinearRoutesState, 
  action: CollinearRoutesActionTypes
) => {
  switch (action.type) {
    case Types.SET_SEARCH_TYPE:
      return action.payload.searchType;
      
    default:
      return state.searchType;
  }
};

export const stopSearchStates = (
  state: CollinearRoutesState, 
  action: CollinearRoutesActionTypes
) => {
  switch (action.type) {
    case Types.SET_ROUTE_DATA:
      return ({
        ...state.stopSearchStates,
        routeData: action.payload.routeData,
      });

    case Types.RESET_ROUTE_DATA:
      return ({
        ...state.stopSearchStates,
        routeData: null,
      });

    case Types.SET_GO_BACK:
      return ({
        ...state.stopSearchStates,
        goBack: action.payload.goBack,
      });

    default:
      return state.stopSearchStates;
  }
};