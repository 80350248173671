import { Schedule, SingleProvider, GoBack, SinglePeak, Headway, SingleSchedule } from "bus-common-types";
import { getGoBackChoice } from "constants/functions";
import { seperateData, getGoBackResultByResultType } from "lib/seperateDataHelpers";
import { goOrBackObj } from "config";
import { SinglePeakTimeData } from "../HeadwayInterval";
import { HeadwayNotNull } from "../HeadwayPart";
import { SingleTimeTablePartData } from "../TimeTablePart";
import { SingleScheduleForTimeTable } from "../types";

export type TimeTableData = {
  departure: string
  destination: string
  schedule: Schedule
  providers: SingleProvider[]
}

export const getLinkedStr = (a: string | number, b: string | number) => (
  `${a}-${b}`
);

export class handlePeakTimes {
  static getSinglePeakTimeStr = (singlePeak: SinglePeak) => {
    return getLinkedStr(singlePeak.startTime, singlePeak.endTime);
  }

  static getPeakTimesIntervalStr = (singlePeak: SinglePeak) => {
    return getLinkedStr(singlePeak.lowerLimit, singlePeak.upperLimit);
  }

  static makePeakTimeDataWithDataMoreThanOne = (peakTimes: SinglePeak[]) => {
    const singlePeakTime = peakTimes[0];
    return ({
      peakType: singlePeakTime.peakType,
      peakTimes: peakTimes.map(t => (
        handlePeakTimes.getSinglePeakTimeStr(t)
      )),
      peakTimesInterval: handlePeakTimes.getPeakTimesIntervalStr(singlePeakTime),
    });
  }

  static makePeakTimeData = (peakTimes: SinglePeak[]): SinglePeakTimeData => {
    if(peakTimes.length === 0) {
      return null;
    } else {
      return handlePeakTimes.makePeakTimeDataWithDataMoreThanOne(peakTimes);
    }
  }

  static getPeakAndNoPeakTimes = (peakTimes: SinglePeak[]) => {
    const seperateDataByPeakType = seperateData.byKeyValue(peakTimes)('peakType');
    const peakTimes_peak = seperateDataByPeakType(1);
    const peakTimes_noPeak = seperateDataByPeakType(2);
    return [
      handlePeakTimes.makePeakTimeData(peakTimes_peak),
      handlePeakTimes.makePeakTimeData(peakTimes_noPeak)
    ];
  }

  static peakNoPeakTimesSeperateByGoBack = (peakTimes: SinglePeak[]) => {
    const goBackPeakTimes = seperateData.fromGoBackData(peakTimes)(getGoBackResultByResultType)('obj');
    return ({
      go: handlePeakTimes.getPeakAndNoPeakTimes(goBackPeakTimes.go),
      back: handlePeakTimes.getPeakAndNoPeakTimes(goBackPeakTimes.back),
    });
  }
}


export class handleTimeTable {
  static getGoBackSchedules = (schedules: SingleSchedule[]) => {
    return seperateData.fromGoBackData(schedules)(getGoBackResultByResultType)('obj');
  }
  static getGoBackLastStation = (destination: string, departure: string) => {
    const lastStatiobGoBackChoices = {
      go: destination,
      back: departure
    };
    return getGoBackChoice(lastStatiobGoBackChoices);
  }
  static getFirstBusGoBack = (headway: Headway) => {
    return {
      go: headway.firstBusGo,
      back: headway.firstBusBack,
    };
  }
  static getLastBusGoBack = (headway: Headway) => {
    return {
      go: headway.lastBusGo,
      back: headway.lastBusBack,
    };
  }

  static isHideHeadway = (scheduleHeadway: Schedule['headway']) => {
    return !scheduleHeadway.weekday && !scheduleHeadway.holiday;
  }

  static makeHeadwayDataWithNotNullData(headway: Headway) {
    const firstBusGoBack = this.getFirstBusGoBack(headway);
    const lastBusGoBack = this.getLastBusGoBack(headway);
    const goBackPeaks = handlePeakTimes.peakNoPeakTimesSeperateByGoBack(headway.peaks);

    return (goBack: GoBack): HeadwayNotNull=> {
      const goBackStr = goOrBackObj[goBack];
      return ({
        first: firstBusGoBack[goBackStr],
        last: lastBusGoBack[goBackStr],
        peaks: goBackPeaks[goBackStr]
      });
    };
  }

  static makeHeadwayData = (headway: Headway | null) => {
    if(headway) {
      return handleTimeTable.makeHeadwayDataWithNotNullData(headway);
    }
    return (goBack: GoBack) => null;
  }

  static getData = (timeTableData: TimeTableData) => (goBack: GoBack) => {
    const { schedule, providers, destination, departure } = timeTableData;
    const { weekday, holiday } = schedule.headway;
    const goBackStr = goOrBackObj[goBack];

    const schedules = handleTimeTable.getGoBackSchedules(schedule.schedules)[goBackStr];
    const lastStation = handleTimeTable.getGoBackLastStation(destination, departure)(goBack);

    return ({
      lastStation,
      providers,
      schedules,
      headway: {
        hideHeadWay: handleTimeTable.isHideHeadway(schedule.headway),
        weekday: handleTimeTable.makeHeadwayData(weekday)(goBack),
        holiday: handleTimeTable.makeHeadwayData(holiday)(goBack)
      }
    });
  }

  static getResult = (timeTableData: TimeTableData): SingleTimeTablePartData[] => {
    const getDataByGoBack = handleTimeTable.getData(timeTableData);
    return [getDataByGoBack(1), getDataByGoBack(2)];
  }

  static appendProvidersToSchedules(data: SingleTimeTablePartData): SingleScheduleForTimeTable[] {
    const providerId = data.providers.length > 0 ? Number(data.providers[0].id) : -1;
    const res = data.schedules.map(s => ({
      ...s,
      providerId,
    }));
    return res;
  }
}

