import { BUS_COMMON_STATIONS_CENTER_PART_SPLIT_INDEXES } from "components/mrt-fare/config";
import { SingleBasicStationForMrtAndBus } from "mrt-pages";

const splitStationsForCommonPart = (stationListData: SingleBasicStationForMrtAndBus[]) => {
  const leftStations = stationListData.slice(
    0, 
    BUS_COMMON_STATIONS_CENTER_PART_SPLIT_INDEXES[0]
  );
  const centerStations = stationListData.slice(
    BUS_COMMON_STATIONS_CENTER_PART_SPLIT_INDEXES[0],
    BUS_COMMON_STATIONS_CENTER_PART_SPLIT_INDEXES[1]
  );
  const rightStations = stationListData.slice(BUS_COMMON_STATIONS_CENTER_PART_SPLIT_INDEXES[1]);

  return ({
    leftStations,
    centerStations,
    rightStations,
  });
};

export default splitStationsForCommonPart;