import { useSelectorSelect } from "lib/customHooks/useSelectorSelect";
import useSelectsValues from "lib/customHooks/useSelectsValues";
import { useEffect, useMemo } from "react";
import { StopsSelectorContainerProps } from "../types";

export interface UseStopsSelectorOptions extends StopsSelectorContainerProps {
  
}

const useStopsSelector = ({
  options,
  onSetStationMetaData,
}: UseStopsSelectorOptions) => {
  const selectState = useSelectorSelect({
    stop: '',
  });
  const selectedStopId = Number(selectState.values.stop);
  
  const metaData = useMemo(() => {
    const matched = options.find(o => String(o.value) === String(selectState.values.stop));
    if(matched) {
      return matched.metaData;
    }
    return undefined;
  }, [options, selectState.values.stop]);

  useEffect(() => {
    metaData && onSetStationMetaData(metaData);
  }, [metaData, onSetStationMetaData]);

  return ({
    handleChange: selectState.handleSelect('stop'),
    value: selectState.values.stop,
  });
};

export default useStopsSelector;