import React, { memo } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import MrtFareButton from './MrtFareButton';
import { MrtStationItemProps } from './types';
import TransportationIcon from './TransportationIcon';
import MrtStationNameStr from './MrtStationNameStr';
import checkDirectionVertical from 'components/mrt-fare/lib/functions/checkDirectionVertical';

const useStyles = makeStyles<Theme, MrtStationItemProps>(theme => ({
  root: props => {
    const isVertical = checkDirectionVertical(props.direction);
    // const flexDirection = 
    
    return ({
      display: 'flex',
      position: 'relative',
      top: props.direction === 'top' ? `${-77}%` : 'unset',
      right: props.stationType === 'INTERSECTION' ? 1000000 : 'unset',
      flexDirection: isVertical ? 'column' : 'unset',
      alignItems: 'center',
      justifyContent: props.direction === 'top' ? 'flex-end' : undefined,
      paddingRight: isVertical ? props.padding : 0,
      paddingBottom: !isVertical ? props.padding : 0,
      opacity: props.stationType === 'INTERSECTION' ? 0 : 100,
      // transform: props.direction === 'top' ? `translateY(${-77}%)` : 'unset',
    });
  },
}));

const MrtStationItem = (props: MrtStationItemProps) => {
  const classes = useStyles(props);
  const isTop = props.direction === 'top';

  return (
    <Box 
      className={classes.root} 
    >
      {isTop ? (
        <>  
          <MrtStationNameStr {...props} />
          <MrtFareButton {...props} />
        </>
      ) : (
        <>
          <MrtFareButton {...props} />
          <MrtStationNameStr {...props} />
        </>
      )}
      <>
        {props.nearTransportations.map((t) => (
          <TransportationIcon 
            key={t} 
            mrtTransportationType={t} 
          />
        ))}
      </>
    </Box>
  );
};

export default memo(MrtStationItem);