import { TitleProps } from "components/home-page/title";
import { SingleOtherLink } from "../types";
import { SingleBlog, SingleQRcode } from "blog";
import routes from "constants/routes";

export const titleData_blog: TitleProps = {
  titleZh: '最新消息',
  titleEng: 'News'
};

export const titleData_qrcode: TitleProps = {
  titleZh: '臺中公車App',
  titleEng: 'Links'
};

export const googlePlayAppUrl = 'https://play.google.com/store/apps/details?id=tms.tw.publictransit.TaichungCityBus';
export const appStoreAppUrl = 'https://apps.apple.com/tw/app/%E5%8F%B0%E4%B8%AD%E5%85%AC%E8%BB%8A/id590226800?l=zh';

const googlePlayQRCode: SingleQRcode = {
  url: googlePlayAppUrl,
  qrcodeSrc: `https://chart.googleapis.com/chart?cht=qr&chl=${googlePlayAppUrl}&chs=150x150`,
  qrcodeBaseImgSrc: './asset/images/icons/app_android.png'
};

const appStoreQRCode: SingleQRcode = {
  url: appStoreAppUrl,
  qrcodeSrc: `https://chart.googleapis.com/chart?cht=qr&chl=${appStoreAppUrl}&chs=150x150`,
  qrcodeBaseImgSrc: './asset/images/icons/app_ios.png'
};

export const qrcodesData = [
  googlePlayQRCode,
  appStoreQRCode,
];

export const otherLinks: SingleOtherLink[] = [
  {
    langKey: 'link.1',
    link: '/other-links',
  },
  {
    langKey: 'link.3',
    link: routes.routeMapListPage,
  },
  {
    langKey: 'link.4',
    link: routes.stopReportPage,
  },
];