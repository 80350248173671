import React from 'react';
import { Box } from '@material-ui/core';
import RouteListEstimateTimeItem, { EstimateTimeProps } from './RouteListEstimateTimeItem';
import EstimateTimeHandler from 'constants/EstimateTimeHandler';
import { EstimateTimeHandlerOptions } from 'bus-common-types';

interface RouteListEstimateTimeItemContainerProps extends EstimateTimeHandlerOptions, Pick<EstimateTimeProps, 'zoomRatio'> {

}

const RouteListEstimateTimeItemContainer = (props: RouteListEstimateTimeItemContainerProps) => {
  const estimateTimeProps = EstimateTimeHandler.getEstimateTime(props);
  return (
    <RouteListEstimateTimeItem {...estimateTimeProps} zoomRatio={props.zoomRatio} />
  );
};

export default RouteListEstimateTimeItemContainer;