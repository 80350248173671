import React from 'react';
import { Box } from '@material-ui/core';
import IntlFormattedMessage from './IntlFormattedMessage';

export const PageIsLoading = () => {
  return (
    <IntlFormattedMessage 
      variant={'h4'}
      style={{
        color: '#fff',
        opacity: 0.75,
      }}
      langKey={'page.isLoading'}
    />
  );
};
const LoadingPage = () => {
  return (
    <Box
      padding={2}
      textAlign={'center'}
    >
      <PageIsLoading />
    </Box>
  );
};

export default LoadingPage;