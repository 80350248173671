import React from 'react';
import { Box, Divider, List, makeStyles } from '@material-ui/core';
import { headerInfo } from 'components/collinear-routes/config';
import StopSearchRouteInfoItem from './StopSearchRouteInfoItem';
import { StopSearchRouteInfoListProps } from './types';

const useStyles = makeStyles({
  root: {
    overflowY: 'scroll',
    flexGrow: 1,
    height: 1
  }
});

export const StopSearchRouteInfoList = (props: StopSearchRouteInfoListProps) => {
  const classes = useStyles();
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
    >
      <StopSearchRouteInfoItem {...headerInfo} />
      <Divider />
      <List className={classes.root}>
        {props.routeInfoListData.map((routeInfo, i) => (
          <StopSearchRouteInfoItem
            key={i}
            {...routeInfo} />
        ))}
      </List>
    </Box>
  );
};



export default StopSearchRouteInfoList;