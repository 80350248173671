import { ID } from "all-common-types";
import { GoBack } from "bus-common-types";
import { BUS_309_ROUTE_ID, INTERSECTION_STATION_SID, MRT_GREEN_ROUTE_ID } from "components/mrt-fare/config";
import useQueryMrtFares from "components/mrt-fare/gql/custom-hooks/useQueryMrtFares";
import { QueryMrtFaresParams } from "components/mrt-fare/gql/schema.gql";
import checkStationContentMode from "components/mrt-fare/lib/functions/checkStationContentMode";
import { HandledStations } from "components/mrt-fare/lib/Handlers/MrtStationsCombineHandlers";
import { IntersectionStationContents, StationContentMode, StationType } from "mrt-pages";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import MrtStationsFareHandlers from "../functions/MrtStationsFareHandlers";
import useHandleMrtTravelTimes from "./useHandleMrtTravelTimes";
import AccumulateTwoRouteTypeContentHandlers from '../functions/AccumulateTwoRouteTypeContentHandlers';

export interface HandledStationsWithIntersectionContents extends HandledStations {
  intersectionContents: IntersectionStationContents
}

export interface UseMrtStationsFaresOptions {
  goBack: GoBack
  selectedRouteId: number
  selectedContentMode: StationContentMode
  selectedStationType: StationType | undefined
  selectedStationId: number
  initHandledStations: HandledStationsWithIntersectionContents
}

export const initFareParams: QueryMrtFaresParams = ({
  busFromStationId: 0,
  mrtFromStationId: 0,
});

export const getFromStationId = ({
  goBack,
  selectedStationType,
  selectedStationId,
}: Pick<UseMrtStationsFaresOptions, 'goBack' | 'selectedStationType' | 'selectedStationId'>): {
  busFromStationId: number,
  mrtFromStationId: number
} => {
  const index = goBack - 1;

  switch (selectedStationType) {
    case 'BUS':
      return ({
        busFromStationId: selectedStationId,
        mrtFromStationId: INTERSECTION_STATION_SID.MRT_GREEN[index],
      });

    case 'MRT_GREEN':
      return ({
        busFromStationId: INTERSECTION_STATION_SID['BUS'][index],
        mrtFromStationId: selectedStationId,
      });

    case 'INTERSECTION':
      return ({
        busFromStationId: INTERSECTION_STATION_SID['BUS'][index],
        mrtFromStationId: INTERSECTION_STATION_SID['MRT_GREEN'][index],
      });

    default:
      return ({
        busFromStationId: 0,
        mrtFromStationId: 0,
      });
  }
};

export const getTravelTimeRouteId = (routeId: ID) => {
  const numRouteId = Number(routeId);
  if(numRouteId === MRT_GREEN_ROUTE_ID) {
    return BUS_309_ROUTE_ID;
  }
  return numRouteId;
};

const useMrtStationsFares = (options: UseMrtStationsFaresOptions) => {
  const {
    goBack,
    selectedStationType,
    selectedContentMode,
    selectedStationId,
    initHandledStations,
  } = options;

  const mode = checkStationContentMode(selectedContentMode);
  const [fareParams, setFareParams] = useState<QueryMrtFaresParams>(initFareParams);
  const [handledStationsWithContent, setStations] = useState(initHandledStations);
  const {
    busFromStationId,
    mrtFromStationId,
  } = fareParams;

  const {
    data,
    handleQuery,
  } = useQueryMrtFares();

  useHandleMrtTravelTimes({
    ...options,
    busFromStationId,
    mrtFromStationId,
    setStationsFn: setStations,
  });

  const stationsWithFaresFn = useMemo(() => (
    MrtStationsFareHandlers.combineStationsWithFaresRawData(
      initHandledStations, data, goBack
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [JSON.stringify(data), goBack, JSON.stringify(initHandledStations)]);
  const handledStationsWithFareContent = useMemo(() => (
    stationsWithFaresFn(selectedContentMode, selectedStationType as any)
  ), [selectedContentMode, selectedStationType, stationsWithFaresFn]);

  useEffect(() => {
    if(mode === 'FARE') {
      setStations(handledStationsWithFareContent);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, JSON.stringify(handledStationsWithFareContent)]);

  useEffect(() => {
    setFareParams(initFareParams);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goBack]);

  useEffect(() => {
    const params = getFromStationId({
      goBack,
      selectedStationType,
      selectedStationId,
    });
    setFareParams(params);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStationId, selectedStationType]);

  useEffect(() => {
    if(mode === 'FARE') {
      handleQuery(busFromStationId, mrtFromStationId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, busFromStationId, mrtFromStationId]);

  const {
    formatMessage,
  } = useIntl();
  const intersectionStationFareContent = formatMessage({
    id: 'mrtFare.station.intersection',
  }, {
    ...handledStationsWithContent.intersectionContents,
  });

  const handledDifferentTypeAccumulatedStationsWithContent = useMemo(() => (
    AccumulateTwoRouteTypeContentHandlers.accumulateStationsWithDiffirentTypeSations({
      mode,
      stations: handledStationsWithContent,
      goBack,
      selectedStationId,
      selectedStationType,
    })
  ), [goBack, handledStationsWithContent, mode, selectedStationId, selectedStationType]);

  return ({
    handledStationsWithContent: handledDifferentTypeAccumulatedStationsWithContent,
    intersectionStationFareContent,
  });
};

export default useMrtStationsFares;