import { ID } from "all-common-types";
import ContextWrapperFn from 'react-function-helpers/lib/functions/ContextWrapper';
import { createContextValueFn, createContextStore } from "react-function-helpers/lib/functions/contextHelpers";
import reducers from "../reducers";

export interface RouteMapState {
  routeId: ID | null
}

const initRouteId = null;

export const initRouteMapState: RouteMapState = {
  routeId: initRouteId,
};

export interface Contenxt {
  dispatch: (x: any) => any
  state: RouteMapState
}

export const ContextValue = createContextValueFn(initRouteMapState, reducers);
const ContextStore = createContextStore(initRouteMapState);
export default ContextStore;

export const ContextWrapper = ContextWrapperFn(ContextValue, ContextStore);