import React from 'react';
import { Box } from '@material-ui/core';
import { RouteNameTitle, RouteMapImage, FromToStopListWithTitleList, ProviderInfo, RouteMapDownload } from './index';
import { RouteMapContentPartProps } from './types';
import LoadingAndError from 'components/common-components/LoadingAndError';

const RouteMapContentPart = (props: RouteMapContentPartProps) => {
  const {
    loading, routeMapContentPartData
  } = props;
  
  if(loading) {
    return (
      <LoadingAndError loading={true} error={undefined} />
    );
  } 
  
  return (
    <Box paddingTop={1}>
      {/* <RouteNameTitle
        {...routeMapContentPartData.routeNameTitleData}
      /> */}
      <Box paddingTop={1} />
      <RouteMapImage
        {...routeMapContentPartData.routeMapImage} />
      <Box paddingTop={1} />
      <FromToStopListWithTitleList
        {...routeMapContentPartData}
        stopList={routeMapContentPartData.stops} 
      />
      {/* <ProviderInfo
        {...routeMapContentPartData.providerInfo} 
      /> */}
      <RouteMapDownload 
        {...routeMapContentPartData.routeMapImage}
        routeId={routeMapContentPartData.id} 
      />
    </Box>
  );
};

export default RouteMapContentPart;