import combineReducers from "constants/combineReducers";
import { CollinearRoutesState } from "../constants/context";
import { selectedRouteId_reducer, stopSearchFromTo_reducer, selectedFromStation } from "./search-reducers";
import { searchType, stopSearchStates } from './stop-search-states-reducers';

const reducers = combineReducers<CollinearRoutesState>({
  selectedRouteId: selectedRouteId_reducer,
  intervalSearchSelectedFromTo: stopSearchFromTo_reducer,
  searchType,
  stopSearchStates,
  selectedFromStation,
});

export default reducers;