import { ID } from "all-common-types";
import { GoBack } from "bus-common-types";
import { SingleBasicStationForMrtAndBus, StationContentSimpleMode } from 'mrt-pages';
import { INTERSECTION_STATION_SID } from "components/mrt-fare/config";
import { HandledStationsWithIntersectionContents, UseMrtStationsFaresOptions } from "../custom-hooks/useMrtStationsFares";

export interface AccumulateStationsWithDiffirentTypeSationsOptions {
  mode: StationContentSimpleMode
  selectedStationId: number
  stations: HandledStationsWithIntersectionContents
  goBack: GoBack
  selectedStationType: Pick<UseMrtStationsFaresOptions, 'selectedStationType'>['selectedStationType']
}

const AccumulateTwoRouteTypeContentHandlers = {
  getBusIntersectionStationIndex(stations: HandledStationsWithIntersectionContents) {
    const busIntersectionStationIdx = stations.commonBusStations.findIndex(s => (
      INTERSECTION_STATION_SID.BUS.includes(s.stationId)
    ));
    return busIntersectionStationIdx;
  },
  getMrtGreenIntersectionStationIndex(stations: HandledStationsWithIntersectionContents) {
    const mrtIntersectionStationIdx = stations.mrtGreenStations.findIndex(s => (
      INTERSECTION_STATION_SID.MRT_GREEN.includes(s.stationId)
    ));
    return mrtIntersectionStationIdx;
  },

  addContentWithInterSectionContent(stationContent: ID, intersectionContent: ID) {
    // 忽略空的content
    return stationContent !== '' ? String(Number(stationContent) + Number(intersectionContent)) : stationContent;
  },

  // 選擇的是bus的站點
  accumulateMrtContentsToBusStations({
    selectedStationId, stations, goBack,
  }: AccumulateStationsWithDiffirentTypeSationsOptions) {
    let res = {
      ...stations,
    };
    const busIntersectionStationIndex = this.getBusIntersectionStationIndex(stations);
    const { intersectionContents, } = stations;
    const selectedBusIdx = stations.commonBusStations
      .findIndex(s => s.stationId === selectedStationId);

    let shouldAccumulation = false;
    // --couldBeRafactored--
    if(goBack === 1) {
      shouldAccumulation = 
        selectedBusIdx === -1 || selectedBusIdx < busIntersectionStationIndex;
    } else {
      shouldAccumulation = selectedBusIdx > busIntersectionStationIndex;
    }

    if(shouldAccumulation) {
      res.mrtGreenStations = res.mrtGreenStations.map(s => ({
        ...s,
        content: this.addContentWithInterSectionContent(s.content, intersectionContents.bus),
      }));
    }
    
    return res;
  },
  accumMrtContentToBusStationsMapFn<Station extends SingleBasicStationForMrtAndBus>(mrtGreenContent: ID) {
    return (station: Station) => ({
      ...station,
      content: this.addContentWithInterSectionContent(station.content, mrtGreenContent),
    });
  },
  accumulateBusContentsToMrtStations({
    stations, goBack,
  }: Pick<AccumulateStationsWithDiffirentTypeSationsOptions, 'stations' | 'goBack'>) {
    let res = {
      ...stations,
    };
    const { intersectionContents, } = stations;
    const accumMapFn = this.accumMrtContentToBusStationsMapFn(intersectionContents.mrtGreen);

    if(goBack === 1) {
      res.commonBusStations = stations.commonBusStations.map(accumMapFn);
    } else {
      res.commonBusStations = stations.commonBusStations.map(accumMapFn);
      res.route309OtherStations = stations.route309OtherStations.map(accumMapFn);
      res.route310OtherStations = stations.route310OtherStations.map(accumMapFn);
    }
    
    return res;
  },

  accumulateStationsWithDiffirentTypeSations(options: AccumulateStationsWithDiffirentTypeSationsOptions) {
    const {
      stations, selectedStationType, mode
    } = options;

    if(!selectedStationType || mode !== 'TRAVEL_TIME') {
      return stations;
    } else {
      switch (selectedStationType) {
        case 'BUS':
          return this.accumulateMrtContentsToBusStations(options);
        case 'MRT_GREEN':
          return this.accumulateBusContentsToMrtStations(options); 
        default:
          return stations;
      }
    }
  },
};

export default AccumulateTwoRouteTypeContentHandlers;