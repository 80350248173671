import React from "react";
import { Box } from "@material-ui/core";
import { IbusImageProps } from "./types";
import ibusImages from "static/ibusImages.json";

const IbusImage = (props: IbusImageProps) => {
  return (
    <Box
      width={`${100}%`}
      style={{
        ...props.style,
        verticalAlign: "middle",
      }}
      {...props}
    >
      <img
        width={`${100}%`}
        src={ibusImages[props.imageKey].src}
        alt={ibusImages[props.imageKey].name}
        style={{
          verticalAlign: "middle",
        }}
      />
    </Box>
  );
};

export default IbusImage;
