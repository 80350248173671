import React, { memo, useEffect } from 'react';
import { AppBar, Box, Toolbar, Button, makeStyles } from '@material-ui/core';
import { AppProps } from 'App';
import { SingleLink, Callback, SingleNotice } from 'all-common-types';
import { MenuRounded } from '@material-ui/icons';
import AppDrawerWrapper from './AppDrawer';
import { useToggle } from 'lib/customHooks';
import { SubMenuLinks, MainMenuLinks } from '../Menus';
import { useHistory } from 'react-router';
import defaultLangTitleLinks from 'static/defaultLangTitleLinks';
import zIndex from 'styles/styleObjs/zIndex';
import LogoTitle from './LogoTitle';
import NoticesContainer from '../NoticesContainer';

const checkIsHomePage = (path: string) => {
  return path === '/';
};

const useStyles = makeStyles((theme) => ({
  root: {
    // '& h4 a': {
    //   color: '#fff'
    // }
  },
  appBar: {
    background: theme.homepageColors.navBar,
    backgroundSize: `${100}%`,
    zIndex: zIndex.navBar,
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  mobileMenuButton: {
    // display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  linksPart: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    }
  }
}));


export type AppBarPartContainerProps = AppProps & {
  notices: SingleNotice[]
  mainMenuLinks: SingleLink[]
  subMenuLinks: SingleLink[]
}
export type AppBarPartProps = AppBarPartContainerProps & {
  isHomePage?: boolean
  open: boolean
  onToggle: Callback
}

const AppBarPart = (props: AppBarPartProps) => {
  const classes = useStyles();
  const {
    isHomePage,
    onToggle,
    setLocale,
    mainMenuLinks,
    subMenuLinks,
  } = props;

  //console.log(mainMenuLinks, subMenuLinks);

  return (
    <AppBar position={'sticky'} className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Box display={'flex'}>
          <Button className={classes.mobileMenuButton} onClick={onToggle}>
            <MenuRounded />
          </Button>
          <LogoTitle />
        </Box>
        <Box justifyContent={'space-between'} className={classes.linksPart}>
          <SubMenuLinks
            links={subMenuLinks}
            langTitleLocaleList={defaultLangTitleLinks}
            changeLangFn={setLocale} />
          {!isHomePage && <MainMenuLinks links={mainMenuLinks} />}
        </Box>
      </Toolbar>
      <AppDrawerWrapper {...props} onClose={onToggle}>
        <SubMenuLinks
          links={subMenuLinks}
          langTitleLocaleList={defaultLangTitleLinks}
          changeLangFn={setLocale} />
        {!isHomePage && <MainMenuLinks links={mainMenuLinks} />}
      </AppDrawerWrapper>
      <NoticesContainer />
    </AppBar>
  );
};

const AppBarPartContainer = (props: AppBarPartContainerProps) => {
  const {
    toggle, handleToggle, setToggle,
  } = useToggle();
  const { location } = useHistory();
  const isHomePage = checkIsHomePage(location.pathname);

  useEffect(() => {
    setToggle(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <AppBarPart
      {...props}
      open={toggle}
      isHomePage={isHomePage}
      onToggle={handleToggle} />
  );
};

export default memo(AppBarPartContainer);