import { SearchActionTypes } from './action-types';
import StopSearchStatesActions from './stop-search-states-actions';

export * from './search-actions';

export enum actionTypes {
  SET_SEARCH_TYPE,

  SET_ROUTE_ID,
  RESET_ROUTE_ID,

  SET_STOP_SEARCH_FROM_TO,
  RESET_STOP_SEARCH_FROM_TO,

  SET_ROUTE_DATA,
  RESET_ROUTE_DATA,

  SET_GO_BACK,

  SET_FROM_STATION,
  RESET_FROM_STATION,
}

export type CollinearRoutesActionTypes = 
  SearchActionTypes | 
  StopSearchStatesActions