import { actionTypes as Types } from '../actions';
import { SelectedRouteStopId, DrivingMapState } from '../constants/context';
import { AllActionTypes } from '../actions';

export const routeStopId = (state: DrivingMapState, action: AllActionTypes): SelectedRouteStopId => {
  switch (action.type) {
  case Types.SET_ROUTE_STOP_ID: {
    return action.routeStopId;
  }
  case Types.RESET_ROUTE_STOP_ID: {
    return undefined;
  }
  default:
    return state.selectedRouteStopIndex;
  }
};