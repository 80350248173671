import { StationColorKeys } from "mrt-pages";

export const FARE_BUTTON_WIDTH = 30;
export const PADDING_BETWEEN_STATIONS = 20;
export const PADDING_BETWEEN_CENTER_STATIONS = PADDING_BETWEEN_STATIONS * 2.2;
export const DISTANCE_BETWEEN_STATIONS = FARE_BUTTON_WIDTH + PADDING_BETWEEN_STATIONS + 2 * 2;

export const MRT_GREEN_ROUTE_ID = 988;
export const BUS_309_ROUTE_ID = 309;
export const BUS_310_ROUTE_ID = 310;

export const BUS_COMMON_STATIONS_CENTER_PART_SPLIT_INDEXES = [13 - 3, 13 + 3 + 1]; // 中間那一段stations的index起訖

export const MRT_COLORS: {
  [key in StationColorKeys]: string
} = {
  MRT_GREEN: '#0a0',
  BUS: '#0032ff',
  INTERSECTION: '#21cfcf',
};

export const COMMON_STATIONS_PART_SPECS = {
  paddingBetweenParts: 200,
  rightPartPadding: 60,
};

export const INTERSECTION_STATION_SID = {
  BUS: [8503, 8403], // go, back
  MRT_GREEN: [13915, 13915],
};