/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from "react";
import ContextStore, {
  SelectedRoute,
  DrivingMapState,
} from "components/driving-map/constants/context";
import SubRoutesSelects from "./SubRoutesSelects";
import {
  getSubRoutesData,
  getRoutesDataWithoutSubRoutes,
  MainSideRoutes,
  getMatchedRouteId,
} from "../../constants/functions";
import { resetRouteData } from "../../actions";
import {
  getRoutesSelectorOptions,
  RoutesSelectorOptions,
} from "constants/functions";
import {
  MapStateToProps,
  MapDispatchToProps,
} from "react-function-helpers/lib/functions/mapContextToProps";
import { Callback } from "all-common-types";
import { connectCtx } from "react-function-helpers";
import {
  resetSelectedRoute,
  setSelectedRoute,
  SetSelectedRouteActionPayLoad,
} from "actions/common-actions/selectedRoute-actions";
import {
  SelectorOptions,
  SingleSelectorOption,
} from "ibus-common-components/lib/components/common-components/FilterSelector/types";
import IntlFilterSelector from "components/common-components/IntlComponents/IntlFilterSelector";
import getMainSideRoutesData from "./functions/getMainSideRoutesData";
import LoadingAndError from "components/common-components/LoadingAndError";
import FoldButton from "./FoldButton";
import { Box } from "@material-ui/core";
import checkRouteIdInMainSideRouteChildren from "./functions/checkRouteIdInMainSideRouteChildren";
import { QUERY_ROUTE_STATION_ICON } from "../../gql/gql-schema";

import { cmsClient } from "constants/API";

export type RoutesSelectsProps = {
  rawAllRoutes: RoutesSelectorOptions;
  routes: RoutesSelectorOptions;
  selectedRouteId: SelectedRoute["id"];
  dispatch?: (action?: any) => any;
  setSelectedRouteFn?: (payload: SetSelectedRouteActionPayLoad) => any;
};
type RoutesSelectsStates = {
  mainRouteId: SelectedRoute["id"];
  isSubRoutesSelectsDisplay: boolean;
  mainSideRoutes:
    | import("components/driving-map/constants/functions").MainSideRoutes
    | null; //all mainSideRoutes(xno, children) data
  subRoutes: RoutesSelectorOptions | null; //temp get subRoutes
  lanternRoutes: number[];
};
//contain filter selector and subRoutesSelects

export const defaultTextAndValue = {
  text: "",
  value: "",
  name: "",
};
export const getSelectedRouteNameValue = (
  routes: SelectorOptions,
  selectedRouteId: string | null | undefined
) => {
  if (routes) {
    const matchedRoute = routes.find((data) => data.value === selectedRouteId);
    if (matchedRoute) {
      return matchedRoute;
    }
  }
  return defaultTextAndValue;
};
export const getDefaultSelectorOption = (
  routeId: string | undefined,
  mainSideRoutes: MainSideRoutes,
  routesSelectorOptions: RoutesSelectorOptions
) => {
  if (!routeId) return undefined;

  // 要在主支線去找
  const matchedRouteId = getMatchedRouteId(mainSideRoutes, routeId);
  const defaultSelectedSingleSelectorOption = routesSelectorOptions.find(
    (r) => String(r.value) === String(matchedRouteId)
  );
  return defaultSelectedSingleSelectorOption;
};

export class RoutesSelects extends React.Component<
  RoutesSelectsProps,
  RoutesSelectsStates
> {
  state = {
    mainRouteId: undefined, // store for check route id in mainSideRoutes children
    mainSideRoutes: null,
    subRoutes: null,
    isSubRoutesSelectsDisplay: true,
    lanternRoutes: [],
  } as RoutesSelectsStates;

  componentDidMount() {
    getMainSideRoutesData().then((res) => {
      this.setState((state) => ({
        ...state,
        mainSideRoutes: res,
      }));
    });

    // get lantern routes
    cmsClient
      .query({
        query: QUERY_ROUTE_STATION_ICON,
        variables: { where: { icon: "lantern" } },
      })
      .then((result) => {
        const xnos = result.data.routeStationIcons.map((icon: any) => icon.xno);
        this.setState((state) => ({
          ...state,
          lanternRoutes: xnos,
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  componentDidUpdate(
    prevProps: RoutesSelectsProps,
    prevState: RoutesSelectsStates
  ) {
    let newState = this.state;
    const { mainSideRoutes, subRoutes, mainRouteId } = this.state;
    const { rawAllRoutes, selectedRouteId } = this.props;

    const subRoutesData = getSubRoutesData(
      rawAllRoutes,
      mainSideRoutes,
      selectedRouteId
    );
    const isInChildren = checkRouteIdInMainSideRouteChildren(mainRouteId)(
      selectedRouteId,
      mainSideRoutes
    );
    // console.log(selectedRouteId, subRoutesData);
    if ((!subRoutes || selectedRouteId !== mainRouteId) && subRoutesData) {
      newState = {
        ...this.state,
        subRoutes: subRoutesData,
        mainRouteId: selectedRouteId,
      };
      this.setState(newState);
    } else if (subRoutes && !subRoutesData && !isInChildren) {
      // from subRoutes changed to no subRoutes
      newState = {
        ...this.state,
        subRoutes: null, // clear subRoutes
      };
      this.setState(newState);
    }
  }

  _handleResetSubRoutes = () => {
    const { dispatch } = this.props;
    dispatch && dispatch(resetSelectedRoute());
    // dispatch && dispatch( resetRouteStopId() );
    dispatch && dispatch(resetRouteData());
    // this.props.dispatch( resetRouteStopId() );
    this.setState({
      subRoutes: null,
    });
  };

  _handleSelectRoute = (selectedOption: SingleSelectorOption) => {
    const { routes, setSelectedRouteFn } = this.props;
    const id = selectedOption.value;
    setSelectedRouteFn &&
      setSelectedRouteFn({
        id,
        routes,
      });
  };

  handleToggleSubRoutesSelectsDisplay = () => {
    this.setState((s) => ({
      ...s,
      isSubRoutesSelectsDisplay: !s.isSubRoutesSelectsDisplay,
    }));
  };
  //
  render() {
    const {
      subRoutes,
      mainSideRoutes,
      isSubRoutesSelectsDisplay,
      lanternRoutes,
    } = this.state;
    const { routes, selectedRouteId } = this.props;
    const routesWithoutSubRoutes = mainSideRoutes
      ? getRoutesDataWithoutSubRoutes(mainSideRoutes, routes)
      : [];
    const routesWithoutSubRoutesHaveLantern = routesWithoutSubRoutes.map(
      (route) => ({
        ...route,
        icon: lanternRoutes.includes(parseInt(route.id, 10))
          ? "icons_lantern_route"
          : undefined,
      })
    );
    const selectedRouteNameValue = getSelectedRouteNameValue(
      routes,
      selectedRouteId
    );

    if (!mainSideRoutes) {
      return <LoadingAndError error={undefined} loading={true} />;
    }

    const defaultSelectedSingleSelectorOption = getDefaultSelectorOption(
      selectedRouteId,
      mainSideRoutes,
      routesWithoutSubRoutes
    );
    //
    return (
      <>
        <Box display={"flex"} alignItems={"center"}>
          <IntlFilterSelector
            defaultSelectedSingleSelectorOption={
              defaultSelectedSingleSelectorOption
            }
            getSelectedOptionFn={this._handleSelectRoute}
            options={routesWithoutSubRoutesHaveLantern}
          />
          {subRoutes && (
            <FoldButton
              isFold={isSubRoutesSelectsDisplay}
              onToggleFold={this.handleToggleSubRoutesSelectsDisplay}
            />
          )}
        </Box>
        {/* { !subRoutes &&
          <IntlFilterSelector
            getSelectedOptionFn={this._handleSelectRoute}
            options={ routesWithoutSubRoutes }
          />
        } */}
        {subRoutes && (
          <SubRoutesSelects
            {...this.props}
            resetSelectedRouteFn={this._handleResetSubRoutes}
            selectedRoute={selectedRouteNameValue.name}
            subRoutesData={subRoutes}
            isSubRoutesSelectsDisplay={isSubRoutesSelectsDisplay}
            toggleSubRoutesSelectsDisplayFn={
              this.handleToggleSubRoutesSelectsDisplay
            }
          />
        )}
      </>
    );
  }
}

export type RoutesSelectsWithCtxProps = {
  rawAllRoutes: RoutesSelectorOptions;
  routes: ReturnType<typeof getRoutesSelectorOptions>;
};

const mapStateToProps: MapStateToProps<
  DrivingMapState,
  RoutesSelectsWithCtxProps,
  {
    selectedRouteId: DrivingMapState["selectedRoute"]["id"];
  }
> = (state) => {
  return {
    selectedRouteId: state.selectedRoute.id,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  RoutesSelectsWithCtxProps,
  {
    dispatch?: Callback;
    setSelectedRouteFn?: (payload: SetSelectedRouteActionPayLoad) => any;
  }
> = (dispatch) => {
  return {
    dispatch,
    setSelectedRouteFn: (payload) => {
      dispatch(setSelectedRoute(payload));
    },
  };
};

const RoutesSelectsWithCtx = connectCtx(ContextStore)(
  mapStateToProps,
  mapDispatchToProps
)(RoutesSelects);

export default memo(RoutesSelectsWithCtx);
