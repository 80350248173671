import { GoBack } from "bus-common-types";
import { DepDestTabListProps } from "components/common-components/TabComponents/types";
import StopReportHandlers from "components/stop-report-page/lib/Handlers/StopReportHandlers";
import useGoBackTab from "lib/customHooks/useGoBackTab";
import { useEffect, useMemo } from "react";
import { DepDestWithInitGoBack } from "../types";

export interface UseGoBackButtonsOptions {
  depDest: DepDestWithInitGoBack
  onSetGoBack: (goBack: GoBack) => any
}

const useGoBackButtons = ({
  depDest,
  onSetGoBack,
}: UseGoBackButtonsOptions) => {
  const goBackTabState = useGoBackTab({
    initGoBack: depDest.initGoBack,
  });

  const selectedDepDest = useMemo(() => (
    StopReportHandlers.getDepDest(depDest, goBackTabState.goBack)
  ), [depDest, goBackTabState.goBack]);

  useEffect(() => {
    onSetGoBack(goBackTabState.goBack);
  }, [goBackTabState.goBack, onSetGoBack]);

  return ({
    ...goBackTabState,
    depDest,
    selectedDepDest,
  });
};

export default useGoBackButtons;