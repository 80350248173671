import { Reducer } from "all-common-types";
import CommonStateTypes, { initCommonState } from "reducers/common-reducers/state";
import IbusCommonActionTypes, { IbusCommonActions } from 'actions/common-actions';

type SelectedRouteReducer = Reducer<
{
  selectedRoute: CommonStateTypes['selectedRoute']
}, 
IbusCommonActions, 
CommonStateTypes['selectedRoute']
>

export const selectedRoute: SelectedRouteReducer = (state, action) => {
  switch (action.type) {
    case IbusCommonActionTypes.SET_SELECTED_ROUTE: {
      const { id, routes } = action.payload;
      const selectedRoute = routes.find(route => route.id === id);
      if(selectedRoute) {
        return ({
          id,
          name: selectedRoute.name,
        });
      }
      return state.selectedRoute;
    }

    case IbusCommonActionTypes.RESET_SELECTED_ROUTE: {
      return initCommonState.selectedRoute;
    }
    
    default:
      return state.selectedRoute;
  }
};