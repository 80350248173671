import React from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { ButtonsWrapperProps } from './types';
import SwitchButton from '../Buttons/SwitchButton';
import SearchButton from '../Buttons/SearchButton';
import zIndex from 'styles/styleObjs/zIndex';

const ButtonsWrapper = (props: ButtonsWrapperProps) => {
  const {
    loading,
    children,
    exchangeValueFn,
    searchFn,
  } = props;

  return (
    <Box display={'flex'}>
      <Box width={'100%'}>
        {children}
      </Box>
      <Box
        paddingTop={1}
        paddingLeft={2}
        style={{
          zIndex: 1000000 + 1,
        }}
      >
        <Box
          paddingBottom={1}
        >
          <SwitchButton 
            onSwitch={exchangeValueFn}
          />
        </Box>
        {loading ? (
          <CircularProgress />
        ): (
          <SearchButton 
            onSearch={searchFn}
          />
        )}
      </Box>
    </Box>
  );
};

export default ButtonsWrapper;