import React from 'react';
import { Box } from '@material-ui/core';
import IntlButton from 'components/common-components/IntlComponents/IntlButtons';
import { ControlsButtonProps } from './types';
import { theme_default } from 'theme/theme';

const ControlsButton = (props: ControlsButtonProps) => {
  return (
    <IntlButton 
      {...props}
      color={ 'primary' }
      variant={ 'contained' }
      style={{ color: theme_default.palette.common.white }}
    />
  );
};

export default ControlsButton;