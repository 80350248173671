import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { FromToStopListItemWithTitleListProps } from './types';
import { seperateData } from 'lib/seperateDataHelpers';
import { FromToStopListItemWithTitle } from '.';

const FromToStopListWithTitleList = (props: FromToStopListItemWithTitleListProps) => {
  const goBackStopList = seperateData.makeGoBackDataToObj(props.stopList);
  return (
    <Box>
      <FromToStopListItemWithTitle
        {...props}
        stopList={goBackStopList.go}
        {...props.goTitle} 
      />
      <Divider />
      <FromToStopListItemWithTitle
        {...props}
        stopList={goBackStopList.back}
        {...props.backTitle} 
      />
    </Box>
  );
};

export default FromToStopListWithTitleList;