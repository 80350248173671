import React from 'react';
import { Box } from '@material-ui/core';
import MapIcon from 'components/driving-map/components/CommonComponents/MapIcon';
import TravelTimePopup, { TravelTimePopupProps } from './TravelTimePopup';
import { Position, GoBack } from 'bus-common-types';


export type SingleStopMarker = {
  index: number
  goBack: GoBack
  position: Position
} & TravelTimePopupProps
const StopMarker = (props: SingleStopMarker) => {
  const {
    index,
  } = props;
  return (
    <MapIcon 
      {...props} 
      goBack={props.goBack} 
      number={index + 1}
    >
      <TravelTimePopup {...props}/>
    </MapIcon>
  );
};

export default StopMarker;