import React, { ReactNode } from 'react';
import { ListItem, Divider, Grid, makeStyles } from '@material-ui/core';
import ListItemTextInGrid from 'components/common-components/ListItemTextInGrid';
import { GridSize } from '@material-ui/core/Grid';
import { Callback } from 'all-common-types';

export interface CommonListItemProps {
  isButton?: boolean
  isSelected?: boolean
  zoomRatio?: number
  textsInList: ReactNode[]
  textsWidthRatios: GridSize[]
  onClick?: Callback
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
}));

const CommonListItem = (props: CommonListItemProps) => {
  const {
    isButton,
    isSelected,
    zoomRatio=1,
    textsInList,
    textsWidthRatios,
    onClick,
  } = props;
  const classes = useStyles();

  return (
    <>
      <ListItem
        className={classes.root}
        onClick={ onClick }
        button={!!isButton as any}
        selected={!!isSelected}
      >
        <Grid container>
          {textsInList.map((text, i) => (
            <ListItemTextInGrid
              key={i}
              xs={textsWidthRatios[i]}
              zoomRatio={zoomRatio}
              primary={ text } />
          ))}
        </Grid>
      </ListItem>
      <Divider />
    </>
  );
};

export default CommonListItem;