import DisplayModeHandlers, { DisplayModeType } from "lib/Handlers/DisplayModeHandlers";
import { useCallback } from "react";

const useDisplayModeToggleButton = () => {
  const displayMode = DisplayModeHandlers.getDisplayMode();

  const handleToggleDisplayMode = useCallback(() => {
    const newDisplayMode: DisplayModeType = displayMode === 'PC' ? 'RWD' : 'PC';
    DisplayModeHandlers.setAndReload(newDisplayMode);
  }, [displayMode]);

  return ({
    displayMode,
    handleToggleDisplayMode,
  });
};

export default useDisplayModeToggleButton;