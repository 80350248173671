import { Links, SingleLink } from "all-common-types";
import { SingleBlog } from "blog";
import handleRouteMatched, { PathsAndLinks } from "./handleRouteMatched";
import { MatchedRoute } from "components/common-components/breadCrumbs";

class handleLinkAndBlogMatch extends handleRouteMatched {
  static getBlogMatched = (blogs: SingleBlog[]) => (params: PathsAndLinks) => {
    const blogMatched = blogs.find(blog => String(blog.id) === String(params.splitpath));
    return blogMatched ? blogMatched.title : false;
  }
  
  static getMatchedRouteWithBlogs(blogs: SingleBlog[]) {
    return (params: PathsAndLinks): MatchedRoute => {
      const matchFn = this.getBlogMatched(blogs);
      return this.getMatchedRoute(matchFn)(params);
    };
  } 

  static getMatchedRouteListWithBlogs(blogs: SingleBlog[]) {
    const matchedRouteFn = this.getMatchedRouteWithBlogs(blogs);
    return this.getMatchedRouteList(matchedRouteFn);
  }
}

export default handleLinkAndBlogMatch;