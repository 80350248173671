import { GetTravelPlanByCombinedFromToAPIParams, getTravelPlanByCombinedFromToAPI } from "../constant/API";
import { OtpItinerary, OtpPosition, OtpLeg } from "travel-planner";

const getTravelPlanByFromTo = async (params: GetTravelPlanByCombinedFromToAPIParams) => {
  const api = getTravelPlanByCombinedFromToAPI(params);
  try {
    const { fromPosition, toPosition, fromName, toName } = params;
    const [from, to]: OtpPosition[] = [0,1].map(idx => {
      const position = idx === 0 ? fromPosition : toPosition;
      const name = idx === 0 ? fromName : toName;
      const [lat, lon] = typeof position === 'string' ? position.split(',') : position;
      return {
        name: name || "",
        lat: typeof lat === 'string' ? parseFloat(lat) : lat,
        lon: typeof lon === 'string' ? parseFloat(lon) : lon,
      };
    });

    const res = await fetch(api, {mode: 'cors'});
    const json = await res.json();

    return json.plan.itineraries.map( (itinerary: OtpItinerary) => ({
      ...itinerary,
      from: from,
      to: to,
      legs: itinerary.legs.map( (leg: OtpLeg) => ({
        ...leg,
        from: leg.from.name === 'Origin' ? from : leg.from,
        to: leg.to.name === 'Destination' ? to : leg.to,
      }))
    }));

  }catch(e){
    return [];
  }
};

export default getTravelPlanByFromTo;