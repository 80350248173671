import { BusType } from "driving-map";
import { RoutesSelectorOptions } from "constants/functions";


const handleFilterRoutesByBusType = (busType: BusType, routes: RoutesSelectorOptions) => {
  switch (busType) {
    case 'city':
      return routes.filter(r => (r.opType === 0 || r.opType === 9));
    case 'road':
      return routes.filter(r => r.opType === 1);
    default:
      return routes;
  }
};

export default handleFilterRoutesByBusType;