import { useLazyQuery } from "@apollo/react-hooks";
import { ID } from "all-common-types";
import { RouteDataType } from "bus-common-types";
import { client} from "constants/API";
import { SelectedRouteId } from "components/collinear-routes/constants/context";
import { QueriedBusInfoListData, QUERY_BUSES } from "gql/schema.gql";
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

export interface UseQueryBusInfosOptions {
  routeId: SelectedRouteId
  isQueryIntervally?: boolean
  intervalSecs?: number
}

function useQueryBuses(options: UseQueryBusInfosOptions) {
  const {
    routeId, isQueryIntervally, intervalSecs,
  } = options;

  const [queryFn, { error, loading, data }] = useLazyQuery<QueriedBusInfoListData>(QUERY_BUSES, {
    client: client,
  });

  const handleQuery = useCallback((routeId: UseQueryBusInfosOptions['routeId']) => {
    if(routeId) {
      queryFn({
        variables: {
          routeId: Number(routeId),
        }
      });
    };
  }, [queryFn]);

  useEffect(() => {
    handleQuery(routeId);

    if(isQueryIntervally && typeof intervalSecs === 'number') {
      const timer = setInterval(() => {
        handleQuery(routeId);
      }, intervalSecs * 1000);

      return () => clearInterval(timer);
    }
  }, [handleQuery, intervalSecs, isQueryIntervally, routeId]);

  const busInfos: RouteDataType['buses'] = useMemo(() => (
    data ? data.route.buses : { edges: [], }
  ), [data]);

  return ({
    error,
    loading,
    data,
    busInfos,
    handleQuery,
  });
};

export default useQueryBuses;