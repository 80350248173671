import React from 'react';
import { Box } from '@material-ui/core';
import StopSearchRouteInfoList from 'components/collinear-routes/components/StopSearchRouteInfoPart/StopSearchRouteInfoList';
import { useSelectTab } from 'lib/customHooks';
import { HandleDepDestAndStopSearchedRouteInfo } from 'components/collinear-routes/fn';
import { SingleTravelTime } from 'collinear-routes';
import { MapDispatchToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import { StopSearchedRouteInfoListContainer_Props, StopSearchedRouteInfoListContainerDispatchFromCtx, StopSearchedRouteInfoListContainerWithCtxProps } from './types';
import { setGoBack } from 'components/collinear-routes/actions/stop-search-states-actions';
import { connectCtx } from 'react-function-helpers';
import ContextStore from 'components/collinear-routes/constants/context';
import { GoBack } from 'bus-common-types';
import useGoBackTab from 'lib/customHooks/useGoBackTab';
import DepDestTabList from 'components/common-components/TabComponents/DepDestTabList';

export interface SingleDepDestAndStopSearchedRouteInfo {
  isDisplay: boolean
  goBack: GoBack
  location: string
  stopSearchedRouteInfo: SingleTravelTime[]
}

export const stopSearchedRouteInfoListUnderTabs = (
  selectedTab: number, depDestAndStopSearchedRouteInfos: SingleDepDestAndStopSearchedRouteInfo[]
) => {
  return (index: number) => (
    <>
      {selectedTab === index && (
        <StopSearchRouteInfoList
          routeInfoListData={
            depDestAndStopSearchedRouteInfos[index].stopSearchedRouteInfo
          } />
      )}
    </>
  );
};

const StopSearchedRouteInfoListContainer = (
  props: StopSearchedRouteInfoListContainer_Props
) => {
  const { depDestAndStopSearchedRouteInfos, setGoBackToCtx } = props;

  const depDest = HandleDepDestAndStopSearchedRouteInfo.getDepDestFromCombinedResult(
    depDestAndStopSearchedRouteInfos
  );
  const initGoBack = HandleDepDestAndStopSearchedRouteInfo.getInitGoBack(depDestAndStopSearchedRouteInfos);

  const {
    selectTab,
    selectedTab,
  } = useGoBackTab({
    callback: setGoBackToCtx,
    initGoBack: initGoBack,
  });

  const stopSearchedRouteInfoListUnderTabsFn = stopSearchedRouteInfoListUnderTabs(selectedTab, depDestAndStopSearchedRouteInfos);

  return (
    <>
      <DepDestTabList
        variant={'contained'}
        tabVariant={'default'}
        depDest={depDest}
        selectedTab={selectedTab}
        selectTabFn={selectTab}
      />
      {stopSearchedRouteInfoListUnderTabsFn(0)}
      {stopSearchedRouteInfoListUnderTabsFn(1)}
    </>
  );
};

interface OwnProps extends StopSearchedRouteInfoListContainerWithCtxProps {}

const mapDispatchToProps: MapDispatchToProps<OwnProps, StopSearchedRouteInfoListContainerDispatchFromCtx> = (dispatch) => {
  return ({
    setGoBackToCtx: (goBack) => {
      const action = setGoBack(goBack);
      dispatch(action);
      return action;
    }
  });
};

const StopSearchedRouteInfoListContainerWithCtx = connectCtx(ContextStore)(undefined, mapDispatchToProps)(StopSearchedRouteInfoListContainer);

export default StopSearchedRouteInfoListContainerWithCtx;