import React from 'react';
import { Box } from '@material-ui/core';
import PlanModeSelector from 'components/travel-planner/components/SearchPart/PlanModeSelector';
import SearchByFromToStopsContainerWithCtx from './SearchByFromToStopsContainer';
import useSearchPart from './functions/useSearchPart';

const SearchPartContainer = () => {
  const {
    value, onChange,
  } = useSearchPart();

  return (
    <Box>
      <PlanModeSelector
        value={value}
        onChange={onChange}
      />
      <SearchByFromToStopsContainerWithCtx
        planMode={value}
      />
    </Box>
  );
};

export default SearchPartContainer;