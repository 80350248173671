import { NoonType } from "all-common-types";
import EstimateTimeHandler from "constants/EstimateTimeHandler";
import { SingleScheduleForTimeTable } from "../types";

const ScheduleTimeHandlers = {
  checkIsAfterNoon(scheduleTime: string) {
    const hourMin = EstimateTimeHandler.splitComeTime(scheduleTime);
    return hourMin.hours >= 12 && !(hourMin.hours === 12 && hourMin.mins === 0);
  },

  splitByNoon(schedules: SingleScheduleForTimeTable[]): SingleScheduleForTimeTable[][] {
    let res: SingleScheduleForTimeTable[][] = [[], []]; // morning, afternoon

    for (let i = 0; i < schedules.length; i++) {
      const schedule = schedules[i];
      const isAfternonn = this.checkIsAfterNoon(schedule.scheduleTime);
      if(isAfternonn) {
        res[1] = [...res[1], schedule];
      } else {
        res[0] = [...res[0], schedule];
      }
    }

    return res;
  },

  splitByHalf(schedules: SingleScheduleForTimeTable[]) {
    const half = Math.round(schedules.length / 2);
    const firstHalf = schedules.slice(0, half);
    const remainHalf = schedules.slice(half);
    
    const res = [firstHalf, remainHalf];
    return res;
  },

  convertIndexToNoonType(index: number): NoonType {
    if(index === 0) {
      return 'morning';
    }
    return 'afternoon';
  }
};

export default ScheduleTimeHandlers;