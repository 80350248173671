import { useState, useCallback, useMemo } from "react";

export interface UseDataListPaginationOptions<Data> {
  initPage?: number
  rowsPerPage?: number
  itemListData?: Data[]
}

function useDataListPagination<Data extends any>({
  initPage=1, rowsPerPage=10, itemListData,
}: UseDataListPaginationOptions<Data>) {
  const [pageNow, setPage] = useState(initPage);
  const pageIndex = pageNow - 1;

  const handleChangePage = useCallback((e: any, newPage: number) => {
    setPage(newPage);
  }, []);

  const itemListDataNow = useMemo(() => (
    itemListData ? (
      itemListData.slice(rowsPerPage * pageIndex, rowsPerPage * (pageIndex + 1))
    ) : []
  ), [itemListData, pageIndex, rowsPerPage]);

  const pagesCount = useMemo(() => (
    itemListData ? (
      Math.floor(itemListData.length / rowsPerPage)
    ) : 0
  ), [itemListData, rowsPerPage]);

  return ({
    pageNow,
    pagesCount,
    setPage,
    handleChangePage,
    itemListDataNow,
  });
};

export default useDataListPagination;