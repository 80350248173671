import React from 'react';
import { Box } from '@material-ui/core';
import SideStationList from './SideStationList';
import { MrtStationsPartProps } from './types';
import { DISTANCE_BETWEEN_STATIONS, PADDING_BETWEEN_STATIONS, FARE_BUTTON_WIDTH } from 'components/mrt-fare/config';
import getMrtPartDirection from './functions/getMrtPartDirection';
import MrtLine from '../../common/MrtLine';

const MrtStationsPart = (props: MrtStationsPartProps) => {
  // const split = 
  return (
    <Box display={'flex'} position={'relative'}>
      <MrtLine
        color={'MRT_GREEN'}
        direction={'horizontal'}
        top={FARE_BUTTON_WIDTH / 2}
        left={FARE_BUTTON_WIDTH / 2}
        width={DISTANCE_BETWEEN_STATIONS * (props.stationListData.length - 1)}
      />
      <SideStationList 
        {...props}
        padding={PADDING_BETWEEN_STATIONS}
        stationNameDirection={getMrtPartDirection} 
        partPositionStyle={{
          display: 'flex'
        }}
      />
    </Box>
  );
};

export default MrtStationsPart;