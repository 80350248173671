import { Position, QueriedTgosAddress, SingleTgosAddress } from "bus-common-types";
import StationNameAutoCompleteHandlers from "components/common-components/AutoCompleteWithQuery/lib/StationNameAutoCompleteHandlers";
import { StationAndAddressAutoCompleteResultType } from "components/common-components/AutoCompleteWithQuery/lib/types";
import { ValueResult } from "ibus-common-components/lib/components/common-components/AutoComplete/types";

const TgosAddressDataHandlers = {
  convertXYtoPosition({
    X, Y
  }: { X: number, Y: number }) {
    const position: Position = [Y, X];
    return position;
  },

  makeSingleValueResultFromAddress(tgosAddress: SingleTgosAddress): ValueResult<StationAndAddressAutoCompleteResultType> {
    const {
      FULL_ADDR
    } = tgosAddress;
    const position = this.convertXYtoPosition(tgosAddress);

    const result: StationAndAddressAutoCompleteResultType = {
      name: FULL_ADDR,
      position,
    };

    return ({
      icon: StationNameAutoCompleteHandlers.makeIconByType('ADDRESS'),
      value: FULL_ADDR,
      result,
    });
  },
  
  handleRawData(rawData: QueriedTgosAddress): ValueResult<StationAndAddressAutoCompleteResultType>[] {
    const {
      AddressList,
    } = rawData;
    const res = AddressList.map(a => this.makeSingleValueResultFromAddress(a));
    return res;
  },
};

export default TgosAddressDataHandlers;