import reducers from '../reducers';
import { FromToLocation, OtpItinerary } from 'travel-planner';
import ContextWrapperFn from 'react-function-helpers/lib/functions/ContextWrapper';
import { createContextValueFn, createContextStore, MyContext } from 'lib/contextHelpers';
import { SingleRouteFromRoutes } from 'all-common-types';

export type SelectedPlan = OtpItinerary | undefined
export type Plans = OtpItinerary[]
export type TravelPlannerState = {
  selectedPlan: SelectedPlan
  plans: OtpItinerary[]
  from: FromToLocation
  to: FromToLocation
  routes: SingleRouteFromRoutes[]
}

export const initSelectedPlan: SelectedPlan = undefined;
export const initPlans: Plans = [];
export const initFromToLocation: FromToLocation = {
  name: '草aaaaaa',
  lat: "24.083560943604",
  lng: "120.69741821289",
};

export const initState: TravelPlannerState = {
  selectedPlan: initSelectedPlan,
  plans: initPlans,
  from: initFromToLocation,
  to: {
    "name": "大里圖書館(中興路)",
    "lat": "24.087476730347",
    "lng": "120.69589233398"
  },
  routes: []
};

export type Context = MyContext<TravelPlannerState>
export const ContextValue = createContextValueFn(initState, reducers);

const ContextStore = createContextStore(initState);
export default ContextStore;

export const ContextWrapper = ContextWrapperFn<TravelPlannerState>(ContextValue, ContextStore);
