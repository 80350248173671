import React from 'react';
import { PriceTypes } from 'components/fare/config';
import PriceListWithTitle from './PriceListWithTitle';

export interface CashPriceProps {
  prices: PriceTypes
}

const CashPrice = (props: CashPriceProps) => (
  <PriceListWithTitle 
    {...props}
    langKey={'fare.searchResult.cash'}
  />
);

export default CashPrice;