import React, { useCallback, useEffect } from 'react';
import { CollinearRoutesState, SelectedRouteId } from '../../constants/context';
import { StopSearchInputItemContainerRefType, StopSearchInputItemContainerProps } from '../types';
import { QueryRouteInfoFn } from '../stopSearchContainers/StopSearchContainer';
import useDelayCallback from 'lib/customHooks/useDelayCallback';
import { autoCompleteDelayTime } from 'config';
import { useIntl } from 'react-intl';
import useQueryRoutesByStationIds from 'components/collinear-routes/gql/custom-hooks/useQueryRoutesByStationIds';

export interface UseStopSearchContainerParams {
  queryRouteInfoFn: QueryRouteInfoFn
  selectedRouteId: SelectedRouteId
}

const useStopSearchContainer = ({
  queryRouteInfoFn,
  selectedRouteId,
}: UseStopSearchContainerParams) => {
  const stopSearchInputItemContainerRef = React.useRef<StopSearchInputItemContainerRefType>(null);
  const {
    formatMessage,
  } = useIntl();

  const {
    handleQuery,
    routesSelectorOptions,
  } = useQueryRoutesByStationIds();

  const {
    handleDelayedCallback
  } = useDelayCallback({
    delayTime: autoCompleteDelayTime,
  });

  const handleSearch = useCallback(() => {
    if(stopSearchInputItemContainerRef.current) {
      const valueResults = stopSearchInputItemContainerRef.current.getNameValueResults();
      if(valueResults.stop.result) {
        const fromStation: CollinearRoutesState['selectedFromStation'] = {
          id: Number(valueResults.stop.result.id),
          name: valueResults.stop.value
        };
        queryRouteInfoFn(fromStation, selectedRouteId);
      } else {
        window.alert(
          formatMessage({
            id: 'collinearRoutes.stopSearch.input.warning',
          })
        );
      }
    }
  }, [formatMessage, queryRouteInfoFn, selectedRouteId]);

  const handleQueryRoutesByStationIds = useCallback<StopSearchInputItemContainerProps['getNameValueResultsWhenChanged']>((nameValueResults) => {
    const { result, } = nameValueResults.stop;
    if(result) {
      handleDelayedCallback(() => handleQuery(result.stationIds));
    }
  }, [handleDelayedCallback, handleQuery]);

  return ({
    stopSearchInputItemContainerRef,
    handleSearch,
    handleQueryRoutesByStationIds,
    routesSelectorOptions,
  });
};

export default useStopSearchContainer;