import { useEffect } from 'react';
import { resetRouteData, resetRouteStopId } from 'components/driving-map/actions';
import { useSelectTab } from 'lib/customHooks';
import { Callback } from 'all-common-types';
import { resetSelectedRoute } from 'actions/common-actions/selectedRoute-actions';

// 用-1的初始數值來判斷是否為初次mount
const useBusSelectPartTab = (dispatch: Callback | undefined, initSelectTab=-1) => {
  const [selectedTab, setSelectTab] = useSelectTab(initSelectTab);

  useEffect(() => {
    // console.log('reseted!');
    if(selectedTab > -1) {
      dispatch && dispatch( resetSelectedRoute() );
      dispatch && dispatch( resetRouteStopId() );
      dispatch && dispatch( resetRouteData() );
    }
  }, [dispatch, selectedTab]);

  return ({
    selectedTab: selectedTab === -1 ? 0 : selectedTab,
    setSelectTab,
  });
};

export default useBusSelectPartTab;