import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import MapPart from 'components/collinear-routes/components/MapPart/MapPart';
import ContextStore, { CollinearRoutesState } from 'components/collinear-routes/constants/context';
import { MapStateToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import { MapPartContainerProps } from '../types';
import { connectCtx } from 'react-function-helpers';
import useMapPart from './functions/useMapPart';

export const ErrorMessage = () => (
  <Typography>{'Something Wrong'}</Typography>
);

const MapPartContainer = (props: MapPartContainerProps) => {
  const {
    error,
    markersAndRoutePoints,
    quried,
    toggledDisplay,
    handleDisplayTimeTable,
  } = useMapPart(props);
  
  if(error) {
    return (
      <ErrorMessage />
    );
  }

  return (
    <MapPart 
      {...props} 
      queried={quried}
      markersAndRoutePoints={markersAndRoutePoints}
      isClogLoading={false}
      onToggleClog={() => {}}
      toggledDisplay={toggledDisplay} 
      displayTimeTableFn={handleDisplayTimeTable} />
  );
};

interface OwnProps {}

export const mapStateToProps: MapStateToProps<CollinearRoutesState, OwnProps, MapPartContainerProps> = (state) => {
  return ({
    routeId: state.selectedRouteId,
    fromStation: state.selectedFromStation,
    goBack: state.stopSearchStates.goBack,
  });
};

export const MapPartContainerWithCtx = connectCtx(ContextStore)(mapStateToProps)(MapPartContainer);

export default MapPartContainer;