import { actionTypes as Types } from './index';
import { IntervalSearchSelectedFromTo, SearchType } from '../constants/context';
import { ID } from 'all-common-types';
import { SetRouteIdAction, ResetRouteIdAction, SetStopSearchFromToAction, ResetStopSearchFromToAction, SetSearchTypeAction } from './action-types';

export const setRouteId = (routeId: string): SetRouteIdAction => ({
  type: Types.SET_ROUTE_ID,
  payload: { routeId },
});
export const resetRouteId = (): ResetRouteIdAction => ({
  type: Types.RESET_ROUTE_ID,
});

export const setStopSearchFromTo = (fromTo: IntervalSearchSelectedFromTo): SetStopSearchFromToAction => ({
  type: Types.SET_STOP_SEARCH_FROM_TO,
  payload: fromTo,
});
export const resetStopSearchFromTo = (): ResetStopSearchFromToAction => ({
  type: Types.RESET_STOP_SEARCH_FROM_TO,
});

export const setSearchType = (searchType: SearchType): SetSearchTypeAction => ({
  type: Types.SET_SEARCH_TYPE,
  payload: { searchType, }
});