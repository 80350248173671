import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { Callback } from 'all-common-types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

export interface FoldButtonProps {
  onToggleFold: Callback
  isFold?: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
  }
}));

const FoldButton = ({ 
  isFold, onToggleFold
}: FoldButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.root}
      onClick={onToggleFold}
    >
      {isFold ? (
        <IntlFormattedMessage
          langKey={'fold'}
        />
      ) : (
        <IntlFormattedMessage 
          langKey={'unfold'}
        />
      )}
    </Button>
  );
};

export default FoldButton;