import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY_CMS_BLOG, QueryCmsBlog } from 'constants/cmsAPI/cmsAPI';
import { useCallback } from 'react';

const useQueryCmsSingleBlog = () => {
  const [queryFn, { loading, error, data }] = useLazyQuery<QueryCmsBlog>(QUERY_CMS_BLOG);

  const handleQuery = useCallback((blogId: string) => {
    queryFn({
      variables: {
        id: blogId,
      }
    });
  }, [queryFn]);
  
  return ({
    loading,
    error,
    data,
    handleQuery,
  });
};

export default useQueryCmsSingleBlog;