/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Box, Typography, Link, makeStyles } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { RouteMapDownloadProps } from './types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import getRouteMapImageSrcByUrlPrefix from 'components/route-map/lib/getRouteMapImageSrcByUrlPrefix';

const useStyles = makeStyles(theme => ({
  downloadPart: {
    backgroundColor: theme.palette.primary.main,
  },
  downloadLink: {
    '& a': {
      // paddingLeft: theme.spacing(4),
    }
  }
}));

const RouteMapDownload = (props: RouteMapDownloadProps) => {
  const {
    name,
    src,
  } = props;
  const classes = useStyles();
  const routeMapSrc = getRouteMapImageSrcByUrlPrefix(src);

  return (
    <Box>
      <Box
        padding={1}
        className={classes.downloadPart}
      >
        <IntlFormattedMessage 
          langKey={'downloadPart'}
        />
      </Box>
      <Box
        className={classes.downloadLink}
        padding={1}
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        <Typography>{name}</Typography>
        <Box
          paddingLeft={3}
        >
          <a
            href={routeMapSrc}
            target={'_blank'}
          >
            <CloudDownload fontSize={'large'} />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default RouteMapDownload;