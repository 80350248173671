import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: 1,
    padding: theme.spacing(2),
    fontWeight: 800,
    textAlign: 'center',
    // color: theme.palette.common.white,
    color: theme.homepageColors.titleColor,
  }
}));

const WelcomTitle = () => {
  const classes = useStyles();
  return (
    <IntlFormattedMessage 
      className={classes.root}
      langKey={'homePage.title'}
      variant={'h4'}
      color={'primary'}
    />
  );
};

export default WelcomTitle;