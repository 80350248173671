import moment from 'moment';
import replaceAllWithVariables from "lib/replaceAllWithVariables";
import { Locale } from "all-common-types";
import { Position } from "bus-common-types";
import { PlanMode } from "travel-planner";

const travelPlanByCombinedFromToAPI = process.env.REACT_APP_TRAVEL_FIND_PLAN_BY_COMBINED_FROM_TO || '//citybus.taichung.gov.tw/ebus/adapter/travel-planner?from={fromPosition}&to={toPosition}&fromName={fromName}&toName={toName}&locale={locale}&mode={planMode}';

interface FromToLocation {
  name: string
  lng: string | number
  lat: string | number
}
export interface GetTravelPlanByFromToAPIParams {
  from: FromToLocation,
  to: FromToLocation,
  locale: Locale
  date?: string
}

export interface GetTravelPlanByCombinedFromToAPIParams {
  fromPosition: Position | string
  toPosition:  Position | string
  // position need name
  fromName?: string
  toName?: string
  locale: Locale
  planMode: PlanMode
}
export const getTravelPlanByCombinedFromToAPI = ({
  fromName, toName, fromPosition, toPosition, locale, planMode,
}: GetTravelPlanByCombinedFromToAPIParams) => {
  const API = travelPlanByCombinedFromToAPI;
  return (
    replaceAllWithVariables(API, {
      fromPlace: typeof fromPosition === 'string' ? fromPosition : fromPosition.join(','),
      toPlace: typeof toPosition === 'string' ? toPosition : toPosition.join(','),
      fromName,
      toName,
      locale,
      planMode: `${planMode},WALK`,
      date: moment().format('MM-DD-YYYY'),
      time: moment().format('h:ssa'),
      arriveBy: 'false',
    })
  );
};

export interface GetSearchAddressAPIOptions {
  address: string
  locale: Locale
}