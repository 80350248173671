import React, { ChangeEvent, ReactNode } from 'react';
import { Select, FormControl, InputLabel, MenuItem, Theme, makeStyles } from '@material-ui/core';
import { SingleSelectOption } from 'all-common-types';
import { SelectorProps } from './types';
import LoadingAndError from '../LoadingAndError';

const defaultMinWidth = 100;

const useStyles = makeStyles<Theme, SelectorProps>({
  formPart: {
    minWidth: props => typeof props.minWidth === 'number' || 'string' ? props.minWidth : defaultMinWidth
  },
  root: {
    textAlign: 'center',
  }
});

const Selector = (props: SelectorProps) => {
  const { 
    id, 
    label, 
    options, 
    changeFn, 
    value, 
    loading,
  } = props;
  const classes = useStyles(props);

  const optionsComponents = options.map((option, i) => (
    <MenuItem key={ i } value={ option.value }>
      { option.text }
    </MenuItem>
  ));
  const loadingComponent = <LoadingAndError loading={true} error={undefined} />;

  return (
    <FormControl className={classes.formPart}>
      <InputLabel htmlFor={ id }>{ label }</InputLabel>
      <Select
        value={ value }
        // variant={}
        onChange={ changeFn }
        className={classes.root}
        inputProps={{
          name: id,
          id,
        }}
      >
        {loading ? loadingComponent : optionsComponents}
      </Select>
    </FormControl>
  );
};
export default Selector;