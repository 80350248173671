import { createContextValueFn, createContextStore } from "react-function-helpers/lib/functions/contextHelpers";
import ContextWrapperFn from "react-function-helpers/lib/functions/ContextWrapper";
import { FromToKeys } from "bus-common-types";
import { SingleRouteFromRoutes } from "all-common-types";
import reducers from "../reducers";
import CommonStateTypes, { initCommonState } from "reducers/common-reducers/state";
import { SingleStationForFareWithGoBack } from "../gql/gql-schema";

export interface FareState extends CommonStateTypes {
  fromToStopsId: {
    from: string
    to: string
  }
  routes: SingleRouteFromRoutes[]
  fromToStops: {
    [x in FromToKeys]: SingleStationForFareWithGoBack[]
  } & {
    isCycled: boolean
    // 不分goBack的全部站點
    anotherWayStops: SingleStationForFareWithGoBack[]
  }
}

export const initFareState: FareState = {
  ...initCommonState,
  routes: [],
  fromToStopsId: {
    from: '',
    to: '',
  },
  fromToStops: {
    // 單循環從from to stops那個query來的
    isCycled: false,
    from: [],
    to: [],
    anotherWayStops: [],
  }
};

export const ContextValue = createContextValueFn(initFareState, reducers);
const ContextStore = createContextStore(initFareState);

export default ContextStore;

export const ContextWrapper = ContextWrapperFn(ContextValue, ContextStore);