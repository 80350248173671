import { ID } from "all-common-types";
import { GoBack } from "bus-common-types";
import useQueryStopReportRouteInfo from "components/stop-report-page/gql/custom-hooks/useQueryStopReportRouteInfo";
import useGoBackTab from "lib/customHooks/useGoBackTab";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MakeCreateStopReportDataOptions, StationMetaData } from "stop-report";

export interface UseStopReportInputPartOptions {
  onGetOptions: (options: MakeCreateStopReportDataOptions) => any
}

export const initReporDataOption: MakeCreateStopReportDataOptions = {
  platform: 'Web',
  routeId: 0,
  routeName: '',
  stopId: 0,
  stationId: 0,
  stopName: '',
  stationName: '',
  goBack: 1,
  description: '',
};

const useStopReportInputPart = ({
  onGetOptions,
}: UseStopReportInputPartOptions) => {
  const [reportDataOption, setReportDataOption] = useState<MakeCreateStopReportDataOptions>(initReporDataOption);

  const queried = useQueryStopReportRouteInfo();
  const {
    stopOptions,
    depDest,
    handleQuery,
  } = queried;

  const handleSetGoBack = useCallback((goBack: GoBack) => {
    setReportDataOption(s => ({
      ...s, goBack,
    }));
  }, []);

  const handleSetRouteId = useCallback((routeId: ID) => {
    setReportDataOption(s => ({
      ...s, routeId: Number(routeId),
    }));
  }, []);

  const handleSetRouteName = useCallback((routeName: string) => {
    setReportDataOption(s => ({
      ...s, routeName,
    }));
  }, []);

  const handleSetDepDestName = useCallback((goBack: GoBack) => {
    setReportDataOption(s => ({
      ...s, goBack,
    }));
  }, []);

  const handleSetStationMetaData = useCallback((metaData: StationMetaData) => {
    setReportDataOption(s => ({
      ...s, ...metaData,
    }));
  }, []);

  const handleSetStopReportDescription = useCallback((desc: string) => {
    setReportDataOption(s => ({
      ...s, description: desc,
    }));
  }, []);

  useEffect(() => {
    if(reportDataOption.routeId) {
      handleQuery(reportDataOption.routeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDataOption.routeId]);

  useEffect(() => {
    onGetOptions(reportDataOption);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(reportDataOption)]);

  const filteredStopOptions = useMemo(() => (
    stopOptions.filter(s => s.goBack === reportDataOption.goBack)
  ), [reportDataOption.goBack, stopOptions]);

  return ({
    depDest,
    stopOptions: filteredStopOptions,
    handleSetGoBack,
    handleSetRouteId,
    handleSetRouteName,
    handleSetDepDestName,
    handleSetStationMetaData,
    handleSetStopReportDescription,
  });
};

export default useStopReportInputPart;