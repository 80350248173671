import { useState, useCallback, RefObject } from "react";
import { SearchByFromToStopsContainerRefType } from "./types";
import { SearchByFromToStopsContainerProps } from "../types";
import { useIntl } from "react-intl";
import { LANG } from "constants/lang";
import { Locale } from "all-common-types";
import { Position } from "bus-common-types";

export interface UseSearchByFromToStopsOptions extends SearchByFromToStopsContainerProps {
  ref: RefObject<SearchByFromToStopsContainerRefType>
}

const useSearchByFromToStops = ({
  ref, planMode, searchFn,
}: UseSearchByFromToStopsOptions) => {
  const {
    locale,
    formatMessage,
  } = useIntl();
  
  const [loading, setLoading] = useState(false);

  const handleSearchByFromToStops = useCallback(() => {
    if(ref.current) {
      const nameValueResults = ref.current.getNameValueResults();
      const {
        from, to
      } = nameValueResults;
      const fromValue = from.value;
      const toValue = to.value;
      const fromResult = from.result;
      const toResult = to.result;

      if(fromValue && toValue) {
        let fromPosition: Position | string = fromValue;
        let toPosition: Position | string = toValue;
  
        if(fromResult) {
          fromPosition = fromResult.position;
        }
        if(toResult) {
          toPosition = toResult.position;
        }
        setLoading(true);
        searchFn({
          fromPosition,
          toPosition,
          fromName: fromValue,
          toName: toValue,
          locale: locale as Locale,
          planMode,
        }, setLoading);
      }
      else {
        //--optimization--
        // dispatch message action to plans result part
        const alertText = formatMessage({
          id: 'travelPlanner.planPart.inputAlert',
          defaultMessage: LANG.zh_TW["travelPlanner.planPart.inputAlert"]
        });
        window.alert(alertText);
      }
    }
  }, [formatMessage, locale, planMode, ref, searchFn]);

  return ({
    loading,
    handleSearchByFromToStops
  });
};

export default useSearchByFromToStops;