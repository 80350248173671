import React from 'react';
import SelectFromTo, { SelectFromToProps } from 'components/fare/components/SearchPart/SelectFromTo';
import { MapStateToProps, MapDispatchToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import ContextStore, { FareState, initFareState } from 'components/fare/constant/context';
import { connectCtx } from 'react-function-helpers/lib/index';
import { handleFromToStopsFromToSelectorOptions } from './fn';
import { setFromToStopsId, setFromToStops } from 'components/fare/actions/selectFromTo_actions';
import { SelectFromToContainerProps } from '../types';
import getFromToSelectOptionsFn from './functions/getFromToSelectOptions';
import { selectFromToSelectorMinWidth } from 'components/fare/config';
import useSelectFromTo from './useSelectFromTo';

export const initFromToValues = initFareState['fromToStopsId'];

export const SelectFromToContainer = (props: SelectFromToContainerProps) => {
  const { 
    isCycledRoute,
    fromToStops,
  } = props;

  const selectFromToState = useSelectFromTo(props);
  const {
    handleSelect,
    fromToValues,
    fromToToggles
  } = selectFromToState;

  const getFromToSelectOptions = getFromToSelectOptionsFn({
    isCycledRoute,
    fromToStops,
    handleSelect,
    fromToValues,
  });

  const selectFromToProps: SelectFromToProps = {
    fromTo: {
      from: {
        ...getFromToSelectOptions('from', fromToToggles.from.fromToggle),
        isCycled: isCycledRoute,
        minWidth: selectFromToSelectorMinWidth,
        value: fromToValues['from'],
        toggle: fromToToggles.from.fromToggle,
        onChangeOptionsGoBack: fromToToggles.from.handleFromToggle,
      },
      to: {
        ...getFromToSelectOptions('to', fromToToggles.to.toToggle),
        isCycled: isCycledRoute,
        minWidth: selectFromToSelectorMinWidth,
        value: fromToValues['to'],
        toggle: fromToToggles.to.toToggle,
        onChangeOptionsGoBack: fromToToggles.to.handleToToggle,
      }
    }
  };

  return (
    <SelectFromTo
      {...selectFromToProps} />
  );
};

interface SelectFromToContainerWithCtxProps {}

type M = MapStateToProps<FareState, SelectFromToContainerWithCtxProps, SelectFromToContainerProps>

export const mapStateToProps: M = (state) => ({
  isCycledRoute: state.fromToStops.isCycled,
  fromToStops: state.fromToStops,
  fromToStopsId: state.fromToStopsId
});

export const mapDispatchToProps: MapDispatchToProps<SelectFromToContainerWithCtxProps, {
  setFromToStopsIdToCtx: (fromToStopsId: FareState['fromToStopsId']) => any
}> = (dispatch) => {
  return ({
    setFromToStopsIdToCtx: (fromToStopsId) => {
      const action = setFromToStopsId(fromToStopsId);
      dispatch(action);
    }
  });
};

const SelectFromToContainerWithCtx = connectCtx(ContextStore)(mapStateToProps, mapDispatchToProps)(SelectFromToContainer);

export default SelectFromToContainerWithCtx;