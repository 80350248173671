import React from 'react';
import BusMapWrapper from 'components/common-components/wrappers/BusMapWrapper';
import { StopSearchMapPartProps, StopSearchMapPartStatesFromCtx } from './types';
import { RouteLine, MapMarker } from 'components/driving-map/components/BusMap';
import { MapStateToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import ContextStore, { CollinearRoutesState } from 'components/collinear-routes/constants/context';
import { getPolyline, getGoBackString } from 'constants/functions';
import { defaultMapCenter } from 'config';
import { connectCtx } from 'react-function-helpers';
import { SingleEstimateStop } from 'bus-common-types';
import { SingleRouteStop } from 'components/common-components/wrappers/types';
import { seperateData } from 'lib/seperateDataHelpers';
import { STOP_SEARCH_MAP_MARKER_POPUP_MIN_WIDTH } from 'components/collinear-routes/config';

export const makeRouteStop = (singleStop: SingleEstimateStop): SingleRouteStop => {
  const res: SingleRouteStop = {
    ...singleStop,
    position: [singleStop.lat, singleStop.lon],
  };
  return res;
};

const StopSearchMapPart = (props: StopSearchMapPartProps) => {
  const {
    mapData,
  } = props;

  return (
    <BusMapWrapper
      {...props}
    >
      <>
        {mapData.routeStops.map((r, index) => {
          const mapMarkerKey = `${r.goBack}-${r.index || index}`;
          return (
            <MapMarker 
              {...r}
              popupMinWidth={STOP_SEARCH_MAP_MARKER_POPUP_MIN_WIDTH}
              key={mapMarkerKey}
              index={r.orderNo}
              isOpen={false}
            >
              {r.stopName}
            </MapMarker>
          );
        })}
      </>
      <RouteLine
        {...props}
        routePoints={mapData.routePoints}
      />
    </BusMapWrapper>
  );
};

interface OwnProps {}

const mapStateToProps: MapStateToProps<CollinearRoutesState, OwnProps, StopSearchMapPartStatesFromCtx> = (state) => {
  const {
    goBack,
    routeData,
  } = state.stopSearchStates;
  // console.log(routeData);

  if(!routeData) {
    return ({
      goBack: 1,
      position: defaultMapCenter,
      mapData: {
        routePoints: [],
        routeStops: [],
      }
    });
  }
  
  else {
    const goBackStr = getGoBackString(goBack);
  
    const routePoints = getPolyline(routeData.routePoint[goBackStr]);
    const {
      handledRouteStops,
    } = routeData;
    const routeStops = seperateData.makeGoBackDataToObj(handledRouteStops)[goBackStr];

    const mapData = {
      routePoints,
      routeStops,
    };
  
    return ({
      goBack,
      position: defaultMapCenter,
      mapData,
    });
  }
};

const StopSearchMapPartWithCtx = connectCtx(ContextStore)(mapStateToProps)(StopSearchMapPart);

export default StopSearchMapPartWithCtx;