import dragonBoatTheme, { dragonBoatThemeOnOffDate } from "theme/dragonBoatTheme";
import goldTheme from "theme/goldTheme";
import newYearThemeCustomer from "theme/newYearThemeCustomer";
import newYearThemeGold from 'theme/newYearThemeGold';
import newYearThemeRed from 'theme/newYearThemeRed';
import moonFestivalTheme, { enableFromTo } from 'theme/moonFestivalTheme';
import xmasTheme, { enableBetween } from 'theme/xmasTheme'

interface ThemeOnOffShelfDate {
  year: number
  month: number
  date: number
}
export interface ThemeShelfAtDate {
  onShelfAt: ThemeOnOffShelfDate
  offShelfAt: ThemeOnOffShelfDate
}

type ThemeKeys = 'gold' | 'new-year-customer' | 'new-year-red' | 'new-year-gold' | 'dragon-boat' | 'moon-festival' | 'xmas'

const OFF_SHELF_DATE: ThemeOnOffShelfDate = enableBetween.offShelfAt;

const ThemeLocalStorage = {
  themeLSKeys: 'theme-state',
  defaultThemeName: 'xmas' as ThemeKeys,

  checkShouldOffShelf() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const date = today.getDate();

    if(year > OFF_SHELF_DATE.year) {
      return true;
    }
    else if(month > OFF_SHELF_DATE.month) {
      return true;
    }
    else if (month === OFF_SHELF_DATE.month && date > OFF_SHELF_DATE.date) {
      return true;
    }
    return false;
  },

  getThemeNameFromLS() {
    const themeName = localStorage.getItem(this.themeLSKeys) as ThemeKeys;
    return themeName ? themeName : this.defaultThemeName;
  },
  getTheme() {
    const themeName = this.getThemeNameFromLS();

    if(this.checkShouldOffShelf()) {
      return goldTheme;
    }

    switch (themeName) {
      case 'gold':
        return goldTheme;
      case 'new-year-customer':
        return newYearThemeCustomer;
      case 'new-year-gold':
        return newYearThemeGold;
      case 'new-year-red':
        return newYearThemeRed;
      case 'dragon-boat':
        return dragonBoatTheme;
      case 'moon-festival':
        return moonFestivalTheme;
      case 'xmas':
        return xmasTheme;
      default:
        return goldTheme;
    }
  },
  setTheme(themeName: ThemeKeys) {
    localStorage.setItem(this.themeLSKeys, themeName);
  },


};

export default ThemeLocalStorage;