import { BusIconProps } from '../BusIcon';
import ibusImages from 'static/ibusImages.json';
import getBusIconByProviderId from 'lib/busProviders/getBusIconByProviderId';

export interface BusIconOptions {
  providerId: number | undefined // 業者id
  carType: string //公車類型
}

export const BARRIER_FREE_BUS_CAR_TYPES = [
  'lfv', 'dsby', 'lfv_2', 'lfv_3', 'midi_dsby', 'lower', 'hyv'
];
export const ELECTRIC_BUS_CAR_TYPES = [
  'midi_ev'
];
export const ELECTRIC_WITH_BARRIER_FREE_BUS_CAR_TYPES = [
  'ev'
];
export const IOV_CAT_TYPES = [
  // disable by 科長
  // 'iov'
  ''
];

const BusIconHandlers = {
  getIcon({
    providerId, carType,
  }: BusIconOptions): BusIconProps {
    const isElectricWithBarrierFree = ELECTRIC_WITH_BARRIER_FREE_BUS_CAR_TYPES.includes(carType);
    const isElectric = ELECTRIC_BUS_CAR_TYPES.includes(carType);
    const isIov = IOV_CAT_TYPES.includes(carType);

    if(isElectricWithBarrierFree) {
      return ({
        iconImage: ibusImages.bus_icon_electric_bus,
        isBarrierFree: true,
      });
    }

    else if(isElectric) {
      return ({
        iconImage: ibusImages.bus_icon_electric_bus,
        isBarrierFree: false,
      });
    }

    else if (isIov) {
      return ({
        iconImage: ibusImages.bus_icon_iov_bus,
        isBarrierFree: false,
      });
    }

    else {
      const isBarrierFree = BARRIER_FREE_BUS_CAR_TYPES.includes(carType);
      let res = {
        iconImage: ibusImages.provider_bus_icons_default_bus,
        isBarrierFree,
      };
      const numProviderId = Number(providerId);
      if(!isNaN(numProviderId)) {
        const busImageByProviderId = getBusIconByProviderId(numProviderId);
        res = {
          ...res,
          iconImage: busImageByProviderId,
        };
      }
      return res;
    }
  },

};

export default BusIconHandlers;