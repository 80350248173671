const routes = {
  homePage: '/',
  blog: '/blog',
  drivingMap: '/driving-map/:routeId?',
  collinearRoutes: '/collinear-routes',
  travelPlanner: '/travel-planner',
  routeMap: '/route-map/:xno',
  routeMapWithNoParams: '/route-map',
  fare: '/fare/:xno',
  routeMapListPage: '/route-map-list',
  stopReportPage: '/stop-report',
  mrtFare: '/mrt-fare',
};

export default routes;