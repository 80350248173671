import useSelectorSelect from "lib/customHooks/useSelectorSelect";
import { PlanMode } from "travel-planner";

const useSearchPart = () => {
  const {
    values,
    handleSelect,
  } = useSelectorSelect<{
    planMode: PlanMode
  }>({
    planMode: 'TRANSIT',
  });

  return ({
    value: values.planMode,
    onChange: handleSelect('planMode'),
  });
};

export default useSearchPart;