import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import SinglePlanItem from './SinglePlanItem';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import PlanTagHandler from 'components/travel-planner/lib/PlanTagHandler';
import { PlansPartProps } from './types';
import PlanDescription from './PlanDescription';

export const shouldDisplayContent = (index: number, chosenIndex?: number) => (
  index === chosenIndex
);

const useStyles = makeStyles({
  root: {
    overflowY: 'scroll',
    height: 1,
  }
});

const PlansPart = (props: PlansPartProps) => {
  const {
    isQueried,
    plans,
    chosenIndex
  } = props;
  const classes = useStyles();

  const plansTags = PlanTagHandler.makePlanTags(plans);
  const tagsProps = plansTags.map(PlanTagHandler.converToPlanTagsProps);

  const PlansContent = () => {
    if(!isQueried) {
      return (
        <IntlFormattedMessage
          langKey={'travelPlanner.planPart.combinedSearchHint'}
        />
      );
    }
    if(plans.length === 0){
      return (
        <IntlFormattedMessage
          langKey={'travelPlanner.planPart.noPlans'} />
      );
    }
    return (
      <>
        <PlanDescription />
        {plans.map((plan, i) => (
          <SinglePlanItem
            key={i}
            {...plan}
            {...props}
            tags={tagsProps[i]}
            displayContent={shouldDisplayContent(i, chosenIndex)}
            index={i} />
        ))}
      </>
    );
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      paddingTop={2}
      className={classes.root}
    >
      <PlansContent />
    </Box>
  );
};

export default PlansPart;