
import { QueriedVisibleJointOperators, QUERY_VISIBLE_JOINT_OPERATORS } from 'components/driving-map/gql/gql-schema';
import { SingleRouteFromRoutes } from 'all-common-types';
import { client, cmsClient } from "constants/API";

import { actionTypes } from './index';
import { QUERY_ROUTES, QueriedRoutes } from 'components/driving-map/constants/API';

type GetRoutesAction = {
  type: actionTypes.GET_ROUTES
  payload: SingleRouteFromRoutes[]
}


export const getRoutes = async (lang: String): Promise<GetRoutesAction> => {
  const routeResult = await client.query<QueriedRoutes>({
    query: QUERY_ROUTES,
    variables: {
      lang
    }
  });

  const jointOperatorResult = await cmsClient.query<QueriedVisibleJointOperators>({
    query: QUERY_VISIBLE_JOINT_OPERATORS
  });

  const operatorRouteIdMap = jointOperatorResult.data.jointOperationsBuses.reduce((obj, val) => {
    const prevValue = obj.get(val.routeId);
    obj.set(val.routeId, prevValue ? [...prevValue, val.providerId] : [val.providerId]);
    return obj;
  },new Map<Number, Number[]>());

  const routes = routeResult.data.routes.edges.map(edge => {
    const operatorRoute = operatorRouteIdMap.get(Number(edge.node.id));
    if (operatorRoute) {
      return {
        ...edge.node,
        providers: {
          edges: edge.node.providers.edges.filter( edge2 => operatorRoute.includes(Number(edge2.node.id)))
        }
      };
    }
    return edge.node;
  });

  return ({
    type: actionTypes.GET_ROUTES,
    payload: routes
  });
};


export type RouteActionTypes = GetRoutesAction
