import { ID, Locale } from "all-common-types";
import { FromTo } from "collinear-routes";
import { client} from "constants/API";
import QUERY_FROM_TO_STATIONS_BY_NAME, { QueriedFromToStationsByName } from "../schemas/QUERY_FROM_TO_STATIONS_BY_NAME";
import { SingleRouteFromStation } from "../schemas/QUERY_FROM_TO_STATION_ROUTES";
import StationRoutesByStationNameHandlers, { QueriedDynamicSchemaFromToStationRoutes } from "./StationRoutesByStationNameHandlers";

export interface SingleRouteWithStationId extends SingleRouteFromStation {
  stationId: ID
}
export interface FromToStationRoutes {
  fromStationRoutes: SingleRouteWithStationId[]
  toStationRoutes: SingleRouteWithStationId[]
}

const defaultStationsRoutes: FromToStationRoutes = {
  fromStationRoutes: [],
  toStationRoutes: [],
};

const queryStationRoutesByStationName = async (fromToStationName: FromTo, lang: Locale): Promise<FromToStationRoutes> => {
  try {
    // 要用station name找出所有的routes，不能用單一的stationId來找
    const queriedFromToStations = await client.query<QueriedFromToStationsByName>({
      query: QUERY_FROM_TO_STATIONS_BY_NAME,
      variables: {
        fromStationName: fromToStationName.from,
        toStationName: fromToStationName.to,
        lang,
      }
    });
    const fromToStationIds = StationRoutesByStationNameHandlers.getFromToStationIds(fromToStationName, queriedFromToStations.data);
    // 動態生成查from to station routes的schema
    const queryRoutesSchema = StationRoutesByStationNameHandlers.makeQueryFromToStationRoutesSchema({
      fromToStationIds,
      lang,
    });
    const queriedFromToStationRoutes = await client.query<QueriedDynamicSchemaFromToStationRoutes>({
      query: queryRoutesSchema,
    });
    const fromToStationRoutes = StationRoutesByStationNameHandlers.parseQueriedDynamicSchemaFromToStationRoutes(queriedFromToStationRoutes.data);
    // 輸出routes
    return fromToStationRoutes;
  } catch(e) {
    console.log(e);
    return defaultStationsRoutes;
  }
};

export default queryStationRoutesByStationName;