import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { TicketType } from 'bus-common-types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { LangKeys } from 'constants/lang';

export const getBoardAndAlightPrices = ({
  originPrice, boardPrice,
}: {
  originPrice: ReactNode, boardPrice: ReactNode
}) => {
  if(typeof originPrice === 'number' && typeof boardPrice === 'number') {
    return ({
      boardPrice,
      alightPrice: originPrice - boardPrice,
      shouldDisplayBoardPriceInfo: true,
    });
  }
  return ({
    boardPrice,
    alightPrice: undefined,
    shouldDisplayBoardPriceInfo: false,
  });
};

export interface TicketPriceProps {
  ticketType: TicketType
  price: ReactNode
}

const TicketPriceItem = (props: TicketPriceProps) => {
  const {
    ticketType, price,
  } = props;

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'}>
        <IntlFormattedMessage
          langKey={`fare.${ticketType}Ticket` as LangKeys} />
        <Box paddingLeft={1}>
          <Typography variant={'h4'} color={'primary'}>
            {` ${price}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TicketPriceItem;