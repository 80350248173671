import { createMuiTheme } from "@material-ui/core/styles";
import {
  GoBackObj,
  Position,
  PeakType,
  DayType,
  EstimateTimeExceptionKeys,
} from "bus-common-types";
import { getDivIconOption, DivIconSpec } from "lib/numberIconFns";
export const theme = createMuiTheme();

export const queryCmsNoticesIntervalTime = 2 * 60 * 1000;
export const MARQUEE_MOVED_PX_PER_SEC = 140;

export const autoCompleteDelayTime = 550; //ms
export const noticeAnimationTimes = {
  animationDuration: 10,
  intervalSeconds: 10,
};

export const routeLineStrokeWidth = "5px";
export const refreshTime = 30;

export const defaultMapCenter: Position = [24.184368, 120.664965];
export const defaultMapBounds: Position[] = [
  [22.99885, 120.21252],
  [25.04843, 121.51755],
];
export const goOrBackObj: GoBackObj = {
  1: "go",
  2: "back",
};
export const estimateTimeExceptionStatusStr: {
  [estimateTime in EstimateTimeExceptionKeys]: string;
} = {
  "-4": "今日未營運",
  "-5": "施工改道",
  "-6": "活動改道",
};

export const mapConfigs = {
  zoom: {
    focused: 15,
    unfocused: 12,
  },
};

export const timeTableConfigs = {
  peakTypeStrObj: {
    1: "尖峰",
    2: "離峰",
  } as {
    [x in PeakType]: string;
  },
  dayTypeStrObj: {
    weekday: "平常日",
    holiday: "假日",
  } as {
    [x in DayType]: string;
  },

  getPeakTypeStr(peakType: PeakType) {
    return timeTableConfigs.peakTypeStrObj[peakType];
  },
  getDayTypeStr(dayType: DayType) {
    return timeTableConfigs.dayTypeStrObj[dayType];
  },
};

export const numberIcon = {
  downTriangleHeight: 8,
  basicWidth: 16,

  getWidth() {
    return this.basicWidth * 2;
  },
  getHeight() {
    return this.basicWidth * 2 + this.downTriangleHeight;
  },
  getSpecForIcon(): DivIconSpec {
    const basicWidth = this.basicWidth;
    const height = this.getHeight();
    const downTriangleHeight = this.downTriangleHeight;
    const spec = {
      basicWidth,
      height,
      downTriangleHeight,
    };
    return spec;
  },

  getConfig() {
    const spec = numberIcon.getSpecForIcon();
    return getDivIconOption(spec);
  },
};
export const mapNumberIconConfig = numberIcon.getConfig;