import { createMuiTheme, Theme } from "@material-ui/core";
import goldTheme from "./goldTheme";
import newYearGold from './homepageImages/newYearGold.json';

const primary = {
  light: '#E67272',
  // main: '#B73232',
  main: '#D40A1F',
  dark: '#8E1414',
};
const secondary = {
  light: '#F9EDDB',
  main: '#E4B367',
};

const newYearThemeGold = createMuiTheme({
  ...goldTheme,
  name: 'new-year-gold',
  homepageColors: {
    titleColor: primary.dark,
    navBar: `
      linear-gradient(${90}deg, ${primary.dark} 0%, ${primary.main} 100%)`,
    noticePart: goldTheme.palette.primary.main,
    // middlePart: `linear-gradient(${90}deg, ${secondary.main} 0%, ${secondary.main} 100%)`,
    middlePart: `linear-gradient(${90}deg, ${primary.main} 0%, ${primary.light} 100%)`,
    footer: `
      linear-gradient(${180}deg, ${primary.main} 0%, ${primary.dark} 100%)`,
  },
  homepageImages: {
    // backgroundImage: newYearGold.homepage_background.src,
    topLeft: newYearGold.homepage_top_left.src,
    topRight: newYearGold.homepage_top_right.src,
    bottomLeft: newYearGold.homepage_below_left.src,
  },
  background: {
    surface: `linear-gradient(${135}deg, ${'#fff'} 0%, ${'#FFF3B2'} 100%)`,
    // homepage: `url(${newYearGold.homepage_background.src}),
    //   ${secondary.light}`,
    // homepage: `
    //   url(${newYearGold.homepage_background.src}),
    //   linear-gradient(${180}deg, ${'#FFD0B2'} 0%, ${'#FFF3B2'} 100%)`,
    homepage: `
      url(${newYearGold.homepage_background.src}),
      linear-gradient(${180}deg, ${'#FFF3B2'} 0%, ${'#FFD0B2'} 100%)`,
  },
  palette: {
    primary,
    secondary: {
      ...secondary,
    }
  },
});

export const checkIsXmasTheme = (theme: Theme) => (
  theme.palette.primary.main === primary.main
);

export default newYearThemeGold;