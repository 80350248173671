import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import SideStationList from './SideStationList';
import CenterIntersectionStationList from './CenterIntersectionStationList';
import { BusStationsPartProps } from './types';
import { PADDING_BETWEEN_STATIONS, FARE_BUTTON_WIDTH } from 'components/mrt-fare/config';
import BusCommonStationsPart from './BusCommonStationsPart';
import Route309OtherStationList from './Route309OtherStationList';
import Route310OtherStationList from './Route310OtherStationList';
import MrtLine from '../../common/MrtLine';

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  otherPart: {
    paddingRight: PADDING_BETWEEN_STATIONS * 2
  }
}));

const BusStationsPart = (props: BusStationsPartProps) => {
  const classes = useStyles();
  
  return (
    <Box display={'flex'} alignItems={'space-between'} flexShrink={0}>
      <Box 
        className={classes.otherPart} 
        position={'relative'} 
        zIndex={1}
      >
        <MrtLine 
          color={'BUS'}
          direction={'vertical'}
          width={192}
          right={192 * 0.9017 * -1}
          top={FARE_BUTTON_WIDTH / 2}
        />
        <Route309OtherStationList {...props} />
        <Route310OtherStationList {...props} />
      </Box>
      <BusCommonStationsPart {...props} />
    </Box>
  );
};

export default BusStationsPart;