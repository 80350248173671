import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { ZoomInOutProps } from './types';
import IntlButton from 'components/common-components/IntlComponents/IntlButtons';

const useStyles = makeStyles(theme => ({
  root: {
    border: `0.5px solid ${'#aaa'}`,
    borderRadius: theme.spacing(0.2),
  },
  title: {
    opacity: 0.6,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  zoomBTNWrapper: {
  },
  zoomBTN: {
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ddd',
    }
  }
}));

const ZoomInOut = (props: ZoomInOutProps) => {
  const {
    zoomValue,
    onZoomIn,
    onZoomOut,
    onReset,
  } = props;
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      display={'flex'}
      alignItems={'center'}
    >
      <IntlFormattedMessage 
        className={classes.title}
        langKey={'zoom.title'}
      />
      <Box
        display={'flex'}
        alignItems={'center'}
        className={classes.zoomBTNWrapper}
      >
        <Box
          className={classes.zoomBTN}
          onClick={onZoomOut}>
          {'－'}
        </Box>
        <Box minWidth={20}>{zoomValue}</Box>
        <Box
          className={classes.zoomBTN}
          onClick={onZoomIn}>
          {'＋'}
        </Box>
        <IntlButton
          langKey={'zoom.reset'}
          onClick={onReset}
        />
      </Box>
    </Box>
  );
};

export default ZoomInOut;