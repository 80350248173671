import { ID } from 'all-common-types';
import { SingleQueryStationByName } from 'constants/API/commonAPI';
import { ValueResult } from 'ibus-common-components/lib/components/common-components/AutoComplete/types';
import { SingleAutoCompleteWithQueryStationsResultType } from './types';

export interface QueriedStationsByNameResultType extends SingleAutoCompleteWithQueryStationsResultType, SingleQueryStationByName {
  stationIds: ID[]
  lat: number
  lng: number
}

const QueryStationsByNameAutoCompleteHandler = {
  makeSingleValueResult(station: SingleQueryStationByName): ValueResult<QueriedStationsByNameResultType> {
    return ({
      ...station,
      value: station.name,
      result: {
        ...station,
        lng: station.lon,
        stationIds: [station.id],
      }
    });
  },

  mergeStationIdToValueResult(stationId: ID, valResult: ValueResult<QueriedStationsByNameResultType>): ValueResult<QueriedStationsByNameResultType> {
    return ({
      ...valResult,
      result: {
        ...valResult.result,
        stationIds: [...valResult.result.stationIds, stationId],
      }
    });
  },

  convertToValueResults(stationsByName: SingleQueryStationByName[]) {
    let res: ValueResult<QueriedStationsByNameResultType>[] = [];
    let resObj: Record<string, ValueResult<QueriedStationsByNameResultType>> = {};

    for (let i = 0; i < stationsByName.length; i++) {
      const station = stationsByName[i];
      const stationName = station.name;
      resObj[stationName] = resObj[stationName] ? 
        this.mergeStationIdToValueResult(
          station.id, resObj[stationName]
        ) :
        this.makeSingleValueResult(station)
      ;
    }
    for (const stationName in resObj) {
      res.push(resObj[stationName]);
    }

    return res;
  }
};

export default QueryStationsByNameAutoCompleteHandler;