import { actionTypes } from '../actions';
import { SelectedPlan, Plans, TravelPlannerState } from '../constant/context';
import { AllActionTypes } from '../actions';


export const selectedPlanReducer = (state: TravelPlannerState, action: AllActionTypes): SelectedPlan => {
  switch (action.type) {
    case actionTypes.SELECT_PLAN:
      return action.payload.plan;
    case actionTypes.RESET_PLANS:
      return undefined;
    default:
      return state.selectedPlan;
  }
};

export const getPlansReducer = (state: TravelPlannerState, action: AllActionTypes): Plans => {
  switch (action.type) {
    case actionTypes.GET_PLANS:
      return action.payload.plans;
    case actionTypes.RESET_PLANS:
      return [];
    default:
      return state.plans;
  }
};