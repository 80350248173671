import { Callback, Locale } from "all-common-types";
import { StationAndAddressAutoCompleteResultType } from "components/common-components/AutoCompleteWithQuery/lib/types";
import { ValueResult } from "ibus-common-components/lib/components/common-components/AutoComplete/types";
import { ChangeEvent, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import QueryAddressStationsHandlers from "./QueryAddressStationsHandlers";
// type HookFn = QueryResultListHookFn<StationAndAddressAutoCompleteResultType>

export interface UseQueryStationsAndAddressOptions {
  initVal?: string
  onCancelCallback?: Callback
}

const useQueryStationsAndAddress = (options: UseQueryStationsAndAddressOptions={ initVal: '', }) => {
  const {
    initVal='',
    onCancelCallback,
  } = options;
  const [val, setVal] = useState<string>(initVal);
  const [valueResultList, setValResultList] = useState<ValueResult<StationAndAddressAutoCompleteResultType>[]>([]);
  const {
    locale,
  } = useIntl();

  const handleGetValueResultList = useCallback((value?: string) => {
    (async () => {
      if(typeof value === 'string' && value.length > 0) {
        const _valueResultList = await QueryAddressStationsHandlers.queryStationsAndAddressDirectly({
          lang: locale as Locale,
          name: value,
        });
        setValResultList(_valueResultList);
      }
    })();
  }, [locale]);

  const handleChangeVal = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
  }, []);

  const handleClearInput = useCallback(() => {
    setVal('');
    onCancelCallback && onCancelCallback('');
  }, [onCancelCallback]);

  return ({
    handleGetValueResultList,
    valueResultList,
    val,
    setVal,
    handleChangeVal,
    handleClearInput,
  });
};

export default useQueryStationsAndAddress;