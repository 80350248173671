import { GoBack } from "bus-common-types";

type ResultType = 'arr' | 'obj'
export function getGoBackResultByResultType<GoData, BackData>(
  goData: GoData, 
  backData: BackData
) {
  function getData(resultType: 'arr'): [GoData, BackData]
  function getData(resultType: 'obj'): {
    go: GoData,
    back: BackData
  }
  function getData(resultType: ResultType) {
    const arrData: [GoData, BackData] = [goData, backData];
    const objData = ({
      go: goData,
      back: backData,
    });
    if(resultType === 'arr') {
      return arrData;
    } else {
      return objData;
    }
  };

  return getData;
}

export class seperateData {
  static byKeyValue<Data extends object>(data: Data[]) {
    return function<Keys extends keyof Data>(key: Keys) {
      type Value = Data[Keys]
      return (value: Value) => {
        return data.filter(d => d[key] === value);
      };
    };
  }

  static fromGoBackData<Data extends {
    goBack: GoBack
  }>(data: Data[]) {
    const goData = seperateData.byKeyValue(data)('goBack')(1);
    const backData = seperateData.byKeyValue(data)('goBack')(2);
    return function(callback: typeof getGoBackResultByResultType) {
      return callback(goData, backData);
    };
  }

  static makeGoBackDataToArr<Data extends {
    goBack: GoBack
  }>(data: Data[]) {
    return this.fromGoBackData(data)(getGoBackResultByResultType)('arr');
  }
  static makeGoBackDataToObj<Data extends {
    goBack: GoBack
  }>(data: Data[]) {
    return this.fromGoBackData(data)(getGoBackResultByResultType)('obj');
  }
}