import React from 'react';
import { Box } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const FarePageTitle = () => {
  return (
    <IntlFormattedMessage
      langKey={'fare.title'}
      variant={'h4'} />
  );
};

export default FarePageTitle;