import { gql } from "apollo-boost";
import cmsApiFragments from "constants/cmsAPI/cmsApiFragments";
import { RawRouteMapData } from "route-map";
import { EdgesData, ID } from "all-common-types";
import { SingleProviderWithTel, Stations } from "bus-common-types";
import RouteFragment from "constants/api-fragments";

export const QUERY_CMS_ROUTE_MAP = gql`
  query QUERY_CMS_ROUTE_MAP($routeId: ID!) {
    routeMaps(where: {
      route_Id: $routeId
    }) {
      ...routeMapFragment
    }
  }
  ${cmsApiFragments.fragment.routeMapFragment}`;

export interface QueriedCmsRouteMap {
  routeMaps: RawRouteMapData[]
}

export const QUERY_ROUTE_FOR_ROUTE_MAP = gql`
  query Route($routeId: Int!, $lang: String!) {
    route(xno: $routeId, lang: $lang) {
      id
      name
      description
      departure
      destination
      providers {
        edges {
          node {
            id
            name
            telephone
          }
        }
      }
      stations {
        ...stationsInfo
      }
    }
  }
  ${ RouteFragment.fragment.stationsInfo }
  `;

export interface QueriedRouteForRouteMap {
  route: {
    id: ID
    name: string
    description: string
    departure: string
    destination: string
    providers: EdgesData<SingleProviderWithTel>
    stations: Stations
  }
}