import { RoutesSelectorOptions } from "constants/functions";
import { BusType } from "driving-map";
import handleFilterRoutesByBusType from "./handleFilterRoutesByBusType";
import HandleFilterRoutesByValue from "./handleFilterRoutesByValue";

export interface FilterRoutesParams {
  routes: RoutesSelectorOptions
  busType: BusType
  routeIdsForFilter: string[] | undefined
}

const BusRoutesSelectsHandlers = {
  filterRoutes({
    routes, routeIdsForFilter, busType,
  }: FilterRoutesParams) {
    let res = [];
    const filteredByRouteIds = HandleFilterRoutesByValue.filterRoutesByRouteIds(routes, routeIdsForFilter);
    const filteredByBusTypeRoutes = handleFilterRoutesByBusType(busType, filteredByRouteIds);
    res = filteredByBusTypeRoutes;
    return res;
  }
};

export default BusRoutesSelectsHandlers;