import React, { useCallback } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import Selector,{ SelectorProps, SelectorRef } from 'components/driving-map/components/BusSelect/Selector';
import { FromToKeys } from 'bus-common-types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { useIntl } from 'react-intl';
import { ChangeOptionsGoBackButtonProps } from './types';
import ChangeOptionsGoBackButton from './ChangeOptionsGoBackButton';
import { FareState } from 'components/fare/constant/context';
import { Callback } from 'all-common-types';
import { LangKeys } from 'constants/lang';

const useSelectFromToStyles = () => {
  const {
    locale,
  } = useIntl();

  const res = {
    xs: 12 as 12,
    md: locale === 'en' ? 12 as 12 : 6 as 6,
  };
  return res;
};
const useStyles = makeStyles(theme => ({
  root: {
     
  },
  buttonWrapper: {
    position: 'sticky',
    bottom: 0,
    zIndex: 1,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    backgroundColor: '#fff',
    boxShadow: `0px -3px 6px rgba(0, 0, 0, 0.1)`,
  }
}));

export interface SelectFromToItemProps extends Omit<SelectorProps, 'label'>, ChangeOptionsGoBackButtonProps {
  labelLangKey: LangKeys
  isCycled: FareState['fromToStops']['isCycled']
}
export interface SelectFromToProps {
  fromTo: {
    [x in FromToKeys]: Omit<SelectFromToItemProps, 'labelLangKey'>
  }
}

export const SelectFromToItem = (props: SelectFromToItemProps) => {
  const selectorRef = React.useRef<SelectorRef>(null);
  const selectFromToStyles = useSelectFromToStyles();
  const classes = useStyles();

  const handleToggleOptionsDisplay = useCallback(() => {
    if(selectorRef && selectorRef.current) {
      selectorRef.current.onToggleOptionsDisplay(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item {...selectFromToStyles} style={{
      textAlign: 'center',
    }}>
      <Selector 
        {...props}
        ref={selectorRef}
        minWidth={'100%'}
        label={
          <IntlFormattedMessage langKey={props.labelLangKey} />
        }
      >
        {props.isCycled && (
          <Box className={classes.buttonWrapper} onClick={handleToggleOptionsDisplay}>
            <ChangeOptionsGoBackButton {...props} />
          </Box>
        )}
      </Selector>
      
    </Grid>
  );
};

const SelectFromTo = (props: SelectFromToProps) => {
  const { fromTo } = props;

  return (
    <Grid container>
      <SelectFromToItem 
        {...fromTo.from}
        labelLangKey={'from'}
      />
      <SelectFromToItem 
        {...fromTo.to}
        labelLangKey={'to'}
      />
    </Grid>
  );
};

export default SelectFromTo;