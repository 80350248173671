/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, ElementType } from 'react';
import { withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';
import BusSelectPart from 'components/driving-map/components/BusSelect/BusSelectPart';
import RoutePart from 'components/driving-map/components/Route/RoutePart';
import BusMapPart from 'components/driving-map/components/BusMap/BusMapPart';
import { ContextWrapper } from 'components/driving-map/constants/context';
import { ApolloProvider } from '@apollo/react-components';
import { client } from 'constants/API';
import { compose } from 'recompose';
import LeftInfoRightMapStyleWrapper from 'components/common-components/wrappers/LeftInfoRightMapStyleWrapper';
import useGoldThemeLeftPartStyles from 'styles/styleObjs/goldThemeLeftPartStyles';
import { SelectTab } from 'components/driving-map/components/BusSelect';
import useDrivingMap from './functions/useDrivingMap';
import useSelectedRouteId from './functions/useSelectedRouteId';

import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '85vh',
    }
  }
}));

export interface DrivingMapProps {
  selectedRouteId: string
}

export const DrivingMap = (props: DrivingMapProps) => {
  const classes = useGoldThemeLeftPartStyles();
  const myClasses = useStyle();

  const {
    selectedTab,
    goBack,
    setSelectTab,
    handleSetGoOrBack,
  } = useDrivingMap();

  return (
    <>
      <LeftInfoRightMapStyleWrapper>
        <Box
          display={'flex'}
          flexDirection={'column'}
          flexGrow={1}
          className={myClasses.root}
        >
          <SelectTab
            selectTabFn={ setSelectTab }
            selectedTab={ selectedTab } />
          <Box
            display={'flex'}
            flexDirection={'column'}
            flexGrow={1}
            className={classes.root}
          >
            <BusSelectPart
              selectedTab={selectedTab}
            />
            <RoutePart
              routeId={props.selectedRouteId}
              goBack={ goBack }
              setGoOrBackFn={ handleSetGoOrBack } />
          </Box>
        </Box>
        <BusMapPart goBack={ goBack } />
      </LeftInfoRightMapStyleWrapper>
    </>
  );
};

const withProvider = (Component: ElementType) => () => {
  const {
    selectedRouteId,
  } = useSelectedRouteId();
  console.log(selectedRouteId);

  return (
    <ApolloProvider client={client}>
      <ContextWrapper customInitState={{
        selectedRoute: {
          name: '',
          id: selectedRouteId
        }
      }}>
        <Component selectedRouteId={selectedRouteId} />
      </ContextWrapper>
    </ApolloProvider>
  );
};

export default compose<{
  selectedRouteId: string
}, any>(
  withRouter,
  memo,
  withProvider,
)(DrivingMap);

// export default withApolloProvider(DrivingMap);