export interface AccumulateArrOptions<Data> {
  dataList: Data[] 
  key: keyof Data
  initNumber?: number
  // isDismissArrFirst?: boolean
  isReverse?: boolean
}

function accumulateArr<Data extends object>({
  dataList, 
  key,
  initNumber=0,
  // isDismissArrFirst=true,
  isReverse=false,
}: AccumulateArrOptions<Data>) {

  if(isReverse) {
    const lastIndex = dataList.length - 1;
    let res: Data[] = [];
    
    res[lastIndex] = { ...dataList[lastIndex], [key]: initNumber, };
    for (let i = lastIndex - 1; i >= 0; i--) {
      const item = dataList[i];
      const prevItemAccuEl = Number(res[i + 1][key]);
      
      res[i] = {
        ...item,
        [key]: Number(item[key]) + prevItemAccuEl,
      };
    }

    return res;
  }
  else {
    let res: Data[] = [
      {...dataList[0], [key]: initNumber, }
    ];

    for (let i = 1; i < dataList.length; i++) {
      const item = dataList[i];
      const prevItemAccuEl = Number(res[i - 1][key]);
      
      res[i] = {
        ...item,
        [key]: Number(item[key]) + prevItemAccuEl,
      };
    }

    return res;
  }
}

export default accumulateArr;