import React from 'react';
import { Box } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const FarePageInfoList = () => {
  return (
    <Box>
      <Box paddingBottom={1}>
        <IntlFormattedMessage langKey={'fare.info1'} />
        <IntlFormattedMessage langKey={'fare.info2'} />
        <IntlFormattedMessage langKey={'fare.info3'} />
        <IntlFormattedMessage langKey={'fare.info4'} />
      </Box>
    </Box>
  );
};

export default FarePageInfoList;