import { useCallback, useState } from "react";

const useKeyForUpdating = () => {
  const [key, setKey] = useState(0);

  const handleUpdateKey = useCallback(() => {
    setKey(k => k + 1);
  }, []);

  return ({
    key,
    setKey,
    handleUpdateKey,
  });
};

export default useKeyForUpdating;