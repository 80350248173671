import React, { useEffect } from 'react';
import AutoCompleteContainer from 'ibus-common-components/lib/components/common-components/AutoComplete/containers/AutoCompleteContainer';
import { SingleAutoCompleteWithQueryProps } from './types';
import useDelayCallback from 'lib/customHooks/useDelayCallback';

function SingleAutoCompleteWithQuery<ResultType>(props: SingleAutoCompleteWithQueryProps<ResultType>) {
  const {
    value,
    queryResultListHook,
  } = props;

  const {
    handleGetValueResultList,
    valueResultList
  } = queryResultListHook();

  const {
    handleDelayedCallback,
  } = useDelayCallback({
    delayTime: 1000,
  });

  React.useEffect(() => {
    handleDelayedCallback(() => handleGetValueResultList(value));
  }, [handleDelayedCallback, handleGetValueResultList, value]);

  return (
    <AutoCompleteContainer
      {...props}
      valueResultList={valueResultList} />
  );
};

export default SingleAutoCompleteWithQuery;