import React from 'react';
import { Box, Button, makeStyles, Theme } from '@material-ui/core';
import { TabItemProps } from './types';

const useStyles = makeStyles<Theme, TabItemProps>(theme => ({
  root: {
    display: props => !props.isDisplay ? 'none' : 'block',
    width: '100%',
    // maxWidth: 180,
    marginRight: props => props.tabVariant === 'default' ? 0 : theme.spacing(0.25),
    marginLeft: props => props.tabVariant === 'default' ? 0 : theme.spacing(0.25),
    // marginLeft: 0,
    // marginLeft: theme.spacing(0.25),
    // marginRight: theme.spacing(0.25),
    borderRadius: props => props.tabVariant === 'default' ? `${8}px ${8}px 0px 0px` : theme.spacing(1),
    background: props => {
      if(props.variant === 'contained') {
        // return (props.isSelected && !props.disabled) ? theme.complementary.main : '#ddd';
        return (props.isSelected && !props.disabled) ? theme.palette.primary.main : '#ddd';
      }
      return undefined;
    },
    boxShadow: 'none',
    borderColor: props => props.isSelected ? theme.palette.primary.main : '#ddd',
    '&:nth-child(n+2)': {
      // marginLeft: theme.spacing(0.25),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  }
}));
// origin style
// const useStyles = makeStyles<Theme, TabItemProps>(theme => ({
//   root: {
//     display: props => !props.isDisplay ? 'none' : 'block',
//     width: '100%',
//     // maxWidth: 180,
//     marginRight: props => props.tabVariant === 'default' ? 0 : theme.spacing(0.25),
//     marginLeft: props => props.tabVariant === 'default' ? 0 : theme.spacing(0.25),
//     // marginLeft: 0,
//     // marginLeft: theme.spacing(0.25),
//     // marginRight: theme.spacing(0.25),
//     borderRadius: props => props.tabVariant === 'default' ? `${8}px ${8}px 0px 0px` : theme.spacing(1),
//     background: props => {
//       if(props.variant === 'contained') {
//         return (props.isSelected && !props.disabled) ? theme.complementary.main : '#ddd';
//       }
//       return undefined;
//     },
//     boxShadow: 'none',
//     color: '#111',
//     borderColor: props => props.isSelected ? theme.palette.primary.main : '#ddd',
//     '&:nth-child(n+2)': {
//       // marginLeft: theme.spacing(0.25),
//     },
//     '&:hover': {
//       backgroundColor: '#bbb',
//       boxShadow: 'none',
//     }
//   }
// }));

const TabItem = (props: TabItemProps) => {
  const {
    isDisplay=true,
    variant='outlined',
    tabVariant='default',
  } = props;

  const classes = useStyles({
    ...props,
    isDisplay,
    tabVariant,
  });

  return (
    <Button
      className={classes.root}
      variant={variant}
      disabled={props.disabled}
      color={props.isSelected ? 'primary' : 'default'}
      onClick={props.onTabChange}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {props.icon}
        {props.label}
      </Box>
    </Button>
  );
};

export default TabItem;