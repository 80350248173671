import { ID } from "all-common-types";

interface SelectedRoute {
  name: string | undefined
  id: string | undefined
}

type BusProviderId = ID | undefined;

interface CommonStateTypes {
  selectedRoute: SelectedRoute
  busProviderId: BusProviderId
}

export const initCommonState = {
  selectedRoute: {
    name: undefined,
    id: undefined
  },
  busProviderId: undefined,
};

export default CommonStateTypes;