import React from 'react';
import { InitNames, TwoAutoCompletesWithExchangeAndSearchProps } from './types';
import { AutoCompleteWithQueryWithResultsAndValues } from '../AutoCompleteWithQuery';
import ButtonsWrapper from './ButtonsWrapper';

export function TwoAutoCompletesWithExchangeAndSearch<Names extends string=InitNames, ResultType=any>(props: TwoAutoCompletesWithExchangeAndSearchProps<Names, ResultType>) {
  const {
    loading,
    queryResultListHook,
    exchangeValueFn,
    searchFn,
    initAutoCompleteWithResultsAndValuesProps,
    autoCompleteWithResultsAndValuesRef,
  } = props;

  return (
    <ButtonsWrapper
      loading={loading}
      exchangeValueFn={exchangeValueFn}
      searchFn={searchFn}
    >
      <AutoCompleteWithQueryWithResultsAndValues
        queryResultListHook={queryResultListHook}
        {...initAutoCompleteWithResultsAndValuesProps}
        autoCompleteWithResultsAndValuesRef={autoCompleteWithResultsAndValuesRef} />
    </ButtonsWrapper>
  );
};

export default TwoAutoCompletesWithExchangeAndSearch;