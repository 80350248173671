import { Reducer } from "all-common-types";
import { FareState, initFareState } from "../constant/context";
import { FareActionTypes } from "../actions";
import { SelectFromToActions } from "../actions/selectFromTo_actions";

type FromToStopsIdReducer = Reducer<FareState, SelectFromToActions, FareState['fromToStopsId']>

type FromToStopsReducer = Reducer<FareState, SelectFromToActions, FareState['fromToStops']>

export const fromToStopsId: FromToStopsIdReducer = (state, action) => {
  switch (action.type) {
    case FareActionTypes.SET_FROM_TO_STOPS_ID:
      return action.payload;
    case FareActionTypes.RESET_FROM_TO_STOPS_ID:
      return initFareState['fromToStopsId'];
    default:
      return state.fromToStopsId;
  }
};

export const fromToStops: FromToStopsReducer = (state, action) => {
  switch (action.type) {
    case FareActionTypes.SET_FROM_TO_STOPS:
      return action.payload;
    default:
      return state.fromToStops;
  }
};