import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ProvidersListProps } from './types';
import { SingleProvider } from 'bus-common-types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

export const getBusProvidersStr = (routeId: ProvidersListProps['routeId']) => (busProviders: SingleProvider[], joinStr=' ') => {
  return busProviders.map(b => b ? b.name : '').join(joinStr);
};

const ProvidersList = ({
  routeId, busProviders
}: ProvidersListProps) => {
  const providersStr = getBusProvidersStr(routeId)(busProviders);

  return (
    <Box
      display={'-webkit-box'}
    >
      <IntlFormattedMessage
        langKey={'drivingMap.timeTable.busProvidersTitle'}
      />
      <Typography variant={'body1'}>{': '}</Typography>
      <Typography variant={ 'body1' }>
        {providersStr}
      </Typography>
    </Box>
  );
};

export default ProvidersList;