import React from 'react';
import { Box, Typography, Link, makeStyles } from '@material-ui/core';
// import AnimatedTimerQueueWrapper, { IntervalAndAnimationTimes } from './Animation/AnimatedTimerQueueWrapper';
import MarqueeWrapper from 'ibus-common-components/lib/components/common-components/Animation/MarqueeWrapper';
import IbusImage from './ImageComponents/IbusImage';
import { SingleNotice } from 'all-common-types';
import { MarqueeWrapperProps } from 'ibus-common-components/lib/components/common-components/Animation/types';
import { MARQUEE_MOVED_PX_PER_SEC } from 'config';

export interface NoticeProps extends SingleNotice {

}

const useNoticeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.white,
  }
}));


export const SingleNoticeItem = ({ link, content }: NoticeProps) => {
  const classes = useNoticeItemStyles();

  return (
    <Box>
      <Typography
        variant={'h6'}
        className={'animated'}
      >
        {!!link ? (
          <Link
            href={link}
            target={'_blank'}
            className={classes.root}
          >
            {content}
          </Link>
        ): (content)}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.5),
    // backgroundColor: theme.palette.grey[200],
    background: theme.homepageColors.noticePart,
    // color: theme.complementary.main,
    color: '#fff',
  },
  animationNoticesPart: {
    width: '100%',
    overflow: 'hidden',
    textAlign: 'left',
  }
}));

interface NoticesProps extends Omit<MarqueeWrapperProps, 'children'> {
  notices: import('all-common-types').Notices
}

const Notices = (props: NoticesProps) => {
  const { notices } = props;

  const classes = useStyles();
  const noticesUpdatingKey = notices.map(n => n.content).join('');

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      className={classes.root}
    >
      <Box
        paddingLeft={1}
      >
        <IbusImage
          width={`${30}px`}
          imageKey={'icons_announce'}
        />
      </Box>
      <Box
        className={classes.animationNoticesPart}
        paddingLeft={1}
      >
        <MarqueeWrapper
          {...props}
          //enforce updating
          key={noticesUpdatingKey}
          autoCalIntervalSeconds={true}
          movedPxPerSec={MARQUEE_MOVED_PX_PER_SEC}
        >
          {notices.map(notice => (
            <SingleNoticeItem key={notice.id} {...notice} />
          ))}
        </MarqueeWrapper>
      </Box>
    </Box>
  );
};

// !!notice.link ? notice.link : ''

export default Notices;