import { SelectFromToActions } from './selectFromTo_actions';
import FareActions from './fare-actions';

export enum FareActionTypes {
  SET_FROM_TO_STOPS_ID,
  RESET_FROM_TO_STOPS_ID,
  SET_ROUTES,
  SET_FROM_TO_STOPS,
}

type FarePageActions = SelectFromToActions | FareActions

export default FarePageActions;

export * from './selectFromTo_actions';

