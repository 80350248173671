import { useCallback, useEffect, useState } from "react";

export interface UseZoomInOutOptions {
  initZoomValue?: number
  zoomInterval?: number
  minZoom?: number
  maxZoom?: number
  callback?: (value: number) => any
}
export const initZoomInOutOptions = {
  initZoomValue: 1,
  zoomInterval: 0.25,
  minZoom: 0.5,
  maxZoom: 1.5,
};

const useZoomInOut = (options: UseZoomInOutOptions=initZoomInOutOptions) => {
  const {
    initZoomValue=initZoomInOutOptions.initZoomValue,
    zoomInterval=initZoomInOutOptions.zoomInterval,
    minZoom=initZoomInOutOptions.minZoom,
    maxZoom=initZoomInOutOptions.maxZoom,
    callback,
  } = options;

  const [zoomValue, setZoom] = useState(initZoomValue);

  const handleZoomIn = useCallback(() => {
    setZoom(z => {
      if(z + zoomInterval <= maxZoom) {
        return z + zoomInterval;
      }
      return z;
    });
  }, [maxZoom, zoomInterval]);

  const handleZoomOut = useCallback(() => {
    setZoom(z => {
      if(z - zoomInterval >= minZoom) {
        return z - zoomInterval;
      }
      return z;
    });
  }, [minZoom, zoomInterval]);

  const handleResetZoom = useCallback(() => {
    setZoom(initZoomInOutOptions.initZoomValue);
  }, []);

  useEffect(() => {
    callback && callback(zoomValue);
  }, [callback, zoomValue]);

  return ({
    zoomValue,
    setZoom,
    handleZoomIn,
    handleZoomOut,
    handleResetZoom,
  });
};

export default useZoomInOut;