import { Callback } from "all-common-types";
import useQueryMrtTravelTimes from "components/mrt-fare/gql/custom-hooks/useQueryMrtTravelTimes";
import getArrLastEl from "lib/functions/getArrLastEl";
import { useEffect, useMemo } from "react";
import MrtStationsTravelTimeHandlers from "../functions/MrtStationsTravelTimeHandlers";
import { getTravelTimeRouteId, UseMrtStationsFaresOptions } from "./useMrtStationsFares";

export interface UseHandleMrtTravelTimesOptions extends UseMrtStationsFaresOptions {
  busFromStationId: number
  mrtFromStationId: number
  setStationsFn: Callback
}

const useHandleMrtTravelTimes = (options: UseHandleMrtTravelTimesOptions) => {
  const {
    goBack,
    selectedRouteId,
    selectedContentMode,
    selectedStationType,
    initHandledStations,
    busFromStationId,
    mrtFromStationId,
    setStationsFn,
  } = options;
  
  const {
    data: queriedMrtTravelTimesData,
    handleQuery: handleQueryMrtTravelTimes,
  } = useQueryMrtTravelTimes({ goBack, });

  useEffect(() => {
    if(selectedContentMode === 'TRAVEL_TIME' && initHandledStations.route310OtherStations.length > 0) {
      const route310LastStation = getArrLastEl(initHandledStations.route310OtherStations);
      
      handleQueryMrtTravelTimes({
        routeId: getTravelTimeRouteId(selectedRouteId),
        goBack,
        busFromStationId,
        mrtFromStationId,
        route310LastStation,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContentMode, busFromStationId, mrtFromStationId]);

  const handledStationsWithTravelTimeContent = useMemo(() => (
    MrtStationsTravelTimeHandlers.combineStationsWithTravelTimesRawData(
      initHandledStations, queriedMrtTravelTimesData
    )(selectedStationType as any, goBack,)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [goBack, selectedStationType, JSON.stringify(initHandledStations), JSON.stringify(queriedMrtTravelTimesData)]);

  useEffect(() => {
    if(selectedContentMode === 'TRAVEL_TIME') {
      if(queriedMrtTravelTimesData) {
        setStationsFn(handledStationsWithTravelTimeContent);
      }
      else { 
        setStationsFn(initHandledStations); 
      }
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContentMode, JSON.stringify(handledStationsWithTravelTimeContent), JSON.stringify(queriedMrtTravelTimesData)]);
};

export default useHandleMrtTravelTimes;