import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import StopReportInputPart from 'components/stop-report-page/components/StopReportInputPart/StopReportInputPart';
import useStopReportInputPart from './functions/useStopReportInputPart';
import { StopReportInputPartContainerProps } from './types';

const StopReportInputPartContainer = (props: StopReportInputPartContainerProps) => {
  const state = useStopReportInputPart(props);
  
  return (
    <Box>
      <StopReportInputPart
        {...state}
        {...props}
        goBackButtonsContainerProps={{
          ...state,
          onSetGoBack: state.handleSetGoBack,
        }}
        reportDescriptionPartContainerProps={{
          onSetDescription: state.handleSetStopReportDescription,
          onSetReportDescription: state.handleSetStopReportDescription,
        }}
        routesFilterSelectorProps={{
          onSetRouteName: state.handleSetRouteName,
          setRouteIdFn: state.handleSetRouteId,
        }}
        stopsSelectorProps={{
          options: state.stopOptions,
          onSetStationMetaData: state.handleSetStationMetaData,
        }}
      />
    </Box>
  );
};

export default memo(StopReportInputPartContainer);