import React from 'react';
import { connectCtx } from 'react-function-helpers';
import { AutoCompleteWithResultsAndValuesRef } from 'ibus-common-components/lib/components/common-components/AutoComplete/types';
import TwoAutoCompletesWithExchangeAndSearch from 'components/common-components/TwoAutoCompletesWithExchangeAndSearch/TwoAutoCompletesWithExchangeAndSearch';
import { SearchByFromToStopsContainerProps, SearchByFromToStopsContainerResultType } from '../types';
import { InitNames } from 'components/common-components/TwoAutoCompletesWithExchangeAndSearch/types';
import useExchangeAutoCompletes from 'components/common-components/TwoAutoCompletesWithExchangeAndSearch/useExchangeAutoCompletes';
import ContextStore from 'components/travel-planner/constant/context';
import mapGetPlansDispatchToSearchFnProps from './mapGetPlansDispatchToSearchFnProps';
import { useSearchByFromToStops } from '.';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import useQueryStationsAndAddress from 'components/travel-planner/lib/functions/useQueryStationsAndAddress';

export const initAutoCompleteWithResultsAndValuesProps = ({
  initNameResultList: {
    from: [],
    to: [],
  },
  initNameValueResults: {
    from: {
      // placeholder: '起點',
      placeholder: (
        <IntlFormattedMessage
          langKey={'from'}
        />
      ) as unknown as string,
      //--for usage test--
      // value: '臺中車站',
      // value: '台中市大里區科技路1號',
      value: '',
      result: undefined,
      getValueResultListFn: () => {},
    },
    to: {
      // placeholder: '終點',
      placeholder: (
        <IntlFormattedMessage
          langKey={'to'}
        />
      ) as unknown as string,
      //--for usage test--
      // value: '草湖',
      // value: '台中市大里區國光路二段710號',
      value: '',
      result: undefined,
      getValueResultListFn: () => {},
    }
  }
});

export const SearchByFromToStopsContainer = (props: SearchByFromToStopsContainerProps) => {
  const {
    planMode, searchFn,
  } = props;

  const ref = React.useRef<AutoCompleteWithResultsAndValuesRef<InitNames, SearchByFromToStopsContainerResultType>>(null);
  
  const {
    loading,
    handleSearchByFromToStops
  } = useSearchByFromToStops({ ref, planMode, searchFn });

  const {
    handleExchange
  } = useExchangeAutoCompletes(ref);

  return (
    <TwoAutoCompletesWithExchangeAndSearch<InitNames>
      loading={loading}
      queryResultListHook={useQueryStationsAndAddress}
      searchFn={handleSearchByFromToStops}
      exchangeValueFn={handleExchange}
      initAutoCompleteWithResultsAndValuesProps={
        initAutoCompleteWithResultsAndValuesProps
      }
      autoCompleteWithResultsAndValuesRef={ref}  />
  );
};

const SearchByFromToStopsContainerWithCtx = connectCtx(ContextStore)(undefined, mapGetPlansDispatchToSearchFnProps)(SearchByFromToStopsContainer);

export default SearchByFromToStopsContainerWithCtx;