import React from 'react';
import { Box } from '@material-ui/core';
import { RouteMapImageProps } from './types';
import getRouteMapImageSrcByUrlPrefix from 'components/route-map/lib/getRouteMapImageSrcByUrlPrefix';

const RouteMapImage = (props: RouteMapImageProps) => {
  const {
    name,
    src
  } = props;

  return (
    <Box>
      <img
        style={{
          width: '100%'
        }}
        alt={name}
        src={getRouteMapImageSrcByUrlPrefix(src)} />
    </Box>
  );
};

export default RouteMapImage;