import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { RouteMapLinkTitleProps } from './types';
import { Link } from 'react-router-dom';
import { ID } from 'all-common-types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import routes from 'constants/routes';

export const getRouteMapLinkTitleLink = (routeId: ID) => (
  `${routes.routeMapWithNoParams}/${routeId}`
);

const useStyles = makeStyles(theme => ({
  root: {
    '&> a': {
      color: theme.palette.primary.dark,
    }
  },
  link: {

  },
  routeName: {
    paddingRight: theme.spacing(2),
  },
  description: {
    paddingRight: theme.spacing(1),
  }
}));

const RouteMapLinkTitle = ({
  routeId, routeName, routeDescription,
}: RouteMapLinkTitleProps) => {
  const classes = useStyles();
  const link = getRouteMapLinkTitleLink(routeId);
  
  return (
    <Box className={classes.root}>
      <Link to={link}>
        <Box display={'flex'} flexWrap={'wrap'}>
          <IntlFormattedMessage
            variant={'h6'}
            className={classes.routeName}
            langKey={'routeWithVar'}
            values={{
              routeName: routeName
            }}
          />
          <Typography
            variant={'h6'}
            className={classes.description}
          >
            {routeDescription}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default RouteMapLinkTitle;