import { blogContent_mockData } from './mockBlogContent';

export const linksData_mockData: import('all-common-types').Links = [{ 
  id: 1, 
  title: 'title-0', 
  link: '/', 
  isDisplay: true,
  isInChildren: false,
  isTargetBlank: false, 
  children: [] 
},
{ 
  id: 2, 
  title: 'title-2', 
  link: '/', 
  isDisplay: true,
  isInChildren: false,
  isTargetBlank: true, 
  children: [] 
},
];

export const titleData_mockData: import('../title').TitleProps = {
  titleZh: '最新消息',
  titleEng: 'News'
};

export const titleData_qrcode_mockData: import('../title').TitleProps = {
  titleZh: '相關連結',
  titleEng: 'Links'
};

export const singleBlogData_mockData: import('blog').SingleBlog = 
  { 
    id: '1', 
    title: '環東路地下道5/5填平開放通車 市公車自5/5恢復原動線行駛', 
    createTime: '2019-09-24', 
    content: blogContent_mockData,
    flag: null, 
    created_at: "2019-09-24T08:54:26.440Z",
    "onShelfAt": "2019-09-01 00:00:00",
    "offShelfAt": "2019-09-19 00:00:00"
  };

export const blogsData_mockData: import('../blogs').BlogsProps = {
  titleData: titleData_mockData,
  blogsData: [singleBlogData_mockData]
};

export const singleQRcodeData_mockData: import('blog').SingleQRcode = {
  url: '',
  qrcodeSrc: 'https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fitunes.apple.com%2Ftw%2Fapp%2F%E5%8F%B0%E4%B8%AD%E5%85%AC%E8%BB%8A%2Fid590226800%3Fl%3Dzh%26mt%3D8&chs=150x150',
  qrcodeBaseImgSrc: ''
};

export const qrcodesData_mockData: import('../qrCodes').QRcodesProps = {
  qrcodesData: [
    singleQRcodeData_mockData
  ]
};

