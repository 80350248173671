import { useIntl } from "react-intl";
import { useCallback, useEffect, useMemo } from "react";
import { QUERY_STATIONS_FOR_FARE, QueriedStationsForFare, SingleStationForFare, SingleStationForFareWithGoBack } from "../gql-schema";
import { ID } from "all-common-types";
import { useLazyQuery } from "@apollo/react-hooks";
import { nodeDataFromEdges } from "constants/functions";
import { FareState, initFareState } from "components/fare/constant/context";
import { GoBack } from "bus-common-types";
import { client} from "constants/API";

export interface HandledStationForFare extends SingleStationForFareWithGoBack {
}

const useQueryStopsForFare = () => {
  const [queryFn, { error, loading, data }] = useLazyQuery<QueriedStationsForFare>(QUERY_STATIONS_FOR_FARE, {
    client: client,
  });

  const {
    locale,
  } = useIntl();

  const handleQuery = useCallback((routeId: ID) => {
    queryFn({
      variables: {
        routeId: Number(routeId),
        lang: locale,
      }
    });
  }, [locale, queryFn]);

  const stationsForFare: HandledStationForFare[] = useMemo(() => (
    data ? data.route.stations.edges.map(s => ({
      ...s.node,
      goBack: s.goBack,
    })) : []
  ), [data]);

  return ({
    error,
    loading,
    handleQuery,
    data,
    stationsForFare,
  });
};

export default useQueryStopsForFare;