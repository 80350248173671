import React from 'react';
import { Box, RadioGroup } from '@material-ui/core';
import { RadioTabListProps } from './types';
import RadioTabItem from './RadioTabItem';

const RadioTabList = ({
  listWrapperProps={},
  value,
  labelLangKeyListData,
  onChange,
}: RadioTabListProps) => {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
    >
      <Box {...listWrapperProps}>
        {labelLangKeyListData.map((langKey, i) => (
          <RadioTabItem
            key={i}
            {...langKey}
            value={langKey.value}
          />
        ))}
      </Box>
    </RadioGroup>
  );
};

export default RadioTabList;