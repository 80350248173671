/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, ChangeEvent, ReactNode } from 'react';
import { SingleSelectOption, Callback } from 'all-common-types';

export type SelectsValues<SelectsIds extends string> = {
  [id in SelectsIds]: {
    name: ReactNode
    options: SingleSelectOption[]
    value: string
  }
}

type UpdateFn<SelectsIds extends string> = (select: SelectsValues<SelectsIds>, setDataCb?: Callback) => SelectsValues<SelectsIds> | Promise<SelectsValues<SelectsIds>>

export type UseSelectsValuesHandleFunctions<SelectsIds extends string> = {
  [id in SelectsIds]: UpdateFn<SelectsIds>
}

export interface UseSelectsValuesParams<SelectsIds extends string, Data extends any=[]> {
  initSelectsValues: SelectsValues<SelectsIds>,
  handleFunctions: UseSelectsValuesHandleFunctions<SelectsIds>,
  initDataForHandle?: Data
  handleDataUpdateFn?: (select: SelectsValues<SelectsIds>, setDataCb: Callback) => any
}

export function handleNewValue<SelectsIds extends string>({
  values,
  value,
  id,
}: {
  values: SelectsValues<SelectsIds>,
  value: string,
  id: SelectsIds
}) {
  return ({
    ...values,
    [id]: {
      ...values[id],
      value,
    }
  });
}

function useSelectsValues<SelectsIds extends string, Data extends any=[]>({
  initSelectsValues,
  handleFunctions,
  initDataForHandle=[] as any,
  handleDataUpdateFn,
}: UseSelectsValuesParams<SelectsIds, Data>) {
  const [handledData, setData] = useState<Data>(initDataForHandle);
  const [selectsValues, setselectsValues] = useState(initSelectsValues);

  const handleChange = useCallback((id: SelectsIds) => {
    return (e: ChangeEvent<{ value: unknown}>) => {
      const { value } = e.target as { value: string };
      const fn = handleFunctions[id];
      const newValues = handleNewValue({
        values: selectsValues,
        value,
        id,
      });
      setselectsValues(newValues);

      (async function() {
        const handledByFnNewValues = await fn(newValues);
        setselectsValues(handledByFnNewValues);
      }());
    };
  }, [handleFunctions, selectsValues]);

  React.useEffect(() => {
    (async function() {
      let newValues = initSelectsValues;
      for (const id in handleFunctions) {
        newValues = await handleFunctions[id](initSelectsValues);
      }
      setselectsValues(newValues);
    }());
  }, []);

  React.useEffect(() => {
    if(handleDataUpdateFn) {
      handleDataUpdateFn(selectsValues, setData);
    }
  }, [selectsValues]);

  return ({
    handledData,
    selectsValues,
    handleChange,
  });
};

export default useSelectsValues;