import React from 'react';
import { ContextWrapper } from 'ibus-common-components/lib/components/common-components/Menus/buttonContext';
import BasicMenuLinks from 'ibus-common-components/lib/components/common-components/Menus/BasicMenu/BasicMenuLinks';
import { SubMenuLinksProps, LinkButtonStyle } from 'ibus-common-components/lib/components/common-components/Menus/types';
import LangMenuLinksItem from 'ibus-common-components/lib/components/common-components/Menus/LangMenuLinksItem/LangMenuLinksItem';
import { Box, Button, makeStyles } from '@material-ui/core';
import IntlButton from 'components/common-components/IntlComponents/IntlButtons';
import DisplayModeToggleButtonContainer from 'components/common-components/Buttons/DisplayModeToggleButtonContainer';

const subMenuLinksLinkButtonStyles: LinkButtonStyle = {
  variant: 'body1',
  paddingLeftRight: 1,
  paddingTopBottom: 0.5,
  buttonVariant: 'outlined',
  buttonColor: 'inherit',
};

const useStyles = makeStyles(theme => ({
  root: {
    '& button': {
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white,
    }
  }
}));

const SubMenuLinks = (props: SubMenuLinksProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <ContextWrapper customInitState={{
        linkButtonStyle: subMenuLinksLinkButtonStyles
      }}>
        <BasicMenuLinks {...props}>
          <LangMenuLinksItem {...props} />
          <DisplayModeToggleButtonContainer />
        </BasicMenuLinks>
      </ContextWrapper>
    </Box>
  );
};

export default SubMenuLinks;