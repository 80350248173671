import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import BlogContent from './BlogContent';

type SingleBlogPageProps = import('blog').SingleBlog
const SingleBlogPage = ({ title, createTime, content }: SingleBlogPageProps) => {
  return (
    <Box padding={1}>
      <Box paddingBottom={1}>
        <Typography variant={'h6'}>{title}</Typography>
        <IntlFormattedMessage 
          langKey={'createTimeWithVar'}
          values={{
            createTime
          }}
        />
        <Divider />
      </Box>
      <BlogContent content={content} />
    </Box>
  );
};

export default SingleBlogPage;