import { EstimateStationInput } from "all-common-types";
import { QueryTravelTimesParams, SingleCollinearRoutesTravelTimeInput } from "components/collinear-routes/gql/schemas/QUERY_TRAVEL_TIMES";
import { RoutesByStationNameHandlerFinalResult } from "./RoutesByStationNameHandler";

const MakeTravelTimeInputFromEstimateTimeResult = {
  makeSingleInput(fromEstimateStopInput: EstimateStationInput, toEstimateStopInput: EstimateStationInput): SingleCollinearRoutesTravelTimeInput {
    return ({
      xno: fromEstimateStopInput.xno,
      goBack: fromEstimateStopInput.goBack,
      fromStationId: fromEstimateStopInput.stationId,
      toStationId: toEstimateStopInput.stationId,
    });
  },
  
  makeQueryParams(result: Pick<RoutesByStationNameHandlerFinalResult, 'from' | 'to'>): QueryTravelTimesParams {
    const {
      from,
      to,
    } = result;

    const res = from.stationEstimateInputs.map((fromEstimateStopInput, i) => (
      this.makeSingleInput(fromEstimateStopInput, to.stationEstimateInputs[i])
    ));

    return ({
      inputs: res,
    });
  },
};

export default MakeTravelTimeInputFromEstimateTimeResult;
