import React from 'react';
import { Box } from '@material-ui/core';
import { useSelectTab } from 'lib/customHooks';
import PlanTabList from 'components/collinear-routes/components/SearchPart/PlanTabList';
import StopSearchWithRouteInfo from '../stopSearchContainers/StopSearchWithRouteInfo';
import { IntervalSearchContainerWithCtx } from '../intervalSearchContainers/IntervalSearchWithRouteInfoContainer';
import { MapDispatchToProps } from 'lib/contextReduxLikeHelpers/mapContextToProps';
import connectCtx from 'lib/contextReduxLikeHelpers/connectCtx';
import ContextStore, { CollinearRoutesState } from 'components/collinear-routes/constants/context';
import { resetRouteId, resetStopSearchFromTo, setSearchType } from 'components/collinear-routes/actions';
import { Callback } from 'all-common-types';
import useGoldThemeLeftPartStyles from 'styles/styleObjs/goldThemeLeftPartStyles';
import { resetRouteData } from 'components/collinear-routes/actions/stop-search-states-actions';
import { resetFromStation } from 'components/collinear-routes/actions/from-station-id.actions';

import { makeStyles } from "@material-ui/core";

export const convertTabToSearchType = (selectedTab: number): CollinearRoutesState['searchType'] => (
  selectedTab === 0 ? 'from-to-search' : 'stop-search'
);

const useStyle = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '85vh',
    }
  }
}));

type SearchAndRouteInfoContainerProps = {
  setSearchType: typeof setSearchType
  resetCtx?: Callback
}

export const SearchAndRouteInfoContainer = (props: SearchAndRouteInfoContainerProps) => {
  const {
    resetCtx, setSearchType,
  } = props;
  const classes = useGoldThemeLeftPartStyles();
  const myClasses = useStyle();
  const [selectedTab, handleTab] = useSelectTab(0, resetCtx);

  React.useEffect(() => {
    const searchType = convertTabToSearchType(selectedTab);
    setSearchType(searchType);
  }, [selectedTab, setSearchType]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      className={myClasses.root}
    >
      <PlanTabList
        selectedTab={selectedTab}
        selectTabFn={handleTab} />
      <Box
        display={'flex'}
        flexDirection={'column'}
        flexGrow={1}
        className={classes.root}
      >
        {selectedTab === 0 && (
          <IntervalSearchContainerWithCtx />
        )}
        {selectedTab === 1 && (
          <StopSearchWithRouteInfo />
        )}
      </Box>

    </Box>
  );
};

export const mapDispatchToProps: MapDispatchToProps<{}, SearchAndRouteInfoContainerProps> = (dispatch) => {
  return ({
    resetCtx: () => {
      dispatch(resetRouteId());
      dispatch(resetStopSearchFromTo());
      dispatch(resetRouteData());
      dispatch(resetFromStation());
    },

    setSearchType: (s) => {
      const action = setSearchType(s);
      dispatch(action);
      return action;
    }
  });
};

export const SearchAndRouteInfoContainerWithCtx = connectCtx(ContextStore)(undefined, mapDispatchToProps)(SearchAndRouteInfoContainer);

export default SearchAndRouteInfoContainerWithCtx;