import { actionTypes as Types } from '../actions';
import { DrivingMapState } from '../constants/context';
import { AllActionTypes } from '../actions';

export const routeData = (state: DrivingMapState, action: AllActionTypes): DrivingMapState['routeData'] => {
  switch (action.type) {
    case Types.SET_ROUTE_DATA: {
      console.log('route data set.');
      return action.routeData;
    }
    case Types.RESET_ROUTE_DATA: {
      console.log('route data reseted.');
      return undefined;
    }
    default:
      return state.routeData;
  }
};