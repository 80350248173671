import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import SideStationList from './SideStationList';
import CenterIntersectionStationList from './CenterIntersectionStationList';
import { BusStationsPartProps } from './types';
import { COMMON_STATIONS_PART_SPECS, PADDING_BETWEEN_STATIONS } from 'components/mrt-fare/config';
import splitStationsForCommonPart from './functions/splitStationsForCommonPart';
import BusCommonStationsPartLines from './BusCommonStationsPartLines';

const BusCommonStationsPart = (props: BusStationsPartProps) => {
  const {
    commonStationListData,
  } = props;

  const {
    leftStations,
    centerStations,
    rightStations,
  } = useMemo(() => splitStationsForCommonPart(commonStationListData), [commonStationListData]);

  if(commonStationListData.length === 0) {
    return null;
  }

  return (
    <Box 
      display={'flex'} 
      alignItems={'space-between'} 
      flexShrink={0}
      position={'relative'}
    >
      <BusCommonStationsPartLines rightStationsLength={rightStations.length} />
      <SideStationList 
        {...props}
        stationListData={leftStations} 
        padding={PADDING_BETWEEN_STATIONS}
        stationNameDirection={'bottom'}
        partPositionStyle={{
          display: 'flex',
          paddingRight: COMMON_STATIONS_PART_SPECS.paddingBetweenParts,
        }}
      />
      <CenterIntersectionStationList 
        {...props} 
        stationListData={centerStations}
      />
      <Box position={'relative'} alignSelf={'flex-end'}>
        <SideStationList 
          {...props} 
          stationListData={rightStations}
          padding={PADDING_BETWEEN_STATIONS}
          stationNameDirection={'top'}
          partPositionStyle={{
            display: 'flex',
            position: 'relative',
            paddingLeft: COMMON_STATIONS_PART_SPECS.rightPartPadding,
          }}
        />
      </Box>
    </Box>
  );
};

export default BusCommonStationsPart;