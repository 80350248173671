import React from "react";
import { Box, Grid, makeStyles, useTheme } from "@material-ui/core";
import HomePageImage from "../HomePageImage";
import IconLinks from "../iconLinks";
import ibusImages from "static/ibusImages.json";
import { MiddleNavPartProps } from "./types";
import { checkIsXmasTheme } from "theme/xmasThemeRedBlack";
import checkHolidayTheme from "theme/checkHolidayTheme";
import { checkIsNewNearCustomer } from "theme/newYearThemeCustomer";
import WelcomTitle from "../WelcomTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  iconLinks: {
    zIndex: 10,
    order: 3,
    [theme.breakpoints.up("lg")]: {
      order: 2,
    },
  },
  topLeft: {},
  homePageImageTopRight: {
    paddingTop: 0,
    order: 2,
  },
}));

const MiddleNavPart = (props: MiddleNavPartProps) => {
  const { homepageImages } = useTheme();
  const classes = useStyles();

  return (
    <Grid container alignItems={"center"} className={classes.root}>
      <Grid item xs={12} md={6} lg={3}>
        <HomePageImage
          className={classes.topLeft}
          md={12}
          lg={12}
          imgSrc={homepageImages.topLeft}
        />
      </Grid>
      <Grid item xs={12} lg={6} className={classes.iconLinks}>
        <Box paddingX={1}>
          <WelcomTitle />
          <IconLinks linksData={props.linksData} />
        </Box>
      </Grid>
      <HomePageImage
        className={classes.homePageImageTopRight}
        md={6}
        lg={3}
        imgSrc={homepageImages.topRight}
      />
    </Grid>
  );
};

export default MiddleNavPart;
