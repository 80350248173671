import { FARE_BUTTON_WIDTH } from "components/mrt-fare/config";
import { StationNameDirection } from "mrt-pages";

const getTransformByDirection = (direction: StationNameDirection) => {
  // if(direction === 'top') {
  //   return `translateY(${'-100%'})`;
  // } 
  if(direction === 'left') {
    return `translateX(calc(-100% - ${FARE_BUTTON_WIDTH * 1.1}px))`;
  } 
  return 'unset';
};

export default getTransformByDirection;