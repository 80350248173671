import { useCallback, useState } from 'react';
import { QueryStationsNamesByValueData } from 'constants/API/commonAPI';
import HandleQueryStationsByValueData from './QueryStationsByNameAutoCompleteHandler';
import { AutoCompleteWithQueryStationsValueResultList } from './types';
import { useIntl } from 'react-intl';
import queryStationsByName from './lib/queryStationsByName';
import { Locale } from 'all-common-types';

const useQueryStations = () => {
  const {
    locale,
  } = useIntl();

  const [data, setData] = useState<QueryStationsNamesByValueData>();
  const [valueResultList, setValueResultList] = useState<AutoCompleteWithQueryStationsValueResultList>([]);
  
  const handleGetValueResultList = useCallback((value: string | undefined) => {
    if(value) {
      queryStationsByName(locale as Locale, value)
        .then(res => {
          const data = {
            stationsByName: res,
          };
          setData(data);
          const handledData = HandleQueryStationsByValueData.convertToValueResults(data.stationsByName);
          setValueResultList(handledData);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [locale]);

  return ({
    handleGetValueResultList,
    data,
    valueResultList,
  });
};

export default useQueryStations;