import useQueryMrtStations from "components/mrt-fare/gql/custom-hooks/useQueryMrtStations";
import useGoBackSwitch from "lib/customHooks/useGoBackSwitch";
import { StationContentMode, StationType } from "mrt-pages";
import { useCallback, useEffect, useState } from "react";
import { SetStationFn, SetStationPayload } from "../../../components/common/types";
import MrtStationsFareHandlers from "../functions/MrtStationsFareHandlers";
import useMrtStationsFares from "./useMrtStationsFares";

export interface UseStationsPartOptions {
  selectedContentMode: StationContentMode
}

export interface SelectedStation extends SetStationPayload {
  stationId: number
  stationType: StationType | undefined
}

export const initSelectedMrtStation: SelectedStation = {
  routeId: -1,
  stationId: -1,
  stationType: undefined,
};

const useStationsPart = (options: UseStationsPartOptions) => {
  const {
    selectedContentMode,
  } = options;

  const {
    checked,
    goBack,
    handleChangeGoBackSwitch,
  } = useGoBackSwitch();

  const {
    handledStations: initHandledStations,
    loading,
    error,
  } = useQueryMrtStations({ goBack, });

  const [selectedStation, setStation] = useState<SelectedStation>(initSelectedMrtStation);
  const [stationDataList, setStationDataList] = useState(initHandledStations);

  const {
    handledStationsWithContent,
    intersectionStationFareContent,
  } = useMrtStationsFares({
    goBack,
    initHandledStations: ({
      ...initHandledStations,
      intersectionContents: MrtStationsFareHandlers.initIntersectionStationContents,
    }),
    selectedContentMode,
    selectedRouteId: selectedStation.routeId,
    selectedStationId: selectedStation.stationId,
    selectedStationType: selectedStation.stationType,
  });

  const handleSetStation: SetStationFn = useCallback((payload) => () => {
    setStation({
      ...payload,
      stationId: Number(payload.stationId),
    });
  }, []);

  useEffect(() => {
    setStationDataList(handledStationsWithContent);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(handledStationsWithContent)]);

  useEffect(() => {
    setStationDataList(initHandledStations);
  }, [initHandledStations]);

  useEffect(() => {
    setStation(initSelectedMrtStation);
  }, [goBack]);

  return ({
    loading,
    error,
    goBack,
    selectedStationId: selectedStation.stationId,
    stationDataList,
    intersectionStationFareContent,
    checked,
    handleSetStation,
    handleChangeGoBackSwitch,
  });
};

export default useStationsPart;