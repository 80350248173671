/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import ContextStore from '../../constants/context';
import RouteLine from './RouteLine';
import RouteStopMarker, { RouteStopMarkerProps } from './RouteStopMarker';
import { GoBack, Position, GoBackStr } from 'bus-common-types';
import { getGoBackChoice } from 'constants/functions';
import RouteStopMarkerContainer from 'components/driving-map/containers/BusMap/RouteStopMarkerContainer';
import { RouteStopMarkerContainerProps } from 'components/driving-map/containers/BusMap/types';

export type GoBackMarkersAndRouteLine = {
  [x in GoBackStr]: {
    routeStopMarkers: RouteStopMarkerContainerProps[]
    routePoints: Position[]
  }
}
export type RouteStopsAndRouteLineProps = {
  routeStopMarkersAndPoints: GoBackMarkersAndRouteLine
  selectedRouteStopIndex?: number
  goBack: GoBack
}

export const RouteStopsAndRouteLine = ({ 
  routeStopMarkersAndPoints,
  selectedRouteStopIndex, 
  goBack 
}: RouteStopsAndRouteLineProps) => {
  const thisMarkersAndPoints = getGoBackChoice(routeStopMarkersAndPoints)(goBack);
  const {
    routeStopMarkers, 
    routePoints, 
  } = thisMarkersAndPoints;
  return (
    <>
      {routeStopMarkers.map((data, i) => {
        const isOpen = selectedRouteStopIndex === i;
        return (
          <RouteStopMarkerContainer
            {...data}
            key={data.stationId}
            index={ i } 
            isOpen={isOpen}
          />
        );
      })}
      <RouteLine 
        routePoints={ routePoints }
        goBack={goBack} />
    </>
  );
};

const RouteStopsAndRouteLineWithCtx = (props: RouteStopsAndRouteLineProps) => {
  const { state } = useContext(ContextStore);
  const { selectedRouteStopIndex } = state;
  return (
    <RouteStopsAndRouteLine 
      selectedRouteStopIndex={ selectedRouteStopIndex } 
      {...props} />
  );
};

export default RouteStopsAndRouteLineWithCtx;