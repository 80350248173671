import useQueryRouteMapListData from "components/route-map-list/gql/custom-hooks/useQueryRouteMapListData";
import { useEffect, useMemo } from "react";
import RouteMapListPageHandlers from "../Handlers/RouteMapListPageHandlers";
import useDataListPagination from "lib/customHooks/useDataListPagination";
import { ROWS_PER_PAGE } from "components/route-map-list/config";
import useSearchBar from "./useSearchBar";

const useRouteMapListPage = () => {
  const queried = useQueryRouteMapListData();
  const searchBarState = useSearchBar({});
  const {
    searchVal,
  } = searchBarState;

  const {
    handleQuery,
    data,
  } = queried;

  useEffect(() => {
    handleQuery();
  }, [handleQuery]);

  const routeMapLinkListData = useMemo(() => (
    data ? RouteMapListPageHandlers.getRouteMapListDataFromRawData(data) : []
  ), [data]);

  const filteredRouteMapLinkListData = useMemo(() => (
    RouteMapListPageHandlers.filterRouteMapListDataByValue(routeMapLinkListData, searchVal)
  ), [routeMapLinkListData, searchVal]);

  const dataListPaginationState = useDataListPagination({
    rowsPerPage: ROWS_PER_PAGE,
    itemListData: filteredRouteMapLinkListData,
  });
  const {
    itemListDataNow,
  } = dataListPaginationState;

  return ({
    ...queried,
    ...dataListPaginationState,
    searchBarState,
    routeMapLinkListData: itemListDataNow,
  });
};

export default useRouteMapListPage;