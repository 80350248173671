const verifyRouteId = (routeId: any) => {
  let res = false;
  const numberedRouteId = Number(routeId);
  const isEmptyStr = routeId === '';

  if(res !== null && !isNaN(numberedRouteId) && !isEmptyStr) {
    res = true;
  }

  return res;
};

export default verifyRouteId;