import React from "react";
import { Map as LeafletMap, TileLayer } from "react-leaflet";
import { handleMapSpec } from "components/driving-map/components/BusMap/functions/busMapPart";
import TGOSTileLayer from 'components/tgos/TGOSTileLayer';
import { BusMapWrapperProps } from "./types";
import useBasicMapStyles from "styles/styleObjs/mapStyles";
import { Box } from "@material-ui/core";

const BusMapWrapper = (props: BusMapWrapperProps) => {
  const {
    position,
    selectedRouteStopIndex,
    children,
    mapData
  } = props;
  const classes = useBasicMapStyles();

  const mapSpec = handleMapSpec.getSpec({
    position,
    selectedRouteStopIndex,
    routeStopMarkers: mapData.routeStops,
    routePoints: mapData.routePoints,
  });

  return (
    <Box
      display={'flex'}
      flexDirection={'columne'}
      flexGrow={'1'}
      className={classes.root}
    >
      <LeafletMap {...mapSpec} zoom={14}>
        <TGOSTileLayer opacity={0.9} />
        {children}
      </LeafletMap>
    </Box>
  );
};

export default BusMapWrapper;
