import gql from "graphql-tag";
import RouteFragment from 'constants/api-fragments';
import { EdgesData, SingleRouteFromRoutes } from "all-common-types";
import { SingleEstimateStop, RouteDataType } from "bus-common-types";

export const QUERY_COLLINEAR_ROUTES_ROUTE_DATA = gql`
  query QUERY_COLLINEAR_ROUTES_ROUTE_DATA($routeId: Int!, $lang: String!) {
    route(xno: $routeId, lang: $lang) {
      id
      name
      description
      departure
      destination
      opType
      routePoint {
        go
        back
      }
      providers {
        edges {
          node {
            id
            name
          }
        }
      }
      stations {
        ...stationsInfo
      }
    }
  }
  ${RouteFragment.fragment.stationsInfo}
`;

export interface QueriedCollinearRoutesRouteData {
  route: Omit<RouteDataType, 'buses'>
}

export const GET_ROUTES_FROM_STATION = gql`
  query GET_ROUTES_FROM_STATION($station: String!, $lang: String!) {
    stationsByName(name: $station, lang: $lang) {
      id
      name
      lat
      lon
      county
      town
      routes {
        edges {
          node {
            id
            name
            description
            seq
          }
        }
      }
    }
  }`;

export type SingleStationByNameData = {
  id: string
  name: string
  lat: number
  lon: number
  county: string
  town: string
  routes: EdgesData<
  Pick<SingleRouteFromRoutes, 'id' | 'name' | 'description' | 'seq'>
  >
}
export type RoutesFromStationDataType = {
  stationsByName: SingleStationByNameData[]
}
