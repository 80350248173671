import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container, Typography, Box, Paper } from '@material-ui/core';
import BreadCrumbs from './breadCrumbs';
import { SinglePage, SingleLink } from 'all-common-types';
import ContextStore, { State } from 'constants/context';
import handleLinksAndPagesMatched, { regShortUrl } from 'lib/handleRouteMatched/handleLinksAndPagesMatched';
import { MapStateToProps } from 'lib/contextReduxLikeHelpers/mapContextToProps';
import connectCtx from 'lib/contextReduxLikeHelpers/connectCtx';
import useGoldThemeLeftPartStyles from 'styles/styleObjs/goldThemeLeftPartStyles';
import IntlFormattedMessage from './IntlFormattedMessage';

import MaterialReactMarkdown from '../common-components/MaterialReactMarkdown';

const matchedRoutesForPage = [
  { path: '/', breadCrumbName: '首頁' },
];

export const PageContent = ({ title, content }: SinglePage) => {
  const classes = useGoldThemeLeftPartStyles();
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      paddingTop={1}
      paddingBottom={1}
    >
      <Paper
        className={classes.root}
        style={{
          flexGrow: 1,
          height: 1,
          overflow: 'auto',
        }}>
        <Typography variant={'h6'}>{title}</Typography>
        <MaterialReactMarkdown children={content} />
      </Paper>
    </Box>
  );
};

type PageProps = {
  page: SinglePage
  links: SingleLink[]
}
export const Page = ({ links, page }: PageProps) => {
  const matchedRoutes = handleLinksAndPagesMatched.getMatchedRouteListWithPages(page)(links);
  return (
    <>
      <BreadCrumbs matchedRoutes={matchedRoutes} />
      <PageContent {...page}/>
    </>
  );
};
export const NotFound = () => {
  return (
    <Typography
      variant={'h3'}
      style={{
        color: '#fff',
      }}
    >
      {'404 not found'}
    </Typography>
  );
};
export const NotFoundPage = () => {
  return (
    <>
      <BreadCrumbs matchedRoutes={[
        ...matchedRoutesForPage,
        { path: '/:::not-found', breadCrumbName: 'not found' },
      ]} />
      <NotFound />
    </>
  );
};
export const PageIsLoading = () => {
  return (
    <IntlFormattedMessage
      variant={'h4'}
      style={{
        color: '#fff',
        opacity: 0.75,
      }}
      langKey={'page.isLoading'}
    />
  );
};
export const LoadingPage = () => {
  return (
    <Box
      padding={2}
      textAlign={'center'}
    >
      <PageIsLoading />
    </Box>
  );
};



type PagesProps = {
  pages: SinglePage[]
  links: SingleLink[]
}
export const Pages = (props: PagesProps) => {
  const { pages, links } = props;

  return (
    <Container style={{ display:'flex', flexDirection:'column', flexGrow:1 }}>
      <Switch>
        {pages.map((page, i) => (
          <Route
            key={i}
            path={regShortUrl(page.shortUrl)}
            render={() => <Page links={links} page={page}/>} />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  );
};

export const mapStateToProps: MapStateToProps<State, {}, PagesProps> = (state) => {
  return ({
    ...state.ibusCommonData,
    links: [
      ...state.ibusCommonData.mainMenuLinks,
      ...state.ibusCommonData.subMenuLinks,
    ]
  });
};

export const PagesWithCtx = connectCtx(ContextStore)(mapStateToProps)(Pages);

