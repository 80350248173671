import { useFnsByKeyCode } from "ibus-common-components/lib/components/common-components/AutoComplete/functions";
import useInput from "lib/customHooks/useInput";
import { useCallback, useState } from "react";

export interface UseSearchBarOptions {
  initSearchVal?: string
  onSearchCallback?: (searchVal: string) => any
}

const useSearchBar = ({
  initSearchVal='',
  onSearchCallback
}: UseSearchBarOptions) => {
  const [searchVal, setSearchVal] = useState(initSearchVal);

  const {
    value,
    handleClearInput,
    handleChangeInput,
  } = useInput({});

  const handleSearch = useCallback(() => {
    setSearchVal(value);
    onSearchCallback && onSearchCallback(value);
  }, [onSearchCallback, value]);

  const handleClearSearch = useCallback(() => {
    setSearchVal('');
    handleClearInput();
    onSearchCallback && onSearchCallback('');
  }, [handleClearInput, onSearchCallback]);

  useFnsByKeyCode({
    lastIndex: 0,
    confirmFn: () => handleSearch,
    escapeFn: handleClearSearch,
  });

  return ({
    value,
    searchVal,
    setSearchVal,
    handleChangeInput,
    handleSearch,
    handleClearSearch,
  });
};

export default useSearchBar;