import React from 'react';
import { GoBackButtonsProps } from './types';
import DepDestTabList from 'components/common-components/TabComponents/DepDestTabList';

const GoBackButtons = (props: GoBackButtonsProps) => {
  return (
    <DepDestTabList
      {...props}
      variant={'contained'}
      tabVariant={'button'}
    />
  );
};

export default GoBackButtons;