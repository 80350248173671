import React from 'react';
import { Box, Button, makeStyles, Divider } from '@material-ui/core';
import CloseButton from '../CloseButton';
import { ClogMessagePartProps } from './types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import ClogMessageItem from './ClogMessageItem';
import zIndex from 'styles/styleObjs/zIndex';
import styleLayout from 'styles/styleObjs/styleLayout';
import ClogMessageList from './ClogMessageList';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 60,
    right: 0,
    left: 0,
    margin: 'auto',
    zIndex: zIndex.timeTable,
    backgroundColor: '#fff',
    // padding: theme.spacing(2),
    maxHeight: styleLayout.appContentHeight,
    width: 400,
    overflow: 'auto',
    boxShadow: '0px 6px 10px #aaa'
  },
  header: {
    backgroundColor: theme.palette.primary.main,
  },
  clogTitle: {
    color: theme.palette.common.white,
  }
}));

const ClogMessagePart = (props: ClogMessagePartProps) => {
  const {
    onToggleClog,
    toggledClog,
  } = props;
  const classes = useStyles();

  if(!toggledClog) {
    return null;
  }

  return (
    <Box
      position={ 'topright' } 
      className={ classes.root }
    >
      <CloseButton 
        onClick={onToggleClog}
      />
      <Box
        padding={1}
        className={classes.header}
      >
        <IntlFormattedMessage
          variant={'h6'}
          langKey={'drivingMap.mapControl.clog.title'}
          className={classes.clogTitle}
        />
      </Box>
      <ClogMessageList
        {...props}
      />
    </Box>
  );
};

export default ClogMessagePart; 