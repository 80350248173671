import { FareDataFromQuery, FaresMatchOptions, FromToStationIds, SingleFare, SingleFarePrices } from "fare";
import { nodeDataFromEdges } from "constants/functions";
import { ID } from "all-common-types";
import { FareResult } from "../components/ResultPart/types";
import { PriceTypes, hidenETicketPriceInfoRouteIds } from "../config";
import { GoBack } from 'bus-common-types';

export const defaultPrice = '-';
export const defaultBoardPrice = undefined;
export const defaultZeroPrice = 0;

export const defaultPrices: PriceTypes = {
  full: defaultPrice,
  half: defaultPrice,
};
export const defaultZeroPrices: PriceTypes = {
  full: defaultZeroPrice,
  half: defaultZeroPrice,
};

export const defaultFareResult: FareResult = {
  shouldHideETicketInfo: false,
  cashPrices: defaultPrices,
  eTicketPrices: defaultPrices,
  eCitizenPrices: defaultPrices,
};
export const defaultZeroFareResult: FareResult = {
  shouldHideETicketInfo: false,
  cashPrices: defaultZeroPrices,
  eTicketPrices: defaultZeroPrices,
  eCitizenPrices: defaultPrices,
};

class FarePricesHandler {
  static handleRawData(rawData: FareDataFromQuery): SingleFare[] {
    const res = nodeDataFromEdges(rawData.fares);
    return res;
  }

  static matchSinglePricesByFromToStopId({
    fromStationId, toStationId, goBack, shouldIgnoreGoBackFilter,
  }: FaresMatchOptions) {
    return (singleFare: SingleFare): boolean => {
      const matchedRes =
        String(singleFare.fromStationId) === String(fromStationId) &&
        String(singleFare.toStationId) === String(toStationId) &&
        shouldIgnoreGoBackFilter ? true : (singleFare.goBack === goBack);

      return matchedRes;
    };
  }

  static getMatchedSinglePrices(options: FaresMatchOptions) {
    return (fares: SingleFare[]) => {
      const filterFn = this.matchSinglePricesByFromToStopId(options);
      const res = fares.find(filterFn);
      return res;
    };
  }

  static getFareResultFromSinglePrices(singleFarePrices: SingleFarePrices | undefined): FareResult {
    let fareResult: FareResult = defaultFareResult;
    if(singleFarePrices) {
      fareResult = {
        shouldHideETicketInfo: false,
        cashPrices: {
          full: singleFarePrices.full === null ? defaultPrice : singleFarePrices.full,
          half: singleFarePrices.reduced === null ? defaultPrice : singleFarePrices.reduced,
        },
        eTicketPrices: {
          full: singleFarePrices.eFull === null ? defaultPrice : singleFarePrices.eFull,
          half: singleFarePrices.eReduced === null ? defaultPrice : singleFarePrices.eReduced,
        },
        eCitizenPrices: {
          full: singleFarePrices.eCitizen === null ? defaultPrice : singleFarePrices.eCitizen,
          half: singleFarePrices.eCitizenReduced === null ? defaultPrice : singleFarePrices.eCitizenReduced,
        }
      };
    }

    return fareResult;
  }

  static getFareResultFromRawData(rawData: FareDataFromQuery) {
    return (fromToStationIds: FromToStationIds, goBack: GoBack, shouldIgnoreGoBackFilter: boolean): FareResult => {
      const handledData = this.handleRawData(rawData);
      const singleFarePrices = this.getMatchedSinglePrices({
        ...fromToStationIds, goBack, shouldIgnoreGoBackFilter
      })(handledData);
      const res = this.getFareResultFromSinglePrices(singleFarePrices);

      return res;
    };
  }

  static checkETicketInfoShouldHiden(routeId: ID | undefined) {
    if(hidenETicketPriceInfoRouteIds.includes(Number(routeId))) {
      return true;
    }
    return false;
  }
}

export default FarePricesHandler;