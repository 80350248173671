
export default (response: Response) => {
  if (response.ok) {
    return response;
  } else {
    let error = new Error(response.statusText);
    error.message = response.statusText;
    throw error;
  }
};

