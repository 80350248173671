import React, { ReactNodeArray } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.5),
    },
    display: 'flex',
    flexGrow: 1,
  },
  infoPart: {
    display: 'flex',
    flexGrow: 1,
  }
}));

interface Props {
  children: ReactNodeArray
}
const LeftInfoRightMapStyleWrapper = ({
  children
}: Props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
    >
      <Grid item xs={12} md={6} lg={4} className={classes.infoPart}>
        {children[0]}
      </Grid>
      <Grid item xs={12} md={6} lg={8} className={classes.infoPart}>
        {children[1]}
      </Grid>
    </Grid>
  );
};

export default LeftInfoRightMapStyleWrapper;