import { actionTypes as Types } from './index';
import { IbusCommonData } from 'constants/context';
import { QueryIbusCommonDataType } from 'constants/cmsAPI/cmsAPI';

export type GetIbusCommonDataAction = {
  type: Types.GET_IBUS_COMMON_DATA,
  payload: QueryIbusCommonDataType
}
export type ResetIbusCommonDataAction = {
  type: Types.RESET_IBUS_COMMON_DATA,
}

export const getIbusCommonData = (payload: QueryIbusCommonDataType): GetIbusCommonDataAction => ({
  type: Types.GET_IBUS_COMMON_DATA,
  payload,
});

export const resetIbusCommonData = (): ResetIbusCommonDataAction => ({
  type: Types.RESET_IBUS_COMMON_DATA,
});

export type AppActionTypes = 
  GetIbusCommonDataAction | 
  ResetIbusCommonDataAction