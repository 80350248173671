import { gql } from "apollo-boost";
import { EdgesData, ID, OpType } from "all-common-types";

export const QUERY_ROUTE_MAP_LIST_PAGE_DATA = gql`
  query QUERY_ROUTE_MAP_LIST_PAGE_DATA($lang: String!) {
    routes(lang: $lang) {
      edges {
        node {
          id
          name
          seq
          opType
          description
          providers {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    providers(lang: $lang) {
      edges {
        node {
          id
          name
        }
      }
    }
  }  
`;

export interface SingleRouteInQueriedRouteMapListPageData {
  id: string
  name: string
  seq: number
  opType: OpType
  description: string
  providers: EdgesData<{ id: ID }>
}
export interface SingleProviderInQueriedRouteMapListPageData {
  id: number
  name: string
}

export interface QueriedRouteMapListPageData {
  routes: EdgesData<SingleRouteInQueriedRouteMapListPageData>
  providers: EdgesData<SingleProviderInQueriedRouteMapListPageData>
}