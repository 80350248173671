import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const PlanDescription = () => {
  return (
    <Box paddingBottom={1}>
      <IntlFormattedMessage 
        langKey={'travelPlanner.planPart.additionalDescription'}
      />
    </Box>
  );
};

export default memo(PlanDescription);