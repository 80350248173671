import React from 'react';
import { Box } from '@material-ui/core';
import { TabListProps } from './types';
import TabItem from './TabItem';
import useEnTabWrap from './functions/useEnTabWrap';

const TabList = (props: TabListProps) => {
  const wrapStates = useEnTabWrap(props);

  return (
    <Box
      display={'flex'}
      {...wrapStates}
    >
      {props.tabDatas.map((t, i) => {
        const handleTabChange = () => {
          props.selectTabFn && props.selectTabFn({}, i);
        };
        return (
          <TabItem
            key={i}
            {...t}
            tabVariant={props.tabVariant}
            variant={props.variant}
            isSelected={props.selectedTab === i}
            onTabChange={handleTabChange}
          />
        );
      })}
    </Box>
  );
};

export default TabList;