import { useMemo } from "react";
import { ContextValue } from 'components/driving-map/constants/context';
import { useParams } from "react-router";

const useSelectedRouteId = () => {
  const {
    state,
  } = ContextValue();
  const {
    routeId,
  } = useParams<{ routeId: string }>();

  const selectedRouteId = useMemo(() => (
    routeId || state.selectedRoute.id || ''
  ), [routeId, state.selectedRoute.id]);

  return ({
    selectedRouteId,
  });
};

export default useSelectedRouteId;