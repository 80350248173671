import { ID } from "all-common-types";
import { gql } from "apollo-boost";
import { GoBack } from "bus-common-types";
import { FareDataFromQuery } from "fare";

export const QUERY_STATIONS_FOR_FARE = gql`
  query QUERY_STATIONS_FOR_FARE($routeId: Int!, $lang: String!) {
    route(xno: $routeId, lang: $lang) {
      departure
      destination
      stations {
        edges {
          goBack
          node {
            id
            name
          }
        }
      }
    }
  }
`;
export interface SingleStationForFare {
  id: ID
  name: string
}
export interface SingleStationForFareWithGoBack  extends SingleStationForFare {
  goBack: GoBack
}
export interface QueriedStationsForFare {
  route: {
    departure: string
    destination: string
    stations: {
      edges: {
        goBack: GoBack
        node: SingleStationForFare
      }[]
    }
  }
}

export const QUERY_FARES = gql`
  query QUERY_FARES($routeId: Int!, $fromStationId: Int, $toStationId: Int) {
    fares(xno: $routeId, from: $fromStationId, to: $toStationId) {
      edges {
        node {
          id
          goBack
          from
          to
          
          full
          reduced
          
          eFull
          eReduced

          eCitizen
          eCitizenReduced
        }
      }
    }
  }
`;
export interface QueriedFares extends FareDataFromQuery {
}