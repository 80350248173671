import { useIntl } from "react-intl";

const TAB_MIN_HEIGHT = 36;

export interface UseEnTabWrapOptions {
  tabMinHeight?: number
  tabDatasLength: number
}

const useEnTabWrap = ({
  tabDatasLength,
  tabMinHeight=TAB_MIN_HEIGHT
}: UseEnTabWrapOptions) => {
  const {
    locale
  } = useIntl();
  
  const flexWrap = locale === 'en' ? 
    'wrap' : 'noWrap';
  
  const minHeight = locale === 'en' ? 
    tabMinHeight * tabDatasLength : tabMinHeight;

  return ({
    flexWrap,
    minHeight
  });
};

export default useEnTabWrap;