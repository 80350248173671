import React, { useState } from 'react';
import { Box, Grid, Container, Divider, makeStyles } from '@material-ui/core';
import FareSearchPart from './SearchPart/FareSearchPartContainer';
import FareResult from './ResultPart/FareResult';
import { ContextWrapper, FareState } from '../constant/context';
import { useParams } from 'react-router';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from 'constants/API';
import FarePageTitle from '../components/SearchPart/FarePageTitle';
import FarePageInfoList from '../components/FarePageInfoList';
import useGoldThemeLeftPartStyles from 'styles/styleObjs/goldThemeLeftPartStyles';
import { GoBack } from 'bus-common-types';

const useStyles = makeStyles(theme => ({
  root: {

  },
  fareContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
  }
}));

const Fare = () => {
  const classes = useGoldThemeLeftPartStyles();
  const fareClasses = useStyles();
  const {
    xno
  } = useParams<{ xno: string }>();

  const selectedRoute: FareState['selectedRoute'] = {
    id: xno,
    name: '',
  };
  const [goBack, setGoBack] = useState<GoBack>(1);

  return (
    <ApolloProvider client={client}>
      <ContextWrapper customInitState={{
        selectedRoute,
      }}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          flexGrow={1}
          paddingTop={2}
          paddingBottom={2}
        >
          <Container className={fareClasses.fareContainer}>
            <Box className={classes.root}>
              <Box paddingBottom={1}>
                <FarePageTitle />
                <FarePageInfoList />
                <Divider />
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <FareSearchPart setGoBackFn={setGoBack} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FareResult goBack={goBack} />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </ContextWrapper>
    </ApolloProvider>
  );
};

export default Fare;