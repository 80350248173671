import { CollinearRoutesState } from "../constants/context";
import { actionTypes as Types } from './index';

export interface SetSearchTypePayload {
  selectedFromStation: CollinearRoutesState['selectedFromStation']
}
export interface SetFromStationAction {
  type: typeof Types.SET_FROM_STATION
  payload: {
    selectedFromStation: CollinearRoutesState['selectedFromStation']
  }
}
export interface ResetFromStationAction {
  type: typeof Types.RESET_FROM_STATION
}
export type SelectedFromStationActions = 
  SetFromStationAction |
  ResetFromStationAction

export const setFromStation = (payload: SetSearchTypePayload): SetFromStationAction => ({
  type: Types.SET_FROM_STATION,
  payload,
});
export const resetFromStation = (): ResetFromStationAction => ({
  type: Types.RESET_FROM_STATION,
});