import React from 'react';
import { Box } from '@material-ui/core';
import MrtLine from '../../common/MrtLine';
import { COMMON_STATIONS_PART_SPECS, FARE_BUTTON_WIDTH, DISTANCE_BETWEEN_STATIONS, PADDING_BETWEEN_CENTER_STATIONS } from 'components/mrt-fare/config';

const halfButtonWidth = FARE_BUTTON_WIDTH / 2;

export interface BusCommonStationsPartLinesProps {
  rightStationsLength: number
}

const BusCommonStationsPartLines = ({
  rightStationsLength,
}: BusCommonStationsPartLinesProps) => {
  const rightPartLineWidth = 
    DISTANCE_BETWEEN_STATIONS * (rightStationsLength - 1) + halfButtonWidth + 140 + COMMON_STATIONS_PART_SPECS.rightPartPadding;

  return (
    <>
      <MrtLine
        color={'BUS'}
        top={halfButtonWidth}
        left={DISTANCE_BETWEEN_STATIONS * -1}
        width={800}
        direction={'horizontal'}
      />
      <MrtLine
        color={'BUS'}
        top={halfButtonWidth}
        // left={`calc(50% + 16px)`}
        left={738}
        // right={}
        width={PADDING_BETWEEN_CENTER_STATIONS * 6 * 1.8}
        direction={'vertical'}
      />
      <MrtLine
        color={'BUS'}
        bottom={15}
        right={halfButtonWidth}
        width={rightPartLineWidth}
        direction={'horizontal'}
      />
    </>
  );
};

export default BusCommonStationsPartLines;