import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import CommonListItem from 'components/common-components/CommonListItem';
import { ROUTE_MAP_LINK_ITEM_RATIO } from 'components/route-map-list/config';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  }
}));

const RouteMapLinkHeader = () => {
  const classes = useStyles();

  const textsInList = [
    <IntlFormattedMessage variant={'h6'} langKey={'provider'} />,
    <IntlFormattedMessage variant={'h6'} langKey={'title'} />
  ];

  return (
    <Box className={classes.root}>
      <CommonListItem
        textsInList={textsInList}
        textsWidthRatios={ROUTE_MAP_LINK_ITEM_RATIO}
      />
    </Box>
  );
};

export default RouteMapLinkHeader;