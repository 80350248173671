import { PlanHintIconTravelMode } from "./components/PlansPart/PlanHintIconList";
import polyline from 'polyline';
import { SingleStep, OtpLeg } from "travel-planner";
import { TravelPlannerState } from "./constant/context";

export const checkIsLastOfArray = (arr: any[], index: number) => (
  index === arr.length - 1
);

export const getPlanHintIconData = (stepData: OtpLeg): PlanHintIconTravelMode => {
  const { mode, routeShortName : routeName } = stepData;

  const res: PlanHintIconTravelMode = {
    mode,
    routeName,
  };
  return res;
};


export type CalNewIndexParam = {
  indexNow: number
  lastIndex: number
}
export type Operation = '+' | '-'
type CalNewIndex = (op: Operation) => (indexes: CalNewIndexParam) => number

export const calNewIndex: CalNewIndex = (operation) => (indexes) => {
  const {
    indexNow,
    lastIndex,
  } = indexes;
  if(operation === '+') {
    return indexNow + 1 > lastIndex ?
      0 : indexNow + 1;
  } else {
    return indexNow - 1 < 0 ?
      lastIndex : indexNow - 1;
  }
};