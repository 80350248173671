import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import IntlButton from '../IntlComponents/IntlButtons';
import { DisplayModeType } from 'lib/Handlers/DisplayModeHandlers';
import { Callback } from 'all-common-types';
import { IntlButtonProps } from '../IntlComponents/types';

export interface DisplayModeToggleButtonProps extends IntlButtonProps {
  displayMode: DisplayModeType
  onToggleDisplayMode: Callback
}

const DisplayModeToggleButton = (props: DisplayModeToggleButtonProps) => {
  const {
    displayMode,
    onToggleDisplayMode,
  } = props;

  return (
    <IntlButton 
      langKey={
        displayMode === 'PC' ? 'displayMode.mobile' : 'displayMode.pc'
      }
      onClick={onToggleDisplayMode}
      {...props}
    />
  );
};

export default memo(DisplayModeToggleButton);