import { gql } from "apollo-boost";
import RouteFragment, { BusesFragment, BusesInfoFragment, EstimateTimesInfoFragment } from "constants/api-fragments";
import { Schedule, SingleProvider, Stations } from "bus-common-types";
import { EdgesData, ID } from "all-common-types";
import { NearBikeStations } from "bus-common-types";
import { RouteBasicDataType } from "bus-common-types";

export const QUERY_TIME_TABLE = gql`
  query QUERY_TIME_TABLE($routeId: Int!, $date: String) {
    schedule(xno: $routeId, date: $date) {
      ...scheduleInfo
    }
    # route {
    #   providers {
    #     id
    #   }
    # }
  }
  ${RouteFragment.fragment.scheduleInfo}
`;

export interface QueriedTimeTable {
  schedule: Schedule
  // route: {
  //   providers: { id: number }[]
  // }
}

export const QUERY_NEAR_TRANSPORTATIONS = gql`
  query QUERY_NEAR_TRANSPORTATIONS($lang: String!, $routeId: Int!, $mrtRadius: Int!, $thsrRadius: Int!) {
    nearThsrStation(xno: $routeId, radius: $thsrRadius, lang: $lang) {
      edges {
        node {
          id # station id
          sid
          thsrStations {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }

    nearMetroStation(xno: $routeId, radius: $mrtRadius, lang: $lang) {
      edges {
        node {
          id # station id
          sid
          metroStations {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }

  }
`;
export interface SingleNearMrt {
  id: ID // station id
  sid: number
  metroStations: EdgesData<{ id: ID }>
}
export interface SingleNearTHSRStation {
  id: ID
  sid: number
  thsrStations: EdgesData<{id: ID}>
}
export interface QueriedNearTransportations {
  nearThsrStation: EdgesData<SingleNearTHSRStation>
  nearMetroStation: EdgesData<SingleNearMrt>
}


// 那些查一次就好的路線資料
export const QUERY_STATIC_ROUTE_DATA = gql`
  query QUERY_STATIC_ROUTE_DATA($routeId: Int!, $lang: String!) {
    route(xno: $routeId, lang: $lang) {
      id
      name
      description
      departure
      destination
      opType
      routePoint {
        go
        back
      }
      providers {
        edges {
          node {
            id
            name
          }
        }
      }
      stations {
        ...stationsInfo
      }
    }

    nearBikeStation(xno: $routeId, lang: $lang, radius: 100) {
      edges {
        node {
          id # station id
          sid
          bikeStations {
            edges {
              node {
                id
                type
                name
              }
            }
          }
        }
      }
    }
  }

  ${ RouteFragment.fragment.stationsInfo }
`;

export interface QueriedStaticRouteData {
  route: RouteBasicDataType & {
    providers: EdgesData<SingleProvider>
    stations: Stations
  }
  nearBikeStation: NearBikeStations
}

// 需要更新的那些資料
export const QUERY_DYNAMIC_ROUTE_DATA = gql`
  query QUERY_DYNAMIC_ROUTE_DATA($routeId: Int!, $lang: String!) {
    route(xno: $routeId, lang: $lang) {
      estimateTimes {
        ...estimateTimesInfoFragment
      }
      buses {
        ...busesFragment
      }
    }
  }
  ${RouteFragment.fragment.estimateTimesInfo}
  ${RouteFragment.fragment.busesFragment}
`;

export interface QueriedDynamicRouteData {
  route: {
    estimateTimes: EstimateTimesInfoFragment
    buses: BusesFragment
  }
}

export interface JointOperator {
  busProvider: String
  providerId: number
  routeId: number
}

export interface QueriedVisibleJointOperators {
  jointOperationsBuses: JointOperator[]
}

export const QUERY_VISIBLE_JOINT_OPERATORS = gql`
  query QUERY_VISIBLE_JOINT_OPERATORS {
    jointOperationsBuses {
      busProvider
      providerId
      routeId
    }
  }
`;

export interface RouteStationIcon {
  id: ID
  xno: number
  routeName: string
  stationIds: string
  icon: string
  stations?: string[]
}

export interface QueriedRouteStationIcons {
  routeStationIcons: RouteStationIcon[]
}

export const QUERY_ROUTE_STATION_ICON = gql`
  query QUERY_ROUTE_STATION_ICON($where: JSON){
    routeStationIcons(where: $where){
      id
      xno
      routeName
      stationIds
      icon
    }
  }
`;
