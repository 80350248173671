import React, {  } from 'react';

import ContextStore, { IntervalSearchSelectedFromTo } from 'components/collinear-routes/constants/context';
import { setStopSearchFromTo, resetRouteId } from 'components/collinear-routes/actions';
import { IntervalRouteInfoListContainerWithCtx } from './IntervalRouteInfoListContainer';
import { MapDispatchToProps } from 'lib/contextReduxLikeHelpers/mapContextToProps';
import connectCtx from 'lib/contextReduxLikeHelpers/connectCtx';
import SearchByFromToStopsContainerWithCtx from './SearchByFromToStopsContainer';

type IntervalSearchContainerProps = {
  handleSetInputToCtxAndResetRouteIdFn?: (fromTo: IntervalSearchSelectedFromTo) => any
}
export const IntervalSearchContainer = () => {
  return (
    <>
      <SearchByFromToStopsContainerWithCtx />
      <IntervalRouteInfoListContainerWithCtx />
    </>
  );
};

export const mapDispatchToProps: MapDispatchToProps<{}, IntervalSearchContainerProps> = (dispatch) => {
  return ({
    handleSetInputToCtxAndResetRouteIdFn: (fromTo: IntervalSearchSelectedFromTo) => {
      dispatch(resetRouteId());
      dispatch(setStopSearchFromTo(fromTo));
    }
  });
};

export const IntervalSearchContainerWithCtx = connectCtx(ContextStore)(undefined, mapDispatchToProps)(IntervalSearchContainer);


export default IntervalSearchContainer;