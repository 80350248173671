import { TravelPlannerState } from "../constant/context";
import { selectedPlanReducer, getPlansReducer } from "./plans-reducers";
import { routesReducer } from "./routeReducers";
import combineReducers from 'constants/combineReducers';

const reducers = combineReducers<TravelPlannerState>({
  selectedPlan: selectedPlanReducer,
  plans: getPlansReducer,
  from: s => s.from,
  to: s => s.to,
  routes: routesReducer
});

export default reducers;