import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import useDisplayModeToggleButton from './functions/useDisplayModeToggleButton';
import DisplayModeToggleButton from './DisplayModeToggleButton';

const DisplayModeToggleButtonContainer = () => {
  const {
    displayMode,
    handleToggleDisplayMode,
  } = useDisplayModeToggleButton();
  
  return (
    <DisplayModeToggleButton 
      displayMode={displayMode}
      onToggleDisplayMode={handleToggleDisplayMode}
    />
  );
};

export default memo(DisplayModeToggleButtonContainer);