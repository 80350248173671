import { SingleRouteInfo } from 'collinear-routes';
import { CollinearRoutesState } from 'components/collinear-routes/constants/context';
import useQueryFromToStationEstimates from 'components/collinear-routes/gql/custom-hooks/useQueryFromToStationEstimates';
import useQueryFromToStationRoutes from 'components/collinear-routes/gql/custom-hooks/useQueryFromToStationRoutes';
import useQueryTravelTimes from 'components/collinear-routes/gql/custom-hooks/useQueryTravelTimes';
import { nodeDataFromEdges } from 'constants/functions';
import checkAllAreLoaded from 'lib/checkAllAreLoaded';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import MakeTravelTimeInputFromEstimateTimeResult from '../Handlers/MakeTravelTimeInputFromEstimateTimeResult';
import RouteInfosHandler from '../Handlers/RouteInfosHandler';

export interface UseQueryRouteInfosOptions extends Pick<CollinearRoutesState, 'intervalSearchSelectedFromTo'> {
  
}

const useQueryRouteInfos = (options: UseQueryRouteInfosOptions) => {
  const {
    intervalSearchSelectedFromTo: {
      from: fromStation, 
      to: toStation, 
    }
  } = options;
  let routeInfos: SingleRouteInfo[] = [];

  const {
    formatMessage,
  } = useIntl();
  const {
    error,
    loading: loadingQueryFromToStationRoutes,
    data: queryFromToStationRoutesData,
    handledFinalResult,
    handleQuery: handleQueryFromToStationRoutes,
  } = useQueryFromToStationRoutes();

  const {
    loading: loadingQueryFromToStationStopEstimates,
    handleQuery: handleQueryFromToStationEstimates,
    data: queryFromToStationStopEstimatesData,
  } = useQueryFromToStationEstimates();

  const {
    loading: loadingTravelTimes,
    handleQuery: handleQueryTravelTimes,
    data: queriedTravelTimes,
  } = useQueryTravelTimes();

  const {
    from,
    to,
  } = handledFinalResult;

  const shouldQueryStationEstimates = 
    from.stationEstimateInputs.length > 0 && 
    to.stationEstimateInputs.length > 0;
  const jsonizedEstimateStopInputs = JSON.stringify([
    ...from.stationEstimateInputs,
    ...to.stationEstimateInputs
  ]);

  useEffect(() => {
    if(fromStation && toStation) {
      handleQueryFromToStationRoutes({
        from: fromStation.name, 
        to: toStation.name,
      });
    }
  }, [formatMessage, fromStation, handleQueryFromToStationRoutes, toStation]);

  useEffect(() => {
    if(shouldQueryStationEstimates) {
      handleQueryFromToStationEstimates({
        fromStationTargets: from.stationEstimateInputs,
        toStationTargets: to.stationEstimateInputs,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonizedEstimateStopInputs, shouldQueryStationEstimates]);
  if(queryFromToStationStopEstimatesData) {
    // handle routeInfos
    routeInfos = RouteInfosHandler.makeRouteInfos({
      fromEstimateTimes: nodeDataFromEdges(queryFromToStationStopEstimatesData.from),
      toEstimateTimes: nodeDataFromEdges(queryFromToStationStopEstimatesData.to),
      routes: handledFinalResult.from.routes,
    });
  }

  useEffect(() => {
    if(handledFinalResult.from.stationEstimateInputs.length > 0) {
      const params = MakeTravelTimeInputFromEstimateTimeResult.makeQueryParams(handledFinalResult);
      handleQueryTravelTimes(params);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(handledFinalResult)]);

  const mergeTraveTimesRouteInfos = useMemo(() => (
    RouteInfosHandler.mergeTravelTimeToRouteInfo(queriedTravelTimes, routeInfos)
  ), [queriedTravelTimes, routeInfos]);

  const loading = useMemo(() => (
    !checkAllAreLoaded([
      loadingQueryFromToStationRoutes,
      loadingQueryFromToStationStopEstimates,
      loadingTravelTimes,
    ])
  ), [loadingQueryFromToStationRoutes, loadingQueryFromToStationStopEstimates, loadingTravelTimes]); 

  return ({
    loading,
    error,
    data: !!queryFromToStationStopEstimatesData || !!queryFromToStationRoutesData,
    handleQuery: handleQueryFromToStationRoutes,
    routeInfos: mergeTraveTimesRouteInfos,
  });
};

export default useQueryRouteInfos;