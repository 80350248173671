import React from 'react';
import { OtpMode } from "travel-planner";
import { getStepIcon } from "./SingleStepItem";
import { Box, Breadcrumbs } from "@material-ui/core";
import { NavigateNext } from '@material-ui/icons';

export type PlanHintIconTravelMode = {
  mode: OtpMode
  routeName?: string
}
export type SinglePlanHintIconItemProps = {
  travelMode: PlanHintIconTravelMode
}

export const getRouteName = (mode: OtpMode, routeName?: string) => (
  mode === 'BUS' && `(${routeName})`
);

export const SinglePlanHintIconItem = ({ travelMode }: SinglePlanHintIconItemProps) => {
  const { mode, routeName } = travelMode;
  return (
    <Box>
      {getStepIcon(mode)}
      {getRouteName(mode, routeName)}
    </Box>
  );
};

export const Separate = () => <>{' >> '}</>;

export const PlanHintIconList = ({planHintIconListsData}: {
  planHintIconListsData: PlanHintIconTravelMode[]
}) => {
  return (
    <Box display={'flex'}>
      <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
        {planHintIconListsData.map((mode, i) => {
          return (
            <SinglePlanHintIconItem
              key={i}
              travelMode={mode} />
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default PlanHintIconList;