import React from 'react';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import { GoBack, Position } from 'bus-common-types';
import { getIconByType } from './functions/getIconByType';

export type MapIconProps = {
  number: number
  goBack: GoBack
  position: Position
  markerRef?: import('react').Ref<any>
  children: import('react').ReactNode
  popupMinWidth?: number
  [x: string]: any
}

export const MapIcon = ({ 
  number, 
  goBack, 
  position, 
  markerRef,
  popupMinWidth,
  children,
}: MapIconProps) => {
  // console.log(position);
  const icon = getIconByType('routeStop')()(number, goBack);
  return (
    <Marker 
      icon={ L.divIcon(icon) as any } 
      position={ position } 
      ref={ markerRef }
    >
      <Popup 
        autoPan={ true } 
        minWidth={popupMinWidth}
        maxWidth={1000}
      >
        {children}
      </Popup>
    </Marker>
  );
};

export default MapIcon;