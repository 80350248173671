import React from 'react';
import { Box } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { TimeTableInfoProps } from './types';
import getLangKeyByTimetableType from './functions/getLangKeyByTimetableType';

const TimeTableInfo = (props: TimeTableInfoProps) => {
  const langKey = getLangKeyByTimetableType(props.timeTableType);

  return (
    <Box>
      <IntlFormattedMessage 
        langKey={langKey}
        color={'error'}
      />
    </Box>
  );
};

export default TimeTableInfo;