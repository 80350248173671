import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { LangKeys } from 'constants/lang';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  indicator: {
    width: theme.spacing(0.75),
    marginRight: theme.spacing(1),
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.homepageColors.titleColor,
  },
  title: {
    fontSize: 24,
    // color: theme.palette.text.primary
    // color: theme.palette.primary.light,
    color: theme.homepageColors.titleColor,
  },
}));

export interface LocaleTitleProps {
  titleLangKey: LangKeys
}

const LocaleTitle = ({
  titleLangKey,
}: LocaleTitleProps) => {
  const classes = useStyles();

  return (
    <Box display={'flex'} className={classes.root}>
      <Box className={classes.indicator} />
      <IntlFormattedMessage
        langKey={titleLangKey}
        className={classes.title}
      />
    </Box>
  );
};

export default LocaleTitle;