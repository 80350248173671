import { useLazyQuery } from "@apollo/react-hooks";
import { ID } from "all-common-types";
import { client} from "constants/API";
import { NEAR_MRT_RADIUS, NEAR_THSR_RADIUS } from "components/driving-map/config";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { QueriedNearTransportations, QUERY_NEAR_TRANSPORTATIONS } from "../gql-schema";
import { nodeDataFromEdges } from "constants/functions";
import { OtherTransportationStations } from "components/driving-map/components/Route/functions/HandleStopIsNearIbike";

function useQueryNearTransportations() {
  const {
    locale,
  } = useIntl();

  const [queryFn, { error, loading, data }] = useLazyQuery<QueriedNearTransportations>(QUERY_NEAR_TRANSPORTATIONS, {
    client: client,
  });

  const handleQuery = useCallback((routeId: ID) => {
    routeId && queryFn({
      variables: {
        lang: locale,
        routeId: Number(routeId),
        mrtRadius: NEAR_MRT_RADIUS,
        thsrRadius: NEAR_THSR_RADIUS,
      }
    });
  }, [locale, queryFn]);

  const transportationStations: Pick<OtherTransportationStations, 'nearMrtStations' | 'nearTHSRStations'> = useMemo(() => (
    data ? ({
      nearTHSRStations: nodeDataFromEdges(data.nearThsrStation),
      nearMrtStations: nodeDataFromEdges(data.nearMetroStation),
    }): ({
      nearTHSRStations: [],
      nearMrtStations: [],
    })
  ), [data]);

  return ({
    error,
    loading,
    data,
    transportationStations,
    handleQuery,
  });
};

export default useQueryNearTransportations;