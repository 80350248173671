import { Locale, SingleSelectOption } from "all-common-types";
import { LangKeys } from "constants/lang";

export type ReportDescriptionOptionKeys = 'unstable' | 'collapsed' | 'dirty' | 'routeMapMissed' | 'routeMapDamaged' | 'busStopMessy' | 'other'

type Options = {
  [l in Locale]: {
    value: ReportDescriptionOptionKeys
    text: string
  }[]
}

const options: Options = {
  'zh': [
    {
      value: 'unstable',
      text: '站牌鬆動不穩',
    },
    {
      value: 'collapsed',
      text: '站牌斷裂/倒塌',
    },
    {
      value: 'dirty',
      text: '站牌（候車亭）塗鴉/汙穢',
    },
    {
      value: 'busStopMessy',
      text: '候車站點髒亂',
    },
    {
      value: 'routeMapMissed',
      text: '路線圖遺失',
    },
    {
      value: 'routeMapDamaged',
      text: '路線圖破損',
    },
    {
      value: 'other',
      text: '其他(可輸入文字)',
    },
  ],
  'en': [
    {
      value: 'unstable',
      text: 'The bus stop sign is loose and unstable.',
    },
    {
      value: 'collapsed',
      text: 'The bus stop sign is broken or collapsed.',
    },
    {
      value: 'dirty',
      text: 'The bus stop sign is smudged.',
    },
    {
      value: 'busStopMessy',
      text: 'The bus stop is messy.',
    },
    {
      value: 'routeMapMissed',
      text: 'Bus route map lost.',
    },
    {
      value: 'routeMapDamaged',
      text: 'Bus route map is damaged.',
    },
    {
      value: 'other',
      text: 'Others(text input)',
    },
  ],
};

const getReportDescriptionOptions = (locale: Locale) => {
  return options[locale];
};

export default getReportDescriptionOptions;