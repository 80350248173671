import { TimetableType } from "all-common-types";
import { LangKeys } from "constants/lang";
import { TimeTableInfoProps } from "../types";

const getLangKeyByTimetableType = (timetableType: TimeTableInfoProps['timeTableType']): LangKeys => {
  switch (timetableType) {
    case 'headway':
      return 'timeTable.info.headway';

    case 'mixed':
      return 'timeTable.info.mixed';

    default:
      return 'timeTable.info.defaultSchedule';
  }
};

export default getLangKeyByTimetableType;