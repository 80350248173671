import { ID } from "all-common-types";
import React from 'react';
import { useToggle } from 'lib/customHooks';
import ClogMessageToggleHandler from 'components/driving-map/lib/ClogMessageToggleHandler';
import useQueryClogs, { UseQueryClogsOptions } from 'components/driving-map/lib/useQueryClogs';

const initTimeTableToggle = false;
const initClogToggle = false;

export interface UseBusMapWithRouteOptions extends UseQueryClogsOptions {
  routeId: ID
}

const useBusMapWithRoute = (options: UseBusMapWithRouteOptions) => {
  const {
    routeId,
  } = options;

  const {
    loading,
    clogs,
    handleQuery,
  } = useQueryClogs(options);

  const {
    toggle: toggledTimeTableDisplay,
    setToggle: setToggleTimeTable,
    handleToggle: handleToggleTimeTable,
  } = useToggle(initTimeTableToggle);

  const {
    toggle: rawToggledClog,
    setToggle: setClogToggle,
    handleToggle: handleClogToggle,
  } = useToggle(initClogToggle);

  //for checking popup and setToggle true
  // React.useEffect(() => {
  //   const shouldUpdateClogToggle = clogs.length > 0 && !rawToggledClog;
  //   if(shouldUpdateClogToggle) {
  //     const clogToggleRes = ClogMessageToggleHandler.checkShouldPopupClogsMessage({
  //       routeId,
  //       clogs,
  //     });
  //     // console.log(clogToggleRes);
  //     if(clogToggleRes) {
  //       setClogToggle(true);
  //       ClogMessageToggleHandler.setClickedRouteToLS(routeId);
  //     }
  //   }
  // }, [clogs, clogs.length, routeId, setClogToggle, rawToggledClog]);

  React.useEffect(() => {
    setToggleTimeTable(initTimeTableToggle);
    setClogToggle(initClogToggle);
    handleQuery(routeId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeId]);

  React.useEffect(() => {
    const haveClogs = clogs.length > 0;
    if(haveClogs) {
      setClogToggle(true);
    } else {
      setClogToggle(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(clogs)]);
  
  const toggledClog = rawToggledClog;
  // const isClogLoading = clogIsEmpty ? true : loading;
  const isClogLoading = loading;

  return ({
    isClogLoading,
    clogMessages: clogs,
    handleClogToggle,
    toggledClog,
    toggledTimeTableDisplay,
    handleToggleTimeTable,
  });
};

export default useBusMapWithRoute;