import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import ibusImages from 'static/ibusImages.json';
import ImageLink from 'components/common-components/ImageComponents/ImageLink';
import checkHomepageImageLinkAvailable from '../lib/checkHomepageImageLinkAvailable';

const href = 'https://funtaichung.tw/2021shopTaichung';

const useStyles = makeStyles(theme => ({
  root: {
    opacity: 0.75,
    borderRadius: theme.spacing(0.5),
    transition: '0.2s',
    overflow: 'hidden',
    '&:hover': {
      opacity: 0.9,
      transition: '0.2s',
    }
  }
}));

const HomepageImageLink = () => {
  const classes = useStyles();
  const isAvailable = checkHomepageImageLinkAvailable();
  if(!isAvailable) {
    return null;
  }

  return (
    <Box
      padding={0.5}
      paddingTop={1}
      maxWidth={128 * 2}
    >
      <ImageLink
        className={classes.root}
        imgSrc={ibusImages.homepage_image_link.src}
        alt={ibusImages.homepage_image_link.name}
        isTargetBlank={true}
        href={href}
      />
    </Box>
  );
};

export default HomepageImageLink;