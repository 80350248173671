import { arrStrToObjStr } from "lib/fn";
import StaticLangKeys from "./lang/langKeys";
import langs from 'static/lang/langs';

type Langs = 'en_US' | 'zh_TW'

export type LangKeys = 
  StaticLangKeys
  
type LangObj = {
  [x in LangKeys]: string
}
type LANG = {
  [x in Langs]: LangObj
}

export const LANG: LANG = langs;


type LangKeysStrObj = {
  [x in LangKeys]: LangKeys
}
export const getLangKeys = (): LangKeysStrObj => {
  const keys = Object.keys(LANG.en_US) as LangKeys[];
  const langKeys = arrStrToObjStr(keys);
  return langKeys; 
};
export const langKeys = getLangKeys();