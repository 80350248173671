import { ID, SingleRouteFromRoutes } from "all-common-types";
import { QueriedRoutes } from "components/driving-map/constants/API";
import { nodeDataFromEdges } from "constants/functions";
import { SingleRouteWithProviderIds } from "lib/customHooks/useQueryRoutes";

const QueriedRoutesHandlers = {
  attachWithProviderIds: (routes: SingleRouteFromRoutes[]): SingleRouteWithProviderIds[] => {
    return routes.map(r => ({
      ...r,
      providerIds: nodeDataFromEdges(r.providers).map(p => Number(p.id)),
    }));
  },

  filterRoutes(routes: SingleRouteFromRoutes[], providerId?: ID, excludeOpTypes?: Array<number>, operatorRouteIdMap?: Map<Number, Number[]>) {
    const filtered = routes.filter((route) => {
      return !excludeOpTypes || excludeOpTypes.indexOf(route.opType) === -1;
    });
    const withProviderIds = this.attachWithProviderIds(filtered);

    if(!providerId) {
      return withProviderIds;
    } else {
      const filteredByProviderId = withProviderIds
        .filter(r => r.providerIds.includes(Number(providerId)))
        .filter(r => {
          if (operatorRouteIdMap){
            const providerIds = operatorRouteIdMap.get(Number(r.id));
            if (providerIds) {
              return providerIds.includes(Number(providerId));
            }
          }
          return true;
        });
      return filteredByProviderId;
    }
  },

  handleRaw(rawData?: QueriedRoutes, providerId?: number, excludeOpTypes?: Array<number>, operatorRouteIdMap?: Map<Number, Number[]>) {
    if(rawData) {
      return this.filterRoutes(
        nodeDataFromEdges(rawData.routes),
        providerId,
        excludeOpTypes,
        operatorRouteIdMap
      );
    } else {
      return [];
    }
  }
};

export default QueriedRoutesHandlers;