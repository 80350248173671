import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import MrtFareButton from './MrtFareButton';
import { MrtIntersectionStationItemProps } from './types';
import { INTERSECTION_STATION_SID } from 'components/mrt-fare/config';
import checkStationIsIntersection from 'components/mrt-fare/lib/functions/checkStationIsIntersection';

const useStyles = makeStyles(theme => ({
  root: {
     
  },
  content: {
    position: 'relative',
    top: -8,
    left: 30,
  }
}));

const MrtIntersectionStationItem = (props: MrtIntersectionStationItemProps) => {
  const classes = useStyles();
  const isThisStation = checkStationIsIntersection(props.selectedStationId);

  return (
    <Box>
      <MrtFareButton 
        {...props} 
        isThisStation={isThisStation} 
        content={''} 
        wrapperStyles={{
          display: 'inline-block',
          position: 'relative',
          zIndex: 1000,
        }}
      />
      <Typography className={classes.content} style={{
        position: 'relative',
        zIndex: 1000,
        width: 320,
      }}>
        {props.content}
      </Typography>
    </Box>
  );
};

export default MrtIntersectionStationItem;