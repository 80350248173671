import reducers from '../reducers';
import ContextWrapperFn from 'react-function-helpers/lib/functions/ContextWrapper';
import { createContextValueFn, createContextStore } from 'lib/contextHelpers';
import { GoBack, RouteDataType } from 'bus-common-types';
import { SingleRouteStop } from 'components/common-components/wrappers/types';
import { ID } from 'all-common-types';

type SelectedStation = {
  id: number
  name: string
} | null
export type SelectedRouteId = string | null
export type IntervalSearchSelectedFromTo = {
  from: SelectedStation
  to: SelectedStation
}

export type SearchType = 'from-to-search' | 'stop-search'
export interface RouteDataForStopSearch extends RouteDataType {
  handledRouteStops: SingleRouteStop[]
}

export interface StopSearchStates {
  goBack: GoBack
  routeData: RouteDataForStopSearch | null
}

export interface CollinearRoutesState {
  searchType: SearchType
  selectedFromStation: SelectedStation
  selectedRouteId: SelectedRouteId
  stopSearchStates: StopSearchStates
  intervalSearchSelectedFromTo: IntervalSearchSelectedFromTo
}

export type Context = {
  dispatch: (x: any) => any
  state: CollinearRoutesState
}

export const initSearchType: SearchType = 'from-to-search';

export const initSelectedRouteId: SelectedRouteId = null;
export const initSelectedFromStation: SelectedStation = null;

export const initStopSearchFromTo: IntervalSearchSelectedFromTo = {
  from: null,
  to: null,
};

export const initStopSearchStates: StopSearchStates = {
  goBack: 2,
  routeData: null,
};

export const initCollinearRoutesState: CollinearRoutesState = {
  searchType: initSearchType,
  selectedFromStation: initSelectedFromStation,
  stopSearchStates: initStopSearchStates,
  selectedRouteId: initSelectedRouteId,
  intervalSearchSelectedFromTo: initStopSearchFromTo
};

export const ContextValue = createContextValueFn(initCollinearRoutesState, reducers);
const ContextStore = createContextStore(initCollinearRoutesState);
export default ContextStore;

export const ContextWrapper = ContextWrapperFn(ContextValue, ContextStore);
