import { ID } from "all-common-types";
import { SingleRouteInfo } from "collinear-routes";
import { QueriedTravelTimes } from "components/collinear-routes/gql/schemas/QUERY_TRAVEL_TIMES";
import MrtStationsTravelTimeHandlers from "components/mrt-fare/containers/StationsPart/functions/MrtStationsTravelTimeHandlers";
import { SingleQueriedTravelTime } from "constants/api-fragments";

export interface SingleTravelTimeWithStopsAmount extends Pick<SingleRouteInfo, 'passedStopsAmount'> {
  xno: number
  accumulatedTravelTime: number
}

const AccumulateTravelTimesHandlers = {
  defaultXno: -1,

  // 給需要列出所有站點旅行時間用的
  accumulateAllTravelTimes<SingleTravelTime extends {
    travelTime: string
  }>(travelTimes: SingleTravelTime[]) {
    let accumulatedSecs: number[] = [];

    travelTimes.forEach((tr, i) => {
      const secs = MrtStationsTravelTimeHandlers.convertTravelTimeToSecs(tr.travelTime);
      if(i === 0) {
        accumulatedSecs.push(0);
      } else {
        const accumulatedSec = accumulatedSecs[i - 1] + secs;
        accumulatedSecs.push(accumulatedSec);
      }
    });

    const res = accumulatedSecs.map(sec => ({
      accumulatedSec: sec,
      travelTimeMinStr: MrtStationsTravelTimeHandlers.parseTravelTimeToContent(sec),
    }));
    return res;
  },

  accumulateTravelTimes(travelTimes: SingleQueriedTravelTime[]) {
    let accumulatedTravelTimeSecs = 0;

    travelTimes.forEach((t, i) => {
      // 不用算首站到上一站的時間
      if(i > 0) {
        const travelTimeSecs = MrtStationsTravelTimeHandlers.convertTravelTimeToSecs(t.travelTime);
        accumulatedTravelTimeSecs += travelTimeSecs;
      }
    });

    return accumulatedTravelTimeSecs;
  },

  mergeAndAccumulate(travelTimes: SingleQueriedTravelTime[]): SingleTravelTimeWithStopsAmount {
    const accumulatedTravelTimeSecs = this.accumulateTravelTimes(travelTimes);

    const passedStopsAmount = travelTimes.length;
    const travelTimeMin = MrtStationsTravelTimeHandlers.parseTravelTimeToContent(accumulatedTravelTimeSecs);
    return ({
      xno: travelTimes[0] ? travelTimes[0].xno : this.defaultXno,
      passedStopsAmount,
      accumulatedTravelTime: travelTimeMin,
    });
  },

  handleRawTravelTimes(queriedTravelTimes: QueriedTravelTimes | undefined): SingleTravelTimeWithStopsAmount[] {
    if(!queriedTravelTimes) {
      return [];
    }

    const res = queriedTravelTimes.travelTime.map(t => (
      this.mergeAndAccumulate(t)
    ));
    return res;
  },
};

export default AccumulateTravelTimesHandlers;