/* eslint-disable no-unused-vars */
import React, {  } from 'react';
import { TextField, Box, Button, makeStyles, Grid, Divider } from '@material-ui/core';
import TimeTablePart from '../TimeTable/TimeTablePart';
import { Callback } from 'all-common-types';
import zIndex from 'styles/styleObjs/zIndex';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import styleLayout from 'styles/styleObjs/styleLayout';
import { TimeTableProps } from './types';
import LoadingAndError from 'components/common-components/LoadingAndError';
import TimeTableContainer from 'components/driving-map/containers/BusMap/TimeTableContainer';
import { TimeTableContainerProps } from 'components/driving-map/containers/BusMap/types';
import TimeTableInfoContainerWithCtx from 'components/driving-map/containers/TimeTable/TimeTableInfoContainer';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 60,
    right: 0,
    left: 0,
    margin: 'auto',
    zIndex: zIndex.timeTable,
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    maxHeight: styleLayout.appContentHeight,
    width: 666,
    overflow: 'auto',
    boxShadow: '0px 6px 10px #aaa',
    [theme.breakpoints.down('md')]: {
      width: 400,
    },
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  timeTablePart: {
    // display: 'flex',
    // '& div': {
    //   border: '1px solid #fff',
    // },
    // [theme.breakpoints.down('md')]: {
    //   display: 'block',
    // }
    '&:first-child': {
      paddingLeft: theme.spacing(1),
      borderRight: '1px solid #ddd',
    },
    [theme.breakpoints.down('md')]: {
      '&:first-child': {
        borderWidth: 0,
        paddingLeft: 0,
      }
    }
  }
}));

const TimeTable = ({
  routeId,
  loading,
  selectedDate, dayType,
  timeTableListData,
  onSelectDate,
  onDisplayTimeTable,
}: TimeTableProps) => {
  const classes = useStyles();

  const header = (
    <>
      <form className={ 'date-pick' } onChange={ onSelectDate }>
        <IntlFormattedMessage 
          langKey={'drivingMap.timeTable.title'}
        />
        <TextField 
          type={'date'}
          value={selectedDate} /> 
      </form>
      <Button 
        className={ classes.button } 
        onClick={ onDisplayTimeTable }
      >
        { 'X' }
      </Button>
    </>
  );
  
  return (
    <Box 
      className={ classes.root } 
      position={ 'topright' } 
    >
      {loading ? (
        <LoadingAndError loading={true} error={undefined} />
      ) : (
        <>
          {header}
          <TimeTableInfoContainerWithCtx 
            routeId={routeId} 
          />
          <Grid container spacing={1}>
            {timeTableListData.map((data, i) => (
              <Grid item xs={12} lg={6} className={classes.timeTablePart}>
                <TimeTablePart 
                  key={ i } 
                  routeId={routeId}
                  dayType={ dayType } 
                  timeTableData={ data } 
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export interface TimeTableWithToggleProps extends Omit<TimeTableContainerProps, 'onDisplayTimeTable'> {
  toggledDisplay: boolean
  toggleFn: Callback
}

export const TimeTableWithToggle = (props: TimeTableWithToggleProps) => {
  const {
    toggledDisplay,
    toggleFn,
  } = props;
  return (
    <>
      <Box display={toggledDisplay ? 'block' : 'none'}>
        <TimeTableContainer
          {...props}
          onDisplayTimeTable={toggleFn} 
        />
      </Box>
    </>
  );
};

export default TimeTable;