import React from 'react';
import Selector from 'components/common-components/SelectsComponents/Selector';
import { SelectorProps } from 'components/common-components/SelectsComponents/types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import useBusProvidersSelector from './useBusProviderSelects';
import { BusProviderSelectorProps } from './types';
import { ALL_PROVIDERS } from 'lib/makeBusProvidersSelectorOptions';

export const busProvidersSelectorName = 'busProvider';
const defaultBusProviderSelectorProps: SelectorProps = {
  id: '',
  label: (
    <IntlFormattedMessage 
      langKey={'provider'}
    />
  ),
  changeFn: () => {},
  value: ALL_PROVIDERS,
  options: [],
};

const BusProvidersSelector = (props: BusProviderSelectorProps) => {
  const states = useBusProvidersSelector({
    selectorName: busProvidersSelectorName,
    initSelectorProps: defaultBusProviderSelectorProps,
    setSelectedBusProviderFn: props.setSelectedBusProviderFn,
  });

  return (
    <Selector 
      minWidth={'100%'}
      {...states}
    />
  );
};

export default BusProvidersSelector;