import React, { memo } from 'react';
import { Box, Divider } from '@material-ui/core';
import { TitleWrapperProps } from './types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const TitleWrapper = (props: TitleWrapperProps) => {
  const {
    isError,
    titleLangKey,
    errorMessageLangKey='stopReport.erroMessage',
  } = props;

  return (
    <Box paddingBottom={2.5}>
      <Box paddingY={0.5}>
        <IntlFormattedMessage 
          langKey={titleLangKey}
          variant={'h6'}
          // color={'primary'}
        />
        {isError && (
          <IntlFormattedMessage 
            langKey={errorMessageLangKey}
            color={'error'}
          />
        )}
        {props.children}
      </Box>
      <Divider />
    </Box>
  );
};

export default memo(TitleWrapper);