
import { useCallback, useMemo } from 'react';
import { cmsClient } from "constants/API";
import { useLazyQuery } from "@apollo/react-hooks";
import { ID } from "all-common-types";
import { QueriedRouteStationIcons, QUERY_ROUTE_STATION_ICON } from "../gql-schema";

const useQueryLanternStations = () => {
  const [queryFn, { data }] = useLazyQuery<QueriedRouteStationIcons>(QUERY_ROUTE_STATION_ICON, {
    client: cmsClient,
  });

  const handleQuery = useCallback((routeId: ID) => {
    routeId && queryFn({
      variables: {
        where: {
          icon: "lantern",
          xno: Number(routeId),
        }
      }
    });
  }, [queryFn]);

  const lanternStations = useMemo(() => {
    if (!data) return [];
    return data.routeStationIcons.map((item) =>({
      ...item,
      stations: item.stationIds.split(",")
    }));
  }, [data]);

  return {
    lanternStations,
    handleQuery
  };
};

export default useQueryLanternStations;