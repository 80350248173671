import React, { Ref } from 'react';
import { FilterSelectorContainerProps } from 'ibus-common-components/lib/components/common-components/FilterSelector/types';
import IntlFilterSelector from 'components/common-components/IntlComponents/IntlFilterSelector';

export interface SelectRoutesProps extends FilterSelectorContainerProps {
}

const SelectRoutes = (props: SelectRoutesProps) => {
  return (
    <IntlFilterSelector 
      {...props} />
  );
};

export default SelectRoutes;