import { useLazyQuery } from "@apollo/react-hooks";
import { client} from "constants/API";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import QUERY_TRAVEL_TIMES, { QueriedTravelTimes, QueryTravelTimesParams } from "../schemas/QUERY_TRAVEL_TIMES";

function useQueryTravelTimes() {
  const [queryFn, { error, loading, data }] = useLazyQuery<QueriedTravelTimes>(QUERY_TRAVEL_TIMES, {
    client: client,
  });

  const handleQuery = useCallback((params: QueryTravelTimesParams) => {
    queryFn({
      variables: {
        inputs: params.inputs,
      }
    });
  }, [queryFn]);

  const travelTimes = useMemo(() => (
    (data && data.travelTime.length > 0) ? data.travelTime.flat() : []
  ), [data]);

  return ({
    error,
    loading,
    data,
    travelTimes,
    handleQuery,
  });
};

export default useQueryTravelTimes;