import React from 'react';
import { Box } from '@material-ui/core';
import ScheduleItem from './ScheduleItem';
import { ScheduleListProps } from './types';

const ScheduleList = ({
  scheduleListData
}: ScheduleListProps) => {
  return (
    <Box>
      {scheduleListData.map((sch) => (
        <ScheduleItem
          key={sch.id}
          time={sch.scheduleTime}
          carType={sch.rawCarType}
          providerId={sch.providerId}
        />
      ))}
    </Box>
  );
};

export default ScheduleList;