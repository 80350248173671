import React from 'react';
import polyline from 'polyline';
import { OtpPosition, StepType, OtpLeg, OtpMode } from 'travel-planner';
import { Polyline, Marker } from 'react-leaflet';
import theme_Xmas from 'theme/theme';
import { POLYLINE_DASHED_DENSITY } from 'components/travel-planner/config';
import getStepLineItemIcon from './getStepLineItemIcon';

export const getPosition = (pos: OtpPosition): [number, number] => (
  [Number(pos.lat), Number(pos.lon)]
);
export const getPolylineStyleByTravelMode = (travel_mode: OtpMode) => {
  if(travel_mode === 'WALK') return 'dashed';
  return 'normal';
};
export const isDashedStyle = (style: string) => (
  style === 'dashed' ? POLYLINE_DASHED_DENSITY : undefined
);

export const getStepLineData = (origin: OtpLeg) => {
  const {
    legGeometry,
    from,
    mode,
  } = origin;
  return ({
    positions: polyline.decode(legGeometry.points) as [number, number][],
    markerPosition: getPosition(from),
    isDashed: isDashedStyle(getPolylineStyleByTravelMode(mode)),
  });
};

type Props = OtpLeg & {
  stepType?: StepType
}
const SingleStepLineItem = (props: Props) => {
  const { stepType='normal' } = props;
  const { positions, markerPosition, isDashed } = getStepLineData(props);
  return (
    <>
      <Marker
        position={markerPosition}
        icon={getStepLineItemIcon(stepType)} />
      <Polyline
        dashArray={isDashed}
        positions={positions}
        color={theme_Xmas.complementary.main} />
    </>
  );
};

export default SingleStepLineItem;