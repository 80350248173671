import { StepType } from "travel-planner";
import { stepIconsAndImgSrcs } from "components/travel-planner/config";
import getIcon from "lib/getIcon";


const getStepLineItemIcon = (stepType: StepType) => {
  const {
    imgSrc, iconSize, iconAnchor
  } = stepIconsAndImgSrcs[stepType];
  return getIcon({
    imgSrc,
    iconSize, 
    iconAnchor,
  });
};

export default getStepLineItemIcon;