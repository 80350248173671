import React from 'react';
import { Polyline } from 'react-leaflet';
import { routeLineStrokeWidth } from 'config';
import { GoBack, Position } from 'bus-common-types';
import { Theme, useTheme } from '@material-ui/core';
import theme_Xmas from 'theme/theme';
import { getPolylineColor } from 'constants/functions';

type RouteLineProps = {
  routePoints: Position[]
  goBack?: GoBack
}

const RouteLine = ({ routePoints, goBack=1 }: RouteLineProps) => {
  const theme = useTheme();
  return (
    <Polyline 
      color={ getPolylineColor(goBack, theme) } //from theme 
      strokeStyle={ routeLineStrokeWidth }
      className={ 'route-line' }
      positions={ routePoints } />
  );
};
export default RouteLine;