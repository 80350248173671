import React, {  } from 'react';
import MapIcon from '../CommonComponents/MapIcon';
import { Position, GoBack } from 'bus-common-types';
import zIndex from 'styles/styleObjs/zIndex';
import { MapMarkerProps } from './types';

class MapMarker extends React.Component<MapMarkerProps, {}> {
  markerRef = undefined as any
  markerRefFn = (el: any) => { this.markerRef = el; };
  //
  componentDidUpdate(prevProps: MapMarkerProps) {
    if(prevProps.isOpen !== this.props.isOpen) {
      this.togglePopup();
    }
  }
  openPopup = () => {
    this.markerRef.leafletElement.openPopup();
  }
  togglePopup = () => {
    this.props.isOpen ? this._handleOpen() : this._handleClose();
  }

  _handleOpen = () => {
    setTimeout(() => {
      this.openPopup();
    }, 500);
  }
  _handleClose = () => {
    this.markerRef.leafletElement && this.markerRef.leafletElement.closePopup();
  }
  //
  render() {
    const { position, index=0, popupMinWidth, goBack } = this.props;
    return (
      <MapIcon 
        goBack={goBack}
        number={ index + 1 }
        zIndex={ zIndex.mapMarker } 
        markerRef={ this.markerRefFn } 
        position={ position }
        popupMinWidth={popupMinWidth}
      >
        {this.props.children}
      </MapIcon>
    );
  }
}
export default MapMarker;