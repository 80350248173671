import React from 'react';
import { connectCtx } from 'react-function-helpers';
import { InitNames } from 'components/common-components/TwoAutoCompletesWithExchangeAndSearch/types';
import { MapDispatchToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import TwoAutoCompletesWithExchangeAndSearch from 'components/common-components/TwoAutoCompletesWithExchangeAndSearch/TwoAutoCompletesWithExchangeAndSearch';
import { SearchByFromToStopsContainerProps, SearchByFromToStopsContainerDispatchesFromCtx } from '../types';
import useQueryStations from 'components/common-components/AutoCompleteWithQuery/useQueryStations';
import { setStopSearchFromTo, resetRouteId } from 'components/collinear-routes/actions';
import ContextStore from 'components/collinear-routes/constants/context';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { setFromStation } from 'components/collinear-routes/actions/from-station-id.actions';
import useSearchByFromToStops from './functions/useSearchByFromToStops';

export const initAutoCompleteWithResultsAndValuesProps = ({
  initNameResultList: {
    from: [],
    to: [],
  },
  initNameValueResults: {
    from: {
      // placeholder: '起點',
      placeholder: (
        <IntlFormattedMessage 
          langKey={'collinearRoutes.fromTo.fromStop'}
        />
      ) as unknown as string,
      //--for usage test--
      // value: '草湖',
      value: '',
      result: undefined,
      getValueResultListFn: () => {},
    },
    to: {
      // placeholder: '終點',
      placeholder: (
        <IntlFormattedMessage 
          langKey={'collinearRoutes.fromTo.toStop'}
        />
      ) as unknown as string,
      //--for usage test--
      // value: '國光橋',
      value: '',
      result: undefined,
      getValueResultListFn: () => {},
    }
  }
});

export const SearchByFromToStopsContainer = (props: SearchByFromToStopsContainerProps) => {
  const {
    ref,
    handleExchange,
    handleSearch,
  } = useSearchByFromToStops(props);

  return (
    <TwoAutoCompletesWithExchangeAndSearch<InitNames>
      loading={false}
      queryResultListHook={useQueryStations}
      searchFn={handleSearch}
      exchangeValueFn={handleExchange}
      initAutoCompleteWithResultsAndValuesProps={
        initAutoCompleteWithResultsAndValuesProps
      }
      autoCompleteWithResultsAndValuesRef={ref}  />
  );
};

interface OwnProps {}

export const mapDispatchToProps: MapDispatchToProps<OwnProps, SearchByFromToStopsContainerDispatchesFromCtx> = (dispatch) => {
  return ({
    setFromStationToCtx: payload => {
      const action = setFromStation(payload);
      dispatch(action);
      return action;
    },
    setFromToStationToCtx: (payload) => {
      const action = setStopSearchFromTo(payload);
      dispatch(action);
      return action;
    },
    resetSelectedRouteId: () => {
      const action = resetRouteId();
      dispatch(action);
    }
  });
};

const SearchByFromToStopsContainerWithCtx = connectCtx(ContextStore)(undefined, mapDispatchToProps)(SearchByFromToStopsContainer);

export default SearchByFromToStopsContainerWithCtx;