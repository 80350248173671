import { useLazyQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { QueriedRouteForRouteMap, QUERY_ROUTE_FOR_ROUTE_MAP } from '../gql-schema';
import { useCallback } from 'react';
import { ID } from 'all-common-types';

const useQueryRouteForRouteMap = () => {
  const { locale } = useIntl();
  const [queryFn, { loading, error, data }] = useLazyQuery<QueriedRouteForRouteMap>(QUERY_ROUTE_FOR_ROUTE_MAP);

  const handleQuery = useCallback((routeId: ID) => {
    queryFn({
      variables: {
        lang: locale,
        routeId: Number(routeId),
      }
    });
  }, [locale, queryFn]);
  
  return ({
    loading,
    error,
    data,
    handleQuery,
  });
};

export default useQueryRouteForRouteMap;