import React, { useContext } from "react";
import { Container, Grid, makeStyles, Box } from "@material-ui/core";
import Blogs from "./blogs";
import WelcomTitle from "./WelcomTitle";
import { titleData_blog } from "components/home-page/static/home-page";
import { SingleNotice } from "all-common-types";
import ContextStore from "constants/context";
import LinksPart from "./LinksPart/LinksPart";
import MiddleNavPart from "./MiddleNavPart/MiddleNavPart";
import styleLayout from "styles/styleObjs/styleLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    flex: 1,
    overflow: "auto",
    minHeight: styleLayout.homepageContentHeight,
    backgroundSize: theme.name == "xmas" ? "auto" : "23%",
    backgroundImage: `url(${theme.homepageImages.bottomLeft})`,
    backgroundPosition: `left bottom`,
    backgroundRepeat: "no-repeat",
  },
  newsAndLinks: {
    // paddingTop: theme.spacing(2)
  },
  iconLinks: {
    order: 3,
    [theme.breakpoints.up("lg")]: {
      order: 2,
    },
  },
  homePageImageTopRight: {
    order: 2,
    [theme.breakpoints.up("lg")]: {
      order: 3,
    },
  },

  blogsAndLinksPart: {
    maxWidth: theme.breakpoints.width("lg"),
    [`@media (max-width: ${1600}px)`]: {
      // maxWidth: theme.breakpoints.width('md'),
      maxWidth: styleLayout.homePageLinksPartMaxWidth,
    },
  },
}));

type HomePageProps = {
  linksData: import("all-common-types").SingleLink[];
  blogsData: import("blog").SingleBlog[];
  noticeData: SingleNotice[];
};
const HomePage = ({ linksData, blogsData }: HomePageProps) => {
  const classes = useStyles();
  const { state } = useContext(ContextStore);

  return (
    <Box className={classes.root}>
      <MiddleNavPart linksData={linksData} />
      <Container className={classes.blogsAndLinksPart}>
        <Grid className={classes.newsAndLinks} container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Blogs titleData={titleData_blog} blogsData={blogsData} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <LinksPart />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HomePage;
