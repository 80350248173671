import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { HintMessageProps } from './types';

const useStyles = makeStyles(theme => ({
  root: {
    opacity: 0.7,
  }
}));

function HintMessage<Value>(props: HintMessageProps<Value>) {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      paddingTop={2}
      textAlign={'center'}
    >
      <IntlFormattedMessage 
        variant={'h5'}
        {...props} 
      />
    </Box>
  );
};

export default HintMessage;