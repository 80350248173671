import React from 'react';
import { MapStateToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import ContextStore, { State } from 'constants/context';
import { TimeTableInfoContainerProps, TimeTableInfoContainerWithCtxProps, TimeTableInfoStateFromCtx } from './types';
import TimeTableInfo from 'components/driving-map/components/TimeTable/TimeTableInfo';
import { connectCtx } from 'react-function-helpers';

const TimeTableInfoContainer = (props: TimeTableInfoContainerProps) => {
  return (
    <TimeTableInfo 
      timeTableType={props.timeTableType}
    />
  );
};

const mapStateToProps: MapStateToProps<State, TimeTableInfoContainerWithCtxProps, TimeTableInfoStateFromCtx> = (state, ownProps) => {
  const routeId = Number(ownProps.routeId);
  const { handledTimeTableRoutes, } = state.ibusCommonData;

  let timeTableType: TimeTableInfoStateFromCtx['timeTableType'] = null;

  if(handledTimeTableRoutes.headway.includes(routeId)) {
    timeTableType = 'headway';
  }
  else if(handledTimeTableRoutes.mixed.includes(routeId)) {
    timeTableType = 'mixed';
  }

  return ({
    timeTableType,
  });
};

const TimeTableInfoContainerWithCtx = connectCtx(ContextStore)(mapStateToProps)(TimeTableInfoContainer);

export default TimeTableInfoContainerWithCtx;