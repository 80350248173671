import React, { ReactNode } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import { basicHoverEffectStyle } from 'components/home-page/blogs';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const useStyles = makeStyles(theme => ({
  root: {
    ...basicHoverEffectStyle('#ddd'),
    backgroundColor: '#eee',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
  }
}));

const UploadLabelWrapper = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();

  return (
    <label>
      <Box className={classes.root}>
        <Box display={'flex'} alignItems={'center'}>
          <CloudUploadOutlined fontSize={'large'} color={'primary'} />
          <IntlFormattedMessage langKey={'uploadPart.clickToUpload'} style={{ paddingLeft: 8, }} />
        </Box>
        <Box style={{ display: 'none', }}>
          {children}
        </Box>
      </Box>
    </label>
  );
};

export default UploadLabelWrapper;