import accumulateArr from "components/mrt-fare/lib/functions/accumulateArr";
import { QueriedTravelTimeFragment, SingleQueriedTravelTime } from "constants/api-fragments";
import { SingleBasicStationForMrtAndBus } from "mrt-pages";
import MrtStationsTravelTimeHandlers, { SingleBasicStationForMrtAndBusWithTravelTime } from './MrtStationsTravelTimeHandlers';

export interface SingleQueriedTravelTimeWithAccumTravelTime extends SingleQueriedTravelTime {
  thisStationTravelTimeSecs: number
  accumTravelTimeSecs: number
}

// 用於處理捷運線的旅行時間資料
const RouteMrtTravelTimeHandlers = {
  getAccumutatedTravelTimes(travelTimes: SingleQueriedTravelTime[]): SingleQueriedTravelTimeWithAccumTravelTime[] {
    let res: SingleQueriedTravelTimeWithAccumTravelTime[] = [];

    for (let i = 0; i < travelTimes.length; i++) {
      const travelTime = travelTimes[i];
      let thisStationTravelTimeSecs = 0;
      let prevAccumTravelTimeSecs = 0;
      // 除首站以外才要算
      if(i > 0) {
        thisStationTravelTimeSecs = MrtStationsTravelTimeHandlers.convertTravelTimeToSecs(travelTime.travelTime);
        prevAccumTravelTimeSecs = res[i - 1].accumTravelTimeSecs;
      }

      res[i] = {
        ...travelTime,
        thisStationTravelTimeSecs,
        accumTravelTimeSecs: prevAccumTravelTimeSecs + thisStationTravelTimeSecs,
      };
    }

    return res;
  },

  combineStationsWithAccumulatedTravelTimes(
    mrtStations: SingleBasicStationForMrtAndBus[], 
    accumulatedTravelTimes: SingleQueriedTravelTimeWithAccumTravelTime[],
    isPassedMrt: boolean
  ) {
    let res: SingleBasicStationForMrtAndBusWithTravelTime[] = [];

    for (let i = 0; i < mrtStations.length; i++) {
      const mrtStation = mrtStations[i];
      const travelTime = MrtStationsTravelTimeHandlers.getMatchedTravelTime({
        travelTimes: accumulatedTravelTimes, station: mrtStation, isPassedMrt,
      });

      res[i] = {
        ...mrtStation,
        travelTime,
        travelTimeSecs: travelTime.accumTravelTimeSecs,
      };
    }

    return res;
  },

  combineStationsWithRawTravelTimes(
    mrtStations: SingleBasicStationForMrtAndBus[], 
    mrtTravelTimes: QueriedTravelTimeFragment,
    isPassedMrt: boolean,
  ) {
    const goTravelTimes = mrtTravelTimes[0]; // 與顯示反方向
    const backTravelTimes = mrtTravelTimes[1]; // 與顯示同方向
    
    const accumulatedGoTravelTimes = this.getAccumutatedTravelTimes(goTravelTimes);
    const accumulatedBackTravelTimes = this.getAccumutatedTravelTimes(backTravelTimes);

    const allTravelTimes = [
      ...accumulatedGoTravelTimes,
      ...accumulatedBackTravelTimes,
    ];

    const res = this.combineStationsWithAccumulatedTravelTimes(mrtStations, allTravelTimes, isPassedMrt);
    return res;
  },
};

export default RouteMrtTravelTimeHandlers;