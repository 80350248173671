import getArrHalfIndex from "lib/functions/getArrHalfIndex";
import { StationNameDirection } from "mrt-pages";

const getCenterPartDirection = (indexNow: number, arrLength: number): StationNameDirection => {
  const halfIndex = getArrHalfIndex(arrLength);

  if(indexNow === 0 || (indexNow > halfIndex && indexNow !== arrLength - 1)) {
    return 'right';
  }
  else {
    return 'left';
  }
};

export default getCenterPartDirection;