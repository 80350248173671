/* eslint-disable no-unused-vars */
import React, {  } from 'react';
import { Box, Typography, LinearProgress, Button } from '@material-ui/core';
import { refreshTime } from 'config';
import useInterval from 'lib/customHooks/useInterval';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import ZoomInOut from '../CommonComponents/ZoomInOut/ZoomInOut';
import { ZoomInOutProps } from '../CommonComponents/ZoomInOut/types';

export interface RefreshBarProps {
  refreshFn?: (x?: any) => any
}
const refreshLog = () => {
  // console.log('refreshed')
};
export const getRemainTimeStr = (remainTime: number) => (
  remainTime === 0 ? (
    <IntlFormattedMessage 
      variant={ 'body1' }
      langKey={'refresh.refreshed'}
    />
  ) : (
    <IntlFormattedMessage 
      variant={ 'body1' }
      langKey={'refresh.refreshAfterSecs'}
      values={{
        secs: remainTime,
      }}
    />
  )
);

const RefreshBar = (props: RefreshBarProps) => {
  const { refreshFn=refreshLog } = props;
  const {
    timeNow,
    manualRefresh,
  } = useInterval({
    refreshCount: refreshTime,
    refreshCallback: refreshFn,
  });

  const progressPercent = (timeNow / refreshTime) * 100;
  const remainTime = refreshTime - timeNow;

  return (
    <Box>
      <LinearProgress variant={ 'determinate' } value={ progressPercent } />
      <Box display={'flex'} justifyContent={'space-between'}>
        {getRemainTimeStr(remainTime)}
        <Button onClick={manualRefresh}> 
          <IntlFormattedMessage 
            langKey={'refresh.main'}
          />
        </Button>
      </Box>
    </Box>
  );
};
export default RefreshBar;