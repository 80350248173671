import { SingleRouteInfo, FromTo, SingleTravelTime } from "collinear-routes";
import { EdgesData } from "all-common-types";
import { SingleEstimateStop, RouteDataType, GoBack, SingleStationInEdge } from "bus-common-types";
import { initRouteTimesInRouteInfo, getTakenTimeStr } from "./static/initRouteTimesRouteInfo";
import { SingleDepDestAndStopSearchedRouteInfo } from "./containers/stopSearchContainers/StopSearched_RouteInfoList_Container";
import EstimateTimeHandler from "constants/EstimateTimeHandler";
import { DepDestTabListProps } from "components/common-components/TabComponents/types";
import { CollinearRoutesState } from "./constants/context";
import { SingleQueriedTravelTime } from "constants/api-fragments";
import AccumulateTravelTimesHandlers from "./containers/Handlers/AccumulateTravelTimesHandlers";
import { seperateData } from "lib/seperateDataHelpers";
import fillNumber from "lib/functions/fillNumber";

export const getTakenTimeFromComeTime = (time_from: string, time_to: string): number => {
  const fromTime = new Date(`1/11 ${time_from}`).getTime();
  const toTime = new Date(`1/11 ${time_to}`).getTime();
  const takenTimeMins = (toTime - fromTime) / 1000 / 60;

  const res = Math.abs(Math.floor(takenTimeMins));
  return res;
};

type GetTakenTimeParam = Parameters<typeof EstimateTimeHandler.getTakenTimeFromEtasAndComeTime>[0]

export const getTakenTime = (
  fromEstimateTime: GetTakenTimeParam, 
  toEstimateTime: GetTakenTimeParam
): number => {
  // const fromTime = new Date(`1/11 ${time_from}`).getTime();
  // const toTime = new Date(`1/11 ${time_to}`).getTime();
  // const takenTimeMins = (toTime - fromTime) / 1000 / 60;
  const fromTakenTime = EstimateTimeHandler.getTakenTimeFromEtasAndComeTime(fromEstimateTime);
  const toTakenTime = EstimateTimeHandler.getTakenTimeFromEtasAndComeTime(toEstimateTime);
  const takenTimeMins = toTakenTime - fromTakenTime;

  const res = Math.abs(Math.floor(takenTimeMins));
  return res;
};

export const getFromToEstimateStopsTimes = (initRouteTimes: typeof initRouteTimesInRouteInfo) => {
  return (fromStop: SingleEstimateStop, toStop: SingleEstimateStop): typeof initRouteTimesInRouteInfo => {
    const comeTime_fromStop = fromStop.comeTime;
    const comeTime_toStop = toStop.comeTime;
    if(!comeTime_fromStop || !comeTime_toStop) {
      return initRouteTimes;
    }

    const takenTime = getTakenTime(fromStop, toStop);
    const startArrivalTime = EstimateTimeHandler.getEstimateTime(fromStop).comeTimeStr as string;

    return ({
      startArrivalTime,
      destArrivalTime: comeTime_toStop,
      takenTime: getTakenTimeStr(takenTime),
    });
  };
};

export const handleRouteTimes = (initRouteTimes=initRouteTimesInRouteInfo) => (
  from: string, to: string, estimateStops: SingleEstimateStop[]
) => {
  const fromStop = estimateStops.find(stop => stop.stopName === from);
  const toStop = estimateStops.find(stop => stop.stopName === to);

  if(fromStop && toStop) {
    const handledTimes = getFromToEstimateStopsTimes(initRouteTimes)(fromStop, toStop);
    return handledTimes;
  }
  return initRouteTimes;
};

export const getRouteInfoStationSeq = (
  to: string, 
  estimateStops: SingleEstimateStop[]
) => (
  estimateStops.findIndex(stop => stop.stopName === to) + 1
);

export type StopSearchedRouteInfo = {
  go: SingleTravelTime[]
  back: SingleTravelTime[]
}
export const getTravelTimesByFromStationId = (
  fromStationName: string, 
  travelTimes: SingleQueriedTravelTime[],
  stations: SingleStationInEdge[]
): SingleTravelTime[] => {
  let res: SingleTravelTime[] = [];
  const fromStationIdx = stations.findIndex(t => (
    String(t.node.name) === fromStationName
  ));
  const slicedStations = stations.slice(fromStationIdx);
  
  // travel times拿到的已經是從from station id之後的
  const accumulatedTravelTimes = AccumulateTravelTimesHandlers.accumulateAllTravelTimes(travelTimes).map(a => a.travelTimeMinStr);

  res = slicedStations.map((s, i) => ({
    id: fillNumber(s.ivrNo),
    stopSeq: fromStationIdx + i + 1,
    stopName: s.node.name,
    travelTimeMins: accumulatedTravelTimes[i],
  }));
  return res;
};
export const getStopSearchedRouteInfo = (
  fromStationName: string, 
  travelTimes: SingleQueriedTravelTime[],
  stations: SingleStationInEdge[]
): StopSearchedRouteInfo => {
  const travelTimesGo = travelTimes.filter(t => t.goBack === 1);
  const travelTimesBack = travelTimes.filter(t => t.goBack === 2);

  const seperatedStations = seperateData.makeGoBackDataToObj(stations);
  const stationsGo = seperatedStations.go;
  const stationsBack = seperatedStations.back;

  const handleTravelTimesGo = getTravelTimesByFromStationId(fromStationName, travelTimesGo, stationsGo);
  const handleTravelTimesBack = getTravelTimesByFromStationId(fromStationName, travelTimesBack, stationsBack);

  return ({
    go: handleTravelTimesGo,
    back: handleTravelTimesBack,
  });
};

export class HandleDepDestAndStopSearchedRouteInfo {
  static combine(
    depDest: {
      departure: string
      destination: string
    }, 
    stopSearchedRouteInfo: StopSearchedRouteInfo
  ): SingleDepDestAndStopSearchedRouteInfo[] {
    return [
      {
        isDisplay: true,
        goBack: 1,
        location: depDest.destination,
        stopSearchedRouteInfo: stopSearchedRouteInfo.go,
      },
      {
        isDisplay: true,
        goBack: 2,
        location: depDest.departure,
        stopSearchedRouteInfo: stopSearchedRouteInfo.back,
      }
    ];
  }

  static checkIsNotEmptyStopSearchedRouteInfo = (
    depDestAndStopSearchedRouteInfo: SingleDepDestAndStopSearchedRouteInfo
  ) => (
    depDestAndStopSearchedRouteInfo.stopSearchedRouteInfo.length > 0
  )

  static filterEmptyRouteInfo(
    depDestAndStopSearchedRouteInfos: SingleDepDestAndStopSearchedRouteInfo[]
  ) {
    const res = depDestAndStopSearchedRouteInfos.map(d => ({
      ...d,
      isDisplay: this.checkIsNotEmptyStopSearchedRouteInfo(d),
    }));
    return res;
  }

  static getHandledFinalResult(
    fromStationName: string, 
    routeData: RouteDataType, 
    travelTimes: SingleQueriedTravelTime[],
  ) {
    const handledRouteInfos = getStopSearchedRouteInfo(fromStationName, travelTimes, routeData.stations.edges);
    const combined = this.combine(routeData, handledRouteInfos);
    const handled = this.filterEmptyRouteInfo(
      combined
    );
    return handled;
  }

  static getDepDestFromCombinedResult<SingleDepDest extends {location: string, isDisplay: boolean}>(
    depDestAndStopSearchedRouteInfos: SingleDepDest[]
  ): DepDestTabListProps['depDest'] {
    if(depDestAndStopSearchedRouteInfos.length === 2) {
      return ({
        destination: {
          location: depDestAndStopSearchedRouteInfos[0].location,
          isDisplay: depDestAndStopSearchedRouteInfos[0].isDisplay,
        },
        departure: {
          location: depDestAndStopSearchedRouteInfos[1].location,
          isDisplay: depDestAndStopSearchedRouteInfos[1].isDisplay,
        },
      });

    }
    return ({
      destination: { location: '', isDisplay: false, },
      departure: { location: '', isDisplay: false, }
    });
  }



  static getInitGoBack<SingleDepDestData extends { isDisplay: boolean, goBack: GoBack}>(
    depDestAndStopSearchedRouteInfos: SingleDepDestData[]
  ) {
    const filteredByDisplay = depDestAndStopSearchedRouteInfos.filter(d => d.isDisplay);

    if(filteredByDisplay.length === 1) {
      const onlyOneGoBack = filteredByDisplay[0].goBack;
      return onlyOneGoBack;
    }
    return 1;
  }
}

