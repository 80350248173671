import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import { StopSearchRouteInfoItemProps } from './types';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import CommonListItem from 'components/common-components/CommonListItem';
import { STOP_SEARCH_ROUTE_INFO_ITEM_WIDTH_RATIOS } from 'components/collinear-routes/config';

const StopSearchRouteInfoItem = (props: StopSearchRouteInfoItemProps) => {
  const {
    stopSeq,
    stopName,
    id,
    travelTimeMins,
  } = props;

  const textsInList = [
    stopSeq, stopName, id, (
      <IntlFormattedMessage
        langKey={'collinearRoutes.estimateTravelTime'}
        values={{
          minutes: travelTimeMins,
        }}
      />
    )
  ];
  
  return (
    <CommonListItem
      textsInList={textsInList}
      textsWidthRatios={STOP_SEARCH_ROUTE_INFO_ITEM_WIDTH_RATIOS} />
  );
};

export default memo(StopSearchRouteInfoItem);