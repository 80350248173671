import { useLazyQuery } from "@apollo/react-hooks";
import { ID } from "all-common-types";
import { SingleSchedule } from "bus-common-types";
import { client} from "constants/API";
import NEW_SCHEMA_API_SCHEMAS, { NewSchemaApiTypes, } from "constants/NewSchemaAPI/commonAPI";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

function useQueryDailyTimeTable() {
  const [queryFn, { error, loading, data }] = useLazyQuery<NewSchemaApiTypes['QueriedDailyTimeTable']>(NEW_SCHEMA_API_SCHEMAS.QUERY_DAILY_TIME_TABLE, {
    client: client,
  });

  const handleQuery = useCallback(({
    routeId, date
  }: {
    routeId: ID
    date: string
  }) => {
    queryFn({
      variables: {
        routeId: Number(routeId),
        date,
      }
    });
  }, [queryFn]);

  // 兼容舊版格式
  const schedules: SingleSchedule[] = useMemo(() => (
    data ? data.dailyTimeTable.edges.map(d => ({
      ...d.node,
      rawCarType: d.node.carType
    })) : []
  ), [data]);

  return ({
    error,
    loading,
    data,
    schedules,
    handleQuery,
  });
};

export default useQueryDailyTimeTable;