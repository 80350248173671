import { SingleRouteGroup } from "driving-map";
import { SingleSelectOption, Locale } from "all-common-types";
import lang from 'static/lang/driving-map.json';

export const ALL_ROUTE_GROUP = 'ALL_ROUTE_GROUP';

export const allRouteGroupZh: SingleSelectOption = {
  text: '所有群組',
  value: lang.zh_TW["drivingMap.busTypeTab.selectAllGroup"]
};

export const allRouteGroupEn: SingleSelectOption = {
  text: 'All Route Group',
  value: lang.en_US["drivingMap.busTypeTab.selectAllGroup"]
};

const handleRouteGroupOptionsToSelectorOptions = (locale: Locale) => (routeGroupList: SingleRouteGroup[]): SingleSelectOption[] => {
  const allRouteGroup = locale === 'zh' ? allRouteGroupZh : allRouteGroupEn;

  return [
    allRouteGroup,
    ...routeGroupList.map(r => ({
      text: r.name,
      value: r.id,
    }))
  ];
};

export default handleRouteGroupOptionsToSelectorOptions;