import { GoBack } from "bus-common-types";
import getArrLastEl from "lib/functions/getArrLastEl";
import { RouteTypeForMrt } from "mrt-pages";
import { HandledStationsWithTravelTimes, SingleBasicStationForMrtAndBusWithTravelTime } from "./MrtStationsTravelTimeHandlers";

export interface CombineLastStationTravelTimeOptions {
  goBack: GoBack
  stationsWithTravelTimes: HandledStationsWithTravelTimes
}

const CombineLastStationTravelTime = {
  combineLastStationTravelTime(
    lastStationTravelTimeSecs: number, 
    stations: SingleBasicStationForMrtAndBusWithTravelTime[]
  ) {
    const res = stations.map(s => ({
      ...s,
      travelTimeSecs: s.travelTimeSecs + lastStationTravelTimeSecs,
    }));
    return res;
  },

  checkAndCombine({
    goBack,
    stationsWithTravelTimes,
  }: CombineLastStationTravelTimeOptions) {
    const {
      route309OtherStations, 
      route310OtherStations, 
      commonBusStations,
    } = stationsWithTravelTimes;

    let res = {
      ...stationsWithTravelTimes,
    };

    if(goBack === 1) {
      const route309LastStation = getArrLastEl(route309OtherStations);
      const route310LastStation = getArrLastEl(route310OtherStations);

      const lastStation = route309LastStation.travelTime.travelTime !== '' ? 
        route309LastStation : route310LastStation;
      res.commonBusStations = this.combineLastStationTravelTime(
        lastStation.travelTimeSecs, commonBusStations,
      );
    }

    else {
      const firstStation = commonBusStations[0];
      if(route309OtherStations.length > 0) {
        res.route309OtherStations = this.combineLastStationTravelTime(
          firstStation.travelTimeSecs, route309OtherStations,
        );
      }
      if(route310OtherStations.length > 0) {
        res.route310OtherStations = this.combineLastStationTravelTime(
          firstStation.travelTimeSecs, route310OtherStations,
        );
      }
    }

    return res;
  },
};

export default CombineLastStationTravelTime;