import React, {  } from 'react';
import CommonListItem from 'components/common-components/CommonListItem';
import { ROUTE_INFO_ITEM_WIDTH_RATIOS } from 'components/collinear-routes/config';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { Box } from '@material-ui/core';

const textsInList = [
  (
    <IntlFormattedMessage
      langKey={'route'}
    />
  ), (
    <IntlFormattedMessage 
      langKey={'esimateTimeMins'}
    />
  ),
  (
    <IntlFormattedMessage 
      langKey={'passedStopsAmount'}
    />
  ), 
  (
    <IntlFormattedMessage
      langKey={'drivingTime'}
    />
  ),
];

const RouteInfoItemHeader = () => {
  return (
    <Box paddingRight={'14px'}>
      <CommonListItem
        textsInList={textsInList}
        textsWidthRatios={ROUTE_INFO_ITEM_WIDTH_RATIOS} />
    </Box>
  );
};



export default RouteInfoItemHeader;