import { Reducer } from "all-common-types";
import { DrivingMapState } from "../constants/context";
import { AllActionTypes, actionTypes } from "../actions";

type IbikeInfoListReducer = Reducer<DrivingMapState, AllActionTypes, DrivingMapState['ibikeInfoList']>

const ibikeInfoList: IbikeInfoListReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_IBIKE_INFO_LIST:
      return action.payload.ibikeInfoList;
    default:
      return state.ibikeInfoList;
  }
};

export default ibikeInfoList;