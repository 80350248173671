import { SearchByFromToLocations } from '../types';
import { MapDispatchToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import { getPlans, resetPlans } from 'components/travel-planner/actions';
import { PlanMode } from 'travel-planner';

interface OwnProps {
  planMode: PlanMode
}

const mapGetPlansDispatchToSearchFnProps: MapDispatchToProps<OwnProps, {
  searchFn: SearchByFromToLocations
}> = (dispatch) => {
  return ({
    searchFn: async (params, setLoading) => {
      const resetAction = resetPlans();
      dispatch(resetAction);

      const action = await getPlans(params);
      dispatch(action);
      if(typeof setLoading === 'function') {
        setLoading(false);
      }
    }
  });
};

export default mapGetPlansDispatchToSearchFnProps;