import React from "react";
import { useStyles } from "../styles/BusMapPart";
import { Box } from "@material-ui/core";
import { Map as LeafletMap } from "react-leaflet";
import TGOSTileLayer from "../../../../tgos/TGOSTileLayer";

import { defaultMapCenter } from "config";

const BusMapWithNoRoute = () => {
  const classes = useStyles();
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      className={classes.root}>
      <LeafletMap center={defaultMapCenter} zoom={12}>
        <TGOSTileLayer opacity={0.9} />
      </LeafletMap>
    </Box>
  );
};

export default BusMapWithNoRoute;
