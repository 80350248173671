import React, {  } from 'react';
import useQueryStations from './useQueryStations';
import SingleAutoCompleteWithQuery from './SingleAutoCompleteWithQuery';
import { SingleAutoCompleteWithQueryStationsProps, SingleAutoCompleteWithQueryStationsResultType } from './types';

function SingleAutoCompleteWithQueryStations(props: SingleAutoCompleteWithQueryStationsProps) {
  return (
    <SingleAutoCompleteWithQuery<SingleAutoCompleteWithQueryStationsResultType>
      {...props}
      queryResultListHook={useQueryStations} />
  );
};

export default SingleAutoCompleteWithQueryStations;