import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { DirectionsBus, DirectionsWalk, Train } from '@material-ui/icons';
import { OtpLeg, OtpMode, StepType, OtpPosition, OtpLegPosition } from 'travel-planner';
import { stepIconsAndImgSrcs, ICON_WIDTH_BASIC } from 'components/travel-planner/config';
import StepLineItem from './StepLineItem';

import useDurationMsg from 'components/travel-planner/lib/functions/useDurationMsg';
import useDistanceMsg from 'components/travel-planner/lib/functions/useDistanceMsg';
import { SingleRouteFromRoutes } from 'all-common-types';

const useStyles = makeStyles(theme => ({
  root: {

  },
  icon: {
    width: ICON_WIDTH_BASIC,
    height: ICON_WIDTH_BASIC,
  },
  leftIconPart: {
    width: ICON_WIDTH_BASIC,
  },
  nameAndMsgPart: {
    padding: theme.spacing(2),
    paddingTop: 0,
    '& p': {
      margin: 0,
    }
  },
  msgPart: {
    paddingTop: theme.spacing(1),
  }
}));

export const getStepIcon = (travelMode: OtpMode) => {
  switch (travelMode) {
    case 'BUS':
      return (
        <DirectionsBus />
      );
    case 'WALK':
      return (
        <DirectionsWalk />
      );
    // 捷運、台鐵、高鐵都是用火車
    case 'RAIL':
    case 'SUBWAY':
      return (
        <Train />
      );
    // 腳踏車一律都用公車
    default:
      return (
        <DirectionsBus />
      );
  }
};

export const getStepTypeLocationIcon = (stepType: StepType) => {
  return stepIconsAndImgSrcs[stepType].IconComponent();
};

export const StepLocation = ({ name }: { name: string }) => {
  return (
    <Typography variant={'body1'} style={{ fontWeight: 800, }}>{name}</Typography>
  );
};

export const useAfterStopMsg = (from: OtpLegPosition, to: OtpLegPosition) => {
  const { formatMessage } = useIntl();
  const afterStop = to.stopIndex !== undefined && from.stopIndex !== undefined ? (to.stopIndex - from.stopIndex) : 0;
  const msg = formatMessage({id: 'travelPlanner.planPart.description.afterStop'}, {afterStop});
  return msg;
};

/**
 * 步行往 豐樂公園
 * 時間：2 分 , 距離：281公尺'
 */
const WalkDescription = ({to, duration, distance}: OtpLeg) => {
  const { formatMessage } = useIntl();
  const durationMsg = useDurationMsg(duration);
  const distanceMsg = useDistanceMsg(distance);
  const routeDesc = `${formatMessage({id: 'travelPlanner.planPart.take.WALK'})}`
    + `${formatMessage({id: 'travelPlanner.planPart.to'})} ${to.name}`;
  const moveDesc = ``
    + `${formatMessage({id: 'travelPlanner.planPart.description.duration'}, {duration: durationMsg})}，`
    + `${formatMessage({id: 'travelPlanner.planPart.description.distance'}, {distance: distanceMsg})}`;

  const classes = useStyles();
  return <Box className={classes.msgPart}>
    <Typography>{routeDesc}</Typography>
    <Typography>{moveDesc}</Typography>
  </Box>;
};

/**
 * 搭乘捷運(捷運綠線) 往北屯總站
 * 上車站：豐樂公園
 * 經過站數：4站 , 時間：8 分 , 距離：3.3公里
 * 下車站：市政府
 */

type OtpLegWithRoute = OtpLeg & {
  singleRoute?: SingleRouteFromRoutes
}

const TransitDescription = ({ mode, from, to , duration, distance, headsign, routeLongName, routeShortName, singleRoute }: OtpLegWithRoute) => {
  const { formatMessage } = useIntl();
  const afterStopMsg = useAfterStopMsg(from, to);
  const durationMsg = useDurationMsg(duration);
  const distanceMsg = useDistanceMsg(distance);
  var routeDesc = `${formatMessage({id: `travelPlanner.planPart.take.${mode}`})}`;
  if (mode === 'BUS'){
    routeDesc += ` (`;
    if (singleRoute){
      const providerName = singleRoute.providers.edges.map(edge => edge.node.name).join('、');
      routeDesc += `${providerName} `;
    }
    routeDesc += `${routeShortName} ${formatMessage({id: `travelPlanner.planPart.line`})}`;
    routeDesc += `) `;
  }else{
    routeDesc += `(${routeLongName}) `;
  }
  routeDesc += `${formatMessage({id: 'travelPlanner.planPart.to'})} ${headsign}`;

  const moveDesc = `${afterStopMsg}，`
    + `${formatMessage({id: 'travelPlanner.planPart.description.duration'}, {duration: durationMsg})}，`
    + `${formatMessage({id: 'travelPlanner.planPart.description.distance'}, {distance: distanceMsg})}`;

  const classes = useStyles();
  return <Box className={classes.msgPart}>
    <Typography>{routeDesc}</Typography>
    <Typography>{`${formatMessage({id: 'travelPlanner.planPart.description.upStation'}, {station: from.name})}`}</Typography>
    <Typography>{moveDesc}</Typography>
    <Typography>{`${formatMessage({id: 'travelPlanner.planPart.description.downStation'}, {station: to.name})}`}</Typography>
  </Box>;
};

type Props = OtpLegWithRoute & {
  stepType: StepType
}
const SingleStepItem = (props: Props) => {
  const {
    from,
    mode,
    stepType
  } = props;

  const classes = useStyles();
  return (
    <Box display={'flex'}>
      <Box
        display={'flex'}
        alignItems={'center'}
        className={classes.leftIconPart}
      >
        {getStepIcon(mode)}
      </Box>
      <Box className={classes.nameAndMsgPart} display={'flex'}>
        <Box>
          <Box className={classes.icon}>{getStepTypeLocationIcon(stepType)}</Box>
          <StepLineItem travelMode={mode} />
        </Box>
        <Box paddingLeft={1}>
          <StepLocation name={from.name} />
          { mode === 'WALK' &&  <WalkDescription {...props} />}
          { (mode === 'BUS' || mode === 'RAIL' || mode === 'SUBWAY') &&  <TransitDescription {...props} />}
        </Box>
      </Box>
    </Box>
  );
};

export const LastStepItem = ({ stepType, to, }: {
  to: OtpPosition
  stepType: StepType
}) => {
  const classes = useStyles();
  return (
    <Box display={'flex'}>
      <Box
        className={classes.leftIconPart}
      />
      <Box className={classes.nameAndMsgPart} display={'flex'}>
        <Box>
          {getStepTypeLocationIcon(stepType)}
        </Box>
        <Box paddingLeft={1}>
          <StepLocation name={to.name} />
        </Box>
      </Box>
    </Box>
  );
};

export default SingleStepItem;