import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { GoBack } from "bus-common-types";
import MrtStationsCombineHandlers from "components/mrt-fare/lib/Handlers/MrtStationsCombineHandlers";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { QueriedMrtStations, QUERY_MRT_STATIONS } from "../schema.gql";

export interface UseQueryMrtStationsOptions {
  goBack: GoBack
}

function useQueryMrtStations({
  goBack
}: UseQueryMrtStationsOptions) {
  const {
    locale,
  } = useIntl();

  const { error, loading, data } = useQuery<QueriedMrtStations>(QUERY_MRT_STATIONS, {
    variables: {
      lang: locale,
    }
  });

  // handle stations
  const handledStations = useMemo(() => (
    MrtStationsCombineHandlers.handleRawData(data, goBack)
  ), [data, goBack]);

  return ({
    error,
    loading,
    data,
    handledStations,
  });
};

export default useQueryMrtStations;