import React from 'react';
import { Box } from '@material-ui/core';
import { RouteMapLinkListProps } from './types';
import RouteMapLinkItem from './RouteMapLinkItem';

const RouteMapLinkList = ({
  routeMapLinkListData,
}: RouteMapLinkListProps) => {
  return (
    <Box>
      {routeMapLinkListData.map((r, i) => (
        <RouteMapLinkItem 
          key={i}
          {...r}
        />
      ))}
    </Box>
  );
};

export default RouteMapLinkList;