import L, { DivIconOptions } from 'leaflet';
import { Position } from "bus-common-types";

interface IconOptionsParams {
  imgSrc: string,
  iconSize: Position
  iconAnchor: Position
  className?: string
  title?: string
}

export const getIconOptions = (params: IconOptionsParams): DivIconOptions => {
  const className = params.className;
  
  return ({
    ...params,
    html: `
      <div class=bus-icon data-content=${params.title || ''}>
        <img 
          class=${className}
          src=${'./asset/images/icons/' + params.imgSrc}
        />
      </div>
    
    `,
    // iconUrl: './asset/images/icons/' + params.imgSrc,
  });
};

const getIcon = (params: IconOptionsParams) => {
  return L.divIcon(getIconOptions(params)) as any;
};

export default getIcon;