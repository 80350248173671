import React from 'react';
import { Box, Button, makeStyles, ButtonProps } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

export interface CloseButtonProps extends ButtonProps {
  
}

const CloseButton = (props: CloseButtonProps) => {
  const classes = useStyles();

  return (
    <Button 
      {...props}
      className={ classes.root }
    >
      { 'X' }
    </Button>
  );
};

export default CloseButton;