import { EstimateStationInput, ID } from 'all-common-types';
import {} from 'components/collinear-routes/constants/API';
import { SingleRouteFromStationName, SingleHandledRouteFromStationName } from 'components/collinear-routes/gql/fragments';
import { FromToStationRoutes, SingleRouteWithStationId } from 'components/collinear-routes/gql/functions/queryStationRoutesByStationName';
import { sortByAttr } from 'lib/fn';

export interface ExtendedStationIdEstimateStopInput extends EstimateStationInput {
  // stationId: ID
}

export interface RoutesByStationIdHandlerFinalResultParams extends FromToStationRoutes {
}

export interface SingleFinalResultData {
  stationEstimateInputs: EstimateStationInput[]
  routes: SingleHandledRouteFromStationName[]
}
export interface RoutesByStationNameHandlerFinalResult {
  from: SingleFinalResultData
  to: SingleFinalResultData
}
export interface UseQueryRoutesByStationNameOptions {
  fromStation: string
  toStation: string
}
export interface SingleRouteFromStation extends SingleRouteFromStationName {
  stationId: ID
}

class RoutesByStationNameHandler {
  static defaultRoutesByStationNameHandlerFinalResult: RoutesByStationNameHandlerFinalResult = {
    from: {
      stationEstimateInputs: [],
      routes: [],
    },
    to: {
      stationEstimateInputs: [],
      routes: [],
    },
  }

  static handleSingleRouteFromStationsByName(route: SingleRouteWithStationId): SingleHandledRouteFromStationName {
    const res: SingleHandledRouteFromStationName = {
      ...route,
      seq: route.node.seq,
      stationId: route.stationId,
      xno: Number(route.node.id),
      routeName: route.node.name,
    };
    return res;
  }
  static getEstimateStopInputFromHandledRoute(route: SingleHandledRouteFromStationName): ExtendedStationIdEstimateStopInput {
    return ({
      xno: route.xno,
      stationId: Number(route.stationId),
      goBack: route.goBack,
    });
  }

  static handleRoutes(routesWithStationId: SingleRouteWithStationId[]): SingleHandledRouteFromStationName[] {
    const res = routesWithStationId
      .map(RoutesByStationNameHandler.handleSingleRouteFromStationsByName)
      .sort(sortByAttr('seq'));
    return res;
  }

  static checkFromToStationsHaveSameRoute(
    fromStationRoute: Pick<SingleHandledRouteFromStationName, 'goBack' | 'xno'>, 
    toStationRoute: Pick<SingleHandledRouteFromStationName, 'goBack' | 'xno'>
  ) {
    if(fromStationRoute.goBack === toStationRoute.goBack && fromStationRoute.xno === toStationRoute.xno) {
      return true;
    }
    return false;
  }

  static getMatchedFromToRoutes(fromStationRoutes: SingleHandledRouteFromStationName[], toStationRoutes: SingleHandledRouteFromStationName[]) {
    let matchedRouteStaionsRes: {
      from: SingleHandledRouteFromStationName[],
      to: SingleHandledRouteFromStationName[]
    } = {
      from: [],
      to: [],
    };

    fromStationRoutes.forEach(fromRoute => {
      const matchedToRouteStation = toStationRoutes.find(toRoute => {
        return this.checkFromToStationsHaveSameRoute(fromRoute, toRoute) && fromRoute.orderNo < toRoute.orderNo;
      });
      if(matchedToRouteStation) {
        matchedRouteStaionsRes.from.push(fromRoute);
        matchedRouteStaionsRes.to.push(matchedToRouteStation);
      }
    });

    return matchedRouteStaionsRes;
  }

  static getFinalFromToStationRoutesForEstimateStopInput(params: RoutesByStationIdHandlerFinalResultParams): RoutesByStationNameHandlerFinalResult {
    const fromStationRoutes = RoutesByStationNameHandler.handleRoutes(params.fromStationRoutes);
    const toStationRoutes = RoutesByStationNameHandler.handleRoutes(params.toStationRoutes);

    const matchedRouteStaionsRes = this.getMatchedFromToRoutes(fromStationRoutes, toStationRoutes);

    const fromStationEstimateInputs = matchedRouteStaionsRes.from
      .map(RoutesByStationNameHandler.getEstimateStopInputFromHandledRoute);
    const toStationEstimateInputs = matchedRouteStaionsRes.to
      .map(RoutesByStationNameHandler.getEstimateStopInputFromHandledRoute); 

    const res: RoutesByStationNameHandlerFinalResult = {
      from: {
        stationEstimateInputs: fromStationEstimateInputs,
        routes: matchedRouteStaionsRes.from,
      },
      to: {
        stationEstimateInputs: toStationEstimateInputs,
        routes: matchedRouteStaionsRes.to,
      },
    };
    // console.log(res);

    return res;
  }
}

export default RoutesByStationNameHandler;