import { actionTypes } from './index';
import { DrivingMapState } from '../constants/context';

interface SetIbikeInfoListActionPayload {
  ibikeInfoList: DrivingMapState['ibikeInfoList']
}

interface SetIbikeInfoListAction {
  type: actionTypes.SET_IBIKE_INFO_LIST,
  payload: SetIbikeInfoListActionPayload
}

export const setIbikeInfoList = (ibikeInfoList: DrivingMapState['ibikeInfoList']): SetIbikeInfoListAction => ({
  type: actionTypes.SET_IBIKE_INFO_LIST,
  payload: { ibikeInfoList },
});

type IbikeInfoListActions = SetIbikeInfoListAction

export default IbikeInfoListActions;