import React from 'react';
import { Box } from '@material-ui/core';
import SingleBlogPage from './singleBlogPage';
import { SingleBlogPageContainerProps } from './types';
import useQueryCmsSingleBlog from 'lib/customHooks/useQueryCmsSingleBlog';
import LoadingPage from 'components/common-components/LoadingPage';
import { NotFoundPage } from 'components/common-components/Page';
import { convertTimeToString } from 'lib/fn';
import BlogNotFoundPage from './BlogNotFoundPage';

const SingleBlogPageContainer = (props: SingleBlogPageContainerProps) => {
  const {
    id 
  } = props;

  const {
    handleQuery,
    data,
    loading,
    error,
  } = useQueryCmsSingleBlog();

  React.useEffect(() => {
    handleQuery(id);
  }, [handleQuery, id]);

  if(loading) {
    return (
      <LoadingPage />
    );
  }
  else if(error) {
    return (
      <BlogNotFoundPage />
    );
  }
  else if(data) {
    const blog = {
      ...data.blog,
      createTime: convertTimeToString(data.blog.created_at)
    };

    return (
      <SingleBlogPage 
        {...blog}
      />
    );
  }
  return (
    null
  );
};

export default SingleBlogPageContainer;