import React from 'react';
import { GridLayer, withLeaflet } from "react-leaflet";
import * as L from "leaflet";
import { useIntl } from 'react-intl';
import "./leaflet-tgos";

const tgos = {
  appId: process.env.REACT_APP_TGOS_APPID,
  apiKey: process.env.REACT_APP_TGOS_APIKEY,
};

type Props = any;

const TGOSLayer = (L as any).TileLayer.TGOS;

class TOGSTileLayer extends GridLayer {
  createLeafletElement(props: Props): any {
    // console.log(L);
    return new TGOSLayer({
      mapType: 'tgos',
      appId: tgos.appId,
      apiKey: tgos.apiKey,
      lang: props.lang,
      attribution:
        'data &copy; <a target="_blank" href="https://www.tgos.tw/tgos/web/tgos_home.aspx">TGOS</a>',
    });
  }
}

function withIntl<P extends {}>(Component: React.ComponentType<P>) {
  return (props: P) => {
    const { locale, } = useIntl();
    return (
      <Component {...props} lang={locale} locale={locale} />
    );
  };
}

export default withLeaflet(withIntl(TOGSTileLayer));
