import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import Selector from 'components/common-components/SelectsComponents/Selector';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { StopsSelectorProps } from './types';

const StopsSelector = (props: StopsSelectorProps) => {
  return (
    <Box>
      <Selector
        {...props}
        id={'stopsSelector'}
        minWidth={'100%'}
        label={
          <IntlFormattedMessage langKey={'stop.selectStop.placeHolder'} />
        }
      />
    </Box>
  );
};

export default memo(StopsSelector);