import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { RouteMapLinkBusProviderTitleProps } from './types';

const useStyles = makeStyles(theme => ({
  root: {
     
  },
  icon: {
    paddingRight: theme.spacing(0.5),
  }
}));

const RouteMapLinkBusProviderTitle = ({
  providerName,
}: RouteMapLinkBusProviderTitleProps) => {
  const classes = useStyles();

  return (
    <Box display={'flex'}>
      <KeyboardArrowRight className={classes.icon} />
      <Typography>
        {providerName}
      </Typography>
    </Box>
  );
};

export default RouteMapLinkBusProviderTitle;