import React from 'react';
import PriceListWithTitle from './PriceListWithTitle';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { ECitizenPriceProps } from './ECitizenPrice';
export interface ETicketPriceProps extends ECitizenPriceProps {
}

const ETicketPrice = (props: ETicketPriceProps) => {
  return (
    <PriceListWithTitle
      {...props}
      langKey={'fare.searchResult.eTicket'}
    >
      {/* 某幾條路線不顯示10公里免費...這段訊息 */}
      {!props.shouldHideETicketInfo && (
        <IntlFormattedMessage
          langKey={'fare.searchResult.eTicketInfo'}
        />
      )}
    </PriceListWithTitle>
  );
};

export default ETicketPrice;