import {
  SingleEstimateStop,
  SingleNearBikeStation,
  SingleEstimateStopWithOtherTrans,
  HaveOtherTransportations,
  HaveIbikeUnion,
} from "bus-common-types";
import {
  SingleNearMrt,
  SingleNearTHSRStation,
  RouteStationIcon,
} from "components/driving-map/gql/gql-schema";
import { ID } from "all-common-types";
import { nodeDataFromEdges } from "constants/functions";

export interface OtherTransportationStations {
  nearBikeStations: SingleNearBikeStation[];
  nearMrtStations: SingleNearMrt[];
  nearTHSRStations: SingleNearTHSRStation[];
  lanternStations: RouteStationIcon[];
}

class HandleStopIsNearIbike {
  static getStationBikesUnion(
    singleBikeStation: SingleNearBikeStation | undefined
  ): HaveIbikeUnion {
    if (!singleBikeStation) {
      return {
        haveYoubike: false,
        haveIbike: false,
      };
    }
    const bikeStations = nodeDataFromEdges(singleBikeStation.bikeStations);
    const ibikes = bikeStations.filter((s) => s.type === 1);
    const youBikes = bikeStations.filter((s) => s.type === 2);

    return {
      haveIbike: ibikes.length > 0,
      haveYoubike: youBikes.length > 0,
    };
  }

  static isNearMrt(singleMrtStation: SingleNearMrt | undefined) {
    if (!singleMrtStation) return false;
    return singleMrtStation.metroStations.edges.length > 0;
  }

  static checkNearTHSR(singleTHSRStation: SingleNearTHSRStation | undefined) {
    if (!singleTHSRStation) return false;
    return singleTHSRStation.thsrStations.edges.length > 0;
  }

  static findMatchedStation(station: { id: ID }) {
    return (otherTransportation: { id: ID }) =>
      String(otherTransportation.id) === String(station.id);
  }

  static checkSingleEstimateStopIsNearOtherTransportation(
    estimateStop: SingleEstimateStop
  ) {
    return ({
      nearMrtStations,
      nearBikeStations,
      nearTHSRStations,
      lanternStations,
    }: OtherTransportationStations): HaveOtherTransportations => {
      const matchedStation = nearBikeStations.find(
        this.findMatchedStation(estimateStop)
      );
      const matchedMrtStation = nearMrtStations.find(
        this.findMatchedStation(estimateStop)
      );
      const matchedTHSRStation = nearTHSRStations.find(
        this.findMatchedStation(estimateStop)
      );
      const haveLantern = lanternStations.find((route) =>
        route.stations ? route.stations.includes(estimateStop.id) : false
      );

      const stationBikesUnion = this.getStationBikesUnion(matchedStation);
      const res: HaveOtherTransportations = {
        ...stationBikesUnion,
        haveMRT: this.isNearMrt(matchedMrtStation),
        haveTHSR: this.checkNearTHSR(matchedTHSRStation),
        haveLantern: haveLantern ? true : false,
      };
      return res;
    };
  }

  static checkStopIsNearMrt() {}
  //graphql
  static makeEstimateStopsWithHaveIbike(
    estimateStops: SingleEstimateStop[],
    otherTransportationStations: OtherTransportationStations
  ): SingleEstimateStopWithOtherTrans[] {
    const res: SingleEstimateStopWithOtherTrans[] = estimateStops.map(
      (estimateStop, i) => {
        const stationBikesUnion =
          HandleStopIsNearIbike.checkSingleEstimateStopIsNearOtherTransportation(
            estimateStop
          )(otherTransportationStations);

        return {
          ...estimateStop,
          ...stationBikesUnion,
        };
      }
    );

    return res;
  }
}

export default HandleStopIsNearIbike;
