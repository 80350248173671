const styleLayout = {
  appContentHeight: 'calc(100vh - 65px - 98px - 24px - 36px - 60px)',
  travelPlannerInfoHeight: 'calc(100vh - 65px - 98px - 24px - 0px - 60px)',
  mapContentHeight: 'calc(100vh - 65px - 98px - 24px + 16px - 60px)',
  homepageContentHeight: 'calc(100vh - 72px - 42px - 75px)',
  leafLetMapMinHeight: '600px',
  pageMinHeight: '300px',
  homePageLinksPartMaxWidth: '920px',
};

export default styleLayout;