import React, { memo } from 'react';
import ReportDescriptionPart from 'components/stop-report-page/components/StopReportInputPart/ReportDescriptionPart';
import { ReportDescriptionPartContainerProps } from './types';
import useReportDescriptionPart from './functions/useReportDescriptionPart';

const ReportDescriptionPartContainer = (props: ReportDescriptionPartContainerProps) => {
  const state = useReportDescriptionPart(props);

  return (
    <ReportDescriptionPart
      {...state.reportDescriptionInputState}
      {...props}
      textAreaValue={state.reportDescriptionInputState.value}
      onChange={state.reportDescriptionInputState.handleChangeInput}
      selectorValue={state.reportDescriptionSelectorState.value}
      changeFn={state.reportDescriptionSelectorState.onChange}
    />
  );
};

export default memo(ReportDescriptionPartContainer);