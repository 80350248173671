import { actionTypes as Types } from '../actions';
import { State, IbusCommonData } from 'constants/context';
import { AppActionTypes } from 'actions';

export const ibusCommonData = (
  state: State, 
  action: AppActionTypes
): IbusCommonData => {
  switch (action.type) {
    case Types.GET_IBUS_COMMON_DATA: {
      const { payload } = action;
      const {
        timeTableRoutes,
      } = payload;

      let headwayRouteIds: number[] = [];
      let mixedRouteIds: number[] = [];

      for (let i = 0; i < timeTableRoutes.length; i++) {
        const route = timeTableRoutes[i];
        const { routeId, timeTableType } = route;

        if(timeTableType === 'headway') {
          headwayRouteIds.push(routeId);
        } else {
          mixedRouteIds.push(routeId);
        }
      }
      
      return ({
        ...payload,
        handledTimeTableRoutes: {
          headway: headwayRouteIds,
          mixed: mixedRouteIds,
        },
      });
    }
    default:
      return state.ibusCommonData;
  }
};

export default ({
  ibusCommonData
});