import { RadioTabItemProps, RadioTabListProps } from "components/common-components/RadioTab/types";
import { StationContentMode } from "mrt-pages";

const selectPartListData: (RadioTabItemProps & { value: StationContentMode })[] = ([
  {
    value: 'FARE_ETICKET_FULL',
    labelLangKey: 'mrtFare.selectPartOption.eFull',
  },
  {
    value: 'FARE_FULL',
    labelLangKey: 'mrtFare.selectPartOption.full',
  },
  {
    value: 'FARE_REDUCED',
    labelLangKey: 'mrtFare.selectPartOption.reduced',
  },
  {
    value: 'TRAVEL_TIME',
    labelLangKey: 'mrtFare.selectPartOption.travelTime',
  },
]);

export default selectPartListData;