import { FromToKeys } from "bus-common-types";
import { Callback, SingleSelectOption } from "all-common-types";
import { SelectFromToContainerProps } from "../../types";
import sliceStopsByStop, { SliceStopsByStopOptions } from "./sliceStopsByStop";
import { FareState } from "components/fare/constant/context";
import { handleFromToStopsFromToSelectorOptions } from "../fn";

export const initFromTo: {
  [x in FromToKeys]: {
    id: string
    defaultOption: string
  }
} = {
  from: {
    id: '起點',
    defaultOption: '起點',
  },
  to: {
    id: '迄點',
    defaultOption: '迄點',
  }
};

export type FromToValues = {
  [x in FromToKeys]: string
}
export interface GetFromToSelectOptionsFnParams {
  isCycledRoute: boolean
  handleSelect: Callback
  fromToStops: FareState['fromToStops']
  fromToValues: FromToValues
}

const getFromToSelectOptionsFn = (params: GetFromToSelectOptionsFnParams) => (fromTo: FromToKeys, isToggledChangeOptions: boolean) => {
  const {
    isCycledRoute,
    fromToStops,
    fromToValues,
    handleSelect,
  } = params;

  const handledFromToStops = handleFromToStopsFromToSelectorOptions(fromToStops, isToggledChangeOptions);
  const options = (fromTo === 'to') ? 
    sliceStopsByStop({
      isCycledRoute,
      fromStopValue: fromToValues['from'],
      stops: handledFromToStops,
    }) : 
    handledFromToStops;

  return ({
    ...initFromTo[fromTo],
    label: initFromTo[fromTo].id,
    changeFn: handleSelect(fromTo),
    options,
  });
};

export default getFromToSelectOptionsFn;