import React from 'react';
import { Box } from '@material-ui/core';
import OtherTransportationIcon from '../OtherTransportationIcon';

class HandleStopIsNearTMRT {
  static getTMRTIcon(isNearTMRT: boolean) {
    if(isNearTMRT) {
      return (
        <OtherTransportationIcon imageKey={'icons_mrt'} isShowIcon={true} title={'mrt'} />
      );
    }
    return null;
  }
}

export default HandleStopIsNearTMRT;