import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import TabItem from './TabItem';
import { DirectionsBus } from '@material-ui/icons';
import { OldStyledTabsProps, SingleTabData } from './types';
import IntlFormattedMessage from '../IntlFormattedMessage';
import { useIntl } from 'react-intl';

const TAB_MIN_HEIGHT = 36;

const drivingMapTabData: SingleTabData[] = [
  {
    icon: <DirectionsBus />,
    label: (
      <IntlFormattedMessage 
        langKey={'drivingMap.busTypeTab.cityBus'}
      />
    )
  },
  {
    icon: <DirectionsBus />,
    label: (
      <IntlFormattedMessage 
        langKey={'drivingMap.busTypeTab.roadBus'}
      />
    )
  }
];

export const useEnTabWrap = (tabDatasLength: number) => {
  const {
    locale
  } = useIntl();
  
  const flexWrap = locale === 'en' ? 
    'wrap' : 'noWrap';
  const minHeight = locale === 'en' ? 
    TAB_MIN_HEIGHT * tabDatasLength : TAB_MIN_HEIGHT;

  return ({
    flexWrap,
    minHeight
  });
};

const useStyles = makeStyles(theme => ({
  root: {
    // minHeight: theme.spacing(4.5),
  }
}));

const OldStyledTabs = (props: OldStyledTabsProps) => {
  const {
    selectedTab,
    selectTabFn,
    tabDatas=drivingMapTabData,
  } = props;
  const classes = useStyles();

  return (
    <Box
      display={'flex'}
      className={classes.root}
      {...props}
    >
      {tabDatas.map((data, i) => {
        const {
          isDisplay=true,
        } = data;
        
        if(isDisplay) {
          return (
            <TabItem 
              key={i} 
              isSelected={i === selectedTab}
              icon={data.icon} 
              label={data.label}
              onTabChange={() => {
                selectTabFn && selectTabFn({}, i);
              }}
            />
          );
        }
      })}
    </Box>
  );
};

export default OldStyledTabs;