import React, { memo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { UploadFormProps } from './types';
import TitleWrapper from '../StopReportInputPart/TitleWrapper';
import IntlButton from 'components/common-components/IntlComponents/IntlButtons';
import { CloudUploadOutlined } from '@material-ui/icons';
import UploadLabelWrapper from './UploadLabelWrapper';
import DragDropFileBox from './DragDropFileBox';
import { LangKeys } from 'constants/lang';

const useStyles = makeStyles(theme => ({
  root: {
     
  },
  uploadInput: {
    display: 'none',
    height: 1,
  },
  confirmButtonPart: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

const UploadForm = (props: UploadFormProps) => {
  const {
    isUploading=false,
    isUploadAvailble=true,
    children,
    onSubmit,
    onChangeFiles,
  } = props;
  const classes = useStyles();
  const isButtonUnavailable = !isUploadAvailble || isUploading;
  const buttonLangKey: LangKeys = 
    isUploading ? 'uploading' : 'stopReport.confirmReport';

  return (
    <Box>
      <form onSubmit={onSubmit}>
        <TitleWrapper isError={false} titleLangKey={'stopReport.title5'}>
          <Box>
            <UploadLabelWrapper>
              <input type="file" multiple name="files" onChange={onChangeFiles} />
            </UploadLabelWrapper>
            <DragDropFileBox {...props} />
          </Box>
        </TitleWrapper>
        {children}
        <Box
          className={classes.confirmButtonPart}
        >
          <IntlButton 
            disabled={isButtonUnavailable}
            color={'primary'}
            variant={'contained'}
            langKey={buttonLangKey}
            onClick={onSubmit}
          />
        </Box>
      </form>
    </Box>
  );
};

export default memo(UploadForm);