import { GoBack } from "bus-common-types";

const getInitGoBackFromGoBackDisplay = (isGoDisplay: boolean, isBackDisplay: boolean): GoBack => {
  const isOnlyOneWayDisplay = (isGoDisplay && !isBackDisplay) || (!isGoDisplay && isBackDisplay);

  const initGoBack = isOnlyOneWayDisplay ? (
    isGoDisplay ? 1 : 2
  ) : 1;
  return initGoBack;
};

export default getInitGoBackFromGoBackDisplay;