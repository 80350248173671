import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import RoutesFilterSelector from 'components/route-map/containers/SelectsPart/RoutesFilterSelector';
import { StopReportInputPartProps } from './types';
import StopsSelectorContainer from 'components/stop-report-page/containers/StopReportInputPart/StopsSelectorContainer';
import GoBackButtonsContainer from 'components/stop-report-page/containers/StopReportInputPart/GoBackButtonsContainer';
import ReportDescriptionPartContainer from 'components/stop-report-page/containers/StopReportInputPart/ReportDescriptionPartContainer';
import TitleWrapper from './TitleWrapper';

const StopReportInputPart = (props: StopReportInputPartProps) => {
  return (
    <Box>
      <Box>
        <TitleWrapper 
          isError={props.optionsErr.routeId}
          titleLangKey={'stopReport.title1'}
        >
          <RoutesFilterSelector
            {...props.routesFilterSelectorProps}
          />
        </TitleWrapper>
        <TitleWrapper 
          isError={props.optionsErr.goBack}
          titleLangKey={'stopReport.title2'}
        >
          <GoBackButtonsContainer
            {...props.goBackButtonsContainerProps}
          />
        </TitleWrapper>
        <StopsSelectorContainer 
          {...props.stopsSelectorProps} 
          isError={props.optionsErr.stopId}
        />
      </Box>
      <ReportDescriptionPartContainer
        {...props.reportDescriptionPartContainerProps}
        isError={props.optionsErr.description}
      />
    </Box>
  );
};

export default memo(StopReportInputPart);