import React from 'react';
import { Box, Button, makeStyles, TextField } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { Clear } from '@material-ui/icons';
import { SearchInputProps } from './types';
import { FormattedMessage } from 'react-intl';
import { langKeys } from 'constants/lang';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    minWidth: 300,
  },
  clearButton: {
    position: 'absolute',
    right: 0,
  }
}));

const SearchInput = ({
  value, onChange, onClearInput,
}: SearchInputProps) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      display={'flex'}
      alignItems={'center'}
    >
      <FormattedMessage
        id={langKeys["routeMap.searchRoutes"]}
        defaultMessage={langKeys["routeMap.searchRoutes"]}
      >
        {t => (
          <TextField
            fullWidth={true}
            variant={'outlined'}
            label={t}
            value={value}
            onChange={onChange}
          />
        )}
      </FormattedMessage>
      <Button
        className={classes.clearButton}
        onClick={onClearInput}
      >
        <Clear />
      </Button>
    </Box>
  );
};

export default SearchInput;