import { actionTypes as Types } from './index';
import { RouteDataType } from 'bus-common-types';
import { RouteDataWithBusInstantInfoList } from '../constants/context';

export type SetRouteDataAction = {
  type: Types.SET_ROUTE_DATA,
  routeData: RouteDataWithBusInstantInfoList,
}
export type ResetRouteDataAction = {
  type: Types.RESET_ROUTE_DATA,
}

export const setRouteData = (routeData: RouteDataWithBusInstantInfoList): SetRouteDataAction => {
  return ({
    type: Types.SET_ROUTE_DATA,
    routeData,
  });
};

export const resetRouteData = (): ResetRouteDataAction => ({
  type: Types.RESET_ROUTE_DATA,
});

export type RouteListsActionTypes = SetRouteDataAction | ResetRouteDataAction