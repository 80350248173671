/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { RouteInfoListWithCtx } from 'components/collinear-routes/components/RouteInfoPart/RouteInfoList';
import ContextStore, { IntervalSearchSelectedFromTo, CollinearRoutesState } from 'components/collinear-routes/constants/context';
import LoadingAndError from 'components/common-components/LoadingAndError';
import useQueryRouteInfos from '../functions/useQueryRouteInfos';
import { MapStateToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import { connectCtx } from 'react-function-helpers';

export type IntervalRouteInfoListContainerProps = {
  intervalSearchSelectedFromTo: IntervalSearchSelectedFromTo
}
export const IntervalRouteInfoListContainer = (props: IntervalRouteInfoListContainerProps) => {
  const {
    data,
    loading,
    error,
    routeInfos
  } = useQueryRouteInfos(props);

  if(loading || error) {
    return (
      <LoadingAndError loading={loading} error={error} />
    );
  }

  if(!data) {
    return null;
  }

  return (
    <RouteInfoListWithCtx routeInfos={routeInfos} />
  );
};

interface OwnProps {}

export const mapStateToProps: MapStateToProps<CollinearRoutesState, OwnProps, IntervalRouteInfoListContainerProps> = (state) => {
  return ({
    intervalSearchSelectedFromTo: state.intervalSearchSelectedFromTo
  });
};

export const IntervalRouteInfoListContainerWithCtx = connectCtx(ContextStore)(mapStateToProps)(IntervalRouteInfoListContainer);

export default IntervalRouteInfoListContainer;