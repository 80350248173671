import { useQuery } from '@apollo/react-hooks';
import { cmsClient } from 'constants/API';
import { QueriedVisibleJointOperators, QUERY_VISIBLE_JOINT_OPERATORS, JointOperator } from 'components/driving-map/gql/gql-schema';

const useQueryVisibleJointOperator = () => {
  let jointOperators: JointOperator[] = [];

  const { loading, error, data } = useQuery<QueriedVisibleJointOperators>(QUERY_VISIBLE_JOINT_OPERATORS, {
    client: cmsClient,
  });

  if (data && data.jointOperationsBuses.length > 0){
    jointOperators = data.jointOperationsBuses;
  }

  return ({
    loading,
    error,
    data: jointOperators,
  });
};

export default useQueryVisibleJointOperator;