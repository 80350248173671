import React from "react";
import { Typography, Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { getSimpleBusTypeName } from "../BusMap/functions/getBusTypeNameByBusType";
import { useIntl } from "react-intl";
import { Locale } from "all-common-types";
import { TIMETABLE_SCHEDULE_DISPLAY_CAR_TYPE } from "components/driving-map/config";
import BusIconContainer from "components/common-components/Other/BusIconContainer";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    // display: "inline-flex",
    display: 'flex',
    margin: 'auto',
    width: '50%',
    // padding: theme.spacing(0.6),
  },
  carType: {
    display: 'inline-block',
    // minWidth: 50,
    // minHeight: 27,
    verticalAlign: 'middle',
    // paddingLeft: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(0.3),
    borderRadius: 1000,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
}));

type ScheduleItemProps = {
  time: string;
  carType: string;
  providerId: number
};
const ScheduleItem = ({ 
  time, 
  carType, 
  providerId,
}: ScheduleItemProps) => {
  const { locale } = useIntl();
  const classes = useStyles();
  const busShortName = getSimpleBusTypeName(carType, locale as Locale);

  const blankComponent = (
    <Box 
      style={{ 
        width: locale === 'zh' ? 52 : 28,
        display: 'inline-block', 
      }} 
    />
  );

  return (
    <Box className={classes.root} alignItems={'center'}>
      <BusIconContainer 
        busIconWidth={32}
        providerId={providerId}
        carType={carType}
      />
      <Typography variant={"button"}>{time}</Typography>
    </Box>
  );
};

export default ScheduleItem;
