import { createMuiTheme } from '@material-ui/core/styles';
import { Complementary, EstimateTime, TravelPlan, GoBack, BackGround, HomepageImages, HomepageColors } from './theme-types';
import ibusImages from 'static/ibusImages.json';
import zIndex from 'styles/styleObjs/zIndex';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    name?: string
    homepageColors: HomepageColors
    homepageImages: HomepageImages
    background: BackGround
    complementary: Complementary
    estimateTime: EstimateTime
    travelPlan: TravelPlan
    goBack: GoBack
  }
  interface ThemeOptions {
    name?: string
    homepageColors?: HomepageColors
    homepageImages?: HomepageImages
    background?: BackGround
    complementary?: Complementary
    estimateTime?: EstimateTime
    travelPlan?: TravelPlan
    goBack?: GoBack
  }
}

const fontFamily = [
  // 'sans-serif',
  // '黑體-繁',
  '微軟正黑體',
  '"Noto Sans TC"', 
  'Roboto',
].join(',');

export const theme_default = createMuiTheme({
  homepageImages: {
    topLeft: ibusImages.homepage_top_left.src,
    topRight: ibusImages.homepage_top_right.src,
    bottomLeft: ibusImages.homepage_below_left.src,
  },
  typography: {
    fontFamily,
    fontSize: 15,
  },
  complementary: {
    light: '#fff',
    main: '#83c7c7',
    dark: '#018a8a'
  },
  estimateTime: {
    timeStatus: {
      default: '#bbbbbb',
      almostArrival: '#c83264',
      minutesRemained: '#e68c3c',
    }
  },
  travelPlan: {
    planStatus: {
      fatest: '#ff8392',
      walkless: '#9785ff',
      direct: '#ff9933',
    }
  },
  goBack: {
    goBackStatus: {
      go: '#3a75a6',
      back: '#a63a60'
    }
  },
  zIndex: {
    modal: zIndex.popup
  }
});

const theme_Xmas = createMuiTheme({
  ...theme_default,
  palette: {
    primary: {
      main: '#139632',
    },
    secondary: {
      main: '#ffff33'
    },
  },

  complementary: {
    light: '#e0384c',
    main: '#e0384c',
    dark: '#b51f2b'
  },
});

export default theme_Xmas;