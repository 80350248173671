import { ID, Locale } from "all-common-types";
import { HandledRouteData } from "driving-map";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import drivingMapQueryFns from "../functions/queryFns";
import RouteDataHandlers from "../functions/RouteDataHandlers";
import { QueriedStaticRouteData } from "../gql-schema";

export interface UseQueryRouteDataOptions {
  routeId: ID
}

const useQueryRouteData = ({
  routeId,
}: UseQueryRouteDataOptions) => {
  const [loading, setLoading] = useState(false);
  const [queriedStaticRouteData, setStaticRouteData] = useState<QueriedStaticRouteData>();
  const [handledRouteData, setHandledRouteData] = useState<HandledRouteData>();
  const {
    locale,
  } = useIntl();
  const params = [routeId, locale] as [ID, Locale];

  const handleQueryDynamicRouteData = useCallback(() => {
    (async function() {
      setLoading(true);
      const queried = await drivingMapQueryFns.queryDynamicData(...params);
      if(queried && queriedStaticRouteData) {
        // recalculate
        const handled = RouteDataHandlers.handleRawData(
          queriedStaticRouteData, queried.data,
        );
        setHandledRouteData(handled);
      }
      setLoading(false);
    }());
  }, [params, queriedStaticRouteData]);

  const handleQueryRouteData = useCallback((params: [ID, Locale]) => {
    (async function() {
      setLoading(true);
      setHandledRouteData(undefined);
      Promise.all([
        drivingMapQueryFns.queryStaticRouteData(...params),
        drivingMapQueryFns.queryDynamicData(...params),
        drivingMapQueryFns.queryVisibleJointOperators(),
      ]).then(vals => {
        const [
          queriedStatic, queriedDynamic, queriedVisibleJointOperators
        ] = vals;

        // handle raw data
        if(queriedStatic && queriedDynamic) {
          let mergeStaticData = { ...queriedStatic.data };
          if (queriedVisibleJointOperators){
            const visibleProviderIds = queriedVisibleJointOperators.data.jointOperationsBuses
              .filter(operator => operator.routeId === Number(params[0]))
              .map( node => node.providerId );

            if (visibleProviderIds.length > 0) {
              mergeStaticData = {
                ...queriedStatic.data,
                route: {
                  ...queriedStatic.data.route,
                  providers: {
                    ...queriedStatic.data.route.providers,
                    edges: queriedStatic.data.route.providers.edges.filter( edge => {
                      const providerId = Number(edge.node.id);
                      if (providerId === 3 || providerId === 15){
                        return visibleProviderIds.includes(Number(edge.node.id));
                      }
                      return true;
                    })
                  }
                }
              };
            }
          }
          const handled = RouteDataHandlers.handleRawData(
            mergeStaticData, queriedDynamic.data,
          );
          setHandledRouteData(handled);
          setStaticRouteData(mergeStaticData);
        }
      }).finally(() => {
        setLoading(false);
      });
    }());
  }, []);

  useEffect(() => {
    handleQueryRouteData(params);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params)]);

  return ({
    loading,
    handledRouteData,
    handleQueryDynamicRouteData,
  });
};

export default useQueryRouteData;