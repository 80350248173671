import { ID } from "all-common-types";
import { GoBack, Position } from "bus-common-types";
import { goOrBackObj } from "config";

export const getDigitOfNumber = (number: number) => {
  return String(number).length;
};

export const getNumberIconWidth = (basicWidth: number) => (number: number | string) => {
  const num = Number(number);
  const digit = getDigitOfNumber(num);
  if(num < 100) {
    return basicWidth * 2;
  } else {
    return basicWidth * digit; 
  }
};

export type DivIconSpec = {
  basicWidth: number
  height: number
  downTriangleHeight: number
}
export const getDivIconOption = ({
  basicWidth,
  height,
  downTriangleHeight,
}: DivIconSpec) => (number: ID, goBack: GoBack) => {
  const goBackStr = goOrBackObj[goBack];
  const numberIconWidth = getNumberIconWidth(basicWidth)(number);
  return ({
    iconSize: [numberIconWidth, height] as Position,
    iconAnchor: [numberIconWidth/2, height] as Position,
    popupAnchor: [0, height * -1] as Position,
    className: 'number-icon',
    html: `<span class=${goBackStr}>${ number }</span>`,
  });
};
;