import { GoBack, RouteDataType } from "bus-common-types";
import useQueryTravelTimes from "components/collinear-routes/gql/custom-hooks/useQueryTravelTimes";
import { refreshTime } from "config";
import useQueryBuses from "gql/custom-hooks/useQueryBuses";
import { useToggle } from "lib/customHooks";
import { useEffect, useMemo, useState } from "react";
import useQueryRouteData from "../../functions/useQueryRouteData";
import { MapPartContainerProps } from "../../types";
import IntervalSearchMapHandlers, { IntervalSearchMapHandlersRawRouteDataParams } from "./IntervalSearchMapHandlers";

export interface UseMapPartOptions extends Pick<MapPartContainerProps, 'routeId'> {
  goBack: GoBack
}

const useMapPart = (options: UseMapPartOptions) => {
  const {
    goBack,
    routeId,
  } = options;

  const { 
    toggle: toggledDisplay, 
    handleToggle: handleDisplayTimeTable,
  } = useToggle();

  const {
    handleQuery,
    error,
    routeData: routeDataWithoutBusInfos,
  } = useQueryRouteData();
  const {
    handleQuery: handleQueryTravelTimes,
    travelTimes,
  } = useQueryTravelTimes();
  const {
    busInfos: buses,
  } = useQueryBuses({
    routeId,
    isQueryIntervally: true,
    intervalSecs: refreshTime,
  });

  useEffect(() => {
    handleQuery(routeId);
  }, [handleQuery, routeId]);

  useEffect(() => {
    if(routeId && goBack) {
      handleQueryTravelTimes({
        inputs: [{
          goBack,
          xno: Number(routeId)
        }]
      });
    }
  }, [handleQueryTravelTimes, routeId, goBack]);

  const routeData: RouteDataType | null = useMemo(() => (
    routeDataWithoutBusInfos ? ({
      ...routeDataWithoutBusInfos,
      buses,
    }) : null
  ), [buses, routeDataWithoutBusInfos]);

  const markersAndRoutePoints = useMemo(() => (
    IntervalSearchMapHandlers.handleRawRouteData({
      queried: {
        buses,
        routeData,
        travelTimes,
      }, goBack,
    })
  ), [buses, goBack, routeData, travelTimes]);

  const quried: IntervalSearchMapHandlersRawRouteDataParams['queried'] = {
    routeData,
    travelTimes,
    buses,
  };

  return ({
    toggledDisplay,
    quried,
    error,
    markersAndRoutePoints,
    handleDisplayTimeTable,
  });
};

export default useMapPart;