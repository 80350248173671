import ContextStore from "components/fare/constant/context";
import FarePricesHandler from "components/fare/functions/FarePricesHandler";
import useQueryFares, { UseQueryFaresOptions } from "components/fare/gql/custom-hooks/useQueryFares";
import { useContext, useMemo } from "react";
import FareResultHandlers from "./FareResultHandlers";

export interface UseFareResultOptions extends Omit<UseQueryFaresOptions, 'shouldIgnoreGoBackFilter'> {
}

const useFareResult = (options: UseFareResultOptions) => {
  const {
    routeId,
    goBack,
    fromToStationIds,
  } = options;
  const {
    state: {
      fromToStops,
    }
  } = useContext(ContextStore);

  const shouldIgnoreGoBackFilter = useMemo(() => {
    const isFirstStop = FareResultHandlers.checkIsCycledRouteFirstStop(fromToStationIds.fromStationId, fromToStops.from);
    return (
      FareResultHandlers.checkIgnoreGoBackFilter(isFirstStop, goBack)
    );
  }, [fromToStationIds.fromStationId, fromToStops.from, goBack]);
  const queriedFare = useQueryFares({
    ...options,
    shouldIgnoreGoBackFilter,
  });

  const shouldHideETicketInfo = FarePricesHandler.checkETicketInfoShouldHiden(routeId);

  return ({
    ...queriedFare,
    shouldHideETicketInfo,
  });
};

export default useFareResult;