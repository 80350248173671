import React from 'react';
import { Box, Button, makeStyles, Theme } from '@material-ui/core';
import { TabItemProps } from './types';

const useStyles = makeStyles<Theme, TabItemProps>(theme => ({
  root: {
    width: '100%',
    // maxWidth: 180,
    borderRadius: `4px 4px 0px 0px`,
    // background: props => props.isSelected ? theme.complementary.main : '#ddd',
    background: props => props.isSelected ? theme.palette.primary.main : '#ddd',
    color: props => props.isSelected ? '#fff' : '#111',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#111',
    }
  }
}));

// origin
// const useStyles = makeStyles<Theme, TabItemProps>(theme => ({
//   root: {
//     width: '100%',
//     // maxWidth: 180,
//     borderRadius: `4px 4px 0px 0px`,
//     color: '#111',
//     background: props => props.isSelected ? theme.complementary.main : '#ddd',
//     '&:hover': {
//       backgroundColor: '#bbb',
//     }
//   }
// }));

const TabItem = (props: TabItemProps) => {
  const classes = useStyles(props);

  return (
    <Button
      className={classes.root}
      onClick={props.onTabChange}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
      >
        {props.icon}
        {props.label}
      </Box>
    </Button>
  );
};

export default TabItem;