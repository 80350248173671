import React from 'react';
import { Box, Divider, makeStyles } from '@material-ui/core';
import selectPartListData from 'components/mrt-fare/static/selectPartListData';
import RadioTabList from 'components/common-components/RadioTab/RadioTabList';
import { SelectPartProps } from './types';
import MrtDescription from '../common/MrtDescription';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    
  }
}));

const SelectPart = (props: SelectPartProps) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <RadioTabList 
          {...props}
          labelLangKeyListData={selectPartListData}
        />
        <MrtDescription />
      </Box>
      <Divider />
    </>
  );
};

export default SelectPart;