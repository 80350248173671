import { useLazyQuery } from "@apollo/react-hooks";
import { ID } from "all-common-types";
import { SingleSchedule } from "bus-common-types";
import { client} from "constants/API";
import NEW_SCHEMA_API_SCHEMAS, { NewSchemaApiTypes, ScheduleNode } from "constants/NewSchemaAPI/commonAPI";
import { useCallback, useMemo } from "react";

function useQuerySchedule() {

  const [queryFn, { error, loading, data }] = useLazyQuery<NewSchemaApiTypes['QueriedSchedule']>(NEW_SCHEMA_API_SCHEMAS.QUERY_SCHEDULE, {
    client: client,
  });

  const handleQuery = useCallback(({
    routeId
  }: {
    routeId: ID
  }) => {
    queryFn({
      variables: {
        routeId: Number(routeId),
      }
    });
  }, [queryFn]);

  const schedules: SingleSchedule[] = useMemo(() => {
    if (data) {
      let uniqueSchedule: Map<string, ScheduleNode> = data.schedule.edges.reduce((obj, edge) => {
        const key = `${edge.node.goBack}-${edge.node.scheduleTime}`;
        if (!obj.has(key)){
          obj.set(key, edge.node);
        }
        return obj;
      }, new Map());

      return Array.from(uniqueSchedule.values()).map(d => ({
        ...d,
        xno: 0,
        carId: "",
        carType: "",
        rawCarType: "normal",
      }));
    }
    return [];
  }, [data]);

  return ({
    error,
    loading,
    data,
    schedules,
    handleQuery,
  });
}
export default useQuerySchedule;