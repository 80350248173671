import React, { useCallback } from 'react';
import { List, makeStyles, Box } from '@material-ui/core';
import { SingleRouteInfo } from 'collinear-routes';
import ContextStore, { CollinearRoutesState } from 'components/collinear-routes/constants/context';
import { setRouteId } from 'components/collinear-routes/actions/search-actions';
import RouteInfoItemHeader from './RouteInfoItemHeader';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import { MapStateToProps, MapDispatchToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import { connectCtx } from 'react-function-helpers';
import RouteInfoDescription from './RouteInfoDescription';
import { setGoBack } from 'components/collinear-routes/actions/stop-search-states-actions';
import { RouteInfoListDispatchFromCtx } from './types';
import RouteInfoItem from './RouteInfoItem';
import RouteInfoNoResult from './RouteInfoNoResult';
import { RouteInfoListProps } from './types';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
    flexGrow: 1,
    height: 1,
  }
}));


export const RouteInfoList = (props: RouteInfoListProps) => {
  const {
    selectedRouteId, routeInfos, selectRoute
  } = props;
  const classes = useStyles();

  const handleSelectRoute = useCallback((routeId: string, goBack) => {
    return () => selectRoute && selectRoute(routeId, goBack);
  }, [selectRoute]);

  if(routeInfos.length === 0) {
    return (
      <RouteInfoNoResult />
    );
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
    >
      <RouteInfoDescription />
      <RouteInfoItemHeader />
      <List className={classes.root}>
        {routeInfos.map((routeInfo, i) => (
          <RouteInfoItem
            key={i}
            {...routeInfo}
            isSelected={selectedRouteId === routeInfo.routeId}
            onClick={handleSelectRoute(routeInfo.routeId, routeInfo.goBack)} />
        ))}
      </List>
    </Box>
  );
};

export interface RouteInfoListWithCtxProps {
  routeInfos: SingleRouteInfo[]
}
interface OwnProps extends RouteInfoListWithCtxProps {}


const mapStateToProps: MapStateToProps<CollinearRoutesState, OwnProps, {
  selectedRouteId: CollinearRoutesState['selectedRouteId']
}> = (state) => {
  return ({
    selectedRouteId: state.selectedRouteId,
  });
};
const mapDispatchToProps: MapDispatchToProps<OwnProps, RouteInfoListDispatchFromCtx> = (dispatch) => {
  return ({
    selectRoute: (routeId, goBack) => {
      const setGoBackAction = setGoBack(goBack);
      const action = setRouteId(routeId);
      dispatch(setGoBackAction);
      dispatch(action);
    }
  });
};

export const RouteInfoListWithCtx = connectCtx(ContextStore)(mapStateToProps, mapDispatchToProps)(RouteInfoList);

export default RouteInfoList;