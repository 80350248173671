import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { DISTANCE_BETWEEN_STATIONS, PADDING_BETWEEN_STATIONS } from 'components/mrt-fare/config';
import SideStationList from './SideStationList';
import { Route310OtherStationListProps } from './types';
import MrtLine from '../../common/MrtLine';

const Route310OtherStationList = (props: Route310OtherStationListProps) => {
  const {
    route310OtherStations,
  } = props;
  const stationsAmount = route310OtherStations.length;
  const mrtLineWidth = (DISTANCE_BETWEEN_STATIONS * stationsAmount) - 10;

  if(stationsAmount === 0) {
    return null;
  }
  
  return (
    <Box position={'relative'}>
      <MrtLine
        color={'BUS'}
        top={48}
        left={10}
        width={mrtLineWidth}
        direction={'horizontal'}
      />
      <Typography variant={'h6'} align={'right'}>{'310'}</Typography>
      <SideStationList
        {...props}
        stationListData={props.route310OtherStations} 
        padding={PADDING_BETWEEN_STATIONS}
        stationNameDirection={'bottom'}
        partPositionStyle={{
          display: 'flex',
          // position: 'relative',
          // top: 60,
        }}
      />
    </Box>
  );
};

export default Route310OtherStationList;