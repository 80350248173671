import React from 'react';
import { Box } from '@material-ui/core';
import RouteMapLinkHeader from './RouteMapLinkHeader';
import RouteMapLinkList from './RouteMapLinkList';
import { RouteMapListPartProps } from './types';

const RouteMapListPart = (props: RouteMapListPartProps) => {
  return (
    <Box>
      <RouteMapLinkHeader />
      <RouteMapLinkList {...props} />
    </Box>
  );
};

export default RouteMapListPart;