import { useLazyQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { QUERY_CMS_NOTICES, QueryCmsNotices } from 'constants/cmsAPI/cmsAPI';
import { Notices } from 'all-common-types';
import { useCallback } from 'react';
import handleDataWithShelfTime from 'lib/handleDataWithShelfTime';

const useQueryCmsNotices = () => {
  let notices: Notices = [];

  const { locale } = useIntl();
  const [queryFn, { loading, error, data }] = useLazyQuery<QueryCmsNotices>(QUERY_CMS_NOTICES);

  const handleQuery = useCallback(() => {
    queryFn({
      variables: {
        locale,
      }
    });
  }, [locale, queryFn]);

  if(data) {
    const filteredByShelfTime = handleDataWithShelfTime.filter(data.notices);
    notices = filteredByShelfTime;
  }
  
  return ({
    loading,
    error,
    data,
    notices,
    handleQuery,
  });
};

export default useQueryCmsNotices;