import React, { useCallback } from 'react';
import { ImagePreviewFile } from 'all-common-types';
import readFileList from './readFileList';
import StopReportHandlers from '../Handlers/StopReportHandlers';
import { useIntl } from 'react-intl';
import useUpload, { UseUploadOptions } from './useUpload';
import usePreviewImages from './usePreviewImages';
import { MakeCreateStopReportDataOptions } from 'stop-report';

export interface UseUploadAndPreviewFilesOptions extends Omit<UseUploadOptions, 'request' | 'overMaxUploadFileMessage'> {
  maxUploadFileAmount?: number
  stopReportDataOptions: MakeCreateStopReportDataOptions
}

const UPLOAD_FORM_NAME = 'pictures';

const useUploadAndPreviewFiles = (options: UseUploadAndPreviewFilesOptions) => {
  const {
    maxUploadFileAmount,
    stopReportDataOptions,
  } = options;

  const { formatMessage, } = useIntl();
  const {
    previewFileList,
    setPreviewFiles,
    handleAddPreviewFiles,
  } = usePreviewImages();

  const readFileListFn = readFileList(handleAddPreviewFiles);
  const overMaxUploadFileMessage = formatMessage({
    id: 'stopReport.overUploadLimit',
  }, { maxUploadFileAmount, });

  const {
    isUploading,
    fileList,
    setFiles,
    handleUpload,
    handleAddFiles,
    handleChangeFiles,
  } = useUpload({
    ...options,
    overMaxUploadFileMessage,
    onSetFiles: readFileListFn,
  });

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();

    const stopReportData = StopReportHandlers.makeCreateData(stopReportDataOptions);
    const stringifiedData = JSON.stringify(stopReportData);
    formData.append('data', stringifiedData);
    
    // append every files
    if(fileList) {
      for (const _file of fileList) {
        formData.append(`files.${UPLOAD_FORM_NAME}`, _file, _file.name);
      }
    }
    const request: RequestInit = {
      method: 'POST',
      body: formData,
    };
    return handleUpload(request);
  }, [fileList, handleUpload, stopReportDataOptions]);

  return ({
    isUploading,
    fileList,
    setFiles,
    previewFileList,
    setPreviewFiles,
    handleAddFiles,
    handleSubmit,
    handleChangeFiles,
  });
};

export default useUploadAndPreviewFiles;