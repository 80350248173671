import { createMuiTheme, PaletteColorOptions } from "@material-ui/core";
import { theme_default } from "./theme";
import ibusImages from 'static/ibusImages.json';
import { ThemeShelfAtDate } from "lib/Handlers/ThemeLocalStorage";

export const dragonBoatThemeOnOffDate: ThemeShelfAtDate = {
  onShelfAt: {
    year: 2021,
    month: 6,
    date: 8,
  },
  offShelfAt: {
    year: 2021,
    month: 6,
    date: 14,
  },
};

const primary = {
  light: '#50C98C',
  main: '#2CC58D',
  dark: '#015B5C',
};

const dragonBoatTheme = createMuiTheme({
  ...theme_default,
  name: 'dragon-boat',
  complementary: {
    ...theme_default.complementary,
    main: primary.dark,
  },
  homepageColors: {
    titleColor: '#111',
    navBar: primary.main,
    noticePart: primary.dark,
    middlePart: primary.light,
    footer: primary.light,
  },
  background: {
    surface: '#fff',
    homepage: `url(${ibusImages.background_image_dragon_boat_theme.src})`,
  },
  palette: {
    primary,
  },
});
export default dragonBoatTheme;