import React from 'react';
import ResultPart from 'components/fare/components/ResultPart/ResultPart';
import ContextStore, { FareState } from 'components/fare/constant/context';
import { MapStateToProps } from 'lib/contextReduxLikeHelpers/mapContextToProps';
import { connectCtx } from 'react-function-helpers';
import { GoBack } from 'bus-common-types';
import useFareResult from './functions/useFareResult';

interface FareResultWithQueryProps extends FareResultWithQueryWithCtxProps {
  selectedRouteId: FareState['selectedRoute']['id']
  fromToStopsId: FareState['fromToStopsId']
}
interface FareResultWithQueryStateWithCtx extends Omit<FareResultWithQueryProps, 'goBack'> {

}
export interface FareResultWithQueryWithCtxProps {
  goBack: GoBack
}

const FareResultWithQuery = (props: FareResultWithQueryProps) => {
  const {
    fromToStopsId,
    selectedRouteId,
    goBack,
  } = props;

  const fromToStationIds = {
    fromStationId: fromToStopsId.from,
    toStationId: fromToStopsId.to,
  };

  const {
    handledFareResult,
    shouldHideETicketInfo,
  } = useFareResult({
    fromToStationIds,
    routeId: selectedRouteId,
    goBack,
  });

  return (
    <ResultPart
      {...handledFareResult}
      shouldHideETicketInfo={shouldHideETicketInfo}
    />
  );
};

const mapStateToProps: MapStateToProps<FareState, FareResultWithQueryWithCtxProps, FareResultWithQueryStateWithCtx> = (state) => {
  return ({
    selectedRouteId: state.selectedRoute.id,
    fromToStopsId: state.fromToStopsId
  });
};

export const FareResultWithCtx = connectCtx(ContextStore)(mapStateToProps)(FareResultWithQuery);
export default FareResultWithCtx;