import React from 'react';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import IntlFormattedMessage from '../IntlFormattedMessage';
import IbusImage from '../ImageComponents/IbusImage';

const LogoTitle = () => {
  return (
    <Box>
      <Link 
        to={'/'}
        style={{
          color: '#fff',
        }}
      >
        <IbusImage 
          width={'75%'}
          imageKey={'title_logo'}
        />
        {/* <IntlFormattedMessage 
          variant={'h4'}
          langKey={'drivingMap'} 
        /> */}
      </Link>
    </Box>
  );
};

export default LogoTitle;