import React, { useState, useCallback } from 'react';
import { OtpItinerary } from 'travel-planner';
import PlansPart from '../components/PlansPart/PlansPart';
import { SetChosenIndexFn } from '../components/PlansPart/SinglePlanItem';
import ContextStore, { TravelPlannerState } from 'components/travel-planner/constant/context';
import { selectPlan } from '../actions/plans-actions';
import { MapStateToProps, MapDispatchToProps } from 'lib/contextReduxLikeHelpers/mapContextToProps';
import connectCtx from 'lib/contextReduxLikeHelpers/connectCtx';
import { PlansContainerProps, PlansContainerWithCtxProps, PlansContainerStateFromCtx, PlansContainerDispatchFromCtx } from './types';

export interface UsePlansOptions extends PlansContainerProps {
}
export const usePlans = (options: UsePlansOptions) => {
  const { plans, selectPlan } = options;
  const [chosenIndex, setChosenIndex] = useState<number>();
  const [isQueried, setQueried] = useState(false);

  const handleSetChosenIndex: SetChosenIndexFn = useCallback((index: number) => {
    if(chosenIndex === index) {
      setChosenIndex(undefined);
    } else {
      setChosenIndex(index);
      //dispatch
      selectPlan && selectPlan(plans[index]);
    }
  }, [chosenIndex, plans, selectPlan]);

  React.useEffect(() => {
    if(plans.length > 0 && !isQueried) {
      setQueried(true);
    }
  },[isQueried, plans.length]);

  return ({
    isQueried,
    chosenIndex,
    handleSetChosenIndex
  });
};

const PlansContainer = (props: PlansContainerProps) => {
  const {
    chosenIndex,
    handleSetChosenIndex,
    isQueried,
  } = usePlans(props);

  return (
    <PlansPart
      {...props}
      isQueried={isQueried}
      chosenIndex={chosenIndex}
      setChosenIndexFn={handleSetChosenIndex} />
  );
};

interface OwnProps extends PlansContainerWithCtxProps {}
export const mapStateToProps: MapStateToProps<
TravelPlannerState, OwnProps, PlansContainerStateFromCtx> = (state) => {
  return ({
    plans: state.plans,
  });
};
export const mapDispatchToProps: MapDispatchToProps<OwnProps, PlansContainerDispatchFromCtx> = (dispatch) => {
  return ({
    selectPlan: (plan: OtpItinerary) => {
      const action = selectPlan(plan);
      dispatch(action);
      return action;
    }
  });
};

export const PlansContainerWithCtx = connectCtx(ContextStore)(mapStateToProps, mapDispatchToProps)(PlansContainer);
export default PlansContainer;