import { ID, InputDate } from "all-common-types";
import { checkIsValidDate, getDate } from "./fn";

type DataWithShelfTime = {
  id: ID
  onShelfAt: string
  offShelfAt: string
}

class handleDataWithShelfTime {
  static getTimeFromDate = (date: Date): number => {
    const dateNow = new Date(date);
    const res = dateNow.setHours(0, 0, 0, 0);
    return res;
  }

  static checkShelfTimeWithTodayTime = ({
    todayTime, onShelfTime, offShelfTime
  }: {
    todayTime: number, onShelfTime: number, offShelfTime: number
  }) => {
    if(todayTime - onShelfTime >= 0 && todayTime - offShelfTime <= 0) {
      return true;
    }
    return false;
  }

  static filter<T extends DataWithShelfTime>(dataList: T[], todayDate?: InputDate): T[] {
    const todayTime = this.getTimeFromDate(getDate(todayDate));

    const filteredData = dataList.filter(data => {
      const onShelfTime = this.getTimeFromDate(getDate(data.onShelfAt));
      const offShelfTime = this.getTimeFromDate(getDate(data.offShelfAt));

      return this.checkShelfTimeWithTodayTime({
        todayTime, onShelfTime, offShelfTime
      });
    });
    return filteredData;
  }
} 

export default handleDataWithShelfTime;