import { useLazyQuery } from "@apollo/react-hooks";
import { ID } from "all-common-types";
import { QUERY_STATION_PASSED_ROUTES, QuriedStationPassedRoutes } from "gql/schema.gql";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function useQueryStationPassedRoutes() {
  const {
    locale,
  } = useIntl();

  const [queryFn, { error, loading, data }] = useLazyQuery<QuriedStationPassedRoutes>(QUERY_STATION_PASSED_ROUTES);

  const handleQuery = useCallback((stationId: ID) => {
    queryFn({
      variables: {
        stationId: Number(stationId),
        lang: locale,
      }
    });
  }, [locale, queryFn]);

  return ({
    error,
    loading,
    data,
    handleQuery,
  });
};

export default useQueryStationPassedRoutes;