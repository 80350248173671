import React from 'react';
import Control from 'react-leaflet-control';
import { makeStyles, Box, Theme } from '@material-ui/core';
import { ID } from 'all-common-types';
import ControlsButton from './ControlsButton';
import { DateRangeOutlined, MapOutlined, LocalAtmOutlined } from '@material-ui/icons';
import { ControlsInMapProps } from './types';
import ClogButton from './ClogMessage/ClogButton';

const haveClogMessagesButtonColor = '#e6658e';

const useStyles = makeStyles<Theme, ControlsInMapProps>(theme => ({
  clogButton: {
    backgroundColor: props => props.haveClogs ? haveClogMessagesButtonColor : theme.palette.primary.main,
  },
  linkButton: {
    color: theme.palette.common.white
  }
}));

const routeMapLink = (routeId?: ID) => (
  `${process.env.PUBLIC_URL}/route-map/${routeId}`
);
const fareLink = (routeId?: ID) => (
  `${process.env.PUBLIC_URL}/fare/${routeId}`
);

const ControlsInMap = (props: ControlsInMapProps) => {
  const {
    controlButtonDisplayOption={
      isClogButtonDisplay: true,
      isTimeTableButtonDisplay: true,
    },
    haveClogs,
    isClogLoading,
    selectedRoute,
    displayTimeTableFn,
    onToggleClog
  } = props;
  const {
    isClogButtonDisplay, isTimeTableButtonDisplay,
  } = controlButtonDisplayOption;
  const classes = useStyles(props);
  const routeId = selectedRoute ? selectedRoute.id : '';

  return (
    <Control position={ 'topright' }>
      <Box display={'flex'}>
        {isClogButtonDisplay && (
          <Box paddingRight={0.5}>
            <ClogButton
              haveClog={!!haveClogs}
              onClick={ onToggleClog }
              disabled={isClogLoading}
            />
          </Box>
        )}
        {isTimeTableButtonDisplay && (
          <Box paddingRight={0.5}>
            <ControlsButton
              icon={<DateRangeOutlined />}
              langKey={'drivingMap.mapControl.schedule'}
              onClick={ displayTimeTableFn }
            />
          </Box>
        )}
        {selectedRoute && (
          <>
            <Box paddingRight={0.5}>
              <ControlsButton
                target={'_blank'}
                href={routeMapLink(routeId)}
                icon={<MapOutlined />}
                langKey={'drivingMap.mapControl.routeMap'}
              />
            </Box>
            <Box paddingRight={0.5}>
              <ControlsButton
                target={'_blank'}
                href={fareLink(selectedRoute.id)}
                icon={<LocalAtmOutlined />}
                langKey={'drivingMap.mapControl.fare'}
              />
            </Box>
          </>
        )}
      </Box>
    </Control>
  );
};

export default ControlsInMap;
