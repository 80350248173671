import React from 'react';
import { ContextWrapper } from '../constants/context';
import SearchAndRouteInfoContainer from './searchAndRouteInfo/SearchAndRouteInfoContainer';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from 'constants/API';
import LeftInfoRightMapStyleWrapper from 'components/common-components/wrappers/LeftInfoRightMapStyleWrapper';
import MapPartWithSearchType from './mapPartContainers/MapPartWithSearchType';


const CollinearRoutesContainer = () => {
  return (
    <ApolloProvider client={client}>
      <ContextWrapper>
        <LeftInfoRightMapStyleWrapper>
          <SearchAndRouteInfoContainer />
          <MapPartWithSearchType />
        </LeftInfoRightMapStyleWrapper>
      </ContextWrapper>
    </ApolloProvider>
  );
};

export default CollinearRoutesContainer;