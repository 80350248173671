import React, { memo } from 'react';
import { Youbike2IconItemProps } from './types';
import OtherTransportationIcon from './OtherTransportationIcon';

const Youbike2IconItem = (props: Youbike2IconItemProps) => {
  return (
    <OtherTransportationIcon
      isShowIcon={props.haveYoubike}
      imageKey={'icons_youbike_2'}
      title={'Youbike 2.0'}
    />
  );
};

export default memo(Youbike2IconItem);