import React from 'react';
import { Box } from '@material-ui/core';
import { StationListProps } from './types';
import MrtStationItem from './MrtStationItem';
import checkStationIsIntersection from 'components/mrt-fare/lib/functions/checkStationIsIntersection';

const StationList = (props: StationListProps) => {
  const {
    stationNameDirection,
  } = props;
  
  return (
    <Box style={{...props.partPositionStyle}}>
      {props.stationListData.map((station, i) => {
        const isIntersection = checkStationIsIntersection(station.stationId);
        const isThisStation = station.stationId === props.selectedStationId;
        const isLast = (i === props.stationListData.length - 1);
        const direction = typeof stationNameDirection === 'function' ? 
          stationNameDirection(i, props.stationListData.length) : stationNameDirection;

        return (
          <MrtStationItem
            {...props}
            {...station}
            stationType={isIntersection ? 'INTERSECTION' : props.stationType}
            padding={isLast ? 0 : props.padding}
            direction={direction}
            isThisStation={isThisStation}
          />
        );
      })}
    </Box>
  );
};

export default StationList;