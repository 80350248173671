import { SingleStop } from "../RouteMapContentPart/types";

export interface SingleStopForRouteMap {
  stopName: SingleStop['stopName']
}

const makeStopListStr = (stops: SingleStopForRouteMap[]) => {
  const stopsStrs = stops.map(s => s.stopName);
  
  const res = stopsStrs.join('、');
  return res;
};

export default makeStopListStr;