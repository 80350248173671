import React from 'react';
import { Box } from '@material-ui/core';
import CommonListItem from 'components/common-components/CommonListItem';
import { ROUTE_MAP_LINK_ITEM_RATIO } from 'components/route-map-list/config';
import { RouteMapLinkItemProps } from './types';
import RouteMapLinkBusProviderTitle from './RouteMapLinkBusProviderTitle';
import RouteMapLinkTitle from './RouteMapLinkTitle';

const RouteMapLinkItem = (props: RouteMapLinkItemProps) => {
  const textsInList = [
    <RouteMapLinkBusProviderTitle {...props} />,
    <RouteMapLinkTitle {...props} />,
  ];

  return (
    <Box>
      <CommonListItem 
        textsInList={textsInList}
        textsWidthRatios={ROUTE_MAP_LINK_ITEM_RATIO}
      />
    </Box>
  );
};

export default RouteMapLinkItem;