import { ImagePreviewFile } from "all-common-types";
import { useCallback, useState } from "react";

const usePreviewImages = () => {
  const [previewFileList, setPreviewFiles] = useState<ImagePreviewFile[]>([]);

  const handleAddPreviewFiles = useCallback((previewFile: ImagePreviewFile) => {
    setPreviewFiles(f => [...f, previewFile]);
  }, []);

  return ({
    previewFileList,
    setPreviewFiles,
    handleAddPreviewFiles,
  });
};
export default usePreviewImages;