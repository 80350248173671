import React, { memo } from 'react';
import { RouteStopMarker } from 'components/driving-map/components/BusMap';
import useRouteStopMarker from './functions/useRouteStopMarker';
import { RouteStopMarkerContainerProps } from './types';

const RouteStopMarkerContainer = (props: RouteStopMarkerContainerProps) => {
  const {
    routePassDisplay,
    routePassData,
    handleToggleRoutePass,
  } = useRouteStopMarker({
    stationId: props.stationId,
  });

  return (
    <RouteStopMarker
      {...props}
      isRoutePassDisplay={routePassDisplay}
      routePassData={routePassData}
      onToggleRoutePassDisplay={handleToggleRoutePass}
    />
  );
};

export default memo(RouteStopMarkerContainer);