import { SingleRouteInfo } from "collinear-routes";
import { SingleHandledRouteFromStationName } from "components/collinear-routes/gql/fragments";
import EstimateTimeHandler from "constants/EstimateTimeHandler";
import { getTakenTime } from "components/collinear-routes/fn";
import { getTakenTimeStr } from "components/collinear-routes/static/initRouteTimesRouteInfo";
import { QueriedTravelTimes } from "components/collinear-routes/gql/schemas/QUERY_TRAVEL_TIMES";
import AccumulateTravelTimesHandlers from "./AccumulateTravelTimesHandlers";
import { SingleEstimateTimeInFragment } from "gql/fragments.gql";

export interface MakeSingleRouteInfoOption {
  fromEstimateTime: SingleEstimateTimeInFragment
  toEstimateTime: SingleEstimateTimeInFragment
  route: SingleHandledRouteFromStationName
}
export interface MakeRouteInfosOption {
  fromEstimateTimes: SingleEstimateTimeInFragment[]
  toEstimateTimes: SingleEstimateTimeInFragment[]
  routes: SingleHandledRouteFromStationName[]
}

class RouteInfosHandler {
  static makeSingleRouteInfo(option: MakeSingleRouteInfoOption): SingleRouteInfo {
    const {
      fromEstimateTime,
      toEstimateTime,
      route,
    } = option;

    const takenTimeMins = getTakenTime(fromEstimateTime, toEstimateTime);
    const takenTimeStr = getTakenTimeStr(takenTimeMins);

    const res: SingleRouteInfo = {
      id: `${route.xno}-${fromEstimateTime.id}-${toEstimateTime.id}`,
      goBack: route.goBack,
      routeId: String(route.xno),
      routeName: route.routeName,
      stationSeq: route.orderNo,
      startArrivalTime: EstimateTimeHandler.getEstimateTime(fromEstimateTime).comeTimeStr as any,
      destArrivalTime: EstimateTimeHandler.getEstimateTime(toEstimateTime).comeTimeStr as any,
      takenTime: takenTimeStr as any,
      passedStopsAmount: 0,
    };

    return res;
  }

  static makeRouteInfos(option: MakeRouteInfosOption): SingleRouteInfo[] {
    let res: SingleRouteInfo[] = [];
    const {
      fromEstimateTimes,
      toEstimateTimes,
      routes,
    } = option;

    routes.forEach((route, i) => {
      if(fromEstimateTimes[i] && toEstimateTimes[i]) {
        const singleRouteInfo = RouteInfosHandler.makeSingleRouteInfo({
          route,
          fromEstimateTime: fromEstimateTimes[i],
          toEstimateTime: toEstimateTimes[i],
        });
        res = [
          ...res,
          singleRouteInfo
        ];
      }
    });
    
    return res;
  }

  // travel times 已經排序過
  static mergeTravelTimeToRouteInfo(queriedTravelTimes: QueriedTravelTimes | undefined, routeInfos: SingleRouteInfo[]): SingleRouteInfo[] {
    if(routeInfos.length > 0 && queriedTravelTimes && queriedTravelTimes.travelTime.length > 0) {
      const mergedAndAccumulatedTravelTimes = 
        AccumulateTravelTimesHandlers.handleRawTravelTimes(queriedTravelTimes);
      const res = routeInfos.map((r, i) => {
        const travelTime = mergedAndAccumulatedTravelTimes[i];
        const takenTime = getTakenTimeStr(travelTime.accumulatedTravelTime) as any;

        return ({
          ...r,
          passedStopsAmount: travelTime.passedStopsAmount,
          takenTime,
        });
      });
      return res;
    }
    return [];
  }
}

export default RouteInfosHandler;