import { TAXI_PROVIDER_ID } from "components/route-map/config";
import { ID } from "all-common-types";

export const checkRouteIncludesTaxiProvider = (route: { providerIds: ID[] }) => {
  const strIds = route.providerIds.map(i => String(i));
  if(strIds.includes(String(TAXI_PROVIDER_ID))) {
    return true;
  }
  return false;
};

function filterRoutesByTaxiProvider<SingleRoute extends { providerIds: ID[] }>(routes: SingleRoute[]) {
  const res = routes.filter(r => (
    !checkRouteIncludesTaxiProvider(r)
  ));
  return res;
};

export default filterRoutesByTaxiProvider;