import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { StationColorKeys } from 'mrt-pages';
import { MRT_COLORS } from 'components/mrt-fare/config';

export interface MrtLineProps {
  top?: any
  bottom?: any
  left?: any
  right?: any
  width: number
  direction: 'vertical' | 'horizontal'
  color: StationColorKeys
}

const useStyles = makeStyles<Theme, MrtLineProps>(theme => ({
  root: props => ({
    position: 'absolute',
    top: props.top,
    bottom: props.bottom,
    left: props.left,
    right: props.right,
    width: props.width,
    height: theme.spacing(0.5),
    transformOrigin: `${0}% ${0}%`,
    transform: props.direction === 'vertical' ? `rotate(${90}deg)` : 'unset',
    backgroundColor: MRT_COLORS[props.color]
  })
}));

const MrtLine = (props: MrtLineProps) => {
  const classes = useStyles(props);

  return (
    <Box className={classes.root} />
  );
};

export default MrtLine;