import React from 'react';
import { List } from '@material-ui/core';
import { SingleStationRoute, EstimateTimeHandlerOptions } from 'bus-common-types';
import CommonListItem from 'components/common-components/CommonListItem';
import { GridSize } from '@material-ui/core/Grid';
import EstimateTimeHandler from 'constants/EstimateTimeHandler';
import RouteListEstimateTimeItemContainer from '../Route/RouteListEstimateTimeItemContainer';

export const RoutePassListItemWidthRatios: GridSize[] = [3, 6, 3];

export type RoutePassListItemProps = SingleStationRoute & EstimateTimeHandlerOptions
export const RoutePassListItem = (props: RoutePassListItemProps) => {
  const {
    name, description
  } = props;
  const estimateTimeItem = (
    <RouteListEstimateTimeItemContainer
      {...props}
    />
  );

  const textsInList = [
    name, description, estimateTimeItem
  ];

  return (
    <CommonListItem
      textsInList={textsInList}
      textsWidthRatios={RoutePassListItemWidthRatios} />
  );
};

type RoutePassListProps = {
  routePassData: RoutePassListItemProps[]
}
export const RoutePassList = (props: RoutePassListProps) => {
  return (
    <List component={'nav'}>
      {props.routePassData.map((r, i) => (
        <RoutePassListItem key={i} {...r} />
      ))}
    </List>
  );
};

export default RoutePassList;