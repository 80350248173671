import { createMuiTheme } from "@material-ui/core";
import { theme_default } from "./theme";
import goldThemeImages from './homepageImages/goldTheme.json';
import ibusImages from 'static/ibusImages.json';

const primary = {
  light: '#ffe7a1',
  main: '#d4b572',
  dark: '#a18545'
};
const complementary = {
  light: 'linear-gradient(90deg, rgba(252,250,240,1) 58%, rgba(230,214,167,1) 100%)',
  dark: 'linear-gradient(90deg, rgba(60,60,60,1) 0%, rgba(84,84,84,1) 12%, rgba(15,15,15,1) 49%, rgba(91,91,91,1) 77%, rgba(52,52,52,1) 100%)',
  main: 'linear-gradient(90deg, rgba(240,203,67,1) 0%, rgba(208,151,37,1) 100%)',
};

const goldTheme = createMuiTheme({
  ...theme_default,
  complementary,
  homepageColors: {
    // titleColor: primary.main,
    titleColor: '#fff',
    navBar: complementary.dark,
    noticePart: primary.main,
    middlePart: complementary.main,
    footer: complementary.dark,
  },
  background: {
    surface: complementary.light,
    homepage: `url(${ibusImages.background_image.src}), rgba(0, 0, 0, ${0.95})`
  },
  homepageImages: {
    topLeft: goldThemeImages.homepage_top_left.src,
    topRight: goldThemeImages.homepage_top_right.src,
    bottomLeft: goldThemeImages.homepage_below_left.src,
  },
  palette: {
    primary,
    // error: {
    //   main: ''
    // },
    text: {
      // primary: '#eee',
    }
  },
});

export default goldTheme;