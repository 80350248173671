import { ID } from "all-common-types";
import { ROUTE_LIST_PART_ZOOM_LS_KEY } from "components/driving-map/config";

const ZoomLocalStorageHandlers = {
  getZoomValueFromLS(localStorageItemKey=ROUTE_LIST_PART_ZOOM_LS_KEY) {
    const zoomVal = localStorage.getItem(localStorageItemKey);
    if(zoomVal) {
      return Number(zoomVal);
    }
    return 1;
  },

  saveZoomValueToLS(key=ROUTE_LIST_PART_ZOOM_LS_KEY) {
    return (value: ID) => {
      localStorage.setItem(key, String(value));
    };
  }
};

export default ZoomLocalStorageHandlers;