import { PlanType, OtpItinerary} from "travel-planner";
import { PlanTagItemProps } from "../components/PlansPart/types";

export interface MakeSingleTagRes {
  planType: PlanType
  haveThisTag: boolean
}

export type OtherTags = MakeSingleTagRes[]

export const sortPlansByWalkDistance = (p1: OtpItinerary, p2: OtpItinerary) => {
  return p1.walkDistance - p2.walkDistance;
};

class PlanTagHandler {
  static getDirectPlanTag(singlePlan: OtpItinerary): MakeSingleTagRes {
    let res: MakeSingleTagRes = {
      planType: 'direct',
      haveThisTag: false,
    };

    const {
      transfers
    } = singlePlan;

    if(transfers === 0) {
      res = {...res, haveThisTag: true};
    }

    return res;
  }
  static getDirectPlanTags(plans: OtpItinerary[]): MakeSingleTagRes[] {
    let res: MakeSingleTagRes[] = [];
    res = plans.map(p => this.getDirectPlanTag(p));

    return res;
  }

  static makeSinglePlanTags(tagsRes: OtherTags): PlanType[] {
    let res: PlanType[] = [];

    tagsRes.forEach((t) => {
      if(t.haveThisTag) {
        res = [
          ...res,
          t.planType,
        ];
      }
    });

    return res;
  }

  static getFatestPlanTag(plans: OtpItinerary[]): MakeSingleTagRes[] {
    let res: MakeSingleTagRes[] = [];

    plans.forEach((p, i) => {
      if(i === 0) {
        res[i] = {
          planType: 'fatest',
          haveThisTag: true,
        };
      }
      else {
        res[i] = {
          planType: 'fatest',
          haveThisTag: false,
        };
      }
    });

    return res;
  }

  static getWalklessPlanTag(plans: OtpItinerary[]): MakeSingleTagRes[] {
    let res: MakeSingleTagRes[] = [];

    const plansAddId = plans.map((p, i) => ({
      ...p,
      id: i,
    }));
    const sortByWalkDistancePlans = plansAddId.sort(sortPlansByWalkDistance);

    sortByWalkDistancePlans.forEach((p, i) => {
      const originIndex = p.id;

      if(i === 0) {
        res[originIndex] = {
          planType: 'walkless',
          haveThisTag: true,
        };
      }

      else {
        res[originIndex] = {
          planType: 'walkless',
          haveThisTag: false,
        };
      }
    });

    return res;
  }


  static makePlanTags(plans: OtpItinerary[]): PlanType[][] {
    let res: PlanType[][] = [];

    const directPlanTags = this.getDirectPlanTags(plans);
    const fatestPlanTags = this.getFatestPlanTag(plans);
    const walklessPlanTags = this.getWalklessPlanTag(plans);

    plans.forEach((plan, i) => {
      res[i] = this.makeSinglePlanTags([
        directPlanTags[i],
        fatestPlanTags[i],
        walklessPlanTags[i],
      ]);
    });

    return res;
  }

  static converToPlanTagItemProps(planType: PlanType): PlanTagItemProps {
    const res: PlanTagItemProps = {
      planTag: planType,
    };
    return res;
  }

  static converToPlanTagsProps(planTypes: PlanType[]): PlanTagItemProps[] {
    const res: PlanTagItemProps[] = planTypes.map(PlanTagHandler.converToPlanTagItemProps);
    return res;
  }
}

export default PlanTagHandler;