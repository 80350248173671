import { useQuery } from '@apollo/react-hooks';
import QUERY_IBUS_COMMON_DATA, { QueryIbusCommonDataType } from 'constants/cmsAPI/cmsAPI';
import { SingleBlog } from 'blog';
import { convertTimeToString, sortByCondition, sortByTop } from 'lib/fn';
import { singleBlogData_mockData } from 'components/home-page/storage/mockData';
import { mainMenuLinks_mockData, pages_mockData, notices_originQuery_mockData, subMenuLinks_mockData } from 'components/common-components/storage/mockData';
import handleDataWithShelfTime from 'lib/handleDataWithShelfTime';
import { IbusCommonData } from 'constants/context';
import { CmsData } from './types';
import { Locale, SingleNotice } from 'all-common-types';

export const sortAndFilteredBlogs = (blogs: SingleBlog[]) => {
  const today = new Date();
  const filterdByShelf = handleDataWithShelfTime.filter(blogs, today);
  const sortByDateDescFn = sortByCondition({
    type: 'date',
    column: 'onShelfAt',
    ascOrDesc: -1
  });
  const sortedByOnShelfAt = filterdByShelf.sort(sortByDateDescFn);
  const sortedByTopBlogs = sortByTop(sortedByOnShelfAt);
  const res = sortedByTopBlogs as SingleBlog[];
  return res;
  // return filterdByShelf.sort(sortByDateDescFn);
};

export const filterNotices = (notices: SingleNotice[]) => {
  const res = handleDataWithShelfTime.filter(notices);
  return res;
};

export const defaultCmsData: QueryIbusCommonDataType = {
  blogs: [singleBlogData_mockData],
  mainMenuLinks: mainMenuLinks_mockData,
  subMenuLinks: subMenuLinks_mockData,
  pages: pages_mockData,
  notices: notices_originQuery_mockData,
  timeTableRoutes: [],
};

export interface UseQueryCmsOptions {
  locale?: Locale
}

const useQueryCms = (options: UseQueryCmsOptions) => {
  const {
    locale='zh'
  } = options;

  let cmsData: QueryIbusCommonDataType = {
    blogs: [],
    mainMenuLinks: [],
    subMenuLinks: [],
    pages: [],
    notices: [],
    timeTableRoutes: [],
  };

  const { loading, error, data } = useQuery<QueryIbusCommonDataType>(QUERY_IBUS_COMMON_DATA, {
    variables: {
      locale,
    }
  });


  if(data) {
    const filteredNotices = filterNotices(data.notices);
    
    cmsData = {
      ...data,
      notices: filteredNotices,
      blogs: sortAndFilteredBlogs(data.blogs),
    };
  }
  else if(error) {
    cmsData = defaultCmsData;
  }

  const blogLists = cmsData.blogs.map(blog => ({
    ...blog,
    createTime: convertTimeToString(blog.created_at),
  }));

  cmsData = {
    ...cmsData,
    blogs: blogLists
  };

  const ibusCommonData: QueryIbusCommonDataType = {
    ...cmsData,
    blogs: cmsData.blogs,
  };

  return ({
    error,
    loading,
    ibusCommonData,
  });
};

export default useQueryCms;