import React from 'react';
import PriceListWithTitle, { PriceListWithTitleProps } from './PriceListWithTitle';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
export interface ECitizenPriceProps extends Omit<PriceListWithTitleProps, 'children' | 'langKey'> {
  shouldHideETicketInfo: boolean
}

const ECitizenPrice = (props: ECitizenPriceProps) => {
  return (
    <PriceListWithTitle 
      {...props}
      langKey={'fare.searchResult.eCitizen'}
    >
      {/* 某幾條路線不顯示10公里免費...這段訊息 */}
      {!props.shouldHideETicketInfo && (
        <IntlFormattedMessage
          langKey={'fare.searchResult.eCitizen.info'} 
        />
      )}
    </PriceListWithTitle>
  );
};

export default ECitizenPrice;