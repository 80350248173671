import React, { ChangeEvent, useCallback, useRef } from 'react';
import { useState } from "react";
import { Obj, Callback } from 'all-common-types';

export const useSelectTab = (initIndex: number=0, sideEffectFn?: Callback): 
[number, (e: any, value: number) => any] => {
  const [selectedTab, setSelect] = useState(initIndex);
  const handleTab = (e: any, value: number) => {
    setSelect(value);
    sideEffectFn && sideEffectFn();
  };
  return [selectedTab, handleTab];
};

type QueryFn = (value: string) => any 
type QueryFnForSearch = (name: any, value: string) => any
export function useMockQuery<Res extends {
  [x: string]: any
}>(
  initRes: Res[], 
  mockData: Res[],
  dataCompareKey: keyof Res,
): [Res[], QueryFn ] {
  const [query, getQuery] = useState<Res[]>(initRes);
  const findResult: QueryFn = (value) => {
    const mockQueryRes = !!value ? mockData.filter(res => {
      return res[dataCompareKey].includes(value);
    }) : [];
    return getQuery(mockQueryRes);
  };
  return [query, findResult];
};

export interface UseToggleStates {
  toggle: boolean
  setToggle: (t: boolean) => any
  handleCloseToggle: Callback
  handleOpenToggle: Callback
  handleToggle: Callback
}

export const useToggle = (initToggle: boolean=false): UseToggleStates => {
  const [toggle, setToggle] = useState(initToggle);
  const handleToggle = () => {
    setToggle(t => !t);
  };
  const handleCloseToggle = useCallback(() => {
    toggle && setToggle(false);
  }, [toggle]);
  const handleOpenToggle = useCallback(() => {
    setToggle(true);
  }, []);
  return { toggle, setToggle, handleToggle, handleCloseToggle, handleOpenToggle };
};

export function useSearch<State extends {[x: string]: string}, K extends keyof State>(initState: State, queryFn?: QueryFnForSearch) {
  const [values, setValues] = useState<State>(initState);
  const setNameValues = (name: K, value: string) => {
    setValues(val => ({
      ...val,
      [name]: value,
    }));
    queryFn && queryFn(name, value);
  };

  const handleSetValues = (name: K) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNameValues(name, value);
  };

  const handleDirectlySetValues = (name: K) => (value: string) => {
    setNameValues(name, value);
  };

  return { values, setValues, handleSetValues, handleDirectlySetValues };
}

export const useSearchPartSearch = (setInputsToCtxFn?: (x?: any) => any) => {
  const searchPartRef = useRef<any>();
  const handleSearch = () => {
    if(searchPartRef.current) {
      let values;
      const { getInputValues } = searchPartRef.current;
      if(getInputValues) {
        values = getInputValues();
      }
      //console.log(values);
      //and dispatch values to context, let query start
      setInputsToCtxFn && setInputsToCtxFn(values);
    }
  };
  return { searchPartRef, handleSearch };
};

export function HookWrapper<HookProps extends any[] | Obj>(props: {
  hook: HookProps
}) {
  //console.log(props.hook);
  return (
    <></>
  );
};
export function TestHookWrapper<HookFn extends (...args: any[]) => any[] | object>(hookFn: HookFn) {
  return (...hookArgs: Parameters<HookFn>) => () => {
    const hookStates = hookFn(...hookArgs);
    return (
      <HookWrapper hook={hookStates} />
    );
  };
}