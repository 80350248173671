import { SingleClogMessage } from "bus-common-types";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { QueriedClogMessages, QUERY_CLOGS } from "../constants/API";
import { useIntl } from "react-intl";
import { useCallback, useState, useEffect, useMemo } from "react";
import { ID, Locale } from "all-common-types";
import { nodeDataFromEdges } from "constants/functions";
import { TAXI_ROUTE_IDS } from "../config";
import getStaticClogs from "./getStaticClogs";
import ClogMessagesHandlers, { HandleRawClogMessagesDataParams } from "./Handlers/ClogMessagesHandlers";

export interface UseQueryClogsOptions extends Pick<HandleRawClogMessagesDataParams, 'routeData'> {
  
}

const useQueryClogs = (options: UseQueryClogsOptions) => {
  const [queryFn, { error, loading, data }] = useLazyQuery<QueriedClogMessages>(QUERY_CLOGS);
  const [handledClogs, setClogs] = useState<SingleClogMessage[]>([]);

  const {
    locale,
  } = useIntl();

  const handleQuery = useCallback((routeId: ID) => {
    const isTaxiRoutes = TAXI_ROUTE_IDS.includes(Number(routeId)); // only taxi routes
    if(isTaxiRoutes) {
      const staticClogs = getStaticClogs(locale as Locale);
      setClogs(staticClogs);
    } else {
      queryFn({
        variables: {
          routeId: Number(routeId),
          lang: locale,
        }
      });
    }
  }, [locale, queryFn]);

  useEffect(() => {
    if(data) {
      const handledClogs = ClogMessagesHandlers.handleRawData({
        ...options,
        clogMessages: nodeDataFromEdges(data.clogMessages),
      });
      setClogs(handledClogs);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), JSON.stringify(options)]);

  return ({
    error,
    loading,
    data,
    clogs: handledClogs,
    handleQuery,
  });
};

export default useQueryClogs;