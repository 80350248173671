import { gql } from "apollo-boost";
import { client} from "constants/API";
import { FareState } from "components/fare/constant/context";

const QUERY_ROUTE_ISCYCLED = gql`
  query QUERY_ROUTE_ISCYCLED($routeId: Int!) {
    route(xno: $routeId, lang:"zh") {
      isCycled
    }
  }
`;
interface QueriedRouteIsCycled {
  route: {
    isCycled: boolean
  }
}

const queryRouteIsCycled = async (routeId: FareState['selectedRoute']['id']) => {
  if(!isNaN(Number(routeId))) {
    try {
      const queried = await client.query<QueriedRouteIsCycled>({
        query: QUERY_ROUTE_ISCYCLED,
        variables: {
          routeId: Number(routeId),
        }
      });
      if(!queried.errors) {
        return queried.data.route.isCycled;
      }
    } catch (error) {
      console.log(error);
    }
  }

  return false;
};

export default queryRouteIsCycled;