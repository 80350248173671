import { SelectedRouteId } from "components/collinear-routes/constants/context";
import { RouteDataType } from "bus-common-types";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { QueriedCollinearRoutesRouteData, QUERY_COLLINEAR_ROUTES_ROUTE_DATA } from "components/collinear-routes/constants/API";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import verifyRouteId from "lib/verifyRouteId";
import { client } from "constants/API";

const useQueryRouteData = () => {
  let routeData: QueriedCollinearRoutesRouteData['route'] | null = null;
  
  const [queryFn, { error, loading, data }] = useLazyQuery<QueriedCollinearRoutesRouteData>(QUERY_COLLINEAR_ROUTES_ROUTE_DATA, {
    client: client,
  });

  const {
    locale,
  } = useIntl();

  const handleQuery = useCallback((routeId: SelectedRouteId) => {
    if(routeId && verifyRouteId(routeId)) {
      queryFn({
        variables: {
          routeId: Number(routeId),
          lang: locale,
        }
      });
    }
  }, [locale, queryFn]);

  //console.log(data);

  if(data) {
    routeData = data.route;
  }

  return ({
    error,
    loading,
    handleQuery,
    routeData,
  });
};

export default useQueryRouteData;