import React from 'react';
import { Box, Grid, List, makeStyles, Typography } from '@material-ui/core';
import { FromToStopListItemWithTitleProps } from './types';
import FromToStopListItemTitle from './FromToStopListItemTitle';
import makeStopListStr from '../functions/makeStopListStr';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    alignItems: 'flex-start',
  }
}));

const FromToStopListItemWithTitle = (props: FromToStopListItemWithTitleProps) => {
  const {
    stopList
  } = props;
  const classes = useStyles();
  const stopListStr = makeStopListStr(stopList);

  return (
    <Grid
      className={classes.root}
      container
    >
      <Grid item xs={12} sm={5}>
        <FromToStopListItemTitle
          {...props} />
      </Grid>
      <Grid item xs={12} sm={7}>
        <IntlFormattedMessage
          variant={'h6'}
          langKey={'routeMap.callingAt'}
        />
        <Typography>{stopListStr}</Typography>
      </Grid>
    </Grid>
  );
};

export default FromToStopListItemWithTitle;