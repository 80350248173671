import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import ReportDescriptionSelector from './ReportDescriptionSelector';
import ReportDescriptionInput from './ReportDescriptionInput';
import { ReportDescriptionPartProps } from './types';
import TitleWrapper from './TitleWrapper';

const ReportDescriptionPart = (props: ReportDescriptionPartProps) => {
  return (
    <Box>
      <TitleWrapper {...props} titleLangKey={'stopReport.title4'}>
        <ReportDescriptionSelector {...props} />
        {props.selectorValue === 'other' && (
          <ReportDescriptionInput {...props} />
        )}
      </TitleWrapper>
    </Box>
  );
};

export default memo(ReportDescriptionPart);