import { gql } from "apollo-boost";
import gqlFragments, { EstimateTimesFragment } from "gql/fragments.gql";

const QUERY_FROM_TO_STATION_ESTIMATES = gql`
  query QUERY_FROM_TO_STATION_ESTIMATES($fromStationTargets: [EstimateStationInput!]!, $toStationTargets: [EstimateStationInput!]!) {
    from: stationEstimates(targets: $fromStationTargets) {
      ...estimateTimesFragment
    }
    to: stationEstimates(targets: $toStationTargets) {
      ...estimateTimesFragment
    }
  }
  ${gqlFragments.estimateTimesFragment}
`;

export interface QueriedFromToStationEstimates {
  from: EstimateTimesFragment
  to: EstimateTimesFragment
}

export default QUERY_FROM_TO_STATION_ESTIMATES;