import React from "react";
import { Box, Typography, makeStyles, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import Title from "./title";
import { getBlogLink } from "components/blog/blogListsPage";
import TopFlag from "components/common-components/topFlag";
import { BlogListProps } from "./types";
import IntlFormattedMessage from "components/common-components/IntlFormattedMessage";

export const basicHoverEffectStyle = (hoveredBGcolor: string = "#ddd") => ({
  transition: "0.2s",
  cursor: "pointer",
  "&:hover": {
    transition: "0.2s",
    backgroundColor: hoveredBGcolor,
  },
});

const useStyles = makeStyles({
  root: {
    padding: 10,
    marginBottom: 6,
    ...basicHoverEffectStyle(),
    "& > a": {
      color: "#111",
    },
    "& > a:hover": {
      textDecoration: "none",
    },
  },
  blogContent: {
    flex: 1,
  }
});

type SingleBlogItemProps = import("blog").SingleBlog;
export const SingleBlogItem = ({
  title,
  createTime,
  id,
  flag,
}: SingleBlogItemProps) => {
  const classes = useStyles();
  const link = getBlogLink(id);

  const blogTitle = (
    <Box
      display={'flex'}
      width={'100%'}
      alignItems={"center"}
    >
      {flag === "top" && <TopFlag />}
      <Typography className={classes.blogContent} variant={"h6"}>{title}</Typography>
    </Box>
  );

  return (
    <Paper className={classes.root}>
      <Link to={link}>
        <Box>
          <Typography variant={"body1"}>{createTime}</Typography>
          {blogTitle}
        </Box>
      </Link>
    </Paper>
  );
};

const useStylesBlogs = makeStyles((theme) => ({
  blogListPart: {
    overflow: "auto",
    height: 300,
    background: theme.background.surface,
    borderRadius: theme.spacing(0.5),
  },
}));

export const BlogList = (props: BlogListProps) => {
  const { blogs } = props;
  const classes = useStylesBlogs();

  if (blogs.length === 0) {
    return (
      <Box padding={1.5} className={classes.blogListPart}>
        <IntlFormattedMessage
          langKey={"blogs.noMessage"}
          variant={"h6"}
          color={"textSecondary"}
        />
      </Box>
    );
  }

  // const sortedBlogLists = blogs.sort((a, b) => {
  //   return new Date(b.onShelfAt).getTime() - new Date(a.onShelfAt).getTime();
  // });

  // const topBlogLists = sortedBlogLists
  //   .filter((blog) => blog.flag === "top")
  //   .concat(sortedBlogLists.filter((blog) => blog.flag !== "top"));

  return (
    <Box padding={1.5} className={classes.blogListPart}>
      {blogs.map((blog, i) => (
        <SingleBlogItem key={i} {...blog} />
      ))}
    </Box>
  );
};

export type BlogsProps = {
  titleData: import("./title").TitleProps;
  blogsData: SingleBlogItemProps[];
};
const Blogs = ({ titleData, blogsData }: BlogsProps) => {
  return (
    <Box>
      <Title {...titleData} />
      <BlogList blogs={blogsData} />
    </Box>
  );
};

export default Blogs;
