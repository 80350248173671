import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { DayType, PeakType } from 'bus-common-types';
import { timeTableConfigs } from 'config';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const useStyles = makeStyles(() => ({
  intervalItem: {
    marginBottom: 10,
  }
}));

export type SinglePeakTimeData = {
  peakType: PeakType
  peakTimes: string[]
  peakTimesInterval: string
} | null
export type HeadwayIntervalItemProps = {
  singlePeakTimeData: SinglePeakTimeData
}

export const getHeadwayPeakTimesStr = (peakTypeStr: string, peakTimes: string[]) => {
  return peakTypeStr + ': ' + peakTimes.join(', ');
};

export const HeadwayIntervalItem = ({ 
  singlePeakTimeData 
}: HeadwayIntervalItemProps) => {
  const classes = useStyles();
  if(singlePeakTimeData) {
    const {
      peakType, 
      peakTimes, 
      peakTimesInterval
    } = singlePeakTimeData;
    const peakTypeStr = timeTableConfigs.getPeakTypeStr(peakType);
    const headwayPeakTimesStr = getHeadwayPeakTimesStr(peakTypeStr, peakTimes);
    
    return (
      <Box className={ classes.intervalItem }>
        <Typography>
          { headwayPeakTimesStr }
        </Typography>
        <IntlFormattedMessage<{peakTimesInterval: string}>
          langKey={'drivingMap.timeTable.intervalInfo'}
          values={{ peakTimesInterval }}  />
      </Box>
    );
  }
  return null;
};

type HeadwayIntervalProps = {
  dayType: DayType
  peakTimeDatas: SinglePeakTimeData[]
}
const HeadwayInterval = ({ dayType, peakTimeDatas }: HeadwayIntervalProps) => {
  const dayTypeStr = timeTableConfigs.getDayTypeStr(dayType);
  return (
    <Box>
      <IntlFormattedMessage
        langKey={'drivingMap.timeTable.headwayIntervalTitle'}
      />
      <Typography variant={ 'h4' }>
        {dayTypeStr}
      </Typography> 
      {peakTimeDatas.map((data, i) => (
        <HeadwayIntervalItem 
          key={ i }
          singlePeakTimeData={data} />
      ))}
    </Box>
  );
};

export default HeadwayInterval;