import React from 'react';
import { Box } from '@material-ui/core';
import SearchInput from './SearchInput';
import SearchButton from 'components/common-components/Buttons/SearchButton';
import { SearchBarProps } from './types';

const SearchBar = (props: SearchBarProps) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
    >
      <SearchInput
        {...props}
      />
      <Box
        paddingLeft={1}
      >
        <SearchButton
          onSearch={props.onSearch}
        />
      </Box>
    </Box>
  );
};

export default SearchBar;