import { ID, Locale } from "all-common-types";
import { client, cmsClient } from "constants/API";
import { QueriedDynamicRouteData, QueriedStaticRouteData, QueriedVisibleJointOperators ,QUERY_DYNAMIC_ROUTE_DATA, QUERY_STATIC_ROUTE_DATA, QUERY_VISIBLE_JOINT_OPERATORS } from "../gql-schema";

const drivingMapQueryFns = {
  queryStaticRouteData: async (routeId: ID, lang: Locale) => {
    try {
      const data = client.query<QueriedStaticRouteData>({
        query: QUERY_STATIC_ROUTE_DATA,
        variables: {
          routeId: Number(routeId),
          lang,
        }
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  queryDynamicData: async (routeId: ID, lang: Locale) => {
    try {
      const data = client.query<QueriedDynamicRouteData>({
        query: QUERY_DYNAMIC_ROUTE_DATA,
        variables: {
          routeId: Number(routeId),
          lang,
        }
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  queryVisibleJointOperators: async () => {
    try {
      const data = cmsClient.query<QueriedVisibleJointOperators>({
        query: QUERY_VISIBLE_JOINT_OPERATORS
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default drivingMapQueryFns;