import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import BusStationsPart from '../../components/StationsPart/BusStationsPart/BusStationsPart';
import MrtStationsPart from '../../components/StationsPart/MrtStationsPart/MrtStationsPart';
import useStationsPart from './custom-hooks/useStationsPart';
import { StationsPartProps } from './types';
import LoadingErrorMessageWrapper from 'components/common-components/wrappers/LoadingErrorEmptyMessageWrapper';
import GoBackSwitch from 'components/fare/components/SearchPart/GoBackSwitch';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import MrtIntersectionStationItem from 'components/mrt-fare/components/common/MrtIntersectionStationItem';
import { INTERSECTION_STATION_SID, MRT_GREEN_ROUTE_ID } from 'components/mrt-fare/config';

const useStyles = makeStyles(theme => ({
  root: {
     
  },
  mrtPart: {
    position: 'absolute',
    zIndex: 10,
    top: `${52}%`,
    left: 904,
    // right: `${-57}%`,
  },
  intersection: {
    position: 'absolute',
    top: `${0}%`,
    right: `${15.5}%`,
  },
}));

const StationsPart = (props: StationsPartProps) => {
  const classes = useStyles();

  const {
    loading,
    checked,
    selectedStationId,
    stationDataList,
    intersectionStationFareContent,
    handleChangeGoBackSwitch,
    handleSetStation,
  } = useStationsPart(props);
  
  const {
    commonBusStations,
    route309OtherStations,
    route310OtherStations,
    mrtGreenStations,
  } = stationDataList;
  
  return (
    <LoadingErrorMessageWrapper 
      loading={loading}
      wrapperProps={{
        style: { position: 'relative', }
      }}
    >
      <GoBackSwitch
        wrapperStyles={{
          position: 'sticky',
          top: 0,
          left: 0,
        }}
        goLabelComponent={
          <IntlFormattedMessage langKey={'goBack.go'} />
        }
        backLabelComponent={
          <IntlFormattedMessage langKey={'goBack.back'} />
        }
        checked={checked}
        onChange={handleChangeGoBackSwitch}
      />
      <Box className={classes.mrtPart}>
        <Box className={classes.intersection}>
          <MrtIntersectionStationItem
            routeId={MRT_GREEN_ROUTE_ID}
            stationId={INTERSECTION_STATION_SID.MRT_GREEN[0]} // 任一個intersection的sid都可以
            selectedStationId={selectedStationId}
            stationType={'INTERSECTION'}
            colorKey={'INTERSECTION'}
            content={intersectionStationFareContent}
            onSetStation={handleSetStation}
          />
        </Box>
        <MrtStationsPart
          selectedStationId={selectedStationId}
          stationListData={mrtGreenStations}
          onSetStation={handleSetStation}
        />
      </Box>
      <BusStationsPart
        selectedStationId={selectedStationId}
        commonStationListData={commonBusStations}
        route309OtherStations={route309OtherStations}
        route310OtherStations={route310OtherStations}
        onSetStation={handleSetStation}
      />
    </LoadingErrorMessageWrapper>
  );
};

export default StationsPart;