import React from 'react';
import StopMarker, { SingleStopMarker } from './StopMarker';
import { RouteLine } from 'components/driving-map/components/BusMap';
import { Position, GoBack } from 'bus-common-types';
import BusMarkerListContainer, { BusMarkerListContainerProps } from 'components/driving-map/containers/BusMap/BusMarkerListContainer';

export type MarkersWithRouteLineProps = {
  routeStops: SingleStopMarker[]
  routePoints: Position[]
  goBack: GoBack
} & BusMarkerListContainerProps
const MarkersWithRouteLine = (props: MarkersWithRouteLineProps) => {
  const { routeStops, goBack } = props;
  return (
    <>
      {routeStops.map((stop, i) => (
        <StopMarker key={i} {...stop} />
      ))}
      <BusMarkerListContainer {...props} />
      <RouteLine {...props} goBack={goBack}/>
    </>
  );
};

export default MarkersWithRouteLine;