import { useState } from "react";
import { BusProviderId } from "bus-common-types";
import { ID } from "all-common-types";
import { ALL_PROVIDERS } from "lib/makeBusProvidersSelectorOptions";

const useSelectsPart = () => {
  const [busProviderId, setId] = useState<ID>(ALL_PROVIDERS);

  return ({
    setId,
    busProviderId
  });
};

export default useSelectsPart;