import { useLazyQuery } from "@apollo/react-hooks";
import { ID } from "all-common-types";
import { DepDestTabListProps } from "components/common-components/TabComponents/types";
import { DepDestWithInitGoBack } from "components/stop-report-page/containers/StopReportInputPart/types";
import StopReportHandlers from "components/stop-report-page/lib/Handlers/StopReportHandlers";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { QueriedRotueInfoForStopReport, QUERY_STOP_REPORT_ROUTE_INFO } from "../gql-schemas";

export interface UseQueryStopReportRouteInfoOptions {
  
}

const defaultDepDest: DepDestWithInitGoBack = {
  departure: {
    disabled: true,
    location: '',
  },
  destination: {
    disabled: true,
    location: '',
  },
  initGoBack: 1,
};

function useQueryStopReportRouteInfo() {
  const {
    locale,
  } = useIntl();

  const [queryFn, { error, loading, data }] = useLazyQuery<QueriedRotueInfoForStopReport>(QUERY_STOP_REPORT_ROUTE_INFO);

  const handleQuery = useCallback((routeId: ID) => {
    queryFn({
      variables: {
        routeId: Number(routeId),
        lang: locale,
      }
    });
  }, [locale, queryFn]);

  const stopOptions = useMemo(() => (
    StopReportHandlers.handleStopOptionsFromRawData(data)
  ), [data]);

  const depDest = useMemo(() => (
    data ? (
      StopReportHandlers.getDepDestForGoBackButton(data.route, stopOptions)
    ) : defaultDepDest
  ), [data, stopOptions]);

  return ({
    error,
    loading,
    data,
    stopOptions,
    depDest,
    handleQuery,
  });
};

export default useQueryStopReportRouteInfo;