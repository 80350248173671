/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@apollo/react-hooks';
import { QUERY_ROUTES, QueriedRoutes } from 'components/driving-map/constants/API';
import { getRoutesSelectorOptions, RoutesSelectorOptions } from 'constants/functions';
import { sortByAttr } from 'lib/fn';
import { SingleRouteFromRoutes } from 'all-common-types';
import { BusType } from 'driving-map';
import { useIntl } from 'react-intl';
import { ALL_PROVIDERS } from 'lib/makeBusProvidersSelectorOptions';
import { useMemo } from 'react';
import QueriedRoutesHandlers from 'lib/Handlers/QueriedRoutesHandlers';
import { QueriedVisibleJointOperators, QUERY_VISIBLE_JOINT_OPERATORS } from 'components/driving-map/gql/gql-schema';
import { cmsClient } from 'constants/API';

export const getValidatedProviderId = (busType: BusType, busProviderId?: number | string) => {
  if(busProviderId === ALL_PROVIDERS) {
    return undefined;
  }
  else {
    const providerId = (typeof busProviderId !== 'undefined' && busType === 'city') ?
      Number(busProviderId) : undefined;
    return providerId;
  }
};

export interface SingleRouteWithProviderIds extends SingleRouteFromRoutes {
  providerIds: number[]
}

const useQueryRoutes = (busType: BusType, busProviderId?: number | string, excludeOpTypes?: Array<number>) => {
  const providerId = getValidatedProviderId(busType, busProviderId);

  const {
    locale,
  } = useIntl();

  const { loading, data } = useQuery<QueriedRoutes>(QUERY_ROUTES, {
    variables: {
      lang: locale,
    },
  });

  const { data: jointOperators } = useQuery<QueriedVisibleJointOperators>(QUERY_VISIBLE_JOINT_OPERATORS, {
    client: cmsClient
  });

  const routes: SingleRouteWithProviderIds[] = useMemo(() => {

    var operatorRouteIdMap: Map<Number, Number[]> | undefined;
    if (jointOperators && providerId){
      operatorRouteIdMap = jointOperators.jointOperationsBuses.reduce((obj, val) => {
        const prevValue = obj.get(val.routeId);
        obj.set(val.routeId, prevValue ? [...prevValue, val.providerId] : [val.providerId]);
        return obj;
      },new Map<Number, Number[]>());
    }

    return QueriedRoutesHandlers.handleRaw(data, providerId, excludeOpTypes, operatorRouteIdMap);
  }, [JSON.stringify(data), JSON.stringify(jointOperators), providerId]);

  const selectorOptionsRoutes: RoutesSelectorOptions = useMemo(() => (
    getRoutesSelectorOptions(routes).sort(sortByAttr('seq'))
  ), [JSON.stringify(routes)]);

  return ({
    loading,
    routes,
    selectorOptionsRoutes,
  });
};

export default useQueryRoutes;