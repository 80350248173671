import { useEffect, useMemo } from 'react';
import useQueryRoutes from 'lib/customHooks/useQueryRoutes';
import { FareSearchPartContainerProps } from '../../types';
import useQueryStopsForFare,
{ HandledStationForFare } from 'components/fare/gql/custom-hooks/useQueryStopsForFare';
import { seperateData } from 'lib/seperateDataHelpers';
import verifyRouteId from 'lib/verifyRouteId';
import { GoBack } from 'bus-common-types';
import { getGoBackString, getReverseGoBackString } from 'constants/functions';
import { DepDestTabListProps } from 'components/common-components/TabComponents/types';
import { defaultDepDest } from 'components/common-components/TabComponents/DepDestTabList';
import useGoBackTab from 'lib/customHooks/useGoBackTab';
import filterRoutesByOptypeAndTaxi from 'lib/filterRoutesByOptypeAndTaxi';
import queryRouteIsCycled from './queryRouteIsCycled';
import { FareState } from 'components/fare/constant/context';

type UseFareSearchPartParams = FareSearchPartContainerProps


export const makeFromToStops = (stationsForFare: HandledStationForFare[], goBack: GoBack) => {
  const goBackStr = getGoBackString(goBack);
  const reversedGotBackStr = getReverseGoBackString(goBack);
  const dividedStops = seperateData.makeGoBackDataToObj(stationsForFare);
  const anotherWayStops = dividedStops[reversedGotBackStr];

  const fromStops = dividedStops[goBackStr];
  const toStops = dividedStops[goBackStr];

  const res = {
    from: fromStops,
    to: toStops,
    anotherWayStops,
  };
  return res;
};

export const convertCheckedToGoBack = (checked: boolean): GoBack => (
  checked ? 2 : 1
);
export const convertGoBackToChecked  = (goBack: GoBack): boolean => (
  goBack === 1 ? false : true
);

export const setFromToStopsAndQueryIsCycled = (setCallback: UseFareSearchPartParams['setFromToStopsToCtx']) => ({
  routeId, stationsForFare, goBack,
}: {
  goBack: GoBack
  stationsForFare: HandledStationForFare[]
  routeId: FareState['selectedRoute']['id']
}) => {
  const fromToStops = makeFromToStops(stationsForFare, goBack);
  // isCycled在塞進context之前才query
  (async function() {
    const isCycled = await queryRouteIsCycled(routeId);
    setCallback({
      ...fromToStops,
      isCycled,
    });
  } ());
};

const useFareSearchPart = (params: UseFareSearchPartParams) => {
  let depDest: DepDestTabListProps['depDest'] = defaultDepDest;

  const {
    resetFromToStopIdsToCtx,
    setFromToStopsToCtx,
    setRoutesToCtx,
    selectedRoute,
    setGoBackFn,
  } = params;

  const {
    goBack,
    selectedTab,
    handleResetSelectedTab,
    selectTab,
  } = useGoBackTab({
    initGoBack: 1,
    callback: setGoBackFn,
  });

  const {
    loading,
    routes,
  } = useQueryRoutes('all');
  const filteredRoutes = useMemo(() => filterRoutesByOptypeAndTaxi(routes), [routes]);

  const {
    data,
    stationsForFare,
    handleQuery,
  } = useQueryStopsForFare();

  useEffect(() => {
    setRoutesToCtx(filteredRoutes);
  }, [filteredRoutes, setRoutesToCtx]);

  useEffect(() => {
    if(verifyRouteId(selectedRoute.id) && selectedRoute.id) {
      handleQuery(selectedRoute.id);
    }
  }, [handleQuery, selectedRoute.id]);

  useEffect(() => {
    setFromToStopsAndQueryIsCycled(setFromToStopsToCtx)({
      routeId: selectedRoute.id,
      goBack,
      stationsForFare,
    });
  }, [goBack, selectedRoute.id, setFromToStopsToCtx, stationsForFare]);

  useEffect(() => {
    resetFromToStopIdsToCtx();
  }, [goBack, resetFromToStopIdsToCtx]);

  useEffect(() => {
    handleResetSelectedTab();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);

  const checked = convertGoBackToChecked(goBack);
  if(data) {
    depDest = {
      destination: {
        isDisplay: !!data.route.destination,
        location: data.route.destination
      },
      departure: {
        isDisplay: !!data.route.departure,
        location: data.route.departure
      },
    };
  }

  return ({
    goBack,
    checked,
    depDest,
    selectedTab,
    selectTab,
    loading,
  });
};

export default useFareSearchPart;