import useInput from "lib/customHooks/useInput";
import { useEffect } from "react";

export interface UseReportDescriptionInputOptions {
  onSetReportDescription: (desc: string) => any
}

const useReportDescriptionInput = ({
  onSetReportDescription,
}: UseReportDescriptionInputOptions) => {
  const inputState = useInput({
    onValueChanged: onSetReportDescription,
  });

  return ({
    ...inputState
  });
};

export default useReportDescriptionInput;