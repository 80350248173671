import { Callback } from "all-common-types";

export interface UploadFetchOptions {
  uploadApiUrl: string
  request?: RequestInit
  onBeforeUploadCheck?: (params?: any) => boolean
  onUploadFailed?: Callback
  onUploadSuccess?: Callback
  onUploadFinnished?: Callback
}

const uploadFetch = ({
  uploadApiUrl,
  request,
  onBeforeUploadCheck,
  onUploadSuccess,
  onUploadFailed,
  onUploadFinnished,
}: UploadFetchOptions) => {
  const fetchFn = () => {
    return fetch(uploadApiUrl, request)
      .then(res => res.json())
      .then(res => {
        // console.log(res);
        if(!res.error) {
          onUploadSuccess && onUploadSuccess(res);
        } else {
          onUploadFailed && onUploadFailed(res);
        }
        return res;
      })
      .finally(() => {
        onUploadFinnished && onUploadFinnished();
      })
      .catch(e => {
        onUploadFailed && onUploadFailed(e);
      });
  };

  if(onBeforeUploadCheck) {
    if(onBeforeUploadCheck()) {
      return fetchFn();
    }
  } else {
    return fetchFn();
  }
};

export default uploadFetch;