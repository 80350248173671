import React from 'react';
import RouteStopsAndRouteLine, { RouteStopsAndRouteLineProps } from 'components/driving-map/components/BusMap/RouteStopsAndRouteLine';
import ControlsInMap from 'components/driving-map/components/BusMap/ControlsInMap';
import { BusMarkerListProps, ControlsInMapProps } from '../types';
import BusMarkerListContainer from 'components/driving-map/containers/BusMap/BusMarkerListContainer';

export type MapContentProps = ControlsInMapProps & RouteStopsAndRouteLineProps & BusMarkerListProps

const MapContent = ({ displayTimeTableFn, ...props }: MapContentProps) => {
  return (
    <>
      <RouteStopsAndRouteLine {...props} />
      <BusMarkerListContainer {...props} />
      <ControlsInMap 
        {...props}
        displayTimeTableFn={displayTimeTableFn} />
    </>
  );
};

export default MapContent;