import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ContextWrapper } from './constants/context';
import { useParams } from 'react-router';
import PageWrapper from 'components/common-components/wrappers/PageWrapper';
import { client } from 'constants/API';
import RouteMapContentPartContainerWithCtx from './containers/RouteMapContentPartContainer/RouteMapContentPartContainerWithCtx';

const RouteMapPage = () => {
  const {
    xno,
  } = useParams<{xno: string}>();

  return (
    <ApolloProvider client={client}>
      <ContextWrapper customInitState={{ routeId: xno }}>
        <PageWrapper>
          <RouteMapContentPartContainerWithCtx />
        </PageWrapper>
      </ContextWrapper>
    </ApolloProvider>
  );
};

export default RouteMapPage;