import { useLazyQuery } from "@apollo/react-hooks";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { QUERY_ROUTE_MAP_LIST_PAGE_DATA, QueriedRouteMapListPageData } from "../gql-schemas";
import { client } from "constants/API";

function useQueryRouteMapListData() {
  const {
    locale,
  } = useIntl();

  const [queryFn, { error, loading, data }] = useLazyQuery<QueriedRouteMapListPageData>(QUERY_ROUTE_MAP_LIST_PAGE_DATA, {
    client: client,
  });

  const handleQuery = useCallback(() => {
    queryFn({
      variables: {
        lang: locale,
      }
    });
  }, [locale, queryFn]);

  return ({
    error,
    loading,
    data,
    handleQuery,
  });
};

export default useQueryRouteMapListData;