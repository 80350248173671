import { DepDest, GoBack } from "bus-common-types";
import { DepDestTabListProps } from "components/common-components/TabComponents/types";
import { DepDestWithInitGoBack } from "components/stop-report-page/containers/StopReportInputPart/types";
import { QueriedRotueInfoForStopReport, SingleStationForStopReport } from "components/stop-report-page/gql/gql-schemas";
import getDepDestByGoBack from "lib/getDepDestByGoBack";
import getInitGoBackFromGoBackDisplay from "lib/getInitGoBackFromGoBackDisplay";
import { MakeCreateStopReportDataOptions, StopReportSingleStopSelectorOption } from "stop-report";

export interface SingleStopSelectorOptionWithGoBack extends StopReportSingleStopSelectorOption {
  goBack: GoBack
}

const StopReportHandlers = {
  makeCreateData(options: MakeCreateStopReportDataOptions) {
    return options;
  },

  makeSingleStopOption(station: SingleStationForStopReport): SingleStopSelectorOptionWithGoBack {
    const stationId = station.node.id;
    const stationName = station.node.name;

    return ({
      text: stationName,
      value: String(stationId),
      goBack: station.goBack,
      metaData: {
        stationId: Number(stationId),
        stationName,
        stopName: stationName,
        stopId: station.sid,
      },
    });
  },
  handleStopOptionsFromRawData(rawData: QueriedRotueInfoForStopReport | undefined): SingleStopSelectorOptionWithGoBack[] {
    if(!rawData) {
      return [];
    }
    else {
      const stations = rawData.route.stations.edges;
      const res = stations.map(s => (
        this.makeSingleStopOption(s)
      ));
      return res;
    }
  },

  getDepDestForGoBackButton(depDest: DepDest, stops: { goBack: GoBack }[]) {
    const isHaveGoStops = stops.find(s => s.goBack === 1);
    const isHaveBackStops = stops.find(s => s.goBack === 2);

    const isGoTabDisplay = !!isHaveGoStops;
    const isBackTabDisplay = !!isHaveBackStops;

    const initGoBack = getInitGoBackFromGoBackDisplay(isGoTabDisplay, isBackTabDisplay);

    const res: DepDestWithInitGoBack = {
      departure: { 
        location: depDest.departure, 
        isDisplay: isBackTabDisplay,
      },
      destination: { 
        location: depDest.destination, 
        isDisplay: isGoTabDisplay,
      },
      initGoBack,
    };
    return res;
  },
  getDepDest(depDest: DepDestTabListProps['depDest'], goBack: GoBack) {
    const _depDest = {
      departure: depDest.departure.location,
      destination: depDest.destination.location,
    };
    const depDestname = getDepDestByGoBack(_depDest, goBack);

    return `往 ${depDestname}`;
  },
};

export default StopReportHandlers;