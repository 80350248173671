import React from "react";
import { Box } from "@material-ui/core";
import { Map as LeafletMap } from "react-leaflet";
import MarkersWithRouteLine, {
  MarkersWithRouteLineProps,
} from "./MarkersWithRouteLine";
import { useStyles as useStyles_map } from "components/driving-map/components/BusMap/styles/BusMapPart";
import { defaultMapCenter } from "config";
import ControlsInMap from "components/driving-map/components/BusMap/ControlsInMap";
import { ControlsInMapProps } from "components/driving-map/components/BusMap/types";
import TOGSTileLayer from "../../../tgos/TGOSTileLayer";
import { TimeTableWithToggle } from "components/driving-map/components/BusMap/TimeTable";
import { nodeDataFromEdges } from "constants/functions";
import { IntervalSearchMapHandlersRawRouteDataParams } from "components/collinear-routes/containers/mapPartContainers/functions/IntervalSearchMapHandlers";

export interface MapPartProps extends MarkersWithRoutelineAndControlsProps, Pick<IntervalSearchMapHandlersRawRouteDataParams, 'queried'> {
  toggledDisplay: boolean;
}

type MarkersWithRoutelineAndControlsProps = ControlsInMapProps & {
  markersAndRoutePoints: MarkersWithRouteLineProps | null;
};

export const MarkersWithRoutelineAndControls = (
  props: MarkersWithRoutelineAndControlsProps
) => {
  const { markersAndRoutePoints } = props;
  if (markersAndRoutePoints) {
    return (
      <>
        <MarkersWithRouteLine {...markersAndRoutePoints} />
        <ControlsInMap
          {...props}
          controlButtonDisplayOption={{
            isClogButtonDisplay: false,
            isTimeTableButtonDisplay: true,
          }}
        />
      </>
    );
  }
  return null;
};

const MapPart = (props: MapPartProps) => {
  const {
    markersAndRoutePoints,
    queried: {
      routeData,
    }
  } = props;
  const classes = useStyles_map();

  const bounds = markersAndRoutePoints
    ? markersAndRoutePoints.routePoints
    : undefined;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      className={classes.root}
    >
      <LeafletMap center={defaultMapCenter} bounds={bounds} zoom={14}>
        <TOGSTileLayer opacity={0.4} />
        <MarkersWithRoutelineAndControls
          {...props}
          markersAndRoutePoints={markersAndRoutePoints}
        />
      </LeafletMap>
      {routeData && (
        <TimeTableWithToggle
          {...props}
          toggleFn={props.displayTimeTableFn}
          routeDataForTimeTable={{
            ...routeData,
            providers: nodeDataFromEdges(routeData.providers),
          }}
        />
      )}
    </Box>
  );
};

export default MapPart;
