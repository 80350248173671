import React from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Variant as ThemeVariant } from '@material-ui/core/styles/createTypography';
import Link from '@material-ui/core/Link';
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import {TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import remarkGfm from 'remark-gfm';

type Variant = ThemeVariant | 'srOnly';

const useStyles = makeStyles((theme) => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
  header: {
    marginTop: theme.spacing(2),
  }
}));

function MarkdownParagraph(props: any) {
  return <Typography>{props.children}</Typography>;
}

const MarkdownHeading = (props: any) => {
  const classes = useStyles();
  let variant: Variant;
  switch (props.level) {
    case 1:
      variant = "h4";
      break;
    case 2:
      variant = "h5";
      break;
    case 3:
      variant = "h6";
      break;
    case 4:
      variant = "subtitle1";
      break;
    default:
      variant = "subtitle2";
      break;
  }
  return <Typography className={classes.header} gutterBottom variant={variant}>{props.children}</Typography>;
};

const MarkdownListItem = (props: any) => {
  const classes = useStyles();
  return (
    <li className={classes.listItem}>
      <Typography component="span">{props.children}</Typography>
    </li>
  );
};

function MarkdownTable(props: any) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">{props.children}</Table>
    </TableContainer>
  );
}

function MarkdownTableCell(props: any) {
  return <TableCell><Typography>{props.children}</Typography></TableCell>;
}

function MarkdownTableRow(props: any) {
  return <TableRow>{props.children}</TableRow>;
}

function MarkdownTableBody(props: any) {
  return <TableBody>{props.children}</TableBody>;
}

function MarkdownTableHead(props: any) {
  return <TableHead>{props.children}</TableHead>;
}

function FitImage(props: any) {
  return <img {...props} style={{ maxWidth: "100%" }} alt={""} />;
}

const wrapComponents = {
  heading: MarkdownHeading,
  paragraph: MarkdownParagraph,
  link: Link,
  linkReference: Link,
  listItem: MarkdownListItem,
  table: MarkdownTable,
  tableHead: MarkdownTableHead,
  tableBody: MarkdownTableBody,
  tableRow: MarkdownTableRow,
  tableCell: MarkdownTableCell,
  image: FitImage,
};

export default ({ components , ...props}: any) =>
  <ReactMarkdown
    allowDangerousHtml={true}
    plugins={[remarkGfm]}
    renderers={{...wrapComponents, ...components}} {...props}
  />;