import React from 'react';
import { GridSize } from '@material-ui/core/Grid';
import { ID } from 'all-common-types';
import replaceAllWithVariables from 'lib/replaceAllWithVariables';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

export const TIMETABLE_SCHEDULE_DISPLAY_CAR_TYPE = 'dsby';

export const NEAR_MRT_RADIUS = 200;
export const NEAR_THSR_RADIUS = 500;

export const SPECIAL_PROVIDER_IDS = {
  uniBus: 3,
  chungTaiwanBus: 15,
}; // 統聯, 中台灣，只顯示統聯

export const TAXI_ROUTE_IDS = [...Array(8)].map((a, index) => (2501 + index));
export const TAXI_PROVIDER_IDS = [25, 8100]; //test server is 25, production is 8100

export const ROUTE_LIST_ITEM_TEXTS_WIDTH_RATIO: GridSize[] = [1, 2, 5, 2, 2];

export const ROUTE_LIST_ITEM_HEADER_TEXTS_WIDTH_RATIO: GridSize[] = [1, 2, 5, 2, 2];
export const ROUTE_LIST_ITEM_HEADER_TEXTS = [
  (
    <IntlFormattedMessage
      langKey={'stopSeq'}
    />
  ),
  (
    <IntlFormattedMessage
      langKey={'stopId'}
    />
  ),
  (
    <IntlFormattedMessage
      langKey={'stopName'}
    />
  ),
  // 'ibike/TMRT',
  '',
  (
    <IntlFormattedMessage
      langKey={'esimateTime'}
    />
  )
];

export const BUS_ICON_WIDTH = 30;
// export const IMAGE_BUS_ICON = 'directions_bus-24px.svg';
export const IMAGE_BUS_ICON = 'bus-icons.png';

export const ROUTE_LIST_PART_ZOOM_LS_KEY = 'ROUTE_LIST_PART_ZOOM';