import moment from 'moment';
import { useIntl } from "react-intl";

export default (duration: number) => {
  const { formatMessage } = useIntl();
  const [hour, min] = moment("2015-01-01").startOf('day')
    .seconds(duration)
    .format('H:m')
    .split(':');

  let msg = '';
  if (parseInt(hour) > 0) {
    msg += `${hour} ${formatMessage({id: 'travelPlanner.planPart.hour'})} `;
    msg += `${min} ${formatMessage({id: 'travelPlanner.planPart.minute'})} `;
  }else{
    msg += `${parseInt(min) > 0 ? min : 1} ${formatMessage({id: 'travelPlanner.planPart.minute'})} `;
  }
  return msg;
};