import { RouteMapLinkItemProps } from "components/route-map-list/components/RouteMapListPart/types";
import { QueriedRouteMapListPageData, SingleRouteInQueriedRouteMapListPageData } from "components/route-map-list/gql/gql-schemas";
import { ProvidersIdNameHashMap } from "lib/busProviders/getProvidersIdNameHashMap";
import getProvidersIdNameHashMap from 'lib/busProviders/getProvidersIdNameHashMap';
import { nodeDataFromEdges } from "constants/functions";
import { sortByAttr } from "lib/fn";
import filterDataListByOpType from "lib/busProviders/filterDataListByOpType";
import filterRoutesByTaxiProvider from "components/route-map/lib/functions/filterRoutesByTaxiProvider";

const RouteMapListPageHandlers = {
  makeSingleRouteMapData(
    route: SingleRouteInQueriedRouteMapListPageData,
    providerHash: ProvidersIdNameHashMap,
  ): RouteMapLinkItemProps {
    const providers = nodeDataFromEdges(route.providers);
    const providerId = Number(providers[0].id);
    const providerName = providerHash[providerId] || ''; // if not matched

    const res: RouteMapLinkItemProps = {
      routeId: route.id,
      routeName: route.name,
      routeDescription: route.description,
      providerName,
    };
    return res;
  },

  getRouteMapListDataFromRawData(rawData: QueriedRouteMapListPageData) {
    const {
      routes, providers,
    } = rawData;

    const handledRoutes = nodeDataFromEdges(routes)
      .map(r => ({
        ...r,
        providerIds: nodeDataFromEdges(r.providers).map(p => Number(p.id))
      }))
      .sort(sortByAttr('seq'));
    const removedTaxiRoutes = filterRoutesByTaxiProvider(handledRoutes);
    const filterdRoutes = filterDataListByOpType(removedTaxiRoutes);

    const providersIdNameHashMap = getProvidersIdNameHashMap(
      nodeDataFromEdges(providers)
    );

    const res = filterdRoutes.map((r) => (
      this.makeSingleRouteMapData(r, providersIdNameHashMap)
    ));
    // console.log(removedTaxiRoutes);
    return res;
  },

  checkRouteMapListNameByValue(route: RouteMapLinkItemProps, value: string) { // 用關鍵字搜尋路線
    if(route.routeDescription.includes(value) || route.routeName.includes(value)) {
      return true;
    }
    return false;
  },

  filterRouteMapListDataByValue(routes: RouteMapLinkItemProps[], value: string) {
    if(!value) {
      return routes;
    }
    const res = routes.filter(r => (
      this.checkRouteMapListNameByValue(r, value)
    ));
    return res;
  },
};

export default RouteMapListPageHandlers;