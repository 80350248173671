import { ID } from "all-common-types";
import { GoBack } from "bus-common-types";
import { BUS_309_ROUTE_ID, BUS_310_ROUTE_ID, MRT_GREEN_ROUTE_ID } from "components/mrt-fare/config";
import { SingleQueriedStationForMrtAndBus } from "components/mrt-fare/gql/fragments.gql";
import { QueriedMrtStations } from "components/mrt-fare/gql/schema.gql";
import reverseArrData from "lib/functions/reverseArrData";
import { SingleBasicStationForMrtAndBus } from "mrt-pages";


export type HandledStationsKeys =
  'route309OtherStations' |
  'route310OtherStations' |
  'commonBusStations' |
  'mrtGreenStations'
export type HandledStations = {
  [x in HandledStationsKeys]: SingleBasicStationForMrtAndBus[]
}
export const initHandledStations: HandledStations = {
  route309OtherStations: [],
  route310OtherStations: [],
  commonBusStations: [],
  mrtGreenStations: [],
};

const MrtStationsCombineHandlers = {
  getStationsFromQueried(stations: SingleQueriedStationForMrtAndBus[], goBack=1 as GoBack) {
    return (routeId: ID) => {
      let res = stations
        .filter(s => s.goBack === goBack)
        .map(s => this.makeSingleBasicStation(s, routeId));

      res = (goBack === 2 && routeId !== MRT_GREEN_ROUTE_ID) ?
        reverseArrData(res) : res; // reverse back stations(only bus routes)
      return res;
    };
  },

  makeSingleBasicStation(queriedStation: SingleQueriedStationForMrtAndBus, routeId: ID): SingleBasicStationForMrtAndBus {
    return ({
      routeId: Number(routeId),
      stationId: Number(queriedStation.node.id), // 統一用sid作為stationId
      stationName: queriedStation.node.name,
      content: '',
      nearTransportations: [],
    });
  },

  splitCommonStations(stations: {
    route309: SingleBasicStationForMrtAndBus[]
    route310: SingleBasicStationForMrtAndBus[]
  }): Omit<HandledStations, 'mrtGreenStations'> {
    const {
      route309: origin309, route310: origin310,
    } = stations;

    let first309MatchedStationIndex = 0;
    let matchedRoute310StationIndex = 0;

    for (let i = 0; i < origin309.length; i++) {
      const station = origin309[i];
      matchedRoute310StationIndex = origin310
        .findIndex(s => s.stationId === station.stationId);

      if(matchedRoute310StationIndex !== -1) {
        first309MatchedStationIndex = i;
        break;
      }
    }

    const route309OtherStations = origin309.slice(0, first309MatchedStationIndex);
    const route310OtherStations = origin310.slice(0, matchedRoute310StationIndex);
    const commonBusStations = origin309.slice(first309MatchedStationIndex);

    return ({
      route309OtherStations,
      route310OtherStations,
      commonBusStations,
    });
  },

  handleRawData(rawData: QueriedMrtStations | undefined, goBack=1 as GoBack): HandledStations {
    if(!rawData) {
      return initHandledStations;
    }

    const originRoute309 = this.getStationsFromQueried(rawData.route309.stations.edges, goBack)(BUS_309_ROUTE_ID);
    const originRoute310 = this.getStationsFromQueried(rawData.route310.stations.edges, goBack)(BUS_310_ROUTE_ID);
    const mrtGreenStations = this.getStationsFromQueried(rawData.mrtGreen.stations.edges, 2)(MRT_GREEN_ROUTE_ID);

    const {
      route309OtherStations,
      route310OtherStations,
      commonBusStations,
    } = this.splitCommonStations({
      route309: originRoute309,
      route310: originRoute310,
    });

    return ({
      route309OtherStations,
      route310OtherStations,
      commonBusStations,
      mrtGreenStations,
    });
  }
};

export default MrtStationsCombineHandlers;