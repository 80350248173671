import React, {  } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import StopReportInputPartContainer from './containers/StopReportInputPart/StopReportInputPartContainer';
import PreviewPart from './components/UploadPart/PreviewPart';
import UploadForm from './components/UploadPart/UploadForm';
import useStopReportPage from './lib/functions/useStopReportPage';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from 'constants/API';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import WarningMessage from './components/StopReportInputPart/WarningMessage';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display:'flex',
    flexDirection:'column',
    flexGrow:1,
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      height: '85vh'
    }
  },
  root: {
    height: 1,
    overflow: 'auto',
    maxWidth: 700,
    width: '100%',
    background: theme.background.surface,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  }
}));

const StopReportPage = () => {
  const classes = useStyles();
  const stopReportPageState = useStopReportPage();

  return (
    <ApolloProvider client={client}>
      <Container className={classes.wrapper}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          flexGrow={1}
          className={classes.root}>
          <IntlFormattedMessage
            langKey={'stopReport.pageTitle'}
            variant={'h4'}
          />
          <StopReportInputPartContainer
            optionsErr={stopReportPageState.optionsErr}
            onGetOptions={stopReportPageState.setOptions}
          />
          <UploadForm
            {...stopReportPageState}
            boxRef={stopReportPageState.dropElRef}
            isUploading={stopReportPageState.isUploading}
            isUploadAvailble={stopReportPageState.isUploadAvailble}
            onChangeFiles={stopReportPageState.handleChangeFiles}
            onSubmit={stopReportPageState.handleSubmit}
          >
            <PreviewPart previewImageList={stopReportPageState.previewFileList} />
          </UploadForm>
          <WarningMessage />
        </Box>
      </Container>
    </ApolloProvider>
  );
};

export default StopReportPage;