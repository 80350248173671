import React from 'react';
import { Box } from '@material-ui/core';
import { SideStationListProps } from './types';
import MrtStationItem from '../../common/MrtStationItem';
import StationList from '../../common/StationList';

const SideStationList = (props: SideStationListProps) => {
  return (
    <StationList 
      {...props}
      stationType={'BUS'}
      colorKey={'BUS'}
    />
  );
};

export default SideStationList;