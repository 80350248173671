import { Reducer } from "all-common-types";
import CommonStateTypes, { initCommonState } from "reducers/common-reducers/state";
import IbusCommonActionTypes, { IbusCommonActions } from 'actions/common-actions';

type BusProviderIdReducer = Reducer<CommonStateTypes, IbusCommonActions, CommonStateTypes['busProviderId']>

export const busProviderId: BusProviderIdReducer = (state, action) => {
  switch (action.type) {
    case IbusCommonActionTypes.SET_BUS_PROVIDER_ID: {
      return action.payload;
    }
    case IbusCommonActionTypes.RESET_BUS_PROVIDER_ID: {
      return initCommonState.busProviderId;
    }
    default:
      return state.busProviderId;
  }
};