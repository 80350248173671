import React, { ChangeEvent, ReactNode } from "react";
import { Box } from "@material-ui/core";
import Selector from "./Selector";
import { SingleSelectOption } from "all-common-types";
import BusRoutesSelectsContainerWithCtx from "components/driving-map/containers/BusSelect/BusRoutesSelectsContainer";

const selectorsWidthArr = ["30%", "70%"];

export type SelectorName = "selectType" | "selectGroup";
export type CityBusPartSelectsValues = {
  [x in SelectorName]: {
    name: ReactNode;
    value: string;
    options: SingleSelectOption[];
  };
};

export type CityBusPartChangeFn = (
  name: SelectorName
) => (e: ChangeEvent<{ value: unknown }>) => any;
type Props = {
  selectsValues: CityBusPartSelectsValues;
  changeFn: CityBusPartChangeFn;
  routeIdsForFilter: string[];
};

export const CityBusPart = (props: Props) => {
  const { selectsValues, routeIdsForFilter } = props;

  const ids = Object.keys(selectsValues) as (keyof CityBusPartSelectsValues)[];

  return (
    <Box>
      <Box
        // paddingTop={1}
        display={"flex"}
        alignItems={"flex-end"}
      >
        {ids.map((id, i) => {
          const value = selectsValues[id];
          return (
            <Selector
              key={id}
              id={id}
              label={value.name}
              minWidth={selectorsWidthArr[i]}
              options={value.options}
              value={value.value}
              changeFn={props.changeFn(id)}
            />
          );
        })}
      </Box>
      <BusRoutesSelectsContainerWithCtx
        busType={"city"}
        routeIdsForFilter={routeIdsForFilter}
      />
    </Box>
  );
};

export default CityBusPart;
