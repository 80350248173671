import combineReducers from 'constants/combineReducers';
import { DrivingMapState } from '../constants/context';
import { routeData } from './routeLists_reducers';
import { routeStopId } from './routeListItem_reducers';
import { selectedRoute } from 'reducers/common-reducers/selectedRoute-reducers';
import { busProviderId } from 'reducers/common-reducers/busProviderId-reducers';
import ibikeInfoList from './ibikeInfoList-reducers';

export * from './routeListItem_reducers';
export * from './routeLists_reducers';

const reducers = combineReducers<DrivingMapState>({
  selectedRoute: selectedRoute,
  selectedRouteStopIndex: routeStopId,
  routeData: routeData,
  busProviderId: busProviderId,
  ibikeInfoList: ibikeInfoList,
});

export default reducers;