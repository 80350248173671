import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import ibusImages from 'static/ibusImages.json';
import ImageLink from 'components/common-components/ImageComponents/ImageLink';
import moment from 'moment';

const href = 'https://docs.google.com/forms/d/1gAuNCf7YCYk3jz_v8x8dwBBYY_7RT9Ldlz9H0v51qRg/viewform?edit_requested=true';

const useStyles = makeStyles(theme => ({
  root: {
    opacity: 0.75,
    borderRadius: theme.spacing(0.5),
    transition: '0.2s',
    overflow: 'hidden',
    '&:hover': {
      opacity: 0.9,
      transition: '0.2s',
    }
  }
}));

const checkAvailable = () => {
  const onShelfAt = moment('2021/10/28', 'YYYY/MM/DD');
  const offShelfAt = moment('2021/11/12', 'YYYY/MM/DD').add(1,'d').add(-1,'s');
  const now = moment();
  return now.isBetween(onShelfAt, offShelfAt);
};

const GoldenDriverImageLink = () => {
  const classes = useStyles();
  if (!checkAvailable()){
    return null;
  }

  return (
    <Box
      padding={0.5}
      paddingTop={1}
      maxWidth={128 * 2}
    >
      <ImageLink
        className={classes.root}
        imgSrc={ibusImages.homepage_golden_image_link.src}
        alt={ibusImages.homepage_golden_image_link.name}
        isTargetBlank={true}
        href={href}
      />
    </Box>
  );
};

export default GoldenDriverImageLink;