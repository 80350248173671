import { SingleSelectOption } from "all-common-types";
import { FareState } from "components/fare/constant/context";
import { SelectFromToContainerProps } from "../types";
import { SingleStationForFareWithGoBack } from "components/fare/gql/gql-schema";

export const getSelectorOptionFromStop = (station: SingleStationForFareWithGoBack): SingleSelectOption => {
  return ({
    ...station,
    // 用sid作為票價選項的唯一value，所以有可能會有雙重被選
    value: String(station.id),
    text: station.name,
  });
};

export const handleFromToStopsFromToSelectorOptions = (
  fromToStops: FareState['fromToStops'],
  isToggledChangeOptions: boolean
) => {
  if(isToggledChangeOptions) {
    return fromToStops.anotherWayStops.map(s => getSelectorOptionFromStop(s));
  }
  // from, to在原始資料上沒區別
  return fromToStops.from.map(s => getSelectorOptionFromStop(s));
};