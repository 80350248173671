/* eslint-disable */
import React, { useContext } from 'react';
import ContextStore, { RouteDataWithBusInstantInfoList, DrivingMapState } from 'components/driving-map/constants/context';
import { GoBack, Position } from 'bus-common-types';
import { defaultMapCenter } from 'config';
import { getGoBackRouteStopMarkersAndPoints } from './functions/busMapPart';
import { BusMapBasicProps } from './BusMapPart/BusMapWrapper';
import { BusMapWithNoRoute, BusMapWithRoute } from './BusMapPart/index';
import { MapStateToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import connectCtx from 'lib/contextReduxLikeHelpers/connectCtx';


export type BusMapPartProps = BusMapBasicProps & {
  routeData: DrivingMapState['routeData']
}
export const BusMapPart = (props: BusMapPartProps) => {
  const {
    routeData,
  } = props

  return (
    <>
      {routeData ? (
        <BusMapWithRoute
          {...props}
          routeData={routeData}
          routeStopMarkersAndPoints={getGoBackRouteStopMarkersAndPoints(routeData)} />
      ) : (
        <BusMapWithNoRoute />
      )}
    </>
  )
}

type ContextedBusMapPartProps = {
  position?: Position,
  goBack: GoBack
}

type M = MapStateToProps<DrivingMapState, ContextedBusMapPartProps, {
  selectedRouteStopIndex: DrivingMapState['selectedRouteStopIndex']
  routeData: DrivingMapState['routeData']
  position: Position
  selectedRoute: DrivingMapState['selectedRoute']
}>
const mapStateToProps: M = (state) => ({
  selectedRouteStopIndex: state.selectedRouteStopIndex,
  routeData: state.routeData,
  position: defaultMapCenter,
  selectedRoute: state.selectedRoute,
})

export const ContextedBusMapPart = connectCtx(ContextStore)(mapStateToProps)(BusMapPart)

export default ContextedBusMapPart;