import { Callback } from "all-common-types";
import { useCallback, useRef } from "react";

interface UseDelayCallbackOptions {
  delayTime?: number
  // callback?: Callback
}

export const initOptions = {
  delayTime: 10
};

const useDelayCallback = (options: UseDelayCallbackOptions) => {
  const {
    delayTime=initOptions.delayTime,
    // callback,
  } = options;

  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleClearTimeout = useCallback(() => {
    if(timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  const handleDelayedCallback = useCallback((callback?: Callback) => {
    //clear prev timeout first
    handleClearTimeout();
    
    if(!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        //console.log('callback from timeout' + `${delayTime}`);
        callback && callback();
      }, delayTime) as any;
    }
  }, [delayTime, handleClearTimeout]);

  return ({
    handleDelayedCallback,
  });
};

export default useDelayCallback;