import React, {  } from 'react';
import { Box, Button } from '@material-ui/core';
import StopSearchInputItemContainer from './StopSearchInputItemContainer';
import RoutesFilterSelector, { RoutesFilterSelectorProps } from '../../components/SearchPart/RoutesFilterSelector';
import ContextStore, { SelectedRouteId, CollinearRoutesState } from '../../constants/context';
import { setRouteId } from '../../actions/search-actions';
import { MapStateToProps, MapDispatchToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import { connectCtx } from 'react-function-helpers';
import useStopSearchContainer from '../functions/useStopSearchContainer';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';
import IntlSearchButton from 'components/common-components/Buttons/IntlSearchButton';


export type QueryRouteInfoFn = (station: CollinearRoutesState['selectedFromStation'], routeId: SelectedRouteId) => any
interface StopSearchContainerProps extends Omit<RoutesFilterSelectorProps, 'options'> {
  queryRouteInfoFn: QueryRouteInfoFn
  selectedRouteId: CollinearRoutesState['selectedRouteId']
}

export const StopSearchContainer = (props: StopSearchContainerProps) => {
  const {
    stopSearchInputItemContainerRef,
    handleQueryRoutesByStationIds,
    routesSelectorOptions,
    handleSearch,
  } = useStopSearchContainer(props);
  
  return (
    <Box
      paddingBottom={1}
    >
      <StopSearchInputItemContainer
        getNameValueResultsWhenChanged={handleQueryRoutesByStationIds}
        stopSearchInputItemContainerRef={stopSearchInputItemContainerRef} />
      <RoutesFilterSelector 
        {...props}
        options={routesSelectorOptions} />
      <Box
        textAlign={'right'}
      >
        <IntlSearchButton
          onSearch={handleSearch}
        />
      </Box>
    </Box>
  );
};


export type StopSearchContainerWithCtxProps = Omit<StopSearchContainerProps, 'selectedRouteId'>

export const mapStateToProps: MapStateToProps<CollinearRoutesState, StopSearchContainerWithCtxProps, {
  selectedRouteId: CollinearRoutesState['selectedRouteId']
}> = (state) => {
  return ({
    selectedRouteId: state.selectedRouteId,
  });
};

export const mapDispatchToProps: MapDispatchToProps<StopSearchContainerWithCtxProps, {
  getSelectedOptionFn: RoutesFilterSelectorProps['getSelectedOptionFn']
}> = (dispatch) => {
  return ({
    getSelectedOptionFn: (selectedRoute) => {
      const action = setRouteId(selectedRoute.value);
      dispatch(action);
    }
  });
};

const StopSearchContainerWithCtx = connectCtx(ContextStore)(mapStateToProps, mapDispatchToProps)(StopSearchContainer);

export default StopSearchContainerWithCtx;