import { EdgesData, ID } from "all-common-types";
import { SingleSchedule, GoBack } from "bus-common-types";
import gql from "graphql-tag";

const QUERY_STATIONS_NAMES_BY_VALUE = gql`
  query QUERY_STATIONS_NAMES_BY_VALUE($station: String!, $lang: String!) {
    stationsByName(name: $station, lang: $lang) {
      edges {
        node {
          id
          name
          lat
          lon
          county
          town
        }
      }
    }
  }`;
interface SingleQueriedStationByName {
  id: ID
  name: string
  lat: number
  lon: number
  county: string
  town: string
}
interface QueriedStationsByName {
  stationsByName: EdgesData<SingleQueriedStationByName>
}

export const QUERY_DAILY_TIME_TABLE = gql`
  query QUERY_DAILY_TIME_TABLE($routeId: Int!, $date: String) {
    dailyTimeTable(xno: $routeId, date: $date) {
      edges {
        node {
          xno
          id
          goBack
          scheduleTime
          carType
          carId
        }
      }
    }
  }
`;
interface DailyTimeTableNode extends Omit<SingleSchedule, 'rawCarType'> {

}
interface QueriedDailyTimeTable {
  dailyTimeTable: EdgesData<DailyTimeTableNode>
}

export const QUERY_SCHEDULE = gql`
  query QUERY_SCHEDULE($routeId: Int!) {
    schedule(xno: $routeId) {
      edges {
        node {
          id
          goBack
          scheduleTime
        }
      }
    }
  }
`;

export interface ScheduleNode {
  id: string;
  goBack: GoBack;
  scheduleTime: string;
}

interface QueriedSchedule{
  schedule: EdgesData<ScheduleNode>
}

const NEW_SCHEMA_API_SCHEMAS = {
  QUERY_STATIONS_NAMES_BY_VALUE,
  QUERY_DAILY_TIME_TABLE,
  QUERY_SCHEDULE,
};

export interface NewSchemaApiTypes {
  SingleQueriedStationByName: SingleQueriedStationByName
  QueriedStationsByName: QueriedStationsByName
  DailyTimeTableNode: DailyTimeTableNode
  QueriedDailyTimeTable: QueriedDailyTimeTable
  QueriedSchedule: QueriedSchedule
}

export default NEW_SCHEMA_API_SCHEMAS;