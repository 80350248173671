import gqlFragments, { StationsByNameFragment } from "gql/fragments.gql";
import gql from "graphql-tag";

const QUERY_FROM_TO_STATIONS_BY_NAME = gql`
  query QUERY_FROM_TO_STATIONS_BY_NAME($fromStationName: String!, $toStationName: String!, $lang: String!) {
    fromStations: stationsByName(name: $fromStationName, lang: $lang) {
      ...stationsByNameFragment
    }
    toStations: stationsByName(name: $toStationName, lang: $lang) {
      ...stationsByNameFragment
    }
  }
  ${gqlFragments.stationsByNameFragment}
`;

export interface QueriedFromToStationsByName {
  fromStations: StationsByNameFragment
  toStations: StationsByNameFragment
}

export default QUERY_FROM_TO_STATIONS_BY_NAME;