import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { MapDispatchToProps, MapStateToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import SelectRoutesContainer from './SelectRoutesContainer';
import SelectFromToContainer from './SelectFromToContainer';
import { setRoutes } from 'components/fare/actions/fare-actions';
import ContextStore, { FareState } from 'components/fare/constant/context';
import { FareSearchPartContainerProps, FareSearchPartContainerDispatchesFromCtx, FareSearchPartContainerWithCtxProps } from '../types';
import { connectCtx } from 'react-function-helpers';
import { setFromToStops, resetFromToStopsId } from 'components/fare/actions';
import useFareSearchPart from './functions/useFareSearchPart';
import GoBackButtons from 'components/fare/components/SearchPart/GoBackButtons';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  }
}));

export const FareSearchPartContainer = (props: FareSearchPartContainerProps) => {
  const classes = useStyles();

  const {
    depDest,
    selectedTab,
    selectTab,
    loading,
  } = useFareSearchPart(props);

  if(loading) return (
    <CircularProgress />
  );

  return (
    <Box className={classes.root}>
      <SelectRoutesContainer />
      <GoBackButtons
        depDest={depDest}
        selectedTab={selectedTab}
        selectTabFn={selectTab}
      />
      <SelectFromToContainer />
    </Box>
  );
};

interface OwnProps extends FareSearchPartContainerWithCtxProps {}

export const mapStateToProps: MapStateToProps<FareState, OwnProps, {
  selectedRoute: FareState['selectedRoute']
}> = (state) => {
  return ({
    selectedRoute: state.selectedRoute,
  });
};

export const mapDispatchToProps: MapDispatchToProps<OwnProps, FareSearchPartContainerDispatchesFromCtx> = (dispatch) => {
  return ({
    setRoutesToCtx: (routes: FareState['routes']) => {
      const action = setRoutes(routes);
      dispatch(action);
    },
    setFromToStopsToCtx: (fromToStops) => {
      const action = setFromToStops(fromToStops);
      dispatch(action);
    },
    resetFromToStopIdsToCtx: () => {
      const action = resetFromToStopsId();
      dispatch(action);
    }
  });
};

const FareSearchPartContainerWithCtx = connectCtx(ContextStore)(mapStateToProps, mapDispatchToProps)(FareSearchPartContainer);

export default FareSearchPartContainerWithCtx;