import { useLazyQuery } from "@apollo/react-hooks";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { QUERY_PASSED_ROUTES_ESTIMATE_TIMES, QueriedPassedRoutesEstimateTimes } from "../schema.gql";
import { EstimateStationInput } from "all-common-types";
import { client} from "constants/API";

function useQueryPassedRoutesEstimateTimes() {
  const {
    locale,
  } = useIntl();

  const [queryFn, { error, loading, data }] = useLazyQuery<QueriedPassedRoutesEstimateTimes>(QUERY_PASSED_ROUTES_ESTIMATE_TIMES, {
    client: client
  });

  const handleQuery = useCallback((estimateTimeTarget: EstimateStationInput[]) => {
    if(estimateTimeTarget.length > 0) {
      queryFn({
        variables: {
          lang: locale,
          targets: estimateTimeTarget,
        }
      });
    }
  }, [locale, queryFn]);

  return ({
    error,
    loading,
    handleQuery,
    data,
  });
};

export default useQueryPassedRoutesEstimateTimes;