import React from 'react';
import { Box } from '@material-ui/core';
import { TimeTableWithToggle } from 'components/driving-map/components/BusMap/TimeTable';
import { useStyles } from 'components/driving-map/components/BusMap/styles/BusMapPart';
import { BusMapProps } from 'components/driving-map/components/BusMap/BusMapPart/BusMapWrapper';
import { BusMapWrapper, BusMapContent } from 'components/driving-map/components/BusMap/BusMapPart/index';
import { Callback, ID } from 'all-common-types';
import { RouteDataWithBusInstantInfoList, SelectedRoute } from 'components/driving-map/constants/context';
import getBusInstantInfoListFromRouteData from '../functions/getBusInstantInfoListFromRouteData';
import ClogMessagePart from '../ClogMessage/ClogMessagePart';
import { ClogMessagePartProps } from '../ClogMessage/types';
import ClogMessageToggleHandler from 'components/driving-map/lib/ClogMessageToggleHandler';
import useBusMapWithRoute from './useBusMapWithRoute';

export type BusMapWithRouteContainerProps = BusMapProps & {
  routeData: RouteDataWithBusInstantInfoList
  selectedRoute?: SelectedRoute | undefined
}
export type BusMapWithRouteProps = BusMapWithRouteContainerProps & {
  routeId: ID
  isClogLoading: boolean
  handleToggle: Callback,
  toggledDisplay: boolean
} & Omit<ClogMessagePartProps, ''>

export const BusMapWithRoute = (props: BusMapWithRouteProps) => {
  const classes = useStyles();
  const {
    clogMessages,
    routeData,

    onToggleClog,
    toggledClog,

    handleToggle,
    toggledDisplay,
  } = props;

  const busInstantInfoList = getBusInstantInfoListFromRouteData(routeData.busInfos);
  const haveClogs = ClogMessageToggleHandler.checkHaveClogs(clogMessages);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      className={ classes.root }
    >
      <BusMapWrapper {...props}>
        <BusMapContent
          {...props}
          busIntantInfoList={busInstantInfoList}
          displayTimeTableFn={ handleToggle }
          haveClogs={haveClogs}
        />
      </BusMapWrapper>
      <TimeTableWithToggle
        routeDataForTimeTable={props.routeData}
        toggledDisplay={toggledDisplay}
        toggleFn={handleToggle} />
      <ClogMessagePart
        toggledClog={toggledClog}
        onToggleClog={onToggleClog}
        clogMessages={clogMessages}
      />
    </Box>
  );
};

export const BusMapWithRouteContainer = (props: BusMapWithRouteContainerProps) => {
  const { routeData } = props;
  const { id } = routeData;
  const {
    isClogLoading,
    clogMessages,
    handleClogToggle,
    toggledClog,
    toggledTimeTableDisplay,
    handleToggleTimeTable,
  } = useBusMapWithRoute({
    ...props,
    routeId: id,
  });

  return (
    <BusMapWithRoute
      {...props}
      routeData={routeData}
      routeId={id}
      isClogLoading={isClogLoading}
      clogMessages={clogMessages}
      onToggleClog={handleClogToggle}
      toggledClog={toggledClog}
      toggledDisplay={toggledTimeTableDisplay}
      handleToggle={handleToggleTimeTable}
    />
  );
};



export default BusMapWithRouteContainer;