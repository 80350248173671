import { StationContentMode } from "mrt-pages";
import checkStationContentMode from "./checkStationContentMode";

const getContentStr = (
  contentMode: StationContentMode, 
  fareOrTime: number | string, 
  isSameType=true
) => {
  return fareOrTime;
  // if(!fareOrTime || fareOrTime === '-' || isSameType) {
  //   return fareOrTime;
  // }

  // switch (checkStationContentMode(contentMode)) {
  //   case 'FARE':  
  //     return `+${fareOrTime}$`;
  
  //   case 'TRAVEL_TIME':
  //     return `+${fareOrTime}`;

  //   default:
  //     return '';
  // }
};

export default getContentStr;