import getReportDescriptionOptions, { ReportDescriptionOptionKeys } from "components/stop-report-page/static/getReportDescriptionOptions";
import useSelectorSelect from "lib/customHooks/useSelectorSelect";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

export interface UseReportDescriptionSelectorOptions {
  onSetDescription: (desc: string) => any
}

const useReportDescriptionSelector = ({
  onSetDescription,
}: UseReportDescriptionSelectorOptions) => {
  const { locale } = useIntl();
  const options = getReportDescriptionOptions(locale as any);

  const selectorSelectstate = useSelectorSelect({
    'descriptionOption': '' as ReportDescriptionOptionKeys,
  });

  const state = useMemo(() => ({
    value: selectorSelectstate.values.descriptionOption,
    onChange: selectorSelectstate.handleSelect('descriptionOption'),
  }), [selectorSelectstate]);

  const description = useMemo(() => {
    const matchedOption = options.find(op => op.value === state.value);
    if(state.value === 'other' || !matchedOption) {
      return '';
    }
    return matchedOption.text;
  }, [options, state.value]);

  useEffect(() => {
    onSetDescription(description);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  return state;
};

export default useReportDescriptionSelector;