import React, {  } from 'react';
import StopSearchContainerWithCtx from './StopSearchContainer';
import StopSearchedRouteInfoListContainer, {  } from './StopSearched_RouteInfoList_Container';
import useStopSearchWithRouteInfo from '../functions/useStopSearchWithRouteInfo';
import { MapDispatchToProps } from 'lib/contextReduxLikeHelpers/mapContextToProps';
import { StopSearchWithRouteInfoProps, StopSearchWithRouteInfoDispatchesFromCtx, StopSearchWithRouteInfoStateFromCtx } from './types';
import { setRouteData } from 'components/collinear-routes/actions/stop-search-states-actions';
import { connectCtx } from 'react-function-helpers';
import ContextStore, { CollinearRoutesState } from 'components/collinear-routes/constants/context';
import { MapStateToProps } from 'ibus-common-components/lib/functions/mapContextToProps';
import { setFromStation } from 'components/collinear-routes/actions/from-station-id.actions';

const StopSearchWithRouteInfo = (props: StopSearchWithRouteInfoProps) => {
  const {
    handleQuery,
    depDestAndStopSearchedRouteInfos,
  } = useStopSearchWithRouteInfo(props);
  
  return (
    <>
      <StopSearchContainerWithCtx
        queryRouteInfoFn={handleQuery} />
      {depDestAndStopSearchedRouteInfos.length > 0 && (
        <StopSearchedRouteInfoListContainer 
          depDestAndStopSearchedRouteInfos={depDestAndStopSearchedRouteInfos} />
      )}
    </>
  );
};

interface OwnProps {}

const mapStateToProps: MapStateToProps<CollinearRoutesState, OwnProps, StopSearchWithRouteInfoStateFromCtx> = (state) => {
  return ({
    routeId: state.selectedRouteId,
    fromStation: state.selectedFromStation,
  });
};

const mapDispatchToProps: MapDispatchToProps<OwnProps, StopSearchWithRouteInfoDispatchesFromCtx> = (dispatch) => {
  return ({
    setRouteDataToCtx: (routeData) => {
      const action = setRouteData(routeData);
      dispatch(action);
      return action;
    },
    setFromStation: (payload) => {
      const action = setFromStation(payload);
      dispatch(action);
      return action;
    }
  });
};

const StopSearchWithRouteInfoWithCtx = connectCtx(ContextStore)(mapStateToProps, mapDispatchToProps)(StopSearchWithRouteInfo);

export default StopSearchWithRouteInfoWithCtx;