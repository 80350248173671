import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import { ClogMessageItemProps } from './types';

const ClogMessageItem = (props: ClogMessageItemProps) => {
  const {
    title,
    description,
  } = props;

  return (
    <Box
      paddingBottom={1}
    >
      <Typography variant={'h6'}>
        {title}
      </Typography>
      <Typography variant={'body1'}>
        {description}
      </Typography>
    </Box>
  );
};

export default ClogMessageItem;