import { useLazyQuery } from "@apollo/react-hooks";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { QueriedMrtFares, QUERY_MRT_FARES } from "../schema.gql";

const initData: QueriedMrtFares = {
  route309Fares: { edges: [], },
  route310Fares: { edges: [], },
  mrtGreenFares: { edges: [], },
};

function useQueryMrtFares() {
  const [queryFn, { error, loading, data }] = useLazyQuery<QueriedMrtFares>(QUERY_MRT_FARES, {
    errorPolicy: 'ignore', // 
  });
  const [queriedData, setData] = useState(data);

  const handleQuery = useCallback((busFromStationId: number, mrtFromStationId: number) => {
    if(busFromStationId && mrtFromStationId) {
      queryFn({
        variables: {
          busFromStationId,
          mrtFromStationId,
        }
      });
    } else {
      setData(initData);
    }
  }, [queryFn]);

  useEffect(() => {
    setData(data);
  }, [data]);

  return ({
    error,
    loading,
    data: queriedData,
    handleQuery,
  });
};

export default useQueryMrtFares;