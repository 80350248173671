import { nodeDataFromEdges, getPolyline, RoutesSelectorOptions } from 'constants/functions';
import { Position, RoutePoint, SingleStationWithPassedRoutes, SingleRouteStationNode } from 'bus-common-types';
import { RoutePassData, SingleRouteStopMarker } from '../components/BusMap/RouteStopMarker';
import { RouteStopMarkerContainerProps } from '../containers/BusMap/types';
import { RouteDataForMapPart } from 'driving-map';

export type MainSideRoutes = Array<{
  children: number[]
  xno: number
}>
export const getRoutesDataWithoutSubRoutes = (mainSideRoutes: MainSideRoutes, data: RoutesSelectorOptions): RoutesSelectorOptions => {
  let allChildrenRoutes: Array<number> = [];
  for (let i = 0; i < mainSideRoutes.length; i++) {
    const { children } = mainSideRoutes[i];
    allChildrenRoutes = [...allChildrenRoutes, ...children];
  }
  // console.log(allChildrenRoutes);
  return data.filter( d => !allChildrenRoutes.includes(parseInt(d.id)) );
};

// 因為routeId可能從props來，所以要找到主線的routeId，找不到則用原routeId
export const getMatchedRouteId = (
  mainSideRoutes: MainSideRoutes | null,
  selectedRouteId: string | undefined,
) => {
  const matchedMainSideRoute = (mainSideRoutes || []).find(r => r.children.includes(Number(selectedRouteId)));
  const matchedRouteId =
      matchedMainSideRoute ? matchedMainSideRoute.xno : selectedRouteId;
  return matchedRouteId;
};

export const getSubRoutesData = (
  originRoutes: RoutesSelectorOptions,
  mainSideRoutes: MainSideRoutes | null,
  selectedRouteId: string | undefined
) => {
  //due to mainSideRoutes is from db
  if(mainSideRoutes && mainSideRoutes.length > 0) {
    const matchedRouteId = getMatchedRouteId(mainSideRoutes, selectedRouteId);
    //only one main route id
    const mainRouteId = matchedRouteId && mainSideRoutes.find(d => d.xno === Number(matchedRouteId));
    if(mainRouteId) {
      const subAndMainRouteIds = [mainRouteId.xno, ...mainRouteId.children].map(id => id * 1);
      return originRoutes.filter(data => subAndMainRouteIds.includes(parseInt(data.id)));
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export class routeStopMarkersHelpers {
  static getRouteStopMarkersFromStationsOrStops = (routeData: RouteDataForMapPart, S: SingleRouteStationNode): {
    position: Position,
    routeStopData: SingleRouteStopMarker
  } => {
    return ({
      position: [S.lat, S.lon] as Position,
      routeStopData: {
        id: S.id,
        routeId: String(routeData.id),
        routeName: routeData.name,
        name: S.name,
      },
    });
  }

  static getRoutePassDataFromStation(station: SingleStationWithPassedRoutes): RoutePassData {
    const res = station.routes.edges.map((s, i) => {
      return ({
        ...s.node,
        goBack: s.goBack,
      });
    });
    return res;
  }

  static getRouteStopMarkersFromStations = (routeData: RouteDataForMapPart) => {
    const stations = nodeDataFromEdges(routeData.stations);

    const res = stations.map((station, index) => {

      return ({
        // ...station,
        ...routeStopMarkersHelpers.getRouteStopMarkersFromStationsOrStops(routeData, station),
        index,
        stationId: Number(station.id),
        sid: routeData.stations.edges[index].sid,
        goBack: routeData.stations.edges[index].goBack,
        // routePassData: routeStopMarkersHelpers.getRoutePassDataFromStation(station),
      });
    });

    return res;
  }
}


export const getRouteStopMarkers = (routeData: RouteDataForMapPart) => {
  const {
    getRouteStopMarkersFromStations: getStaions,
  } = routeStopMarkersHelpers;
  const {
    name,
    stations
  } = routeData;
  let markers: RouteStopMarkerContainerProps[] = getStaions(routeData);
  return markers;
  // return seperateGoBackDatas(markers);
};

export const getGoBackRouteline = (routePoint: RoutePoint) => ({
  go: getPolyline(routePoint.go),
  back: getPolyline(routePoint.back),
});
