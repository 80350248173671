import React from "react";
import { Map as LeafletMap, TileLayer } from "react-leaflet";
import { Box, makeStyles } from "@material-ui/core";
import { OtpItinerary, OtpLeg, SinglePlan } from "travel-planner";
import StepLines from "components/travel-planner/components/MapPart/StepLines";
import ContextStore, {
  TravelPlannerState,
} from "components/travel-planner/constant/context";
import "components/driving-map/style/style.scss";
import connectCtx from "lib/contextReduxLikeHelpers/connectCtx";
import { MapStateToProps } from "lib/contextReduxLikeHelpers/mapContextToProps";
import { defaultMapCenter } from "config";
import TOGSTileLayer from "../../tgos/TGOSTileLayer";
import { getPolyline } from "constants/functions";
import zIndex from "styles/styleObjs/zIndex";

type Props = {
  position?: [number, number];
  selectedPlan?: OtpItinerary;
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      height: '70vh',
    },
  },
}));

export const MapContainer = (props: Props) => {
  const { position = defaultMapCenter, selectedPlan } = props;
  const classes = useStyles();

  const bounds = selectedPlan && selectedPlan.legs
    ? selectedPlan.legs.reduce((arr: [number,number][], leg) => (
      arr.concat(getPolyline(leg.legGeometry.points))
    ),[])
    : undefined;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      className={classes.root}>
      <LeafletMap bounds={bounds} center={position} zoom={14} style={{
        zIndex: zIndex.popup,
      }}>
        <TOGSTileLayer opacity={0.4} />
        {selectedPlan && <StepLines {...selectedPlan} />}
      </LeafletMap>
    </Box>
  );
};

const mapStateToProps: MapStateToProps<
TravelPlannerState,
{},
{
  selectedPlan: TravelPlannerState["selectedPlan"];
}
> = (state) => {
  return {
    selectedPlan: state.selectedPlan,
  };
};
const MapContainerWithCtx = connectCtx(ContextStore)(mapStateToProps)(
  MapContainer
);

export default MapContainerWithCtx;
