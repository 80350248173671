import { actionTypes as Types } from './index';

type SetRouteStopIdAction = {
  type: Types.SET_ROUTE_STOP_ID
  routeStopId: number
}
type ResetRouteStopIdAction = {
  type: Types.RESET_ROUTE_STOP_ID
}

export const setRouteStopId = (routeStopId: number): SetRouteStopIdAction => ({
  type: Types.SET_ROUTE_STOP_ID,
  routeStopId,
});
export const resetRouteStopId = (): ResetRouteStopIdAction => ({
  type: Types.RESET_ROUTE_STOP_ID,
});

export type RouteListItemActionTypes = SetRouteStopIdAction | ResetRouteStopIdAction