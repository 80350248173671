import React from 'react';
import { Box, Divider } from '@material-ui/core';
import BusProvidersSelector from './BusProvidersSelector';
import RoutesFilterSelector from './RoutesFilterSelector';
import useSelectsPart from './useSelectsPart';
import { MapDispatchToProps } from 'react-function-helpers/lib/functions/mapContextToProps';
import { SelectsPartProps, SelectsPartWithCtxProps, SelectsPartDispatches } from './types';
import { setRouteId } from 'components/route-map/actions/route-map-actions';
import { connectCtx } from 'react-function-helpers';
import ContextStore from 'components/route-map/constants/context';

const SelectsPart = (props: SelectsPartProps) => {
  const {
    setRouteIdFn,
  } = props;
  
  const {
    setId,
    busProviderId,
  } = useSelectsPart();

  return (
    <Box>
      <BusProvidersSelector
        setSelectedBusProviderFn={setId}
      />
      <Box width={'100%'}>
        <RoutesFilterSelector
          busProviderId={busProviderId as any}
          setRouteIdFn={setRouteIdFn}
        />
      </Box>
      <Divider />
    </Box>
  );
};

interface OwnProps extends SelectsPartWithCtxProps {}

const mapDispatchToProps: MapDispatchToProps<OwnProps, SelectsPartDispatches> = (dispatch) => {
  return ({
    setRouteIdFn: (routeId) => {
      const action = setRouteId({ routeId });
      dispatch(action);
    }
  });
};

const SelectsPartWithCtx = connectCtx(ContextStore)(undefined, mapDispatchToProps)(SelectsPart);

export default SelectsPartWithCtx;