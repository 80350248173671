import React, { ReactNode } from 'react';
import { StepType } from "travel-planner";
import { ElementType } from "react";
import { MyLocation, TripOrigin, RoomRounded } from "@material-ui/icons";
import { Position } from 'bus-common-types';

export const ICON_WIDTH_BASIC = 24;

const IMAGE_STEP_ICON_FIRST = 'my_location-24px.svg';
const IMAGE_STEP_ICON_NORMAL = 'trip_origin-24px.svg';
const IMAGE_STEP_ICON_LAST = 'room-24px.svg';

const ICON_SIZE_STEP_ICON_CIRCLE_TYPE: Position = [25, 25];
const ICON_SIZE_STEP_ICON_CIRCLE_TYPE_2: Position = [20, 20];
const ICON_SIZE_STEP_ICON_REC_TYPE: Position = [30, 30];

const getIconAnchor = (iconSize: Position): Position => ([
  iconSize[0] / 2,
  iconSize[1] / 2
]);

const ICON_ANCHOR_STEP_ICON_CIRCLE_TYPE: Position = getIconAnchor(ICON_SIZE_STEP_ICON_CIRCLE_TYPE);
const ICON_ANCHOR_STEP_ICON_CIRCLE_TYPE_2: Position = getIconAnchor(ICON_SIZE_STEP_ICON_CIRCLE_TYPE_2);
const ICON_ANCHOR_STEP_ICON_REC_TYPE: Position = [
  ICON_SIZE_STEP_ICON_REC_TYPE[0] / 2,
  ICON_SIZE_STEP_ICON_REC_TYPE[1]
];

type StepIconsAndImgSrcs = {
  [x in StepType]: {
    IconComponent: () => ReactNode,
    imgSrc: string
    iconSize: Position
    iconAnchor: Position
  }
}
export const stepIconsAndImgSrcs: StepIconsAndImgSrcs = {
  'first': {
    IconComponent: () => <MyLocation />,
    imgSrc: IMAGE_STEP_ICON_FIRST,
    iconSize: ICON_SIZE_STEP_ICON_CIRCLE_TYPE,
    iconAnchor: ICON_ANCHOR_STEP_ICON_CIRCLE_TYPE
  },
  'normal': {
    IconComponent: () => <TripOrigin />,
    imgSrc: IMAGE_STEP_ICON_NORMAL,
    iconSize: ICON_SIZE_STEP_ICON_CIRCLE_TYPE_2,
    iconAnchor: ICON_ANCHOR_STEP_ICON_CIRCLE_TYPE_2
  },
  'last': {
    IconComponent: () => <RoomRounded />,
    imgSrc: IMAGE_STEP_ICON_LAST,
    iconSize: ICON_SIZE_STEP_ICON_REC_TYPE,
    iconAnchor: ICON_ANCHOR_STEP_ICON_REC_TYPE
  },
};

export const POLYLINE_DASHED_DENSITY = '8';