import React, { Ref } from 'react';
import { Box } from '@material-ui/core';
import { FilterSelectorContainerProps } from 'ibus-common-components/lib/components/common-components/FilterSelector/types';
import IntlFilterSelector from 'components/common-components/IntlComponents/IntlFilterSelector';


export interface RoutesFilterSelectorProps extends FilterSelectorContainerProps {

}
const RoutesFilterSelector = (props: RoutesFilterSelectorProps) => {
  return (
    <Box>
      <IntlFilterSelector
        {...props}
      />
    </Box>
  );
};

export default RoutesFilterSelector;