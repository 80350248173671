import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Provider from './components/containers/Provider';
import ContextStore, { ContextValue } from 'constants/context';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { cmsClient } from 'constants/API';
import { Locale } from 'all-common-types';
import LocaleLocalStorage from 'lib/LocaleLocalStorage';
import ThemeLocalStorage from 'lib/Handlers/ThemeLocalStorage';
import DisplayModeHandlers from 'lib/Handlers/DisplayModeHandlers';
import 'styles/styles.scss';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


const version = process.env.REACT_APP_VERSION;
console.log(`Version: ${version}`);

export const useLocale = (initLocale='zh') => {
  const LSlocale = LocaleLocalStorage.getLocale();
  const initCustomLocale = LSlocale ? LSlocale : (initLocale ? initLocale : 'zh');

  const [locale, setLocale] = useState<Locale>(initCustomLocale as Locale);

  const handleSetLocale = useCallback((locale: Locale) => {
    setLocale(locale);
    LocaleLocalStorage.setLocale(locale);
  }, []);

  return ({
    locale,
    handleSetLocale,
  });
};

const useIndex = () => {
  const localeState = useLocale();

  useEffect(() => {
    DisplayModeHandlers.detectDisplayModeAndSetViewport();
  }, []);

  return ({
    ...localeState,
  });
};

const Index = () => {
  const {
    locale,
    handleSetLocale,
  } = useIndex();

  const value = ContextValue();

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ThemeProvider
        theme={ThemeLocalStorage.getTheme()}>
        <CssBaseline />
        <Provider
          key={locale}
          locale={locale}
          client={cmsClient}
        >
          <ContextStore.Provider value={value}>
            <App
              locale={ locale }
              setLocale={ handleSetLocale } />
          </ContextStore.Provider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
};


ReactDOM.render(
  <Index />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
