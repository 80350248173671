import { SingleLink } from "all-common-types";
import { splitPath } from "../fn";
import { MatchedRoute } from "components/common-components/breadCrumbs";

export type MatchedResult = string | false
export type MatchFn = (params: PathsAndLinks) => MatchedResult

export type PathsAndLinks = {
  links: SingleLink[], 
  splitpath: string, 
  joinedPath: string
}
export type MatchedRouteFn = (params: PathsAndLinks) => MatchedRoute
export type GetBreadCrumbNameParams = {
  splitpath: string,
  primaryMatched: MatchedResult,
  secondaryMatched: MatchedResult,
}

class handleRouteMatched {
  static getLinkMatched(links: SingleLink[], joinedPath: string) {
    const linkMatched = links.find(link => joinedPath === link.link);
    return linkMatched ? linkMatched.title : false;
  }

  static getBreadCrumbName({
    splitpath, primaryMatched, secondaryMatched
  }: GetBreadCrumbNameParams) {
    if(typeof primaryMatched === 'string') {
      return primaryMatched;
    }
    else if(typeof secondaryMatched === 'string') {
      return secondaryMatched;
    }
    return splitpath;
  }

  static getMatchedRoute(matchResultFn: MatchFn) {
    return (params: PathsAndLinks) => {
      const { splitpath, joinedPath, links } = params;
      const primaryMatched = matchResultFn(params);
      const linkMatched = handleRouteMatched.getLinkMatched(links, joinedPath);
      const breadCrumbName = handleRouteMatched.getBreadCrumbName({
        splitpath,
        primaryMatched,
        secondaryMatched: linkMatched,
      });
      return ({
        path: joinedPath,
        breadCrumbName,
      });
    };
  }

  static getMatchedRouteList = (matchedRouteFn: MatchedRouteFn) => (locationNow: string) => {
    const {
      joinedPaths,
      splitPaths, 
    } = splitPath(locationNow);
    return (links: SingleLink[]): MatchedRoute[] => {
      let res: MatchedRoute[] = [];
      splitPaths.forEach((splitpath, i) => {
        const joinedPath = joinedPaths[i];
        const matchedRoute = matchedRouteFn({ joinedPath, splitpath, links });
        res = [...res, matchedRoute];
      });
      return res;
    };
  }
}

export default handleRouteMatched;