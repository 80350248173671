import React from 'react';
import { Box, makeStyles, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core';

const useStyles_singleQRcodeItem = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 120,
    // padding: theme.spacing(1),
    transition: '0.2s',
    borderColor: 'transparent',
    borderWidth: 0.5,
    borderStyle: 'solid',
    '&:hover': {
      // opacity: 0.75,
      transition: '0.2s',
      borderColor: theme.palette.primary.main,
    },
    '& img': {
      width: '100%',
      display: 'block',
    }
  }
}));


type SingleQRcodeItemProps = import('blog').SingleQRcode
export const SingleQRcodeItem = ({
  qrcodeSrc,
  qrcodeBaseImgSrc,
  url,
}: SingleQRcodeItemProps) => {
  const classes = useStyles_singleQRcodeItem();
  return (
    <Box
      paddingRight={0.5}
      paddingLeft={0.5}
    >
      <Box
        className={classes.root}
        // paddingRight={1}
        borderRadius={4}
        overflow={'hidden'}
      >
        <Link
          display={'block'}
          target={'_blank'}
          href={url}
        >
          <img src={qrcodeSrc} alt={'qr-code'} />
          <img src={qrcodeBaseImgSrc} alt={'qrcode base'} />
        </Link>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  imgPart: {
    maxWidth: 200,
    width: `${50}%`,
    display: 'block',
    margin: 'auto',
  }
}));

export type QRcodesProps = {
  qrcodesData: SingleQRcodeItemProps[]
}
const QRcodes = ({
  qrcodesData
}: QRcodesProps) => {
  const {
    homepageImages,
  } = useTheme();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box display={'flex'}>
        {qrcodesData.map((qrcode, i) => (
          <SingleQRcodeItem key={i} {...qrcode} />
        ))}
      </Box>
      {homepageImages.bottom && (
        <Box padding={2}>
          <img
            className={classes.imgPart}
            src={homepageImages.bottom}
            alt={'bottom-img'}
          />
        </Box>
      )}
    </Box>
  );
};

export default QRcodes;