import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const useStyles = makeStyles(theme => ({
  root: {
     
  },
  text: {
    color: theme.palette.primary.dark,
  }
}));

const RouteInfoDescription = () => {
  const classes = useStyles();

  return (
    <Box paddingY={0.5}>
      <IntlFormattedMessage
        className={classes.text}
        langKey={'collinearRoutes.routeInfo.info'}
        variant={'subtitle1'}
      />
    </Box>
  );
};

export default RouteInfoDescription;