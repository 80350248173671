import React, { ReactNode } from "react";
import IbikeIconItem from "../IbikeIconItem";
import THSRIconItem from "../THSRIconItem";
import { OtherTransportationsItemProps } from "../types";
import Youbike2IconItem from "../Youbike2IconItem";
import HandleStopIsNearTMRT from "./HandleStopIsNearTMRT";
import OtherTransportationIcon from "../OtherTransportationIcon";

type OtherTransportationsComponentKeys =
  | "IBIKE"
  | "YOUBIKE_2"
  | "THSR"
  | "MRT"
  | "LANTERN";

const OtherTransportationsKeysComponents: {
  [key in OtherTransportationsComponentKeys]: ReactNode;
} = {
  LANTERN: (
    <OtherTransportationIcon
      isShowIcon={true}
      imageKey={"icons_lantern"}
      title={"Latern"}
    />
  ),
  IBIKE: <IbikeIconItem haveIbike={true} />,
  YOUBIKE_2: <Youbike2IconItem haveYoubike={true} />,
  MRT: HandleStopIsNearTMRT.getTMRTIcon(true),
  THSR: <THSRIconItem haveTHSR={true} />,
};

// 要照順序
const propsList: (keyof OtherTransportationsItemProps)[] = [
  "haveLantern",
  "haveIbike",
  "haveYoubike",
  "haveMRT",
  "haveTHSR",
];

const propsToComponentKeys: {
  [key in keyof OtherTransportationsItemProps]: OtherTransportationsComponentKeys;
} = {
  haveLantern: "LANTERN",
  haveIbike: "IBIKE",
  haveYoubike: "YOUBIKE_2",
  haveMRT: "MRT",
  haveTHSR: "THSR",
};

// 另一種方法
// const OtherTransportationsComponentsKeyComponents: {
//   key: OtherTransportationsComponentKeys,
//   propKey: keyof OtherTransportationsItemProps,
//   component: ReactNode,
// }[] = [
//   {
//     key: 'IBIKE',
//     propKey: 'haveIbike',
//     component: OtherTransportationsKeysComponents.IBIKE,
//   },
//   {
//     key: 'YOUBIKE_2',
//     propKey: 'haveYoubike',
//     component: OtherTransportationsKeysComponents.YOUBIKE_2,
//   },
//   {
//     key: 'MRT',
//     propKey: 'haveMRT',
//     component: OtherTransportationsKeysComponents.MRT,
//   },
//   {
//     key: 'THSR',
//     propKey: 'haveTHSR',
//     component: OtherTransportationsKeysComponents.THSR,
//   },
// ]

const OtherTransportationsHandlers = {
  getComponent(
    haveTransportation: boolean,
    prop: keyof OtherTransportationsItemProps
  ) {
    if (haveTransportation) {
      const componentKey = propsToComponentKeys[prop];
      return OtherTransportationsKeysComponents[componentKey];
    }
    return undefined;
  },

  getComponents(props: OtherTransportationsItemProps) {
    let res: ReactNode[] = [];
    propsList.forEach((prop) => {
      const haveTransportation = props[prop];
      const component = this.getComponent(haveTransportation, prop);
      component && res.push(component);
    });
    return res;
  },
};

export default OtherTransportationsHandlers;
