import React, { memo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import IntlFormattedMessage from 'components/common-components/IntlFormattedMessage';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1),
    // maxWidth: 300,
  }
}));

const MrtDescription = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <IntlFormattedMessage
        langKey={'mrtFare.description.title'}
        variant={'h6'}
      />
      <IntlFormattedMessage
        langKey={'mrtFare.description.descp1'}
      />
      <IntlFormattedMessage
        langKey={'mrtFare.description.descp2'}
      />
    </Box>
  );
};

export default memo(MrtDescription);