import { GoBack } from "bus-common-types";
import { useCallback, useState } from "react";

const useGoBackSwitch = (initChecked=false) => {
  const [checked, setChecked] = useState(initChecked);
  const goBack = checked ? 2 : 1 as GoBack;

  const handleChangeGoBackSwitch = useCallback((e: any, checked: boolean) => {
    setChecked(checked);
  }, []);

  return ({
    checked,
    setChecked,
    goBack,
    handleChangeGoBackSwitch,
  });
};

export default useGoBackSwitch;
